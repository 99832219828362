type Props = {
  className?: string;
};

const MasterCardSmIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="18"
      fill="none"
      viewBox="0 0 24 14"
      className={className}
    >
      <path fill="#FF5F00" d="M14.708 3.196H9.84v7.951h4.868V3.196z"></path>
      <path
        fill="#EB001B"
        d="M10.34 7.173a5.048 5.048 0 011.933-3.975 5.057 5.057 0 100 7.951 5.047 5.047 0 01-1.932-3.976z"
      ></path>
      <path
        fill="#F79E1B"
        d="M19.97 10.31v-.162h.072v-.042h-.167v.034h.066v.163l.03.008zm.325 0v-.204h-.05l-.06.14-.059-.14h-.05v.197h.037v-.15l.055.128h.038l.055-.128v.15l.034.008zM20.454 7.172a5.057 5.057 0 01-8.181 3.975 5.057 5.057 0 000-7.951 5.056 5.056 0 018.181 3.975v.001z"
      ></path>
    </svg>
  );
}

export default MasterCardSmIcon;