import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBirthdate } from 'src/hooks/useBirthdate';
import { RootState } from '../index';

interface PreApproveLoanOtherInfoState {
    nationality: string;
    visaType: string;
    birthdate: IBirthdate|undefined;
    email: string;
    mobileNumber: string;
    currentAddress: string;
    currentRegion: string;
    maritalStatus: string;
    isSpouseCosign: boolean;
    saveToStorage: boolean;
    formErrors: Record<string, string>;
}

const savePreApproveLoanOtherInfoState = (info: PreApproveLoanOtherInfoState) => {
    if (window.sessionStorage) {
        const { formErrors, saveToStorage, ...others } = info;
        window.sessionStorage.setItem('preApproveLoanOtherInfoState', JSON.stringify(others));
    }
};

const loadPreApproveLoanOtherInfoState = (): PreApproveLoanOtherInfoState => {
    try {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            const serializedState = window.sessionStorage.getItem('preApproveLoanOtherInfoState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
                parsedState.formErrors = {};
                parsedState.saveToStorage = true;

                return parsedState as PreApproveLoanOtherInfoState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        nationality: 'Filipino',
        visaType: '',
        birthdate: undefined,
        email: '',
        mobileNumber: '',
        currentAddress: '',
        currentRegion: '',
        maritalStatus: '',
        isSpouseCosign: false,
        saveToStorage: true,
        formErrors: {}
    };
}

const initialState: PreApproveLoanOtherInfoState = {
    nationality: 'Filipino',
    visaType: '',
    birthdate: undefined,
    email: '',
    mobileNumber: '',
    currentAddress: '',
    currentRegion: '',
    maritalStatus: '',
    isSpouseCosign: false,
    saveToStorage: true,
    formErrors: {}
};

export const preApproveLoanOtherInfoSlice = createSlice({
    name: 'preApproveLoanOtherInfo',

    initialState,

    reducers: {
        setNationality: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.nationality = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setVisaType: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.visaType = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setBirthdate: (state: PreApproveLoanOtherInfoState, action: PayloadAction<IBirthdate>) => {
            state.birthdate = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setEmail: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.email = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setMobileNumber: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.mobileNumber = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setCurrentAddress: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.currentAddress = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setCurrentRegion: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.currentRegion = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setMaritalStatus: (state: PreApproveLoanOtherInfoState, action: PayloadAction<string>) => {
            state.maritalStatus = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setIsSpouseCosign: (state: PreApproveLoanOtherInfoState, action: PayloadAction<boolean>) => {
            state.isSpouseCosign = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanOtherInfoState(state);
            }
        },

        setSaveToStorage: (state: PreApproveLoanOtherInfoState, action: PayloadAction<boolean>) => {
            state.saveToStorage = action.payload;
        },

        loadFromStorage: (state: PreApproveLoanOtherInfoState, action: PayloadAction<{ email?: string, mobileNumber?: string }>) => {
            const state2 = loadPreApproveLoanOtherInfoState();

            if (action.payload.email) {
                state2.email = action.payload.email;
            }

            if (action.payload.mobileNumber) {
                state2.mobileNumber = action.payload.mobileNumber;
            }

            return state2;
        },

        setState: (state: PreApproveLoanOtherInfoState, action: PayloadAction<Partial<PreApproveLoanOtherInfoState>>) => {
            state.nationality = action.payload.nationality || state.nationality;
            state.visaType = action.payload.visaType || state.visaType;
            state.birthdate = action.payload.birthdate || state.birthdate;
            state.email = action.payload.email || state.email;
            state.mobileNumber = action.payload.mobileNumber || state.mobileNumber;
            state.currentAddress = action.payload.currentAddress || state.currentAddress;
            state.currentRegion = action.payload.currentRegion || state.currentRegion;
            state.maritalStatus = action.payload.maritalStatus || state.maritalStatus;
            state.saveToStorage = action.payload.saveToStorage || state.saveToStorage;
            state.formErrors = {};
        },

        setDefaultFormValues: (state: PreApproveLoanOtherInfoState) => {
            state.nationality = 'Filipino';
            state.visaType = '';
            state.birthdate = undefined;
            state.email = '';
            state.mobileNumber = '';
            state.currentAddress = '';
            state.currentRegion = '';
            state.maritalStatus = '';
            state.isSpouseCosign = false;

            savePreApproveLoanOtherInfoState(state);
        },

        setFormErrors: (state: PreApproveLoanOtherInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        }
    }
});

export const {
    setNationality,
    setVisaType,
    setBirthdate,
    setEmail,
    setMobileNumber,
    setCurrentAddress,
    setCurrentRegion,
    setMaritalStatus,
    setIsSpouseCosign,
    setSaveToStorage,
    loadFromStorage,
    setState,
    setFormErrors,
    setDefaultFormValues
} = preApproveLoanOtherInfoSlice.actions;

export const selectNationality = (state: RootState) => state.preApproveLoanOtherInfo.nationality;
export const selectVisaType = (state: RootState) => state.preApproveLoanOtherInfo.visaType;
export const selectBirthdate = (state: RootState) => state.preApproveLoanOtherInfo.birthdate;
export const selectEmail = (state: RootState) => state.preApproveLoanOtherInfo.email;
export const selectMobileNumber = (state: RootState) => state.preApproveLoanOtherInfo.mobileNumber;
export const selectCurrentAddress = (state: RootState) => state.preApproveLoanOtherInfo.currentAddress;
export const selectCurrentRegion = (state: RootState) => state.preApproveLoanOtherInfo.currentRegion;
export const selectMaritalStatus = (state: RootState) => state.preApproveLoanOtherInfo.maritalStatus;
export const selectIsSpouseCosign = (state: RootState) => state.preApproveLoanOtherInfo.isSpouseCosign;
export const selectFormErrors = (state: RootState) => state.preApproveLoanOtherInfo.formErrors;

export default preApproveLoanOtherInfoSlice.reducer;