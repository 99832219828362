import CircleCheckSolidIcon from 'src/components/icons/CircleCheckSolidIcon';
import { useNavigate } from 'react-router-dom';
import GCashSmIcon from 'src/components/icons/GCashSmIcon';
import MayaSmIcon from 'src/components/icons/MayaSmIcon';
import UnionBankSmIcon from 'src/components/icons/UnionBankSmIcon';
import RcbcSmIcon from 'src/components/icons/RcbcSmIcon';
import ChinaBankSmIcon from 'src/components/icons/ChinaBankSmIcon';
import PaymentOptIcons from 'src/page_components/pay-reservation/PaymentOptIcons';

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const paymentChannel = window.localStorage.getItem('payment_channel');
    const paymentDate = (new Date(window.localStorage.getItem('payment_date') || ''));
    const month = paymentDate.toLocaleString('en-US', {month: 'long'});
    const day = paymentDate.toLocaleString('en-US', {day: 'numeric'});
    const year = paymentDate.toLocaleString('en-US', {year: 'numeric'});
    const time =paymentDate.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});


    return (
        <>
            <div className={`w-full h-[800px] md:h-full bg-white flex justify-center items-center text-black`}>
                <div className="w-full px-3 md:w-1/2">
                    <div className="flex justify-center items-center flex-col mb-6">
                        <CircleCheckSolidIcon className="w-36 mb-4 text-green-500"/>
                        <h2 className="text-3xl font-bold text-center">Payment Successful!</h2>
                    </div>
                    <div className="flex gap-3 md:gap-6 flex-col">
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                            <div className="border-2  w-full">
                                <div className="bg-uwi-primary-100 text-white text-center p-4 md:p-3">Amount Paid</div>
                                <div className="text-base md:text-sm p-4 md:p-3 text-center font-bold">
                                    {parseFloat(window.localStorage.getItem('payment_amount') || '').toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                </div>
                            </div>
                            <div className="border-2  w-full">
                                <div className="bg-uwi-primary-100 text-white text-center p-4 md:p-3">Date Paid</div>
                                <div className="text-base md:text-sm p-4 md:p-3 text-center font-bold">
                                    {day} {month} {year} {time}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-3 md:gap-6">
                            <div className="border-2 w-full">
                                <div className="bg-uwi-primary-100 text-white text-center p-4 md:p-3">Payment Channel</div>
                                <div className="text-base md:text-sm p-4 md:p-3 flex justify-center items-center">
                                    <div className="rounded-md border-[1px] px-3 py-[2px] mr-2">
                                        <PaymentOptIcons icon={paymentChannel || ''} className="w-5 h-5" isSmall={true} />
                                    </div>
                                    {window.localStorage.getItem('payment_method')}
                                </div>
                            </div>
                            <div className="border-2 w-full">
                                <div className="bg-uwi-primary-100 text-white text-center p-4 md:p-3">Reference Number</div>
                                <div className="text-base md:text-sm p-4 md:p-3 text-center">{window.localStorage.getItem('payment_reference_id')}</div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <button
                            className={`
                                w-[calc(50%-8px)] md:w-[170px]
                                flex justify-center items-center rounded-2xl
                                text-[15px] font-bold
                                text-white px-8 py-4
                                bg-uwi-primary-100 hover:bg-uwi-primary-200
                                disabled:opacity-20
                                mt-4
                            `}
                            onClick={() => navigate('/dashboard-payment-reservations')}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentSuccessPage;
