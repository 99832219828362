import React, { useEffect, useMemo, useState } from 'react';
import Container from 'typedi';
import { useNavigate } from 'react-router-dom';
import { PaidProperty, PaymentApi } from 'src/api/PaymentApi';
import { PreQualificationApi } from 'src/api/PreQualificationApi';
import { PaymentMethodsApi, Rule } from 'src/api/PaymentMethodsApi';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { AngleDownSolidIcon, CircleLoaderIcon, DirectDebitIcon, EWalletIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import paymentMethodStaticData from 'src/data/paymentMethods.json';
import { Select, SelectOption } from 'src/components/common';
import PaymentOptIcons from 'src/page_components/pay-reservation/PaymentOptIcons';
import PaymentError from 'src/page_components/pay-reservation/PaymentError';
import useToast from 'src/hooks/useToast';
import CardIcon from 'src/components/icons/CardIcon';

declare global {
    interface Window {
        Xendit: {
            setPublishableKey: (key: string) => void;
            card: {
                validateCardNumber: (cardNumber: string) => boolean;
                validateExpiry: (expMonth: string, expYear: string) => boolean;
                validateCvn: (cvn: string) => boolean;
                createToken: (
                    cardData: {
                        amount: number;
                        card_number: string;
                        card_exp_month: string;
                        card_exp_year: string;
                        card_cvn: string;
                        is_multiple_use: boolean;
                    },
                    callback: (error: any, response: any) => void
                ) => void;
            };
        };
    }
}


const url = new URL(window.location.toString());
const params = new URLSearchParams(url.search);

interface PaymentMethodSelectOption {
    channelCode: string;
    name: string;
    title: string;
    image: string;
    disabled: boolean;
    transactionFeeRule: Rule[]|null;
    paymentFeeRule: Rule|null;
}

interface PaymentMethodSelect {
    name: string;
    title: string;
    options: PaymentMethodSelectOption[]
}

type Props = {
    title: string;
    name: string;
    type: string;
    disabled?: boolean;
    channelCode: string;
    onClick: (type: string, name: string) => void;
};

type GroupProps = {
    children: JSX.Element;
    title: string;
    expanded: string;
    onExpand: (title: string) => void;
    options: PaymentMethodSelectOption[]
};

const head = document.getElementsByTagName("head")[0];

var xenditScript = document.createElement("script");
xenditScript.type = "text/javascript";
xenditScript.src = "https://js.xendit.co/v1/xendit.min.js";
head.appendChild(xenditScript);

const PaymentOptGroupIcons = ({className, icon} : {className?: string, icon: string}) => {
    return (
        <>
            {icon === 'E-Wallet' && (
                <EWalletIcon className={className}/>
            )}

            {icon === 'Direct Debit' && (
                <DirectDebitIcon className={className}/>
            )}

            {icon === 'Credit Card' && (
                <CardIcon className={className}/>
            )}
        </>
    );
}

const PaymentOptionGroup = ({children, title, expanded, onExpand, options} : GroupProps) => {

    return (
        <div className="w-full mb-4 border-2 rounded-lg px-2 py-1 relative" onClick={() => {
            onExpand(expanded === title ? '' : title);
        }}>
            <div className="text-left flex justify-between cursor-pointer items-center">
                <div className="flex items-center">
                    <PaymentOptGroupIcons className="mr-2 h-7" icon={title}/>
                    {title}
                </div>
                <div className="flex items-center">
                    <div className="flex gap-1">
                        {(title !== 'Credit Card') ? (
                            <>
                                {options.map((option, idx) => (
                                    <React.Fragment key={option.channelCode}>
                                        {idx < 2 && (
                                            <div className="rounded-md border-[1px] px-3 py-[2px]">
                                                <PaymentOptIcons className="w-5 h-5" icon={option.channelCode} isSmall={true}/>
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </>
                        ): (
                            <>
                                <div className="rounded-md border-[1px] px-3 py-[2px]">
                                    <PaymentOptIcons className="w-5 h-5" icon="MASTERCARD" isSmall={true}/>
                                </div>
                                <div className="rounded-md border-[1px] px-3 py-[2px]">
                                    <PaymentOptIcons className="w-5 h-5" icon="VISA" isSmall={true}/>
                                </div>
                            </>
                        )}

                        {options.length > 2 && (
                            <div className="font-bold text-gray-100 border-[1px] px-3 py-1 text-xs rounded-md flex items-center">
                                &#43;{options.length - 2}
                            </div>
                        )}
                    </div>
                    <div>
                        <AngleDownSolidIcon className={`w-4 h-4 ml-2 ${expanded === title ? 'rotate-180' : ''}`}/>
                    </div>
                </div>
            </div>
            <div className={`w-full flex justify-center py-4 px-2 md:p-4
                ${expanded === title ? 'flex' : 'hidden'}
                absolute top-0 left-0 mt-[50px]
                bg-white rounded-lg shadow-lg z-10 flex-wrap gap-3 justify-center
            `}>
                {children}
            </div>
        </div>
    );
}

const PaymentOption = ({title, name, disabled, type, channelCode, onClick} : Props) => {
    return (
        <div className="flex w-[125px] md:w-auto">
            <button
                type="button"
                className={`
                    w-full flex flex-col items-center
                    ${disabled ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'}
                    hover:bg-gray-400/10
                    px-2 md:px-4 py-2 border-2 border-gray-100/20
                `}
                onClick={() => onClick(type, name)}
                disabled={disabled}
        >
                <PaymentOptIcons icon={channelCode} className="h-6 md:h-9"/>
            </button>
        </div>
    );
}

const PayReservationPage = () => {
    const navigate = useNavigate();
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState((params.get('uwi_redirect') === 'payment-fail'));
    const [error, setError] = useState('An unexpected error occurred. Please try again later.');
    const [payments, setPayments] = useState<PaymentMethodSelect[]>([]);
    const [subProjects, setSubProjects] = useState<AddedSubProject[]>([]);
    const [selectedSubProject, setSelectedSubProject] = useState<number>(0);
    const [expanded, setExpanded] = useState('');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodSelectOption|null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string|null>(null);
    const [reservationFee, setReservationFee] = useState(0);
    const [referenceId, setReferenceId] = useState('');
    const [paidPropertyIds, setPaidPropertyIds] = useState<string[]>([]);
    const [paidProperties, setPaidProperties] = useState<PaidProperty[]>([]);
    const [currentPaidProperty, setCurrentPaidProperty] = useState<PaidProperty>({
        subProjectId: '',
        amount: 0,
        referenceId: ''
    });
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [cardNumber, setCardNumber] = useState<string>('');
    const [validThru, setValidThru] = useState<string>('');
    const [cvv, setCvv] = useState<string>('');
    const [ccToken, setCcToken] = useState<string>('');
    const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false);
    const [creditCardType, setCreditCardType] = useState<string>('test');

    const subProjectId = window.localStorage.getItem('sub_project_id');
    const preQualId = window.localStorage.getItem('pre_qualification_id');

    useEffect(() => {

        if (preQualId) {
            Container
                .get(PaymentApi)
                .paymentInfo(preQualId)
                .then((data) => {
                    setReferenceId(data.referenceId);
                    if (data.paidProperties) {
                        let paidPropertyIds:string[] = [];
                        setPaidProperties(data.paidProperties);

                        data.paidProperties.forEach((pp) => {
                            paidPropertyIds.push(pp.subProjectId);
                        })
                        setPaidPropertyIds(paidPropertyIds);
                    }
                })
                .catch((error) => {
                    setIsError(true);
                    setIsLoading(false);
                    setError(`${error.data[0].field} - ${error.data[0].errors[0]}`);
                });

            Container
                .get(PreQualificationApi)
                .getInfo(preQualId)
                .then((preQual) => {
                    setSubProjects(preQual.subProjects ? preQual.subProjects : []);

                    preQual.subProjects?.forEach((sp, idx) => {
                        if (sp.id === subProjectId) {
                            setSelectedSubProject(idx);

                            setReservationFee(sp.subProjectReservationFee);
                        }

                    })
                })
        }

        Container
            .get(PaymentMethodsApi)
            .getWithDetails()
            .then((serverPms) => {
                if (serverPms) {
                    const pmStaticData = [...paymentMethodStaticData];
                    const pms:PaymentMethodSelect[] = [];

                    for (let i = 0; Object.keys(serverPms).length > i; i++) {
                        const pmStaticDataCategory = pmStaticData[i];
                        const serverPmsOptions = serverPms[pmStaticDataCategory.key];
                        const serverPmsOptionsKeys = Object.keys(serverPmsOptions);

                        const pm:PaymentMethodSelect = {
                            name: pmStaticDataCategory.key,
                            title: pmStaticDataCategory.title,
                            options: []
                        };

                        if (pmStaticDataCategory.options) {
                            for (let j = 0; pmStaticDataCategory.options.length > j; j++) {
                                const pmStaticDataCategoryOptions = pmStaticDataCategory.options[j];
                                const isDisabled = !serverPmsOptionsKeys.includes(pmStaticDataCategoryOptions.channelCode);

                                if (!isDisabled) {
                                    let pmOptions: PaymentMethodSelectOption = {
                                        name: pmStaticDataCategoryOptions.name,
                                        title: pmStaticDataCategoryOptions.title,
                                        channelCode: pmStaticDataCategoryOptions.channelCode,
                                        disabled: isDisabled,
                                        image: '/payment-options/payment-xendit.png', // change to `${pmStaticDataCategoryOptions.channelCode}.png`
                                        transactionFeeRule: null,
                                        paymentFeeRule: null
                                    };

                                    if (serverPmsOptions[pmStaticDataCategoryOptions.channelCode].transactionFeeRule !== null) {
                                        pmOptions = {
                                            ...pmOptions,
                                            ...{ transactionFeeRule: serverPmsOptions[pmStaticDataCategoryOptions.channelCode].transactionFeeRule.rules }
                                        };
                                    }

                                    if (serverPmsOptions[pmStaticDataCategoryOptions.channelCode].paymentFeeRule !== null) {
                                        pmOptions = {
                                            ...pmOptions,
                                            ...{
                                                paymentFeeRule: {
                                                    feeType: serverPmsOptions[pmStaticDataCategoryOptions.channelCode].paymentFeeRule.feeType,
                                                    feeValue: serverPmsOptions[pmStaticDataCategoryOptions.channelCode].paymentFeeRule.feeValue
                                                }
                                            }
                                        };
                                    }

                                    pm.options.push(pmOptions);
                                }
                            }
                        }


                        pms.push(pm);
                    }

                    setPayments(pms);
                }
            })
            .catch(errorHandlerWithToast);
    }, []);

    useEffect(() => {
        setIsPaid(false);

        if (subProjects.length) {
            if (paidPropertyIds.includes(subProjects[selectedSubProject].id)) {
                setCurrentPaidProperty(paidProperties[paidPropertyIds.indexOf(subProjects[selectedSubProject].id)]);
                setIsPaid(true);
            }

            setReservationFee(subProjects[selectedSubProject].subProjectReservationFee);
        }

    }, [selectedSubProject, subProjects]);

    const xenditResponseHandler = (err: any, creditCardCharge: any) => {
        if (err) {
          console.log(err);
          toast(err.message, 'error');
          return;
        }

        if (creditCardCharge.status === "VERIFIED") {
            setCcToken(creditCardCharge.id);
            setIsIframeVisible(false);
            console.log(creditCardCharge);
            setCreditCardType(creditCardCharge.card_info.brand);
            setExpanded('');
        } else if (creditCardCharge.status === "IN_REVIEW") {
            setIsIframeVisible(true);
            window.open(
                creditCardCharge.payer_authentication_url,
                "xendit-verification"
            );
        } else if (creditCardCharge.status === "FAILED") {
            toast('Credit Card Charge Failed', 'error');
        }
    }

    const createCreditCardToken = (totalAmount: number) => {
        window.Xendit.setPublishableKey(
            "xnd_public_development_FQHGN1wW616jXF4upKVOujkslTOhwbybOsEOyQBejUyMWXstXjlvt2bc4vI2"
        );

        window.Xendit.card.createToken({
            amount: totalAmount,
            card_number: cardNumber,
            card_exp_month: validThru.split('/')[0],
            card_exp_year: `20${validThru.split('/')[1]}`,
            card_cvn: cvv,
            is_multiple_use: false
        }, xenditResponseHandler);
    }

    // y = reservationFee / (1 - transferRate) - reservationFee
    const transactionFee = useMemo(() => {
        let totalFee = 0.0;
        let reservationFeeFloat = parseFloat(reservationFee.toString());

        if (selectedPaymentMethod) {
            let payFee = 0.0;
            if (selectedPaymentMethod.paymentFeeRule) {
                let seriesOfPaymentFees = 0;
                if (selectedPaymentMethod.paymentFeeRule.feeType === 'percent') {
                    seriesOfPaymentFees = ((reservationFeeFloat / (1.0 - (selectedPaymentMethod.paymentFeeRule.feeValue / 100.0))) - reservationFeeFloat);
                }
                else {
                    seriesOfPaymentFees = selectedPaymentMethod.paymentFeeRule.feeValue;
                }
                payFee = seriesOfPaymentFees;
            }

            let transFee = 0.0;
            const reservationFeeAndPaymentFee = reservationFeeFloat + payFee;
            if (selectedPaymentMethod.transactionFeeRule) {
                const seriesOfTransactionFees = selectedPaymentMethod.transactionFeeRule.map((o) => {
                    if (o.feeType === 'percent') {
                        return ((reservationFeeAndPaymentFee / (1.0 - (o.feeValue / 100.0))) - reservationFeeAndPaymentFee);
                    }

                    return o.feeValue;
                });

                seriesOfTransactionFees.sort((a, b) => b - a);

                transFee = seriesOfTransactionFees[0];
            }

            totalFee = parseFloat((transFee + payFee).toFixed(2));
        }

        return totalFee;
    }, [reservationFee, selectedPaymentMethod?.channelCode]);

    const totalAmount = useMemo(() => {
        const total = reservationFee + transactionFee

        if (selectedPaymentMethod?.channelCode === 'CC') {
            createCreditCardToken(total);
        }

        return total;
    }, [transactionFee, reservationFee]);

    const invoice = async (paymentOption: string) => {
        const invoice = await Container.get(PaymentApi).bookingPayment(paymentOption);

        if (invoice) {
            window.location.replace(invoice.invoice_url);
        }
    }

    const eWallet = async (paymentOption: string) => {
        const payment = await Container.get(PaymentApi)
            .eWalletpayment(
                paymentOption,
                subProjects[selectedSubProject].id,
                (preQualId ? preQualId : ''),
                window.location.href
            )
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
                setError(error.data[0].errors[0]);
            });

        if (payment) {
            window.localStorage.setItem('payment_reference_id', payment.referenceId);
            window.localStorage.setItem('payment_amount', payment.amount);
            window.localStorage.setItem('payment_channel', payment.channelCode);
            window.localStorage.setItem('payment_date', payment.paymentDate);
            window.location.replace(payment.desktopUrl);
        }
    }

    const directDebit = async (bank: string) => {
        Container
            .get(PaymentApi)
            .bankRedirect(
                bank,
                subProjects[selectedSubProject].id,
                (preQualId ? preQualId : ''),
                window.location.href
            )
            .then(bankData => {
                if (bankData) {
                    window.sessionStorage.setItem('xendit_customer_id', bankData.customerId);
                    window.localStorage.setItem('sub_project_id', subProjects[selectedSubProject].id);
                    window.location.replace(bankData.url);
                }
                else {
                    window.localStorage.removeItem('xendit_customer_id');
                    window.localStorage.removeItem('sub_project_id');
                }
            })
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
                setError(`${error.data[0].field} - ${error.data[0].errors[0]}`);
            });
    }

    const handleChangePaymentMethod = (paymentMethod: PaymentMethodSelectOption, category: string) => {
        setSelectedPaymentMethod(paymentMethod);
        setSelectedCategory(category);
    };

    const chargeCreditCard = () => {
        Container.get(PaymentApi)
            .creditCardCharge({
                preQualificationId: preQualId ? preQualId : '',
                subProjectId: subProjects[selectedSubProject].id,
                token: ccToken,
                referenceId,
                amount: totalAmount
            }).then((resp) => {
                console.log(resp);
                window.localStorage.setItem('payment_reference_id', resp.referenceId);
                window.localStorage.setItem('payment_amount', resp.amount);
                window.localStorage.setItem('payment_channel', resp.channelCode);
                window.localStorage.setItem('payment_date', resp.paymentDate);

                navigate('/payment-success');
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsError(true);
                setIsLoading(false);
            });
    }

    const handleCCDone = (payment: PaymentMethodSelect) => {

        if (!cardNumber) {
            toast('Card Number is required', 'error');
            return;
        }

        if (!validThru) {
            toast('Validity date is required', 'error');
            return;
        }

        if (!cvv) {
            toast('CVV is required', 'error');
            return;
        }

        handleChangePaymentMethod(payment.options[0], payment.name);
    }

    const handleContinue = async () => {
        if (!selectedPaymentMethod) {
            toast('Please select payment method of your choice then try again.', 'error');
            return;
        }

        console.log(selectedCategory)

        setIsLoading(true);

        window.localStorage.setItem('payment_method', selectedPaymentMethod.title);

        if (selectedCategory === 'e-wallet') {
            eWallet(selectedPaymentMethod.channelCode);
        }
        else if (selectedCategory === 'direct-debit') {
            directDebit(selectedPaymentMethod.channelCode);
        }
        else if (selectedCategory === 'credit-card') {
            chargeCreditCard();
        }
    };

    return (
        <>
            <div className={`${isIframeVisible ? 'flex': 'hidden'} w-full h-full bg-white absolute left-0 top-0 z-20`}>
                <iframe name="xendit-verification" title="Xendit Payment" className="w-full h-full"></iframe>
            </div>
            <div
                className={`
                    bg-uwi-content-100 shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]
                    text-black rounded-t-3xl
                    h-[calc(100vh-156px)] xs:h-[calc(100vh-155px)] md:h-[612px] lg:h-[597px] w-full
                    flex justify-center items-center flex-col
                    px-7 lg:px-10
                `}
            >
                {(isLoading) && (
                    <div className="absolute w-full h-full left-0 top-0 flex justify-center items-center bg-white z-10">
                        <div className="w-3/4 justify-center flex">
                            <CircleLoaderIcon className="animate-spin mr-3" />
                            <div>Redirecting to your selected payment option portal, Please Wait..</div>
                        </div>
                    </div>
                )}

                <PaymentError isVisible={isError} error={error} setIsVisible={(visible) => setIsError(visible)}/>

                <div className="flex text-center w-full px-6 lg:px-20 gap-x-9 flex-col md:flex-row">
                    <div className="w-full md:w-1/2 shadow-md rounded-lg p-6 divide-y">
                        {(subProjects[selectedSubProject] && subProjectId) && (
                            <div className="py-4 text-primary-100 font-bold">
                                {subProjects[selectedSubProject].name}
                            </div>
                        )}

                        {(selectedPaymentMethod) && (
                            <>
                            {selectedPaymentMethod.name === 'credit-card' ? (
                                <>
                                    {creditCardType && (
                                        <div className="mx-auto py-4">
                                            <PaymentOptIcons icon={creditCardType} className="w-full h-6 md:h-9"/>
                                        </div>
                                    )}
                                </>
                            ): (
                                <div className="mx-auto py-4">
                                    <PaymentOptIcons icon={selectedPaymentMethod.channelCode} className="w-full h-6 md:h-9"/>
                                </div>
                            )}
                            </>
                        )}

                        {isPaid ? (
                            <div className="py-4">
                                <ul className="flex flex-col gap-4">
                                    <li className="flex justify-between text-[0.875rem]">
                                        <label>Status</label>
                                        <span>Paid</span>
                                    </li>
                                    <li className="flex justify-between text-[0.875rem]">
                                        <label>Reference Number</label>
                                        <span>{currentPaidProperty.referenceId}</span>
                                    </li>
                                    <li className="font-bold flex justify-between text-[1.25rem]">
                                        <label>Amount Paid</label>
                                        <span>{(currentPaidProperty.amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                    </li>
                                </ul>
                            </div>
                        ): (
                            <div className="py-4">
                                <ul className="flex flex-col gap-4">
                                    <li className="flex justify-between text-[0.875rem]">
                                        <label>Reservation Fee</label>
                                        <span>{reservationFee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                    </li>
                                    <li className="flex justify-between text-[0.875rem]">
                                        <label>Transaction Fee</label>
                                        <span>{transactionFee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                    </li>
                                    <li className="font-bold flex justify-between text-[1.25rem]">
                                        <label>Total Amount</label>
                                        <span>{totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className="w-full md:w-1/2 mt-9 md:mt-0">
                        <div className="flex flex-col mb-2 justify-center items-center">
                            <div className="text-sm mb-3">Choose Property</div>

                            <Select
                                className="w-full text-left"
                                placeholder="Select Property"
                                value={selectedSubProject ? selectedSubProject : 0}
                                onChange={(newVal) => {
                                    setSelectedSubProject(newVal as number)
                                }}
                            >
                                {subProjects.map((sp, idx) => (
                                    <SelectOption
                                        value={idx}
                                        key={`sp_${idx}`}
                                    >
                                        {`${sp.name} ${paidPropertyIds.includes(sp.id) ? '(Paid)': ''}`}
                                    </SelectOption>
                                ))}
                            </Select>
                        </div>
                        <div className={`flex flex-col py-2 justify-center items-center ${isPaid ? 'cursor-not-allowed opacity-25' : ''}`}>
                            <div className="text-sm mb-3">Choose a Payment Method</div>

                            {payments.map((payment) => (
                                <PaymentOptionGroup
                                    options={payment.options}
                                    key={payment.name}
                                    title={payment.title}
                                    onExpand={(title) => !isPaid && setExpanded(title)}
                                    expanded={expanded}
                                >
                                    <>
                                        {payment.name === 'credit-card' ? (
                                            <div onClick={(e) => e.stopPropagation() } className="w-full flex flex-col items-center gap-4">
                                                <label htmlFor="cardNumber" className="mb-2 block text-center text-xs form-label">Card Number</label>
                                                <input
                                                    type="text"
                                                    className="form-field bg-white/40"
                                                    placeholder="0000 0000 0000 0000"
                                                    onChange={(e) => setCardNumber(e.target.value)}
                                                    id="cardNumber"
                                                    value={cardNumber}
                                                />
                                                <label htmlFor="validThru" className="mb-2 block text-center text-xs form-label">Valid Thru</label>
                                                <input
                                                    type="text"
                                                    className="form-field bg-white/40"
                                                    placeholder="MM/YY"
                                                    onChange={(e) => setValidThru(e.target.value)}
                                                    id="validThru"
                                                    value={validThru}
                                                />
                                                <label htmlFor="cvv" className="mb-2 block text-center text-xs form-label">CVV</label>
                                                <input
                                                    type="text"
                                                    className="form-field bg-white/40"
                                                    placeholder="000"
                                                    onChange={(e) => setCvv(e.target.value)}
                                                    id="cvv"
                                                    value={cvv}
                                                />
                                                <button className="flex justify-center items-center rounded-2xl text-[15px] font-bold
                                                    text-white px-8 py-4 bg-uwi-primary-100 hover:bg-uwi-primary-200 mt-4 w-1/2"
                                                    onClick={() => handleCCDone(payment)}
                                                >Done</button>
                                            </div>
                                        ) : (
                                            <>
                                                {payment.options.map((option) => (
                                                    <React.Fragment key={option.channelCode}>
                                                        <PaymentOption
                                                            channelCode={option.channelCode}
                                                            type={payment.name}
                                                            name={option.name}
                                                            title={option.title}
                                                            disabled={option.disabled}
                                                            onClick={() => handleChangePaymentMethod(option, payment.name)}
                                                        ></PaymentOption>
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )}
                                    </>
                                </PaymentOptionGroup>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="text-gray-50 text-sm mt-4 text-center">By continuing, you will be redirected to the payment portal to complete your reservation</div>

                <div className="w-full flex justify-center mt-8">
                    <button
                        className={`
                            w-[calc(50%-8px)] md:w-[150px] mr-4
                            flex justify-center items-center rounded-2xl
                            px-8 py-4
                            font-bold text-[15px]
                            border border-solid
                            border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                            hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                        `}
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                    <button
                        className={`
                            w-[calc(50%-8px)] md:w-[150px]
                            flex justify-center items-center rounded-2xl
                            text-[15px] font-bold
                            text-white px-8 py-4
                            bg-uwi-primary-100 hover:bg-uwi-primary-200
                            ${isPaid ? 'cursor-not-allowed opacity-25' : ''}
                        `}
                        onClick={() => handleContinue()}
                        disabled={isPaid}
                    >
                        Continue

                        {(isLoading) && (
                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                        )}
                    </button>
                </div>
            </div>
        </>
    );
}

export default PayReservationPage;
