import { useMemo } from 'react';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { projectName, subProjectName } from 'src/helpers/booking';

type Props = {
    subProjects: AddedSubProject[];
    subProjectId: string;
    onSelect: (subProj: AddedSubProject, index: number) => void;
};

const TabNavigation = ({
    subProjects,
    subProjectId,
    onSelect
}: Props) => {

    const uniqSubProjects = useMemo(() => {
        const list: AddedSubProject[] = [];

        subProjects.forEach(subProj => {
            if (list.findIndex(e => (e.id === subProj.id)) === -1) {
                list.push(subProj);
            }
        });

        return list;
    }, [subProjects]);

    return (
        <>
            {uniqSubProjects.map((subProj, idx) => (
                <button
                    key={subProj.id}
                    className={`
                        w-full py-4 px-3
                        ${(subProjectId === subProj.id) ? 'bg-uwi-primary-100 text-white' : 'hover:bg-uwi-primary-100 hover:text-white'} 
                    `}
                    onClick={() => onSelect(subProj, idx)}
                >
                    <div className="text-left mb-1">{projectName(subProj.name)}</div>
                    <div className="text-left text-xs text-gray-50">{subProjectName(subProj.name)}</div>
                </button>
            ))}
        </>
    );
};

export default TabNavigation;
