import { FormFieldWrapper } from 'src/components/common';

type Props = {
    label: string;
    isError: boolean;
    isChecked: boolean;
    onChecked: (check: boolean) => void;
    index: number;
}

const CheckBox = ({
    label,
    isError,
    isChecked,
    onChecked,
    index
}: Props) => {
    return (
        <div className="flex w-full items-center justify-center text-xs mb-1">
            <div className="w-[80%] flex items-center">
                <div>
                    <FormFieldWrapper
                        showError={isError}
                        errorMessage={'To continue, please certify by checking the checkbox'}
                        className="w-full"
                    >
                        <input
                            type="checkbox"
                            className={`w-5 h-5 rounded-4xl border-2 border-gray-200 ${isError ? 'mr-10' : 'mr-4'} accent-green-100 uwi-input`}
                            checked={isChecked}
                            onChange={(e) => onChecked(e.target.checked)}
                            id={`certify_checkbox${index}`}
                        />
                    </FormFieldWrapper>
                </div>
                <label className="form-label" htmlFor={`certify_checkbox${index}`}>
                    {label}
                </label>
            </div>
        </div>
    )
}

export default CheckBox;