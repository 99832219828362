import { FormFieldWrapper, YesNoField } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectHasCancelledCreditCard,
    selectHasFullPaymentCertificate,
    selectExtraIncomeAmount,
    selectExistingDebtAmount,
    selectCoBorrowerMonthlyIncome,
    setHasCancelledCreditCard as setHasCancelledCreditCardAct,
    setHasFullPaymentCertificate as setHasFullPaymentCertificateAct,
    setExtraIncomeAmount as setExtraIncomeAmountAct,
    setExistingDebtAmount as setExistingDebtAmountAct,
    setCoBorrowerMonthlyIncome as setCoBorrowerMonthlyIncomeAct
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import PreApprovalFormGroup from './PreApprovalFormGroup';
import useDebtIncomeInfoValidation from '../validations/useDebtIncomeInfoValidation';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';

const DebtIncomeInfo = () => {
    const {
        formErrors,
        setFormErrors,
        validateForm
    } = useDebtIncomeInfoValidation();

    const dispatch = useAppDispatch();
    const hasCancelledCreditCard = useAppSelector(selectHasCancelledCreditCard);
    const hasFullPaymentCertificate = useAppSelector(selectHasFullPaymentCertificate);
    const extraIncomeAmount = useAppSelector(selectExtraIncomeAmount);
    const existingDebtAmount = useAppSelector(selectExistingDebtAmount);
    const coBorrowerMonthlyIncome = useAppSelector(selectCoBorrowerMonthlyIncome);

    const handleOnChangeHasCancelledCreditCard = (val: boolean) => {
        dispatch(setHasCancelledCreditCardAct(val as boolean));
        if (!val) {
            dispatch(setHasFullPaymentCertificateAct(false));
        }
    };

    return (
        <div className="w-full px-4 md:px-10">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[350px]">
                    <PreApprovalFormGroup
                        label="Co-borrower monthly income"
                        tooltipText="Your co-borrower's gross monthly income including allowances."
                        showTooltip={true}
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'coBorrowerMonthlyIncome')}
                            errorMessage={(hasProp(formErrors, 'coBorrowerMonthlyIncome')) ? formErrors.coBorrowerMonthlyIncome : ''}
                            className="w-full"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'coBorrowerMonthlyIncome')) ? '!border-red-200' : ''}
                                `}
                                type="tel"
                                placeholder="Monthly Income"
                                min={1}
                                max={1000000000}
                                value={(typeof coBorrowerMonthlyIncome === 'number') ? coBorrowerMonthlyIncome.toLocaleString('en-US') : ''}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value.replace(/,/g, ''));
                                    dispatch(setCoBorrowerMonthlyIncomeAct(isNaN(value) ? 0 : value));
                                    const { coBorrowerMonthlyIncome, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Extra Income (Monthly)"
                        tooltipText="Your Additional generated earnings apart from the primary source of income."
                        showTooltip={true}
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'extraIncomeAmount')}
                            errorMessage={(hasProp(formErrors, 'extraIncomeAmount')) ? formErrors.extraIncomeAmount : ''}
                            className="w-full"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'extraIncomeAmount')) ? '!border-red-200' : ''}
                                `}
                                type="tel"
                                placeholder="Enter Amount"
                                value={(extraIncomeAmount >= 0) ? extraIncomeAmount.toLocaleString('en-US') : ''}
                                onChange={(e) => {
                                    dispatch(setExtraIncomeAmountAct(parseInt(e.target.value.replace(/,/g, ''))));
                                    const { extraIncomeSource, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Credit Card Cancelled?"
                        tooltipText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                    >
                        <YesNoField
                            value={hasCancelledCreditCard}
                            onChange={(val) => handleOnChangeHasCancelledCreditCard(val as boolean)}
                        />
                    </PreApprovalFormGroup>

                    {(hasCancelledCreditCard) && (
                        <PreApprovalFormGroup
                            label="Do you have a certificate of full payment?"
                            tooltipText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        >
                            <YesNoField
                                value={hasFullPaymentCertificate}
                                onChange={(val) => {
                                    dispatch(setHasFullPaymentCertificateAct(val));
                                }}
                            />
                        </PreApprovalFormGroup>
                    )}

                    <PreApprovalFormGroup
                        label="Existing Debt (Monthly)"
                        tooltipText="Your Total amount of financial obligations and loan repayments on a monthly basis."
                        showTooltip={true}
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'existingDebtAmount')}
                            errorMessage={(hasProp(formErrors, 'existingDebtAmount')) ? formErrors.existingDebtAmount : ''}
                            className="w-full"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'existingDebtAmount')) ? '!border-red-200' : ''}
                                `}
                                type="tel"
                                placeholder="Enter Amount"
                                value={(existingDebtAmount >= 0) ? existingDebtAmount.toLocaleString('en-US') : ''}
                                onChange={(e) => {
                                    dispatch(setExistingDebtAmountAct(parseInt(e.target.value.replace(/,/g, ''))));
                                    const { existingDebtAmount, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={validateForm}
            />
        </div>
    );
}

export default DebtIncomeInfo;
