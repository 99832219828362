import { useState } from 'react';
import { Container } from 'typedi';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { HomeLoanApplication, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import { validateEmail } from 'src/helpers/validation';
import {
    selectCompanyIndustry,
    selectCompanyName,
    selectOfficePhone,
    selectOfficeAddress,
    selectTin,
    selectSss,
    selectEmploymentRank,
    selectOfficeEmail,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/applyLoanEmploymentInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const useEmploymentInfo = (homeLoanApp?: HomeLoanApplication) => {
    const { extractFormErrors } = useGenericErrorHandler();

    const dispatch = useAppDispatch();

    const selectedCompanyIndustry = useAppSelector(selectCompanyIndustry);
    const selectedCompanyName = useAppSelector(selectCompanyName);
    const selectedOfficePhone = useAppSelector(selectOfficePhone);
    const selectedOfficeAddress = useAppSelector(selectOfficeAddress);
    const selectedTin = useAppSelector(selectTin);
    const selectedSss = useAppSelector(selectSss);
    const selectedEmploymentRank = useAppSelector(selectEmploymentRank);
    const selectedOfficeEmail = useAppSelector(selectOfficeEmail);
    const formErrors = useAppSelector(selectFormErrors);

    const [isDirty, setIsDirty] = useState(false);

    const validateForm = () => {
        const errors:Record<string, string> = {};
        if (selectedCompanyIndustry.trim().length === 0) {
            errors.companyIndustry = 'Company Industry is required';
        }

        if (selectedCompanyName.trim().length === 0) {
            errors.companyName = 'Company Name is required';
        }
        else if (selectedCompanyName.trim().length < 2) {
            errors.companyName = 'Company Name must be longer than or equal to 2 characters';
        }

        if (selectedOfficePhone.trim().length === 0) {
            errors.officePhone = 'Office Phone is required';
        }
        else if (selectedOfficePhone.trim().length < 2) {
            errors.officePhone = 'Office Phone must be longer than or equal to 2 characters';
        }

        if (selectedTin.trim().length === 0) {
            errors.tin = 'Tax Identification Number is required';
        }
        else if (selectedTin.trim().length < 2) {
            errors.tin = 'Tax Identification Number must be longer than or equal to 2 characters';
        }

        if (selectedSss.trim().length === 0) {
            errors.sss = 'SSS/GSIS Number is required';
        }
        else if (selectedSss.trim().length < 2) {
            errors.sss = 'SSS/GSIS Number must be longer than or equal to 2 characters';
        }

        if (selectedEmploymentRank.trim().length === 0) {
            errors.employmentRank = 'Employment rank is required';
        }

        if (selectedOfficeEmail.trim().length === 0) {
            errors.officeEmail = 'Office email is required';
        }
        else if (selectedOfficeEmail && !validateEmail(selectedOfficeEmail)) {
            errors.officeEmail = 'Office Email should be a valid email address.';
        }

        if (selectedOfficeAddress.trim().length === 0) {
            errors.officeAddress = 'Office Address is required.'
        }
        else if (selectedOfficeAddress.trim().length < 2) {
            errors.officeAddress = 'Office Address must be longer than or equal to 2 characters';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    const handleNext = async () => {
        if (!validateForm()) {
            return false;
        }

        if (!isDirty) {
            return true;
        }

        if (homeLoanApp) {
            try {
                await Container.get(HomeLoanApplicationApi).update(
                    homeLoanApp.id,
                    {
                        companyIndustry: selectedCompanyIndustry,
                        companyName: selectedCompanyName,
                        officePhone: selectedOfficePhone,
                        officeAddress: selectedOfficeAddress,
                        tin: selectedTin,
                        sss: selectedSss,
                        employmentRank: selectedEmploymentRank,
                        officeEmail: selectedOfficeEmail
                    }
                );

                return true;
            }
            catch (ex) {
                const errors = extractFormErrors(ex);
                dispatch(setFormErrors(errors));
            }
        }

        return false;
    };

    return {
        validateForm,
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    };
};

export default useEmploymentInfo;