import { useState } from 'react';
import { Container } from 'typedi';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectDeveloperName,
    selectProjectCost,
    selectProjectStatus,
    selectProjectType,
    selectRegion,
    setDefaultFormValues as setLoanPropertyInfoDefaultFormValues
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    selectMonthlyIncome,
    selectWorkExperience,
    selectWorkType,
    selectIsRegularEmployee,
    selectBusinessProprietorship,
    selectIsBusinessProfitable
} from 'src/store/features/preApproveLoanWorkInfoSlice';
import {
    selectNationality,
    selectVisaType,
    selectBirthdate,
    selectMaritalStatus
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import {
    selectHasCreditCard,
    selectHasCancelledCreditCard,
    selectHasFullPaymentCertificate,
    selectExtraIncomeAmount,
    selectExistingDebtAmount,
    selectCoBorrowerMonthlyIncome
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import {
    setDefaultFormValues as setLoanLoanInfoDefaultFormValues,
    selectLoanTerm,
    selectPercentOfProjectCost,
    selectOwnedBanks
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import { BasicWidgetPreQualificationApi, PreQualificationForm } from 'src/api/BasicWidgetPreQualificationApi';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const useBasicWidgetPreQualification = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const projectCost = useAppSelector(selectProjectCost);
    const projectType = useAppSelector(selectProjectType);
    const projectStatus = useAppSelector(selectProjectStatus);
    const developerName = useAppSelector(selectDeveloperName);
    const projectRegion = useAppSelector(selectRegion);
    const workType = useAppSelector(selectWorkType);
    const selectedIsRegularEmployee = useAppSelector(selectIsRegularEmployee);
    const isBusinessProfitable = useAppSelector(selectIsBusinessProfitable);
    const workExperience = useAppSelector(selectWorkExperience);
    const businessProprietorship = useAppSelector(selectBusinessProprietorship);
    const monthlyIncome = useAppSelector(selectMonthlyIncome);
    const nationality = useAppSelector(selectNationality);
    const visaType = useAppSelector(selectVisaType);
    const selectedBirthdate = useAppSelector(selectBirthdate);
    const selectedMaritalStatus = useAppSelector(selectMaritalStatus);
    const selectedCoBorrowerMonthlyIncome = useAppSelector(selectCoBorrowerMonthlyIncome);
    const selectedHasCreditCard = useAppSelector(selectHasCreditCard);
    const selectedHasCancelledCreditCard = useAppSelector(selectHasCancelledCreditCard);
    const selectedHasFullPaymentCertificate = useAppSelector(selectHasFullPaymentCertificate);
    const selectedExtraIncomeAmount = useAppSelector(selectExtraIncomeAmount);
    const selectedExistingDebtAmount = useAppSelector(selectExistingDebtAmount);
    const selectedLoanTerm = useAppSelector(selectLoanTerm);
    const selectedPercentOfProjectCost = useAppSelector(selectPercentOfProjectCost);
    const selectedOwnedBanks = useAppSelector(selectOwnedBanks);

    const [isSavingPreQualification, setIsSavingPreQualification] = useState(false);

    const createPreQualification = async (shouldRedirectToDashboard = true) => {
        if (selectedBirthdate) {
            setIsSavingPreQualification(true);

            let formattedBirthMonth = selectedBirthdate.month;
            if (formattedBirthMonth !== undefined) {
                formattedBirthMonth = formattedBirthMonth + 1;
            }

            let isRegularEmployee = selectedIsRegularEmployee;
            if (workType === 'Self-Employed') {
                isRegularEmployee = false;
            }

            let hasFullPaymentCertificate = selectedHasFullPaymentCertificate;
            if (!selectedHasCancelledCreditCard) {
                hasFullPaymentCertificate = false;
            }

            const dto: PreQualificationForm = {
                projectCost,
                projectType,
                projectStatus,
                developerName,
                projectRegion,
                workType,
                isRegularEmployee,
                isBusinessProfitable,
                workExperience,
                businessProprietorship: businessProprietorship === '' ? undefined : businessProprietorship,
                monthlyIncome,
                nationality,
                visaType: (nationality === 'Foreigner') ? visaType : undefined,
                birthdate: `${selectedBirthdate.year}-${formattedBirthMonth?.toString().padStart(2, '0')}-${selectedBirthdate.day?.toString().padStart(2, '0')}`,
                maritalStatus: selectedMaritalStatus,
                coBorrowerMonthlyIncome: selectedCoBorrowerMonthlyIncome,
                hasCreditCard: selectedHasCreditCard,
                hasCancelledCreditCard: selectedHasCancelledCreditCard,
                hasFullPaymentCertificate,
                extraIncomeAmount: selectedExtraIncomeAmount,
                existingDebtAmount: selectedExistingDebtAmount,
                loanTerm: selectedLoanTerm,
                percentOfProjectCost: selectedPercentOfProjectCost,
                ownedBanks: selectedOwnedBanks
            };

            try {
                const preQual = await Container.get(BasicWidgetPreQualificationApi).create(dto);

                // remove values from local storage
                dispatch(setLoanPropertyInfoDefaultFormValues());
                dispatch(setLoanLoanInfoDefaultFormValues());

                if (shouldRedirectToDashboard) {
                    navigate('/dashboard-pre-qualifications');
                }

                return preQual;
            }
            catch (ex) {
                errorHandlerWithToast(ex);
            }
            finally {
                setIsSavingPreQualification(false);
            }
        }

        return null;
    };

    const updatePreQualification = async (preQualificationId: string) => {
        if (
            selectedBirthdate
            && typeof selectedBirthdate.year === 'number'
            && typeof selectedBirthdate.month === 'number'
            && typeof selectedBirthdate.day === 'number'
        ) {
            setIsSavingPreQualification(true);

            let formattedBirthMonth = selectedBirthdate.month;
            if (formattedBirthMonth !== undefined) {
                formattedBirthMonth = formattedBirthMonth + 1;
            }

            let isRegularEmployee = selectedIsRegularEmployee;
            if (workType === 'Self-Employed') {
                isRegularEmployee = false;
            }

            let hasFullPaymentCertificate = selectedHasFullPaymentCertificate;
            if (!selectedHasCancelledCreditCard) {
                hasFullPaymentCertificate = false;
            }

            const dto: PreQualificationForm = {
                projectCost,
                projectType,
                projectStatus,
                developerName,
                projectRegion,
                workType,
                isRegularEmployee,
                isBusinessProfitable,
                workExperience,
                businessProprietorship: businessProprietorship === '' ? undefined : businessProprietorship,
                monthlyIncome,
                nationality,
                visaType: (nationality === 'Foreigner') ? visaType : undefined,
                birthdate: `${selectedBirthdate.year}-${formattedBirthMonth?.toString().padStart(2, '0')}-${selectedBirthdate.day?.toString().padStart(2, '0')}`,
                maritalStatus: selectedMaritalStatus,
                coBorrowerMonthlyIncome: selectedCoBorrowerMonthlyIncome,
                hasCreditCard: selectedHasCreditCard,
                hasCancelledCreditCard: selectedHasCancelledCreditCard,
                hasFullPaymentCertificate,
                extraIncomeAmount: selectedExtraIncomeAmount,
                existingDebtAmount: selectedExistingDebtAmount,
                loanTerm: selectedLoanTerm,
                percentOfProjectCost: selectedPercentOfProjectCost,
                ownedBanks: selectedOwnedBanks
            };

            try {
                await Container.get(BasicWidgetPreQualificationApi).update(
                    preQualificationId,
                    dto
                );
                // trigger toast system here to inform user that he/she successfully created a
                // pre approval

                navigate('/dashboard-pre-qualifications');
            }
            catch (ex) {
                errorHandlerWithToast(ex);
            }
            finally {
                setIsSavingPreQualification(false);
            }
        }
    };

    const savePreQualificationInfo = async () => {};

    return {
        createPreQualification,
        updatePreQualification,
        savePreQualificationInfo,
        isSavingPreQualification
    };
}

export default useBasicWidgetPreQualification;