export class ErrorCode {
    public static readonly OK = 0;
    public static readonly FAILED = 1;
    public static readonly VALIDATION_ERROR = 1001;
    public static readonly NOT_FOUND = 1002;
    public static readonly ALREADY_EXISTS = 1003;
    public static readonly ADMIN_ROLE_NOT_FOUND = 1004;
    public static readonly ADMIN_USER_ALREADY_EXISTS = 1005;
    public static readonly ADMIN_USER_NOT_FOUND = 1006;
    public static readonly EMAIL_TEMPLATE_NOT_FOUND = 1007;
    public static readonly AUTHENTICATION_ERROR = 1008;
    public static readonly BANK_HOME_LOAN_NOT_FOUND = 1009;
    public static readonly HOME_LOAN_PRE_APPROVAL_NOT_FOUND = 1010;
    public static readonly HOME_LOAN_APPLICATION_NOT_FOUND = 1011;
    public static readonly HOME_LOAN_APPLICATION_DOCUMENT_NOT_FOUND = 1012;
    public static readonly USER_NOT_FOUND = 1013;
    public static readonly BANK_NOT_FOUND = 1014;
    public static readonly DEVELOPER_NOT_FOUND = 1015;
    public static readonly INVALID_DEVELOPER_CLIENT_ID = 1016;
    public static readonly DEVELOPER_ACCOUNT_ALREADY_EXISTS = 1017;
    public static readonly DEVELOPER_ACCOUNT_NOT_FOUND = 1018;
    public static readonly PROJECT_NOT_FOUND = 1019;
    public static readonly LOAN_TYPE_NOT_FOUND = 1020;
    public static readonly SUB_PROJECT_NOT_FOUND = 1021;
    public static readonly HOME_DEVELOPER_NOT_FOUND = 1022;
    public static readonly HOME_DEVELOPER_ALREADY_EXISTS = 1023;
    public static readonly BANK_ALREADY_EXISTS = 1024;
    public static readonly FILE_TOO_LARGE = 1025;
    public static readonly EMAIL_TEMPLATE_ALREADY_EXISTS = 1026;
    public static readonly APPLICATION_DOCUMENT_TEMPLATE_NOT_FOUND = 1027;
    public static readonly INVALID_FILE = 1028;
    public static readonly ARTICLE_NOT_FOUND = 1029;
    public static readonly ARTICLE_SLUG_NOT_FOUND = 1030;
    public static readonly INVALID_ADMIN_USER_CREDENTIALS = 1031;
    public static readonly DEVELOPER_ALREADY_EXISTS = 1032;
    public static readonly TAG_NOT_FOUND = 1033;
    public static readonly ACCOUNT_LOCKED = 1034;
    public static readonly ACCOUNT_INACTIVE = 1035;
    public static readonly INVALID_CREDENTIALS = 1036;
}

export interface ListResponse<T> {
    total: number;
    list: T[];
}

export interface GenericResponse<T> {
    code: ErrorCode;
    data: T;
}
