type Props = {
    text: string;
    isSelected: boolean;
    hasNext?: boolean;
    hasPrevious?: boolean;
    className?: string;
    onClickItem?: () => void;
};

const LinePaginationItem = ({
    text,
    className,
    isSelected,
    hasNext,
    hasPrevious,
    onClickItem
}: Props) => {
    const handleOnClickItem = onClickItem || (() => {});

    return (
        <div className={`relative ${className}`}>
            <div className={`flex flex-nowrap justify-center mb-3 text-xs`}>
                <button
                    className={`
                        md:visible h-[24px]
                        ${(isSelected) ? 'text-white visible hover:bg-transparent focus:bg-transparent' : 'hover:bg-transparent focus:bg-transparent text-white/50 hover:text-white'}
                    `}
                    onClick={() => handleOnClickItem()}
                >
                    {text}
                </button>
            </div>
            
            <div className="flex flex-nowrap items-center">
                <div className={`h-1 w-[calc(50%-14px)] rounded-r-xl mr-2.5 grow ${(hasPrevious) ? 'bg-white/20' : 'bg-transparent'}`} />

                <div
                    className={`w-2.5 h-2.5 rounded-full mr-2.5 cursor-pointer ${(isSelected) ? 'bg-white' : 'bg-white/20'}`}
                    onClick={() => handleOnClickItem()}
                />

                <div className={`h-1 w-[calc(50%-14px)] rounded-l-xl grow ${(hasNext) ? 'bg-white/20' : 'bg-transparent'}`} />
            </div>
        </div>
    );
}

export default LinePaginationItem;
