type Props = {
    className?: string;
};

const PhoneSolidIcon = ({ className }: Props) => {
    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M511.2,387L488,487.8c-3.3,14.2-15.8,24.2-30.5,24.2C205.2,512,0,306.8,0,54.5C0,39.8,10,27.3,24.2,24L125,0.8
                c14.7-3.4,29.7,4.2,35.8,18.1l46.5,108.5c5.4,12.8,1.8,27.7-9,36.4l-53.8,43.2c34,69.2,90.3,125.5,159.5,159.5l44.1-53.8
                c8.7-10.8,23.7-14.5,36.5-9l108.5,46.5C506.1,357.2,514.6,372.4,511.2,387z"
                fill="currentColor"
            />
        </svg>
    );
  }
  
  export default PhoneSolidIcon;