import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export interface Lender {
    id: string;
    name: string;
    shortName: string;
    image: string;
    thumbnail: string;
    ordinal: number;
}

@Service()
export class LenderApi extends BaseApi {
    /**
     * API path to get the list of lenders
     */
    static readonly PATH_LIST = '/v1/lender/list';

    async list(): Promise<Lender[]> {
        return this.http.get(
            LenderApi.PATH_LIST,
            {},
            {},
            false
        );
    }

}