/**
 * Format given string to capitalize the first letter
 * of the word
 * 
 * @param   word    The string to be formatted.
 */
export function capitalize(word: string):string {
    return word[0].toUpperCase() + word.substring(1);
}

/**
 * Calculates the display width of the given text.
 * 
 * @param   text    The text to be calculated
 */
export function calculateDisplayTextWidth(text: string, fontSize: number): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
        return 0;
    }

    context.font = `${fontSize}px Arial`;
    const metrics = context.measureText(text);

    return metrics.width;
}