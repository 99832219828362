import { SubProject } from 'src/api/SubProjectApi';
import { AngleLeftSolidIcon, AngleRightSolidIcon } from 'src/components/icons';

type Props = {
    subProject: SubProject | null;
    images: SubProjectImage[];
    currentVal: number;
    current: (current: number) => void;
};

export interface SubProjectImage {
    id: string;
    subProjectId: string;
    type: string;
    image: string;
    createdAt?: Date;
    updatedAt?: Date;
}

const ImageSlider = ({
    subProject,
    images,
    currentVal,
    current
}: Props) => {

    const handleLeft = () => {
        if (currentVal > 0) {
            current(currentVal-1);
        } else {
            current(images.length-1);
        }
    }

    const handleRight = () => {
        if (currentVal < images.length-1) {
            current(currentVal+1);
        } else {
            current(0);
        }
    }

    return (
        <>
            <div className={`w-full md:w-[630px] lg:w-[680px]`}>
                {images.length > 0
                    ?
                    <>
                        <div className="w-full">
                            <img
                                src={images[currentVal].image}
                                alt={`${(subProject) ? subProject.name : ''}`}
                                className={`object-contain w-full md:w-[630px] md:h-[313px] lg:w-[680px] lg:h-[355px]`}
                            />

                        </div>
                        <ul className="flex my-3 justify-center overflow-x-auto w-full">
                            <li onClick={() => handleLeft()} className="mx-2 text-gray-50 hover:opacity-75 select-none cursor-pointer flex justify-center items-center md:w-[20px] md:h-[70px] lg:w-[20px] lg:h-[50px]"><AngleLeftSolidIcon/></li>
                            {images.map((image, index) => {
                                return <li key={index} className="flex-none mx-2 cursor-pointer hover:opacity-75 rounded-md overflow-hidden">
                                    <img
                                        src={image.image}
                                        alt={`${(subProject) ? subProject.name : ''}`}
                                        className={`object-cover w-[70px] h-[70px] md:w-[60px] md:h-[60px] lg:w-[50px] lg:h-[50px]`}
                                        onClick={() => current(index)}
                                    />
                                </li>
                            })}
                            <li onClick={() => handleRight()} className="mx-2 text-gray-50 hover:opacity-75 select-none cursor-pointer flex justify-center items-center md:w-[20px] md:h-[70px] lg:w-[20px] lg:h-[50px]"><AngleRightSolidIcon/></li>
                        </ul>
                    </>
                    :
                    <div className="w-full md:w-[630px] lg:w-[680px]">
                        <div className="w-full">
                            <img
                                src={subProject ? subProject.image: ''}
                                alt={`${(subProject) ? subProject.name : ''}`}
                                className={`object-contain w-full md:w-[630px] md:h-[313px] lg:w-[680px] lg:h-[355px]`}
                            />
                        </div>
                    </div>
                }

            </div>

        </>
    );
}

export default ImageSlider;
