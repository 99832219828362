// This file is automatically generated
// Don't edit manually
 
const str = `
### BY ACCESSING THIS SITE, YOU AGREE TO THE FOLLOWING TERMS AND CONDITIONS:

UWI maintains this Site to provide you with information about our partners’ products and services and to facilitate communication with UWI, its related companies, and its partners. UWI requires that all the visitors to our site(s) on the Internet (the "Site") adhere to the following rules and regulations. By accessing the Site you indicate your acknowledgment and acceptance of these terms and conditions or any amendment that we may introduce from time to time.  By accepting these Terms, you also understand and consent to UWI’s Privacy Policy, which is incorporated into, and made part of these Terms.

Copyright in the pages, screens, UWI owns text and images appearing at the Site or others as indicated. The information and materials contained in the Site may not be copied, displayed, distributed, downloaded, licensed, modified, published, reposted, reproduced, reused, sold, transmitted, used to create a derivative work or otherwise used for public or commercial purposes without the express written permission of UWI.

Not all of the products or services described on the Site are available in all geographic areas. You may not be eligible for all the products or services described. UWI reserves the right to determine your eligibility for any product or service.

Any communication or material you transmit to us via the Site or Internet electronic mail is on a non-confidential basis and may be used by UWI for any purpose within the limits of our privacy statement including reproduction, publication broadcast and posting. 

We will use our best efforts to include accurate and up to date information on the Site, but we make no warranties or representations as to the accuracy of the information. You agree that all access and use of the Site and its contents is at your own risk. By using the Site, you acknowledge that we specifically disclaim any liability (whether based in contract, tort, strict liability or otherwise) for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with your access to or use of the Site, (even if we have been advised of the possibility of such damages) including liability associated with any viruses which may infect a user's computer equipment. You further understand and agree that UWI does not guarantee any support or maintenance for any incident arising out of or in any way connected with your access to or use of the Site.

With regard to services or products purchased or obtained by the user from a non-UWI person or entity, UWI makes no warranty of merchantability or warranty of fitness for a particular purpose with regard to such services and products and UWI specifically disclaims all other warranties with regard to said services and products.

Any personal information you supply to UWI when using the Site will be used by UWI in accordance with our Privacy Policy. While UWI strives to require third parties with whom it has agreements, to comply with UWI’s Privacy Policy and any other pertinent Data Privacy Laws, regulations or policies, you acknowledge that UWI shall not be responsible for any violation of policy, nor for actions, omissions or negligence of any third party and agree to hold harmless UWI, its officers, directors, or employees for any claim arising from such violation.

You agree that your use of this Site shall be governed by all applicable Philippine laws and agree that the venue of any relevant suit shall be located in the proper courts of the City of Makati, Philippines, to the exclusion of other courts.
    
`;

export default str;
                        