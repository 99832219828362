type Props = {
    className?: string;
};

const WidgetCTAIcon = ({ className }: Props) => {
    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg" 
            className={className}
        >
            <path
                d="M35.74,25.4c-0.34-0.7-0.5-1.46-0.5-2.29c0-0.89,0.19-1.69,0.56-2.38s0.92-1.23,1.65-1.6
                c0.55-0.29,1.24-0.43,2.06-0.43c1.07,0,1.9,0.22,2.5,0.67s0.99,1.07,1.16,1.85l-1.72,0.32c-0.12-0.42-0.35-0.75-0.68-1
                S40,20.18,39.5,20.18c-0.76,0-1.36,0.24-1.81,0.72s-0.67,1.19-0.67,2.14c0,1.02,0.23,1.78,0.68,2.29s1.05,0.76,1.78,0.76
                c0.36,0,0.73-0.07,1.09-0.21s0.68-0.32,0.94-0.52v-1.09h-1.99v-1.45h3.74v3.42c-0.36,0.35-0.89,0.66-1.58,0.93s-1.39,0.4-2.09,0.4
                c-0.9,0-1.68-0.19-2.35-0.57S36.07,26.1,35.74,25.4z M44.99,26.51c-0.41-0.57-0.62-1.28-0.62-2.14c0-1.03,0.27-1.84,0.81-2.42
                s1.22-0.88,2.04-0.88c0.93,0,1.66,0.31,2.19,0.92s0.79,1.55,0.77,2.81h-4.12c0.01,0.49,0.14,0.87,0.4,1.14s0.57,0.41,0.95,0.41
                c0.26,0,0.47-0.07,0.65-0.21s0.31-0.37,0.4-0.68l1.64,0.28c-0.21,0.6-0.54,1.06-1,1.37s-1.02,0.47-1.71,0.47
                C46.31,27.57,45.51,27.22,44.99,26.51z M46.09,23.79h2.46c-0.01-0.48-0.13-0.84-0.37-1.09s-0.52-0.37-0.86-0.37
                c-0.36,0-0.66,0.13-0.89,0.39S46.09,23.34,46.09,23.79z M51.61,25.24c0,0.58,0.02,0.97,0.05,1.16c0.04,0.27,0.12,0.48,0.23,0.64
                s0.29,0.29,0.52,0.39s0.51,0.15,0.8,0.15c0.48,0,0.92-0.08,1.3-0.25l-0.14-1.28c-0.29,0.11-0.51,0.16-0.66,0.16
                c-0.11,0-0.2-0.03-0.28-0.08s-0.12-0.12-0.15-0.21s-0.03-0.38-0.03-0.89v-2.51h1.12v-1.31h-1.12v-2.2l-1.65,0.96v1.24h-0.76v1.31
                h0.76V25.24z M62.29,23.37v-0.16c0-0.32-0.08-0.54-0.23-0.68s-0.45-0.2-0.88-0.2c-0.29,0-0.52,0.06-0.69,0.17s-0.3,0.32-0.4,0.61
                l-1.49-0.27c0.17-0.6,0.46-1.05,0.87-1.34s1.02-0.43,1.83-0.43c0.73,0,1.28,0.09,1.64,0.26s0.61,0.39,0.76,0.66s0.22,0.76,0.22,1.47
                l-0.02,1.92c0,0.55,0.03,0.95,0.08,1.21s0.15,0.54,0.3,0.83h-1.63c-0.04-0.11-0.1-0.27-0.16-0.49c-0.03-0.1-0.05-0.16-0.06-0.19
                c-0.28,0.27-0.58,0.48-0.9,0.62s-0.66,0.21-1.03,0.21c-0.64,0-1.15-0.17-1.51-0.52s-0.55-0.79-0.55-1.32c0-0.35,0.08-0.67,0.25-0.94
                s0.4-0.49,0.71-0.63s0.74-0.27,1.31-0.38C61.46,23.63,62,23.5,62.29,23.37z M62.29,24.44c-0.21,0.07-0.54,0.15-1,0.25
                s-0.76,0.19-0.9,0.29c-0.21,0.15-0.32,0.35-0.32,0.58c0,0.23,0.09,0.43,0.26,0.6s0.39,0.25,0.66,0.25c0.3,0,0.58-0.1,0.85-0.29
                c0.2-0.15,0.33-0.33,0.39-0.54c0.04-0.14,0.06-0.41,0.06-0.8V24.44z M29.05,41.83h1.65v-4.93h1.23v-1.29H30.7v-0.44
                c0-0.32,0.05-0.53,0.16-0.64s0.27-0.17,0.51-0.17c0.24,0,0.49,0.03,0.76,0.09l0.22-1.15c-0.45-0.14-0.91-0.21-1.38-0.21
                c-0.46,0-0.83,0.08-1.13,0.24s-0.5,0.37-0.62,0.63s-0.17,0.65-0.17,1.17v0.47h-0.91v1.29h0.91V41.83z M34.43,39.91
                c0-1.06,0.05-1.75,0.14-2.09s0.22-0.56,0.38-0.69s0.36-0.19,0.59-0.19c0.24,0,0.5,0.09,0.77,0.27l0.51-1.44
                c-0.35-0.21-0.71-0.31-1.08-0.31c-0.27,0-0.5,0.07-0.71,0.2s-0.44,0.41-0.71,0.83v-0.88h-1.53v6.22h1.65V39.91z M37.52,41.83h1.65
                v-6.22h-1.65V41.83z M39.17,33.24h-1.65v1.52h1.65V33.24z M46.19,39.2h-4.12c0.01,0.49,0.14,0.87,0.4,1.14s0.57,0.41,0.95,0.41
                c0.26,0,0.47-0.07,0.65-0.21s0.31-0.37,0.4-0.68l1.64,0.28c-0.21,0.6-0.54,1.06-1,1.37s-1.02,0.47-1.71,0.47
                c-1.08,0-1.88-0.35-2.4-1.06c-0.41-0.57-0.62-1.28-0.62-2.14c0-1.03,0.27-1.84,0.81-2.42s1.22-0.88,2.04-0.88
                c0.93,0,1.66,0.31,2.19,0.92S46.21,37.93,46.19,39.2z M44.55,38.19c-0.01-0.48-0.13-0.84-0.37-1.09s-0.52-0.37-0.86-0.37
                c-0.36,0-0.66,0.13-0.89,0.39s-0.35,0.62-0.35,1.07H44.55z M51.1,36.89c0.16,0.11,0.27,0.26,0.34,0.46s0.11,0.63,0.11,1.3v3.18h1.65
                v-3.87c0-0.48-0.03-0.85-0.09-1.11s-0.17-0.49-0.32-0.69s-0.38-0.37-0.68-0.5s-0.63-0.2-1-0.2c-0.82,0-1.51,0.35-2.05,1.05v-0.91
                h-1.53v6.22h1.65v-2.82c0-0.7,0.04-1.17,0.13-1.43s0.24-0.46,0.47-0.62s0.48-0.23,0.77-0.23C50.75,36.73,50.94,36.78,51.1,36.89z
                M54.49,38.7c0-1.05,0.25-1.86,0.74-2.41s1.12-0.82,1.88-0.82c0.7,0,1.3,0.29,1.8,0.87v-3.09h1.65v8.59h-1.53v-0.91
                c-0.25,0.36-0.55,0.62-0.9,0.79s-0.7,0.26-1.05,0.26c-0.71,0-1.33-0.29-1.84-0.86S54.49,39.73,54.49,38.7z M56.17,38.59
                c0,0.66,0.09,1.14,0.28,1.44c0.27,0.43,0.64,0.64,1.11,0.64c0.38,0,0.7-0.16,0.97-0.48s0.4-0.8,0.4-1.44c0-0.71-0.13-1.23-0.39-1.54
                s-0.59-0.47-0.99-0.47c-0.39,0-0.72,0.16-0.98,0.47S56.17,37.97,56.17,38.59z M62.19,41.83h1.65v-8.59h-1.65V41.83z M66.69,42.71
                c-0.18,0.22-0.47,0.34-0.85,0.34c-0.21,0-0.44-0.03-0.7-0.08l0.15,1.29c0.3,0.07,0.62,0.1,0.93,0.1c0.31,0,0.59-0.03,0.84-0.1
                s0.46-0.16,0.63-0.28s0.32-0.27,0.45-0.47s0.27-0.47,0.41-0.83l0.39-1.08l2.2-5.99h-1.71l-1.45,4.42l-1.49-4.42h-1.75l2.37,6.24
                C67.02,42.2,66.87,42.48,66.69,42.71z M31.9,63.07h2.89V48.75H31.9v5.63h-5.66v-5.63h-2.89v14.32h2.89v-6.26h5.66V63.07z
                M39.77,62.66c-0.86-0.43-1.52-1.06-1.97-1.89s-0.67-1.84-0.67-3.03c0-0.91,0.22-1.79,0.67-2.65c0.45-0.85,1.09-1.5,1.91-1.95
                s1.74-0.67,2.76-0.67c1.57,0,2.85,0.51,3.86,1.53s1.5,2.31,1.5,3.86c0,1.57-0.51,2.87-1.52,3.9c-1.01,1.03-2.29,1.55-3.82,1.55
                C41.54,63.3,40.63,63.09,39.77,62.66z M39.94,57.88c0,1.03,0.24,1.82,0.73,2.36s1.09,0.82,1.81,0.82s1.32-0.27,1.8-0.82
                s0.73-1.34,0.73-2.38c0-1.02-0.24-1.8-0.73-2.34s-1.09-0.82-1.8-0.82s-1.32,0.27-1.81,0.82S39.94,56.85,39.94,57.88z M65.03,63.07
                v-6.63c0-0.99-0.1-1.71-0.28-2.15c-0.26-0.6-0.65-1.05-1.18-1.36s-1.17-0.46-1.93-0.46c-0.6,0-1.16,0.14-1.69,0.41
                s-1.02,0.69-1.47,1.24c-0.31-0.55-0.71-0.97-1.2-1.24s-1.06-0.41-1.73-0.41c-1.25,0-2.33,0.55-3.23,1.65V52.7h-2.53v10.37h2.74
                v-5.04c0-1,0.07-1.71,0.22-2.14s0.39-0.76,0.73-0.99c0.34-0.23,0.72-0.34,1.14-0.34c0.35,0,0.63,0.07,0.83,0.21
                c0.21,0.14,0.36,0.36,0.45,0.65s0.15,0.94,0.15,1.95v5.68h2.74v-4.98c0-0.98,0.07-1.69,0.22-2.14c0.15-0.45,0.39-0.8,0.73-1.03
                c0.34-0.23,0.7-0.35,1.08-0.35c0.53,0,0.92,0.19,1.17,0.59c0.19,0.3,0.28,0.96,0.28,1.99v5.93H65.03z M73.77,59.77l2.73,0.46
                c-0.35,1-0.91,1.77-1.67,2.29c-0.76,0.52-1.71,0.79-2.85,0.79c-1.8,0-3.14-0.59-4-1.77c-0.68-0.94-1.03-2.13-1.03-3.57
                c0-1.72,0.45-3.06,1.35-4.04s2.03-1.46,3.41-1.46c1.54,0,2.76,0.51,3.65,1.53s1.32,2.58,1.28,4.68h-6.88
                c0.02,0.81,0.24,1.45,0.66,1.9c0.42,0.45,0.95,0.68,1.58,0.68c0.43,0,0.79-0.12,1.08-0.35S73.62,60.29,73.77,59.77z M69.82,56.99
                h4.1c-0.02-0.79-0.22-1.4-0.62-1.81c-0.39-0.41-0.87-0.62-1.43-0.62c-0.6,0-1.09,0.22-1.48,0.65
                C70.01,55.65,69.82,56.25,69.82,56.99z M30.2,66.87h-2.89v14.2h10.08v-2.41H30.2V66.87z M48,71.99c1,1.02,1.5,2.31,1.5,3.86
                c0,1.57-0.51,2.87-1.52,3.9c-1.01,1.03-2.29,1.55-3.82,1.55c-0.95,0-1.86-0.21-2.72-0.64s-1.52-1.06-1.97-1.89s-0.67-1.84-0.67-3.03
                c0-0.91,0.22-1.79,0.67-2.65c0.45-0.85,1.09-1.5,1.91-1.95s1.74-0.67,2.76-0.67C45.71,70.46,46.99,70.97,48,71.99z M46.68,75.86
                c0-1.02-0.24-1.8-0.73-2.34s-1.09-0.82-1.8-0.82s-1.32,0.27-1.81,0.82s-0.73,1.33-0.73,2.36c0,1.03,0.24,1.82,0.73,2.36
                s1.09,0.82,1.81,0.82s1.32-0.27,1.8-0.82S46.68,76.91,46.68,75.86z M60.17,79.68c0.09,0.43,0.25,0.9,0.49,1.39h-2.71
                c-0.07-0.18-0.16-0.45-0.26-0.81c-0.05-0.16-0.08-0.27-0.1-0.32c-0.47,0.46-0.97,0.8-1.5,1.03c-0.53,0.23-1.1,0.34-1.71,0.34
                c-1.07,0-1.91-0.29-2.52-0.87c-0.62-0.58-0.92-1.31-0.92-2.2c0-0.59,0.14-1.11,0.42-1.57c0.28-0.46,0.67-0.81,1.18-1.05
                c0.5-0.24,1.23-0.46,2.18-0.64c1.28-0.24,2.17-0.46,2.67-0.67v-0.27c0-0.53-0.13-0.9-0.39-1.13c-0.26-0.22-0.75-0.34-1.47-0.34
                c-0.49,0-0.87,0.1-1.14,0.29c-0.27,0.19-0.5,0.53-0.66,1.01l-2.49-0.45c0.28-1,0.76-1.74,1.45-2.23c0.68-0.48,1.7-0.72,3.05-0.72
                c1.22,0,2.13,0.15,2.73,0.43c0.6,0.29,1.02,0.66,1.26,1.1c0.24,0.45,0.37,1.26,0.37,2.46l-0.03,3.2
                C60.04,78.57,60.08,79.24,60.17,79.68z M57.37,76.09c-0.35,0.12-0.91,0.26-1.67,0.42c-0.76,0.16-1.26,0.32-1.49,0.48
                c-0.36,0.25-0.54,0.58-0.54,0.97c0,0.38,0.14,0.72,0.43,1c0.29,0.28,0.65,0.42,1.09,0.42c0.49,0,0.97-0.16,1.42-0.49
                c0.33-0.25,0.55-0.55,0.65-0.91c0.07-0.23,0.11-0.68,0.11-1.34V76.09z M71.52,71.63c-0.26-0.34-0.64-0.62-1.14-0.83
                c-0.5-0.22-1.05-0.33-1.66-0.33c-1.37,0-2.51,0.59-3.42,1.76V70.7h-2.55v10.37h2.74v-4.7c0-1.16,0.07-1.95,0.21-2.38
                s0.4-0.77,0.78-1.04c0.38-0.26,0.8-0.39,1.28-0.39c0.37,0,0.69,0.09,0.95,0.27c0.26,0.18,0.45,0.44,0.57,0.77
                c0.12,0.33,0.18,1.05,0.18,2.17v5.29h2.74v-6.45c0-0.8-0.05-1.42-0.15-1.85S71.77,71.96,71.52,71.63z"
                fill="currentColor"
            />
        </svg>
    );
  }
  
  export default WidgetCTAIcon;