import { HomeLoanApplication } from 'src/api/HomeLoanApplicationApi';
import PersonalInfo from './field_groups/PersonalInfo';
import ContactInfo from './field_groups/ContactInfo';
import EmploymentInfo from './field_groups/EmploymentInfo';
import OtherInfo from './field_groups/OtherInfo';
import RequiredInfo from './field_groups/RequiredInfo';

type Props = {
    selectedPage: number;
    className?: string;
    homeLoanApp?: HomeLoanApplication;
};

const ApplyFormList = ({
    className,
    selectedPage,
    homeLoanApp
}: Props) => {
    return (
        <div className={`mb-3 px-4 ${className || ''}`}>
            {(selectedPage === 0) && (
                <PersonalInfo
                    homeLoanApp={homeLoanApp}
                />
            )}

            {(selectedPage === 1) && (
                <ContactInfo
                    homeLoanApp={homeLoanApp}
                />
            )}

            {(selectedPage === 2) && (
                <EmploymentInfo
                    homeLoanApp={homeLoanApp}
                />
            )}

            {(selectedPage === 3) && (
                <OtherInfo
                    homeLoanApp={homeLoanApp}
                />
            )}

            {(selectedPage === 4) && (
                <RequiredInfo
                    homeLoanApp={homeLoanApp}
                />
            )}
        </div>
    );
};

export default ApplyFormList;
