import { useCallback, useState } from 'react';
import { HttpRequestException } from 'src/api/lib/HttpRequestException';
import { ErrorCode } from 'src/api/types';
import useToast from './useToast';

const useGenericErrorHandler = () => {
    const toast = useToast();

    const [errors, setErrors] = useState<string[]>([]);

    const errorHandlerWithToast = useCallback((ex: any) => {
        if (ex instanceof HttpRequestException) {
            if (ex.getCode() === ErrorCode.VALIDATION_ERROR) {
                const errors = ex.getData() as { errors: string[]; field: string }[];
                const errMsg = errors.reduce(
                    (prevVal, curVal) => prevVal.concat(...curVal.errors), 
                    [] as string[]
                ).join(' ');

                toast(errMsg, 'error');
            }
            else {
                toast(ex.getData() as string, 'error');
            }
        }
        else {
            toast(ex.message, 'error');
        }
    }, [toast]);

    const commonErrorHandler = useCallback((ex: any) => {
        if (ex instanceof HttpRequestException) {
            if (ex.getCode() === ErrorCode.VALIDATION_ERROR) {
                const errors = ex.getData() as { errors: string[]; field: string }[];

                setErrors(errors.reduce(
                    (prevVal, curVal) => prevVal.concat(...curVal.errors), 
                    [] as string[]
                ));
            }
            else {
                setErrors([ex.getData() as string]);
            }
        }
        else {
            setErrors([ex.message]);
        }
    }, []);

    const extractFormErrors = (ex: any) => {
        const formErrors: Record<string, string> = {};

        if (ex instanceof HttpRequestException) {
            if (ex.getCode() === ErrorCode.VALIDATION_ERROR) {
                const errors = ex.getData() as { errors: string[]; field: string }[];

                errors.forEach(err => {
                    formErrors[err.field] = err.errors[0];
                });
            }
            else {
                formErrors['__common__'] = ex.getData() as string;
            }
        }
        else {
            formErrors['__common__'] = ex.message;
        }

        return formErrors;
    };

    return {
        errorHandlerWithToast,
        commonErrorHandler,
        extractFormErrors,
        setCommonErrors: setErrors,
        errors
    };
};

export default useGenericErrorHandler;
