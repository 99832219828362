import { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate } from 'react-router-dom';
import { LenderLoanApi, LenderLoanWithMoreInfo } from 'src/api/LenderLoanApi';
import { useAppSelector } from 'src/hooks/generic';
import useAuth from 'src/hooks/useAuth';
import {
    selectTotalSubProjectsCost,
    selectSelectedSubProjects,
    selectProjectCost,
    selectProjectStatus,
    selectProjectType
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    selectIsBusinessProfitable,
    selectIsRegularEmployee,
    selectMonthlyIncome,
    selectWorkExperience,
    selectWorkType
} from 'src/store/features/preApproveLoanWorkInfoSlice';
import {
    selectExtraIncomeAmount,
    selectExistingDebtAmount,
    selectHasCancelledCreditCard,
    selectHasFullPaymentCertificate,
    selectCoBorrowerMonthlyIncome
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import {
    selectBirthdate,
    selectNationality,
    selectVisaType,
    selectIsSpouseCosign,
    selectMaritalStatus,
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import {
    selectLoanTerm,
    selectPercentOfProjectCost,
    selectOwnedBanks
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import { selectHasBookingFeature, selectHasBuyerPortal, selectHasReservationPaymentsFeature, selectWidgetType } from 'src/store/features/commonSlice';
import { AngleDownSolidIcon, CircleExclamationSolidIcon, CircleLoaderIcon } from 'src/components/icons';
import { HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import { dateDiffInYears } from 'src/helpers/date';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import visaTypes from 'src/data/visaTypes.json';
import workExperiences from 'src/data/workExperiences.json';
import usePreQualificationApi from '../usePreQualificationApi';
import { hasProp } from 'src/helpers/object';

type Props = {
    className?: string;
    preQualificationId?: string;
    onUserNotLoggedIn?: () => void;
    onResult?: (count: number) => void;
};

const SearchResults = ({
    className,
    onUserNotLoggedIn,
    preQualificationId,
    onResult
}: Props) => {
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const hasBuyerPortal = useAppSelector(selectHasBuyerPortal);

    const {
        isSavingPreQualification,
        createPreQualification,
        savePreQualificationInfo
    } = usePreQualificationApi();

    const navigate = useNavigate();
    const { user } = useAuth();

    const hasBookingFeature = useAppSelector(selectHasBookingFeature);
    const hasReservationPaymentsFeature = useAppSelector(selectHasReservationPaymentsFeature);
    const widgetType = useAppSelector(selectWidgetType);
    const subProjects = useAppSelector(selectSelectedSubProjects);
    const totalSubProjectsCost = useAppSelector(selectTotalSubProjectsCost);
    const loanTerm = useAppSelector(selectLoanTerm);
    const percentageOfProjectCost = useAppSelector(selectPercentOfProjectCost);
    const monthlyIncome = useAppSelector(selectMonthlyIncome);
    const extraIncomeAmount = useAppSelector(selectExtraIncomeAmount);
    const existingDebtAmount = useAppSelector(selectExistingDebtAmount);
    const ownedBanks = useAppSelector(selectOwnedBanks);
    const coBorrowerMonthlyIncome = useAppSelector(selectCoBorrowerMonthlyIncome);
    const birthdate = useAppSelector(selectBirthdate);
    const workType = useAppSelector(selectWorkType);
    const workExperience = useAppSelector(selectWorkExperience);
    const selectedIsRegularEmployee = useAppSelector(selectIsRegularEmployee);
    const isBusinessProfitable = useAppSelector(selectIsBusinessProfitable);
    const hasCancelledCreditCard = useAppSelector(selectHasCancelledCreditCard);
    const hasFullPaymentCertificate = useAppSelector(selectHasFullPaymentCertificate);
    const nationality = useAppSelector(selectNationality);
    const visaType = useAppSelector(selectVisaType);
    const projectCost = useAppSelector(selectProjectCost);
    const projectType = useAppSelector(selectProjectType);
    const projectStatus = useAppSelector(selectProjectStatus);
    const isSpouseCosign = useAppSelector(selectIsSpouseCosign);
    const maritalStatus = useAppSelector(selectMaritalStatus);

    const [result, setResult] = useState<LenderLoanWithMoreInfo|null>(null);
    const [isModifiedPercentageOfProjectCostData, setIsModifiedPercentageOfProjectCostData] = useState(false);
    const [isModifiedLoanTermData, setIsModifiedLoanTermData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);

    const handleResult = useMemo(() => {
        return onResult || (() => {});
    }, []);

    const searchPreQualifications = useCallback(async () => {
        if (
            percentageOfProjectCost > 0
            && monthlyIncome > 0
            && typeof birthdate !== 'undefined'
        ) {
            const monthlyReceivable = monthlyIncome + extraIncomeAmount + coBorrowerMonthlyIncome;

            const birthdateObj = new Date(birthdate.year as number, birthdate.month as number, birthdate.day as number);
            const currentDate = new Date();
            const age = dateDiffInYears(currentDate, birthdateObj);

            let isRegularEmployment = selectedIsRegularEmployee;
            if (workType === 'Self-Employed') {
                isRegularEmployment = false;
            }

            let projType = '';
            let projStatus = '';
            let projTypes: string[] = [];
            let projId = '';

            if (widgetType === 'advance') {
                for (let i = subProjects.length - 1; i >= 0; i -= 1) {
                    projTypes.push(subProjects[i].projectType);

                    if (subProjects[i].projectType === 'Vacant Lot') {
                        projType = subProjects[i].projectType;
                        projStatus = subProjects[i].projectStatus;
                        projId = subProjects[i].projectId;
                        break;
                    }
                    else if (
                        (
                            subProjects[i].projectType === 'Condominium'
                            && ['', 'Apartment', 'House & Lot', 'Townhouse'].includes(projType)
                        )
                        || (
                            subProjects[i].projectType === 'Apartment'
                            && ['', 'House & Lot', 'Townhouse'].includes(projType)
                        )
                        || (
                            subProjects[i].projectType === 'Townhouse'
                            && ['', 'House & Lot'].includes(projType)
                        )
                        || (
                            subProjects[i].projectType === 'House & Lot'
                            && projType === ''
                        )
                    ) {
                        projType = subProjects[i].projectType;
                        projStatus = subProjects[i].projectStatus;
                        projId = subProjects[i].projectId;
                    }
                }
            }
            else {
                projType = projectType;
                projStatus = projectStatus;
            }

            setIsLoading(true);

            try {
                if (nationality === 'Foreigner') {
                    const idx = visaTypes.findIndex(type => (type === visaType));

                    if (idx === visaTypes.length - 1) {
                        setIsLoading(false);
                        return;
                    }

                    const matches = ['Vacant Lot', 'House & Lot', 'Apartment', 'Townhouse'].filter(element => projTypes.includes(element));
                    if (matches.length) {
                        setIsLoading(false);
                        return;
                    }
                }

                if (maritalStatus === 'Separated (Legally Married)' && !isSpouseCosign) {
                    setIsLoading(false);
                    return;
                }

                const workExperienceIdx = workExperiences.findIndex(workExp => (workExp === workExperience));

                const resp = await Container.get(LenderLoanApi).searchPreQualificationLenderLoans({
                    loanTerm,
                    percentageOfProjectCost,
                    monthlyReceivable,
                    projectCost: (widgetType === 'advance') ? totalSubProjectsCost : projectCost,
                    ownedBanks,
                    monthlyDebt: existingDebtAmount,
                    age,
                    workType,
                    workExperience: workExperienceIdx,
                    projectType: projType,
                    projectStatus: projStatus,
                    isRegularEmployment,
                    visaType,
                    nationality,
                    projectId: projId
                });

                if (resp.list.length > 0) {
                    setResult(resp.list[0]);
                }

                if (resp.isModifiedData) {
                    if (resp.modifiedData.includes('percentage_of_project_cost')) {
                        setIsModifiedPercentageOfProjectCostData(true);
                    }
                    else {
                        setIsModifiedPercentageOfProjectCostData(false);
                    }

                    if (resp.modifiedData.includes('loan_term')) {
                        setIsModifiedLoanTermData(true);
                    }
                    else {
                        setIsModifiedLoanTermData(false);
                    }
                }
                else {
                    setIsModifiedPercentageOfProjectCostData(false);
                    setIsModifiedLoanTermData(false);
                }

                handleResult(resp.list.length);
            }
            catch (ex) {
                errorHandlerWithToast(ex);
            }
            finally {
                setIsLoading(false);
            }
        }
    }, [
        totalSubProjectsCost,
        loanTerm,
        percentageOfProjectCost,
        monthlyIncome,
        extraIncomeAmount,
        existingDebtAmount,
        coBorrowerMonthlyIncome,
        ownedBanks,
        birthdate,
        workType,
        workExperience,
        selectedIsRegularEmployee,
        nationality,
        visaType,
        projectCost,
        projectType,
        projectStatus,
        widgetType,
        subProjects,
        handleResult,
        errorHandlerWithToast
    ]);

    useEffect(() => {
        if (
            (hasCancelledCreditCard === true && hasFullPaymentCertificate === false)
            || (workType === 'Self-Employed' && isBusinessProfitable === false)
        ) {
            return;
        }

        searchPreQualifications();
    }, [
        hasCancelledCreditCard,
        hasFullPaymentCertificate,
        workType,
        isBusinessProfitable,
        searchPreQualifications
    ]);

    const handleUserNotLoggedIn = onUserNotLoggedIn || (() => {});

    const handleApply = async () => {
        if (!user) {
            handleUserNotLoggedIn();
            return;
        }

        let preQualId = preQualificationId;
        if (!preQualId) {
            const preApprovalRequest = await createPreQualification(false);
            if (preApprovalRequest !== null) {
                preQualId = preApprovalRequest.id;
            }
        }

        try {
            if (result) {
                setIsLoading(true);
                const homeLoanApp = await Container.get(HomeLoanApplicationApi).create({
                    preQualificationId: preQualId || '',
                    lenderLoanId: result.id,
                    percentOfProjectCost: (isModifiedPercentageOfProjectCostData && result) ? Math.floor(result.percentageOfProjectCost) : undefined,
                    loanTerm: (isModifiedLoanTermData && result) ? result.loanTerm : undefined
                });

                navigate(`/home-loan-apply?id=${homeLoanApp.id}`);
            }
        }
        catch (ex) {
            errorHandlerWithToast(ex);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleBookATour = async () => {
        if (hasBuyerPortal) {
            savePreQualificationInfo('book_a_tour');
            return;
        }

        if (!user) {
            handleUserNotLoggedIn();
            return;
        }

        let preQualId = preQualificationId;
        if (!preQualId) {
            const preQualification = await createPreQualification(false);
            if (preQualification !== null) {
                preQualId = preQualification.id;
            }
        }

        navigate(`/book-a-tour?preQualificationId=${preQualId}`);
    };

    const handlePayReservation = async () => {
        if (hasBuyerPortal) {
            savePreQualificationInfo('reserve_now');
            return;
        }

        let preQualId = preQualificationId;
        if (!preQualId) {
            const preQualification = await createPreQualification(false);
            if (preQualification !== null) {
                preQualId = preQualification.id;
            }
        }

        window.localStorage.setItem('pre_qualification_id', (preQualificationId ? preQualificationId : ''));
        navigate('/pay-reservation');
    }

    return (
        <div>
            {isLoading && (
                <div className="flex justify-center">
                    <CircleLoaderIcon className="w-16 h-16 animate-spin"/>
                </div>
            )}

            {!isLoading && result === null && (
                <div className="text-lg leading-6 bg-yellow-100 text-black py-2 px-4 rounded-lg">
                    <p>Unfortunately, we couldn’t match you with a lender based on the information you have provided. Adding a co-borrower; Increasing the loan term and/or ensuring you have a certificate of full payment for cancelled credit cards greatly increase your chances of finding a match.</p>
                    {(nationality === 'Foreigner') && (
                        <p>For Non-Filipinos:  Please ensure that you have a valid visa.</p>
                    )}
                </div>
            )}

            {!isLoading && result !== null && (
                <>
                    <div className="mb-6">
                        {(isModifiedLoanTermData || isModifiedPercentageOfProjectCostData) ? (
                            <div className="flex p-2.5 gap-2 items-center justify-center bg-yellow-100">
                                <CircleExclamationSolidIcon
                                    className={`w-5 h-5 text-yellow-400`}
                                />
                                <span className="text-sm font-bold">We have adjusted your search inquiry to facilitate obtaining a loan.</span>
                            </div>
                        ) : (
                            <span className="text-sm font-bold">Congratulations, we've found a match for your query.</span>
                        )}
                    </div>
                    <div className={`p-8 rounded-xl h-full flex flex-wrap shadow-[0px_10px_20px_rgba(0,0,0,0.1)] ${className || ''}`}>
                        <div className="w-full py-2">
                            <div className="w-full bg-yellow-100 py-2 px-4 mb-4 lg:mt-0 font-bold">
                                Note: Quoted Rate is subject to change without prior notice and is subject to Lender&apos;s final approval.
                            </div>
                            <div className="w-full flex md:block">
                                <div className="w-full">
                                    <div className="text-center text-[14px] text-gray-50">
                                        Monthly Payments
                                    </div>
                                    <div className="flex justify-center items-baseline mt-2 text-uwi-primary-100">
                                        <div className="text-base sm:text-[26px] md:text-[40px] xl:text-[32px] leading-none mr-2">PHP</div>
                                        <div className="text-[30px] sm:text-[50px] md:text-[60px] xl:text-[80px] font-bold leading-none">
                                            {Math.round(result.monthlyPayments).toLocaleString('en-US')}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="text-center text-[14px] md:mt-4 text-gray-50">
                                        Fixed Period Interest
                                    </div>
                                    <div className="flex justify-center items-baseline mt-2 md:mt-1">
                                        <div className="text-[30px] sm:text-[50px] lg:text-[30px] font-bold leading-none mr-2">
                                            {result.fixedInterestPercentage.toFixed(2)}%
                                        </div>
                                        <div className="text-xs sm:text-[26px] md:text-base lg:[20px] leading-none">for {result.fixedInterestYear} year</div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-2 w-full mt-10">
                                {(hasBookingFeature) && (
                                    <button
                                        className={`
                                            flex justify-center items-center
                                            rounded-2xl
                                            text-[15px] font-bold
                                            px-8 py-4
                                            border
                                            ${
                                                (!(isSavingPreQualification || isLoading))
                                                ? `
                                                    border-uwi-primary-100 hover:border-uwi-primary-200
                                                    bg-transparent hover:bg-uwi-primary-200
                                                    text-uwi-primary-100 hover:text-white
                                                `
                                                : 'bg-gray-100 text-white'
                                            }
                                        `}
                                        onClick={() => handleBookATour()}
                                    >
                                        Book a Tour
                                        {(isSavingPreQualification || isLoading) && (
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                                        )}
                                    </button>
                                )}

                                {(hasReservationPaymentsFeature) && (
                                    <button
                                        className="flex justify-center items-center rounded-2xl text-[15px] font-bold px-8 py-4 border border-uwi-primary-100 hover:border-uwi-primary-200
                                            bg-transparent hover:bg-uwi-primary-200
                                            text-uwi-primary-100 hover:text-white"
                                        onClick={() => handlePayReservation()}
                                    >
                                        Reserve Now
                                    </button>
                                )}

                                {(!hasBuyerPortal) && (
                                    <button
                                        className={`
                                            flex justify-center items-center
                                            rounded-2xl
                                            text-[15px] font-bold
                                            px-8 py-4
                                            ${(!(isSavingPreQualification || isLoading)) ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white'}
                                        `}
                                        onClick={() => handleApply()}
                                    >
                                        Apply Now
                                        {(isSavingPreQualification || isLoading) && (
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="w-full">
                            <ul className={`
                                w-full flex flex-col gap-1 transition-all overflow-hidden
                                text-sm md:text-xs pt-4
                                ${(showDetails) ? 'max-h-[700px] md:max-h-[700px]' : 'max-h-[70px] md:max-h-[70px]'}
                            `}>
                                <li className={`
                                    py-2 flex px-5
                                    ${(isModifiedPercentageOfProjectCostData) ? 'bg-yellow-100 font-bold' : 'odd:bg-gray-75'}
                                `}>
                                    <div className="w-[calc((100%-32px)/2)] mr-8 flex gap-4 items-center">
                                        {(isModifiedPercentageOfProjectCostData) && (
                                            <span className="w-4 h-4">
                                                <CircleExclamationSolidIcon
                                                    className={`w-full h-full text-yellow-400`}
                                                />
                                            </span>
                                        )}
                                        <span className="flex w-full justify-end">Gross Loan</span>
                                    </div>
                                    <div className="w-[calc((100%-32px)/2)] flex items-center">
                                        {
                                            (
                                                (widgetType === 'advance' ? totalSubProjectsCost : projectCost) * (Math.floor(result.percentageOfProjectCost) / 100)
                                            )
                                            .toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
                                        }
                                    </div>
                                </li>

                                <li
                                    className={`
                                        py-2 flex px-5
                                        ${(isModifiedPercentageOfProjectCostData) ? 'bg-yellow-100 font-bold' : 'odd:bg-gray-75'}
                                    `}
                                >
                                    <div className="w-[calc((100%-32px)/2)] mr-8 flex gap-4 items-center">
                                        {(isModifiedPercentageOfProjectCostData) && (
                                            <span className="w-4 h-4">
                                                <CircleExclamationSolidIcon
                                                    className={`w-full h-full text-yellow-400`}
                                                />
                                            </span>
                                        )}
                                        <span className="flex w-full justify-end">Required Downpayment</span>
                                    </div>
                                    <div className="w-[calc((100%-32px)/2)] flex items-center">
                                        {
                                            (
                                                (widgetType === 'advance' ? totalSubProjectsCost : projectCost) * (Math.floor(100 - ((isModifiedPercentageOfProjectCostData) ? result.percentageOfProjectCost : percentageOfProjectCost)) / 100)
                                            )
                                            .toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
                                        }
                                    </div>
                                </li>

                                {(isModifiedLoanTermData) && (
                                    <li className="py-2 flex px-5 bg-yellow-100 font-bold">
                                        <div className="w-[calc((100%-32px)/2)] mr-8 flex gap-4 items-center">
                                            <span className="w-4 h-4">
                                                <CircleExclamationSolidIcon
                                                    className={`w-full h-full text-yellow-400`}
                                                />
                                            </span>
                                            <span className="flex w-full justify-end">Loan Term</span>
                                        </div>
                                        <div className="w-[calc((100%-32px)/2)] flex items-center">
                                            {result.loanTerm}
                                        </div>
                                    </li>
                                )}

                                <li className={`
                                    py-2 flex px-5
                                    ${(isModifiedPercentageOfProjectCostData || isModifiedLoanTermData) ? 'bg-yellow-100 font-bold' : 'odd:bg-gray-75'}
                                `}>
                                    <div className="w-[calc((100%-32px)/2)] mr-8 flex gap-4 items-center">
                                        {(isModifiedPercentageOfProjectCostData || isModifiedLoanTermData) && (
                                            <span className="w-4 h-4">
                                                <CircleExclamationSolidIcon
                                                    className={`w-full h-full text-yellow-400`}
                                                />
                                            </span>
                                        )}
                                        <span className="flex w-full justify-end">Monthly Payments</span>
                                    </div>
                                    <div className="w-[calc((100%-32px)/2)] flex items-center">
                                        {Math.round(result.monthlyPayments).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                    </div>
                                </li>
                                <li className={`
                                    py-2 flex px-5
                                    ${(isModifiedPercentageOfProjectCostData || isModifiedLoanTermData) ? 'bg-yellow-100 font-bold' : 'odd:bg-gray-75'}
                                `}>
                                    <div className="w-[calc((100%-32px)/2)] mr-8 flex gap-4 items-center">
                                        {(isModifiedPercentageOfProjectCostData || isModifiedLoanTermData) && (
                                            <span className="w-4 h-4">
                                                <CircleExclamationSolidIcon
                                                    className={`w-full h-full text-yellow-400`}
                                                />
                                            </span>
                                        )}
                                        <span className="flex w-full justify-end">Total Payments</span>
                                    </div>
                                    <div className="w-[calc((100%-32px)/2)] flex items-center">{result.totalPayments.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</div>
                                </li>
                                <li className="odd:bg-gray-75 py-2 flex">
                                    <div className="w-[calc((100%-32px)/2)] text-right mr-8">Fixed Period Interest</div>
                                    <div className="w-[calc((100%-32px)/2)]">
                                        {result.fixedInterestPercentage.toFixed(2)}% for {result.fixedInterestYear} year
                                    </div>
                                </li>
                                <li className="odd:bg-gray-75 py-2 flex">
                                    <div className="w-[calc((100%-32px)/2)] text-right mr-8">Bank Fees</div>
                                    <div className="w-[calc((100%-32px)/2)]">{result.bankFees}</div>
                                </li>
                                <li className="odd:bg-gray-75 py-2 flex">
                                    <div className="w-[calc((100%-32px)/2)] text-right mr-8">Loan Application Fee</div>
                                    <div className="w-[calc((100%-32px)/2)]">{result.loanApplicationFee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</div>
                                </li>
                                <li className="odd:bg-gray-75 py-2 flex">
                                    <div className="w-[calc((100%-32px)/2)] text-right mr-8">Insurances</div>
                                    <div className="w-[calc((100%-32px)/2)]">{result.insurances}</div>
                                </li>
                                <li className="odd:bg-gray-75 py-2 flex">
                                    <div className="w-[calc((100%-32px)/2)] text-right mr-8">Processing time</div>
                                    <div className="w-[calc((100%-32px)/2)]">{result.processingTime}</div>
                                </li>
                            </ul>

                            <div className="w-full flex justify-center">
                                <button
                                    onClick={() => setShowDetails(!showDetails)}
                                    className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent focus:bg-transparent focus-visible:border-none"
                                >
                                    <AngleDownSolidIcon
                                        className={`
                                            w-8 h-8
                                            ${(showDetails) ? 'rotate-180' : ''}
                                        `}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default SearchResults;
