import { useEffect, useMemo, useState } from 'react';
import { Container } from 'typedi';
import { CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { Booking, BookingApi, BookingCalendarTimeSlot } from 'src/api/BookingApi';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import { ErrorList, Select, SelectOption } from 'src/components/common';
import useToast from 'src/hooks/useToast';
import { formatBookingTimeTo12HourFormat } from 'src/helpers/booking';
import { useAppSelector } from 'src/hooks/generic';
import { selectAgents, selectIsAgentSelectionEnabled } from 'src/store/features/commonSlice';
import { BookingCalendar } from '../book-a-tour/booking_calendar';

type Props = {
    subProject: AddedSubProject;
    booking: Booking;
    onUpdatedBooking: (booking: Booking) => void;
    preQualificationId: string;
};

const UpdateBookingForm = ({
    subProject,
    booking,
    onUpdatedBooking,
    preQualificationId
}: Props) => {
    const isAgentSelectionEnabled = useAppSelector(selectIsAgentSelectionEnabled);
    const agents = useAppSelector(selectAgents);
    
    const {
        commonErrorHandler,
        setCommonErrors,
        errors
    } = useGenericErrorHandler();
    const toast = useToast();

    const [hasUpdated, setHasUpdated] = useState(false);
    const [timeSlotList, setTimeSlotList] = useState<BookingCalendarTimeSlot[]>([]);
    const [isLoadingTimeSlotList, setIsLoadingTimeSlotList] = useState(false);
    const [isTimeSlotFirstDataLoaded, setIsTimeSlotFirstDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(booking.bookingDate);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<BookingCalendarTimeSlot>({
        id: '',
        startTime: booking.startTime,
        endTime: booking.endTime,
        isAvailable: true
    });
    const [agentId, setAgentId] = useState(booking.developerAccountId);

    useEffect(() => {
        setIsLoadingTimeSlotList(true);
        Container
            .get(BookingApi)
            .subProjectTimeSlotsInfoForDate(subProject.id, {
                date: selectedDate,
                agentId: (agentId.trim().length > 0 && agentId !== 'system') ? agentId : undefined
            })
            .then(timeSlotList => {    
                const timeSlot = timeSlotList.find(ts => (ts.startTime === booking.startTime));
                if (timeSlot) {
                    setSelectedTimeSlot(timeSlot);
                }

                setTimeSlotList(timeSlotList);
                setIsTimeSlotFirstDataLoaded(true);
            })
            .catch(commonErrorHandler)
            .finally(() => {
                setIsLoadingTimeSlotList(false);
            });
    }, [subProject, selectedDate, agentId, booking, booking?.startTime, commonErrorHandler]);

    const handleClickDate = (date: Date) => {
        setSelectedDate(date);
        setHasUpdated(true);
        setCommonErrors([]);

        if (subProject) {
            setIsLoadingTimeSlotList(true);
            Container
                .get(BookingApi)
                .subProjectTimeSlotsInfoForDate(subProject.id, {
                    date,
                    agentId: (agentId.trim().length > 0 && agentId !== 'system') ? agentId : undefined
                })
                .then(timeSlotList => {
                    setTimeSlotList(timeSlotList);
                    setIsTimeSlotFirstDataLoaded(true);

                    if (booking && formatDateToYYYYMMDD(booking.bookingDate) === formatDateToYYYYMMDD(date)) {
                        const timeSlot = timeSlotList.find(ts => (ts.startTime === booking.startTime));
                        if (timeSlot) {
                            setSelectedTimeSlot(timeSlot);
                            return;
                        }
                    }

                    setSelectedTimeSlot({
                        id: '',
                        startTime: '',
                        endTime: '',
                        isAvailable: true
                    });
                })
                .catch(commonErrorHandler)
                .finally(() => {
                    setIsLoadingTimeSlotList(false);
                });
        }
    };

    const handleChangeSelectedTimeSlot = (val: string) => {
        const timeSlot = timeSlotList.find(ts => (ts.id === val));
        if (timeSlot) {
            setSelectedTimeSlot(timeSlot);
            setHasUpdated(true);
        }
    };

    const handleSubmit = () => {
        const errors: string[] = [];

        if (selectedDate === null) {
            errors.push('Please select a date first.');
        }

        if (selectedTimeSlot.id === '') {
            errors.push('Please select a time slot first.');
        }

        if (isAgentSelectionEnabled && agentId === '') {
            errors.push('Please select an agent first.');
        }

        setCommonErrors(errors);

        if (subProject && selectedTimeSlot && selectedDate && errors.length === 0) {
            const tmpAgentId = (isAgentSelectionEnabled && agentId !== '') ? agentId : 'system';

            setIsSubmitting(true);
            Container
                .get(BookingApi)
                .subProjectBookDateAndTimeSlot(subProject.id, {
                    preQualificationId,
                    date: selectedDate,
                    bookingScheduleId: selectedTimeSlot.id,
                    agentId: tmpAgentId
                })
                .then(booking => {
                    onUpdatedBooking(booking);
                    toast('Great, your booking has been successfully updated.', 'success');
                    setHasUpdated(false);
                })
                .catch(commonErrorHandler)
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const agentOpts = useMemo(() => {
        const agentsCopy = [
            ...[{
                id: 'system',
                name: 'Auto Select'
            }],
            ...agents
        ];

        return agentsCopy;
    }, [agents]);

    return (
        <div className="flex justify-center">
            <div className="w-full lg:w-[calc(100%-230px)] pl-5 pt-4 pb-4">
                <h1 className="text-center mb-5 font-bold">
                    {subProject.name}
                </h1>

                <div className="md:flex md:px-4 lg:px-0">
                    <div className="w-full md:w-1/2 md:mr-10">
                        {(isAgentSelectionEnabled) && (
                            <Select
                                value={agentId}
                                onChange={(agentId) => setAgentId(agentId as string)}
                                className="mb-4"
                                placeholder="Select An Agent For This Booking"
                            >
                                {agentOpts.map(agent => (
                                    <SelectOption
                                        key={agent.id}
                                        value={agent.id}
                                    >
                                        {agent.name}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}

                        <BookingCalendar
                            onClickDate={handleClickDate}
                            selectedDate={selectedDate}
                            isSubProjectBookingInfoLoaded={true}
                            bookingDate={formatDateToYYYYMMDD(booking.bookingDate)}
                            bookingStatus={booking.status}
                            subProjectId={subProject.id}
                            agentId={(agentId.trim().length > 0 && agentId !== 'system') ? agentId : undefined}
                        />
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="">
                            <ErrorList
                                errors={errors}
                                visible={true}
                            />

                            {(isTimeSlotFirstDataLoaded && !isLoadingTimeSlotList && timeSlotList.length === 0) && (
                                <div className="w-full py-4 text-center px-4 bg-red-300 text-red-800 leading-5">
                                    Sorry, there are no time slots available for this day.
                                </div>
                            )}
                        </div>

                        {(isLoadingTimeSlotList) && (
                            <div className="w-full flex justify-center items-center h-[100px]">
                                <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                            </div>
                        )}

                        {(!isLoadingTimeSlotList && timeSlotList.length > 0) && (
                            <>
                                {timeSlotList.map((timeSlot, idx) => (
                                    <div key={idx}>
                                        {
                                            (timeSlot.isAvailable)
                                              ? <div className="border border-solid border-gray-50 py-1.5 pl-6 flex items-center mb-1">
                                                    <input
                                                        type="radio"
                                                        className="w-[18px] h-[18px] mr-3 uwi-input"
                                                        value={timeSlot.id}
                                                        checked={selectedTimeSlot?.id === timeSlot.id}
                                                        onChange={(e) => handleChangeSelectedTimeSlot(e.target.value)}
                                                    />
                                                    <div className="mr-8 text-black text-sm">{formatBookingTimeTo12HourFormat(timeSlot.startTime)} - {formatBookingTimeTo12HourFormat(timeSlot.endTime)}</div>
                                                    <div className="text-green-600 text-sm">Available</div>
                                                </div>
                                              : <div className="border border-solid border-gray-50 text-gray-50 py-1.5 pl-6 flex items-center mb-1">
                                                    <div className="w-[18px] h-[18px] bg-gray-50 rounded-full mr-3"></div>
                                                    <div className="mr-8 text-sm">{formatBookingTimeTo12HourFormat(timeSlot.startTime)} - {formatBookingTimeTo12HourFormat(timeSlot.endTime)}</div>
                                                    <div className="text-sm">Fully Booked</div>
                                                </div>
                                        }
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <button
                        className={`
                            flex justify-center
                            rounded-2xl
                            text-[15px] font-bold
                            px-4 md:px-8 py-4
                            ${(!isSubmitting && hasUpdated) ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white cursor-not-allowed'}
                        `}
                        onClick={() => handleSubmit()}
                        disabled={!hasUpdated}
                    >
                        Update Booking
                        {isSubmitting && (
                            <CircleLoaderIcon className="h-4 w-4 animate-spin ml-3 text-white" />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateBookingForm;
