import { useEffect, useMemo, useRef } from 'react';
import { CircleExclamationSolidIcon, XMarkSolidIcon } from 'src/components/icons';
import { ToastObject } from 'src/store/features/toastDataSlice';

type Props = {
    toastObj: ToastObject;
    onRemove?: (toastObjId: string) => void;
};

export default function ToastEntry({
    toastObj,
    onRemove
}: Props) {
    const lastTimeRef = useRef<number|null>(null);

    const handleRemove = useMemo(() => {
        return (onRemove || (() => {}));
    }, [onRemove]);

    useEffect(() => {
        let timeout = (lastTimeRef.current) ? 5000 - (new Date().getTime() - lastTimeRef.current) : 5000;
        timeout = Math.max(timeout, 0);
        const timeoutHandler = setTimeout(() => {
            handleRemove(toastObj.id);
        }, timeout);

        if (lastTimeRef.current === null) {
            lastTimeRef.current = new Date().getTime();
        }

        return () => {
            clearTimeout(timeoutHandler);
        };
    }, [toastObj, handleRemove]);

    return (
        <div
            key={toastObj.id}
            className={`
                bg-white text-black
                shadow-[0px_5px_10px_0px_rgba(0,0,0,0.3)]
                rounded-lg
                p-3
                mb-3
                min-w-[350px] max-w-[350px]
                flex justify-between items-center
            `}
        >
            <span
                className={`
                    flex items-center justify-start
                    ${(toastObj.type === 'error') ? 'text-red-100' : ''}
                    ${(toastObj.type === 'success') ? 'text-green-100' : ''}
                `}
            >
                <span><CircleExclamationSolidIcon className="w-5 h-5 mr-2"/></span>

                {toastObj.message}
            </span>
            <button
                className="hover:bg-transparent focus:bg-transparent text-gray-50 hover:text-uwi-primary-200"
                onClick={() => handleRemove(toastObj.id)}
            >
                <XMarkSolidIcon className="w-5 h-5" />
            </button>
        </div>
    );
}