import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface ApplyLoanContactInfoState {
    phoneNumber: string;
    mobileNumber: string;
    email: string;
    currentAddress: string;
    currentAddressHomeOwnership: string;
    currentAddressResidenceSince: number;
    permanentAddress: string;
    permanentAddressHomeOwnership: string;
    permanentAddressResidenceSince: number;
    previousAddress: string;
    previousAddressHomeOwnership: string;
    previousAddressResidenceSince: number;
    formErrors: Record<string, string>;
}

const initialState: ApplyLoanContactInfoState = {
    phoneNumber: '',
    mobileNumber: '',
    email: '',
    currentAddress: '',
    currentAddressHomeOwnership: '',
    currentAddressResidenceSince: 0,
    permanentAddress: '',
    permanentAddressHomeOwnership: '',
    permanentAddressResidenceSince: 0,
    previousAddress: '',
    previousAddressHomeOwnership: '',
    previousAddressResidenceSince: 0,
    formErrors: {}
};

export const applyLoanContactInfoSlice = createSlice({
    name: 'applyLoanContactInfo',

    initialState,

    reducers: {
        setPhoneNumber: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload;
        },

        setMobileNumber: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.mobileNumber = action.payload;
        },

        setEmail: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.email = action.payload;
        },

        setCurrentAddress: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.currentAddress = action.payload;
        },

        setCurrentAddressHomeOwnership: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.currentAddressHomeOwnership = action.payload;
        },

        setCurrentAddressResidenceSince: (state: ApplyLoanContactInfoState, action: PayloadAction<number>) => {
            state.currentAddressResidenceSince = action.payload;
        },

        setPermanentAddress: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.permanentAddress = action.payload;
        },

        setPermanentAddressHomeOwnership: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.permanentAddressHomeOwnership = action.payload;
        },

        setPermanentAddressResidenceSince: (state: ApplyLoanContactInfoState, action: PayloadAction<number>) => {
            state.permanentAddressResidenceSince = action.payload;
        },

        setPermanentAddressMerged: (state: ApplyLoanContactInfoState, action: PayloadAction<{ permanentAddress: string; permanentAddressHomeOwnership: string; permanentAddressResidenceSince: number; }>) => {
            state.permanentAddress = action.payload.permanentAddress;
            state.permanentAddressHomeOwnership = action.payload.permanentAddressHomeOwnership;
            state.permanentAddressResidenceSince = action.payload.permanentAddressResidenceSince;
        },

        setPreviousAddress: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.previousAddress = action.payload;
        },

        setPreviousAddressHomeOwnership: (state: ApplyLoanContactInfoState, action: PayloadAction<string>) => {
            state.previousAddressHomeOwnership = action.payload;
        },

        setPreviousAddressResidenceSince: (state: ApplyLoanContactInfoState, action: PayloadAction<number>) => {
            state.previousAddressResidenceSince = action.payload;
        },

        setPreviousAddressMerged: (state: ApplyLoanContactInfoState, action: PayloadAction<{ previousAddress: string; previousAddressHomeOwnership: string; previousAddressResidenceSince: number; }>) => {
            state.previousAddress = action.payload.previousAddress;
            state.previousAddressHomeOwnership = action.payload.previousAddressHomeOwnership;
            state.previousAddressResidenceSince = action.payload.previousAddressResidenceSince;
        },

        setState: (state: ApplyLoanContactInfoState, action: PayloadAction<Partial<ApplyLoanContactInfoState>>) => {
            state.phoneNumber = action.payload.phoneNumber || state.phoneNumber;
            state.mobileNumber = action.payload.mobileNumber || state.mobileNumber;
            state.email = action.payload.email || state.email;
            state.currentAddress = action.payload.currentAddress || state.currentAddress;
            state.currentAddressHomeOwnership = action.payload.currentAddressHomeOwnership || state.currentAddressHomeOwnership;
            state.currentAddressResidenceSince = action.payload.currentAddressResidenceSince || state.currentAddressResidenceSince;
            state.permanentAddress = action.payload.permanentAddress || state.permanentAddress;
            state.permanentAddressHomeOwnership = action.payload.permanentAddressHomeOwnership || state.permanentAddressHomeOwnership;
            state.permanentAddressResidenceSince = action.payload.permanentAddressResidenceSince || state.permanentAddressResidenceSince;
            state.previousAddress = action.payload.previousAddress || state.previousAddress;
            state.previousAddressHomeOwnership = action.payload.previousAddressHomeOwnership || state.previousAddressHomeOwnership;
            state.previousAddressResidenceSince = action.payload.permanentAddressResidenceSince || state.previousAddressResidenceSince;
        },

        setFormErrors: (state: ApplyLoanContactInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        },

        setDefaultFormValues: (state: ApplyLoanContactInfoState) => {
            state.phoneNumber = '';
            state.mobileNumber = '';
            state.email = '';
            state.currentAddress = '';
            state.currentAddressHomeOwnership = '';
            state.currentAddressResidenceSince = 0;
            state.permanentAddress = '';
            state.permanentAddressHomeOwnership = '';
            state.permanentAddressResidenceSince = 0;
            state.previousAddress = '';
            state.previousAddressHomeOwnership = '';
            state.previousAddressResidenceSince = 0;
        }
    }
});

export const {
    setPhoneNumber,
    setMobileNumber,
    setEmail,
    setCurrentAddress,
    setCurrentAddressHomeOwnership,
    setCurrentAddressResidenceSince,
    setPermanentAddress,
    setPermanentAddressHomeOwnership,
    setPermanentAddressResidenceSince,
    setPreviousAddress,
    setPreviousAddressHomeOwnership,
    setPreviousAddressResidenceSince,
    setState,
    setPermanentAddressMerged,
    setPreviousAddressMerged,
    setFormErrors,
    setDefaultFormValues
} = applyLoanContactInfoSlice.actions;

export const selectPhoneNumber = (state: RootState) => state.applyLoanContactInfo.phoneNumber;
export const selectMobileNumber = (state: RootState) => state.applyLoanContactInfo.mobileNumber;
export const selectEmail = (state: RootState) => state.applyLoanContactInfo.email;
export const selectCurrentAddress = (state: RootState) => state.applyLoanContactInfo.currentAddress;
export const selectCurrentAddressHomeOwnership = (state: RootState) => state.applyLoanContactInfo.currentAddressHomeOwnership;
export const selectCurrentAddressResidenceSince = (state: RootState) => state.applyLoanContactInfo.currentAddressResidenceSince;
export const selectPermanentAddress = (state: RootState) => state.applyLoanContactInfo.permanentAddress;
export const selectPermanentAddressHomeOwnership = (state: RootState) => state.applyLoanContactInfo.permanentAddressHomeOwnership;
export const selectPermanentAddressResidenceSince = (state: RootState) => state.applyLoanContactInfo.permanentAddressResidenceSince;
export const selectPreviousAddress = (state: RootState) => state.applyLoanContactInfo.previousAddress;
export const selectPreviousAddressHomeOwnership = (state: RootState) => state.applyLoanContactInfo.previousAddressHomeOwnership;
export const selectPreviousAddressResidenceSince = (state: RootState) => state.applyLoanContactInfo.previousAddressResidenceSince;
export const selectFormErrors = (state: RootState) => state.applyLoanContactInfo.formErrors;

export default applyLoanContactInfoSlice.reducer;