import { UserSessionService } from 'src/api/lib/UserSessionService';
import { User } from 'src/api/lib/UserSessionService';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Container } from 'typedi';
import { RootState } from '../index';

interface UserState {
    user: User | null;
    hasLoadedFromStorage: boolean;
}

const initialState: UserState = {
    user: null,
    hasLoadedFromStorage: false
};

export const userSlice = createSlice({
    name: 'user',

    initialState,

    reducers: {
        setUser: (state: UserState, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        }
    },

    extraReducers(builder) {
        builder.addCase(refreshLoggedUserFromLocalStorage.fulfilled, (state, { payload }) => {
            if (!payload || !state.user || state.user.id !== payload.id) {
                state.user = payload;
                state.hasLoadedFromStorage = true;
            }
        });
    }
});

export const {
    setUser
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectHasLoadedFromStorage = (state: RootState) => state.user.hasLoadedFromStorage;

export const refreshLoggedUserFromLocalStorage = createAsyncThunk<User|null, void>('user/refreshLoggedUserFromLocalStorage', async () => {
    const user = await Container.get(UserSessionService).getUser();

    return user;
});

export default userSlice.reducer;
