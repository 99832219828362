import React, { useState, useEffect, useRef } from 'react';
import AngleDownSolidIcon from 'src/components/icons/AngleDownSolidIcon';
import { hasProp } from 'src/helpers/object';

type Props = {
    className?: string;
    renderedValueWrapperClassName?: string;
    renderedValueInnerWrapperClassName?: string;
    renderedValueWrapperPlaceholderClassName?: string;
    renderedValueInnerWrapperPlaceholderClassName?: string;
    arrowIconClassName?: string;
    popupWrapperClassName?: string;
    placeholder?: string;
    value?: string | number;
    renderValue?: (val?: string | number) => JSX.Element;
    children: JSX.Element | JSX.Element[];
    onChange?: (val?: string | number) => void;
}

export default function Select({
    className,
    children,
    value,
    renderValue,
    renderedValueWrapperClassName,
    renderedValueInnerWrapperClassName,
    renderedValueWrapperPlaceholderClassName,
    renderedValueInnerWrapperPlaceholderClassName,
    arrowIconClassName,
    popupWrapperClassName,
    placeholder,
    onChange
}: Props) {
    const [selected, setSelected] = useState<string|number|undefined>(undefined);
    const [isPopupShown, setIsPopupShown] = useState(false);
    const [options, setOptions] = useState<Record<string, string>>({});
    const el = useRef(null);

    useEffect(() => {
        setSelected(value);
    }, [value]);

    useEffect(() => {
        const opts: Record<string, string> = {};
        React.Children.forEach(children, (child) => {
            const key = (child.props.value as (number | string)).toString();
            const value = (child.props.children) ? child.props.children.toString() : '';
            opts[key] = value;
        });

        setOptions(opts);
    }, [children]);

    const handleClick = () => {
        setTimeout(() => {
            setIsPopupShown(!isPopupShown);
        }, 50);
    };

    useEffect(() => {
        const windowClickHandler = () => {
            setIsPopupShown(false);
        };

        window.addEventListener('click', windowClickHandler);

        return () => {
            window.removeEventListener('click', windowClickHandler);
        };
    }, []);

    const setSelectedWrapper = (val: string) => {
        setSelected(val);
        setIsPopupShown(false);

        if (onChange) {
            onChange(val);
        }
    };

    const allChildren = React.Children.map(children, (child) => {
        const clone = React.cloneElement(child, {
            selected,
            setSelected: setSelectedWrapper
        });

        return clone;
    });

    return (
        <div
            ref={el}
            tabIndex={0}
            className={`
                relative rounded-xl
                border border-solid border-gray-200
                cursor-pointer
                flex items-center
                text-[15px] font-normal leading-normal
                ${className || ''}
            `}
        >
            { /* renderValue */ }
            { (renderValue) ? renderValue() : '' }

            { /* not renderValue and has selected option */ }
            {(!renderValue && selected !== undefined && !!options[selected.toString()] && (
                <div
                    className={`
                        w-full
                        flex items-center
                        pl-3 pr-2.5 py-2
                        text-[15px] font-normal leading-normal
                        overflow-hidden
                        ${renderedValueWrapperClassName || ''}
                    `}
                    onClick={() => handleClick()}
                >
                    <span className={`mr-2 flex-grow whitespace-nowrap overflow-hidden ${renderedValueInnerWrapperClassName || ''}`}>
                        {options[selected.toString()]}
                    </span>
                    
                    <AngleDownSolidIcon className={`w-4 h-4 ${isPopupShown ? 'rotate-180' : ''} ${arrowIconClassName || ''}`} />
                </div>
            ))}

            { /* not renderValue and default option */ }
            {(!renderValue && (selected === undefined || !hasProp(options, selected.toString())) && (
                <div
                    className={`
                        w-full flex items-center 
                        text-gray-150
                        pl-3 pr-2.5 py-2
                        text-[15px] font-normal leading-normal
                        overflow-hidden
                        ${renderedValueWrapperPlaceholderClassName || ''}
                    `}
                    onClick={() => handleClick()}
                >
                    <span className={`mr-2 flex-grow whitespace-nowrap overflow-hidden ${renderedValueInnerWrapperPlaceholderClassName || ''}`}>
                        {placeholder || 'Select'}
                    </span>
                    
                    <AngleDownSolidIcon className={`w-4 h-4 ${arrowIconClassName || ''}`} />
                </div>
            ))}

            { /* popup section */ }
            {(isPopupShown) && (
                <div
                    className={`
                        absolute z-10 top-[calc(100%+4px)] max-h-[200px] overflow-y-auto uwi-scrollbar px-2 py-2 
                        rounded-lg shadow-lg shadow-slate-400 bg-white w-full
                        ${popupWrapperClassName || ''}
                    `}
                >
                    {allChildren}
                </div>
            )}
        </div>
    );
};
