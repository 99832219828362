import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { selectToastObjects, setToastObjects } from 'src/store/features/toastDataSlice';
import ToastEntry from './ToastEntry';

export default function Toast() {
    const dispatch = useAppDispatch();
    const toastObjects = useAppSelector(selectToastObjects);

    const removeToastObj = (toastObjId: string) => {
        const objects = toastObjects.filter(obj => (obj.id !== toastObjId));

        dispatch(setToastObjects(objects));
    };

    return (
        <div className="absolute top-20 right-6 z-[100] gap-y-3">
            {toastObjects.map(toastObj => (
                <ToastEntry
                    key={toastObj.id}
                    toastObj={toastObj}
                    onRemove={() => removeToastObj(toastObj.id)}
                />
            ))}
        </div>
    );
}