import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from 'src/api/ProjectApi';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { hasProp } from 'src/helpers/object';
import { RootState } from '../index';

interface PreApproveLoanPropertyInfoState {
    projects: Project[];
    projectTypes: string[];
    projectStatuses: string[];
    projectCost: number;
    priceRange: {
        min: number;
        max: number;
    };
    selectedSubProjects: AddedSubProject[];
    projectType: string;
    projectStatus: string;
    developerName: string;
    region: string;
    cities: string[];
    city: string;
    projectIds: string[];
    saveToStorage: boolean;
    formErrors: Record<string, string>;
}

const savePreApproveLoanPropertyInfoState = (info: PreApproveLoanPropertyInfoState) => {
    if (window.sessionStorage) {
        const {
            formErrors,
            saveToStorage,
            projects,
            priceRange,
            cities,
            city,
            ...others
        } = info;
        window.sessionStorage.setItem('preApproveLoanPropertyInfoState', JSON.stringify(others));
    }
};

const loadPreApproveLoanPropertyInfoState = (): PreApproveLoanPropertyInfoState => {
    try {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            const serializedState = window.sessionStorage.getItem('preApproveLoanPropertyInfoState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
                parsedState.formErrors = {};
                parsedState.saveToStorage = true;
                parsedState.projects = [];
                parsedState.priceRange = {
                    min: 0,
                    max: 0
                };
                parsedState.cities = [];
    
                return parsedState as PreApproveLoanPropertyInfoState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        saveToStorage: true,
        formErrors: {},
        projects: [],
        projectTypes: [],
        projectStatuses: [],
        projectIds: [],
        priceRange: {
            min: 0,
            max: 0
        },
        selectedSubProjects: [],
        projectType: '',
        projectStatus: '',
        projectCost: 0,
        region: '',
        developerName: '',
        cities: [],
        city: ''
    };
}

const initialState: PreApproveLoanPropertyInfoState = {
    saveToStorage: true,
    formErrors: {},
    projects: [],
    projectTypes: [],
    projectStatuses: [],
    projectIds: [],
    priceRange: {
        min: 0,
        max: 0
    },
    selectedSubProjects: [],
    projectType: '',
    projectStatus: '',
    projectCost: 0,
    region: '',
    developerName: '',
    cities: [],
    city: ''
};

export const preApproveLoanPropertyInfoSlice = createSlice({
    name: 'preApproveLoanPropertyInfo',

    initialState,

    reducers: {
        setCity: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string>) => {
            state.city = action.payload;
        },

        setProjects: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<Project[]>) => {
            state.projects = action.payload;
        },

        setProjectIds: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string[]>) => {
            state.projectIds = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setProjectTypes: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string[]>) => {
            state.projectTypes = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setProjectStatuses: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string[]>) => {
            state.projectStatuses = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setPriceRange: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<{ min: number; max: number; }>) => {
            state.priceRange = action.payload;
        },

        setSelectedSubProjects: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<AddedSubProject[]>) => {
            state.selectedSubProjects = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setProjectCost: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<number>) => {
            state.projectCost = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setProjectStatus: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string>) => {
            state.projectStatus = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setProjectType: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string>) => {
            state.projectType = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setDeveloperName: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string>) => {
            state.developerName = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setRegion: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string>) => {
            state.region = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setSaveToStorage: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<boolean>) => {
            state.saveToStorage = action.payload;
        },

        setDefaultFormValues: (state: PreApproveLoanPropertyInfoState) => {
            state.projectIds = [];
            state.selectedSubProjects = [];

            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        setCities: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<string[]>) => {
            state.cities = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanPropertyInfoState(state);
            }
        },

        loadFromStorage: (state: PreApproveLoanPropertyInfoState) => {
            const data = loadPreApproveLoanPropertyInfoState();
            const { projects, cities, priceRange, ...others } = data;

            return {
                ...state,
                ...others
            };
        },

        setState: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<Partial<PreApproveLoanPropertyInfoState>>) => {
            state.selectedSubProjects = hasProp(action.payload, 'selectedSubProjects') ? action.payload.selectedSubProjects as AddedSubProject[] : state.selectedSubProjects;
            state.developerName = action.payload.developerName || state.developerName;
            state.projectCost = (action.payload.projectCost !== undefined) ? action.payload.projectCost : state.projectCost;
            state.projectType = action.payload.projectType || state.projectType;
            state.projectStatus = action.payload.projectStatus || state.projectStatus;
            state.projectTypes = action.payload.projectTypes || state.projectTypes;
            state.projectStatuses = action.payload.projectStatuses || state.projectStatuses;
            state.region = action.payload.region || state.region;
            state.projectIds = action.payload.projectIds || state.projectIds;
            state.saveToStorage = action.payload.saveToStorage || state.saveToStorage;
            state.formErrors = {};
        },

        setFormErrors: (state: PreApproveLoanPropertyInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        }
    }
});

export const {
    setProjects,
    setProjectTypes,
    setProjectStatuses,
    setPriceRange,
    setDefaultFormValues,
    setSaveToStorage,
    setSelectedSubProjects,
    loadFromStorage,
    setState,
    setFormErrors,
    setProjectIds,
    setProjectCost,
    setProjectType,
    setProjectStatus,
    setRegion,
    setDeveloperName,
    setCities,
    setCity
} = preApproveLoanPropertyInfoSlice.actions;

export const selectProjects = (state: RootState) => state.preApproveLoanPropertyInfo.projects;
export const selectProjectTypes = (state: RootState) => state.preApproveLoanPropertyInfo.projectTypes;
export const selectProjectStatuses = (state: RootState) => state.preApproveLoanPropertyInfo.projectStatuses;
export const selectPriceRange = (state: RootState) => state.preApproveLoanPropertyInfo.priceRange;
export const selectFormErrors = (state: RootState) => state.preApproveLoanPropertyInfo.formErrors;
export const selectSelectedSubProjects = (state: RootState) => state.preApproveLoanPropertyInfo.selectedSubProjects;
export const selectProjectIds = (state: RootState) => state.preApproveLoanPropertyInfo.projectIds;
export const selectProjectCost = (state: RootState) => state.preApproveLoanPropertyInfo.projectCost;
export const selectProjectType = (state: RootState) => state.preApproveLoanPropertyInfo.projectType;
export const selectProjectStatus = (state: RootState) => state.preApproveLoanPropertyInfo.projectStatus;
export const selectDeveloperName = (state: RootState) => state.preApproveLoanPropertyInfo.developerName;
export const selectRegion = (state: RootState) => state.preApproveLoanPropertyInfo.region;
export const selectCities = (state: RootState) => state.preApproveLoanPropertyInfo.cities;
export const selectCity = (state: RootState) => state.preApproveLoanPropertyInfo.city;

export const selectTotalSubProjectsCost = (state: RootState) => {
    return state.preApproveLoanPropertyInfo.selectedSubProjects.reduce((prevVal, curVal) => {
        return (prevVal + (curVal.price * curVal.quantity));
    }, 0);
};

export const selectTotalSubProjectsQuantity = (state: RootState) => {
    return state.preApproveLoanPropertyInfo.selectedSubProjects.reduce((prevVal, curVal) => {
        return (prevVal + curVal.quantity);
    }, 0);
};

export default preApproveLoanPropertyInfoSlice.reducer;