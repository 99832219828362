import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container } from 'typedi';
import { useAppDispatch } from 'src/hooks/generic';
import { LinePagination } from 'src/components/common';
import ApplyContext from 'src/page_components/home_loan_apply/ApplyContext';
import ApplyFormList from 'src/page_components/home_loan_apply/ApplyFormList';
import useAuth from 'src/hooks/useAuth';
import { HomeLoanApplication, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import {
    setState as setPersonalInfoState,
    setDefaultFormValues as setPersonalInfoDefaulFormValues
} from 'src/store/features/applyLoanPersonalInfoSlice';
import {
    setState as setContactInfoState,
    setDefaultFormValues as setContactInfoDefaulFormValues
} from 'src/store/features/applyLoanContactInfoSlice';
import {
    setState as setEmploymentInfoState,
    setDefaultFormValues as setEmploymentInfoDefaulFormValues
} from 'src/store/features/applyLoanEmploymentInfoSlice';
import {
    setState as setOtherInfoState,
    setDefaultFormValues as setOtherInfoDefaulFormValues
} from 'src/store/features/applyLoanOtherInfoSlice';
import { IBirthdate } from 'src/hooks/useBirthdate';
import { CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const paginationItems = [
    'Personal Information',
    'Contact Information',
    'Employment Information',
    'Other Information',
    'Required Information'
];

const HomeLoanApplicationPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, hasLoadedFromStorage } = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const [searchParams] = useSearchParams();

    const id = searchParams.get('id');

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent(`/home-loan-apply?id=${id}`)}`);
        }
    }, [user, hasLoadedFromStorage, navigate, id]);

    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [homeLoanApp, setHomeLoanApp] = useState<HomeLoanApplication|undefined>();

    useEffect(() => {
        setPage(0);
        dispatch(setPersonalInfoDefaulFormValues());
        dispatch(setContactInfoDefaulFormValues());
        dispatch(setEmploymentInfoDefaulFormValues());
        dispatch(setOtherInfoDefaulFormValues());

        if (id && user) {
            setIsLoading(true);

            Container
                .get(HomeLoanApplicationApi)
                .getInfo(id as string)
                .then(homeLoanApp => {
                    let spouseBirthdate: IBirthdate|undefined = undefined;
                    if (homeLoanApp.spouseBirthdate !== null) {
                        spouseBirthdate = {
                            month: homeLoanApp.spouseBirthdate.getMonth(),
                            day: homeLoanApp.spouseBirthdate.getDate(),
                            year: homeLoanApp.spouseBirthdate.getFullYear()
                        };
                    }

                    dispatch(setPersonalInfoState({
                        firstName: homeLoanApp.firstName,
                        middleName: homeLoanApp.middleName,
                        lastName: homeLoanApp.lastName,
                        nameSuffix: homeLoanApp.nameSuffix,
                        gender: homeLoanApp.gender,
                        placeOfBirth: homeLoanApp.placeOfBirth,
                        educationalBG: homeLoanApp.educationalBg,
                        civilStatus: homeLoanApp.civilStatus,
                        motherMaidenName: homeLoanApp.motherMaidenName,
                        spouseFirstName: homeLoanApp.spouseFirstName,
                        spouseMiddleName: homeLoanApp.spouseMiddleName,
                        spouseLastName: homeLoanApp.spouseLastName,
                        spouseMotherMaidenName: homeLoanApp.spouseMotherMaidenName,
                        spouseBirthdate
                    }));
                    dispatch(setContactInfoState({
                        phoneNumber: homeLoanApp.phoneNumber,
                        mobileNumber: homeLoanApp.mobileNumber,
                        email: homeLoanApp.email,
                        currentAddress: homeLoanApp.currentAddress,
                        currentAddressHomeOwnership: homeLoanApp.currentAddressHomeOwnership,
                        currentAddressResidenceSince: homeLoanApp.currentAddressResidenceSince,
                        permanentAddress: homeLoanApp.permanentAddress,
                        permanentAddressHomeOwnership: homeLoanApp.permanentAddressHomeOwnership,
                        permanentAddressResidenceSince: homeLoanApp.permanentAddressResidenceSince,
                        previousAddress: homeLoanApp.previousAddress,
                        previousAddressHomeOwnership: homeLoanApp.previousAddressHomeOwnership,
                        previousAddressResidenceSince: homeLoanApp.previousAddressResidenceSince
                    }));
                    dispatch(setEmploymentInfoState({
                        companyIndustry: homeLoanApp.companyIndustry,
                        companyName: homeLoanApp.companyName,
                        officePhone: homeLoanApp.officePhone,
                        officeAddress: homeLoanApp.officeAddress,
                        tin: homeLoanApp.tin,
                        sss: homeLoanApp.sss,
                        employmentRank: homeLoanApp.employmentRank,
                        officeEmail: homeLoanApp.officeEmail
                    }));
                    dispatch(setOtherInfoState({
                        dependents: homeLoanApp.dependents,
                        projectContactPersonName: homeLoanApp.projectContactPersonName,
                        projectAddress: homeLoanApp.projectAddress,
                        projectContactPersonNumber: homeLoanApp.projectContactPersonNumber,
                        attorneyInfactName: homeLoanApp.attorneyInfactName,
                        attorneyInfactAddress: homeLoanApp.attorneyInfactAddress,
                        attorneyInfactContactNumber: homeLoanApp.attorneyInfactContactNumber,
                        attorneyInfactRelationship: homeLoanApp.attorneyInfactRelationship,
                    }));
                    setHomeLoanApp(homeLoanApp);
                })
                .catch((e) => {
                    errorHandlerWithToast(e);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        else {
            setIsLoading(false);
        }
    }, [id, user, dispatch, errorHandlerWithToast]);


    const changePage = (page: number) => {
        if (page === 5) {
            // handleFinishForm();
        }
        else {
            setPage(page);
        }
    };

    return (
        <>
            {isLoading && (
                <div className="bg-uwi-content-100 rounded-t-3xl">
                    <div className="w-full h-[75vh] flex justify-center items-center">
                        <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                    </div>
                </div>
            )}

            {!isLoading && !homeLoanApp && (
                <div className="bg-uwi-content-100 text-black rounded-t-3xl">
                    <div className="w-full h-[75vh] flex justify-center items-center">
                        <span>Page not found</span>
                    </div>
                </div>
            )}

            {!isLoading && homeLoanApp && (
                <ApplyContext.Provider value={{ page, setPage: changePage }}>
                    <LinePagination
                        selectedPage={page}
                        setPage={setPage}
                        paginationItems={paginationItems}
                        className="mb-4"
                    />

                    <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                        <div className="pt-10">
                            <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-258px)] xs:h-[calc(100vh-257px)] md:h-[510px] lg:h-[495px]">
                                <ApplyFormList
                                    className="w-full"
                                    selectedPage={page}
                                    homeLoanApp={homeLoanApp}
                                />
                            </div>
                        </div>
                    </div>
                </ApplyContext.Provider>
            )}
        </>
    );
}

export default HomeLoanApplicationPage;
