import 'reflect-metadata';
import { hasProp } from 'src/helpers/object';
import { Service } from 'typedi';
import { HttpRequestImpl } from './HttpRequestImpl';

const url = new URL(window.location.toString());
const params = new URLSearchParams(url.search);

@Service()
export class HttpRequestFactory {
    private savedImpls: Record<string, HttpRequestImpl> = {};

    getImplementation(symbol = 'default'): HttpRequestImpl {
        if (Object.prototype.hasOwnProperty.call(this.savedImpls, symbol)) {
            return this.savedImpls[symbol];
        }

        let clientId = '';
        if (params.get('clientId') && url.origin === process.env.REACT_APP_BASE_LOADER_URI) {
            clientId = params.get('clientId') || '';
        }
        else if (typeof window !== 'undefined' && hasProp(window, '__UWI_WIDGET_CLIENT_ID__')) {
            clientId = (window as any).__UWI_WIDGET_CLIENT_ID__;
        }

        let httpReqImpl = new HttpRequestImpl(
            process.env.REACT_APP_API_URL || 'http://localhost:3000',
            clientId
        );

        this.savedImpls[symbol] = httpReqImpl;

        return this.savedImpls[symbol];
    }
}
