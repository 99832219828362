import { useAppSelector } from 'src/hooks/generic';
import { selectIsProjectOnlyView, selectWidgetType } from 'src/store/features/commonSlice';
import useProjectInfoScreen2Validation from './useProjectInfoScreen2Validation';
import useDebtIncomeInfoValidation from './useDebtIncomeInfoValidation';
import useWorkInfoValidation from './useWorkInfoValidation';
import useOtherInfoValidation from './useOtherInfoValidation';
import useLoanInfoValidation from './useLoanInfoValidation';
import { paginationItemKeys } from './../data/paginationItemsData';
import usePropertyInfoValidation from './usePropertyInfoValidation';

const useValidation = () => {
    const isProjectOnlyView = useAppSelector(selectIsProjectOnlyView);
    const widgetType = useAppSelector(selectWidgetType);
    
    const projectInfoScreen2Validator = useProjectInfoScreen2Validation();
    const propertyValidator = usePropertyInfoValidation();
    const workInfoValidator = useWorkInfoValidation();
    const otherInfoValidator = useOtherInfoValidation();
    const debtIncomeValidator = useDebtIncomeInfoValidation();
    const loanInfoValidator = useLoanInfoValidation();

    const validate = async () => {
        if (widgetType === 'advance') {
            const isPropertyInfoValid = await projectInfoScreen2Validator.validateForm();
            if (!isPropertyInfoValid) {
                return (isProjectOnlyView) ? [false, paginationItemKeys[0]] : [false, 'projectInfoScreen2'];
            }
        }
        else {
            const isPropertyInfoValid = await propertyValidator.validateForm();
            if (!isPropertyInfoValid) {
                return [false, paginationItemKeys[0]];
            }
        }

        const isWorkInfoValid = await workInfoValidator.validateForm();
        if (!isWorkInfoValid) {
            return [false, paginationItemKeys[1]];
        }

        const isOtherInfoValid = await otherInfoValidator.validateForm();
        if (!isOtherInfoValid) {
            return [false, paginationItemKeys[2]];
        }

        const isDebtIncomeInfoValid = await debtIncomeValidator.validateForm();
        if (!isDebtIncomeInfoValid) {
            return [false, paginationItemKeys[3]];
        }

        const isLoanInfoValid = await loanInfoValidator.validateForm();
        if (!isLoanInfoValid) {
            return [false, paginationItemKeys[4]];
        }

        return [true, ''];
    };

    return {
        validate
    };
}

export default useValidation;