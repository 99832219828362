/**
 * Creates a tween animation.
 *
 * @param   from           The starting value.
 * @param   to             The target value.
 * @param   durationInMs   The full duration of the animation.
 * @param   callback       The callback called every tween animation.
 *
 * @return void
 */
 export function tween(from: number, to: number, durationInMs: number, callback: (num: number) => void) {
    const diff = Math.abs(to - from);
    const toAdd:boolean = (to > from);
    const speed = diff / durationInMs;
    let elapsedTime = 0;
    let lastRecordedTime:number|null = null;

    const animate = (timestamp:number) => {
        if (!lastRecordedTime) {
            lastRecordedTime = timestamp;
        }

        elapsedTime += (timestamp - lastRecordedTime);
        if (elapsedTime < durationInMs) {
            window.requestAnimationFrame(animate);
            const delta = (toAdd) ? from + (speed * elapsedTime) : from - (speed * elapsedTime);
            callback(delta);
        }
        else {
            callback(to);
        }

        lastRecordedTime = timestamp;
    };

    window.requestAnimationFrame(animate);
}

/**
 * Calls the callback after the duration time is met.
 *
 * @param   duration   The full duration of the animation.
 * @param   callback   The callback called every tween animation.
 *
 * @return void
 */
let isDebouncing = false;
export function debounce(durationInMs:number, callback:() => void) {
    if (isDebouncing) {
        return;
    }

    isDebouncing = true;
    let elapsedTime = 0;
    let lastRecordedTime:number|null = null;

    const animate = (timestamp:number) => {
        if (!lastRecordedTime) {
            lastRecordedTime = timestamp;
        }

        elapsedTime += (timestamp - lastRecordedTime);
        if (elapsedTime < durationInMs) {
            window.requestAnimationFrame(animate);
        }
        else {
            callback();
            isDebouncing = false;
        }

        lastRecordedTime = timestamp;
    };

    window.requestAnimationFrame(animate);
}