import { useState } from 'react';
import { Container } from 'typedi';
import { ErrorList, PasswordField } from 'src/components/common';
import { CircleLoaderIcon } from 'src/components/icons';
import { ForgotPasswordApi } from 'src/api/ForgotPasswordApi';
import { useNavigate } from 'react-router-dom';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

type Props = {
    email?: string;
    otp?: string;
    onButtonClick: () => void;
};

const ThirdForm = ({
    email,
    otp,
    onButtonClick
}: Props) => {
    const navigate = useNavigate();
    const {
        commonErrorHandler,
        extractFormErrors,
        errors,
        setCommonErrors
    } = useGenericErrorHandler();

    const [isLoading, setIsLoading] = useState(false);
    const [isErrorListVisible, setIsErrorListVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [isValidOTP, setIsValidOTP] = useState(true);

    const validate = () => {
        const errors = [];

        if (newPassword.length === 0) {
            errors.push('New Password is required');
        }

        if (newPassword.length > 0 && newPasswordConfirm !== newPassword) {
            errors.push('Confirm New Password should be equal to New Password');
        }

        setCommonErrors(errors);
        setIsErrorListVisible((errors.length > 0));

        return (errors.length === 0);
    }

    const handleSubmit = async () => {
        if (!validate() || !email || !otp) {
            return;
        }

        setIsLoading(true);

        try {
            await Container.get(ForgotPasswordApi).changePassword(
                email, newPassword, otp
            );

            navigate('/login');
        }
        catch (ex) {
            const errors = extractFormErrors(ex);

            if (errors.forgotPasswordKey) {
                setIsValidOTP(false);
            }

            commonErrorHandler(ex);
            setIsErrorListVisible(true);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <form
            className="w-full flex flex-col items-center md:mb-0 gap-y-3"
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <ErrorList
                errors={errors}
                visible={isErrorListVisible}
                onClose={() => setIsErrorListVisible(false)}
                floatInMobile={false}
            />

            {!isValidOTP ? (
                <p className="py-4 px-4 bg-yellow-100 border-yellow-400 border border-solid rounded-xl text-sm leading-4 mb-3">
                    Re-enter or Re-send OTP <button onClick={onButtonClick} className='underline text-yellow-600'>here</button>
                </p>
            ): (
                <p className="py-4 px-4 bg-yellow-100 border-yellow-400 border border-solid rounded-xl text-sm leading-4 mb-3">
                    We will ask for this password whenever you Sign-In.
                </p>
            )}


            <div className="mb-8 w-full">
                <div className="w-full flex flex-col items-center mb-3">
                    <label htmlFor="password" className="mb-2 block text-center form-label">New Password</label>
                    <PasswordField
                        className="form-field bg-white/40"
                        attrs={{
                            id: "password",
                            placeholder: "New Password",
                            required: true
                        }}
                        onChange={(password) => setNewPassword(password)}
                    />
                </div>
                <div className="w-full flex flex-col items-center">
                    <label htmlFor="confirm_password" className="mb-2 block text-center form-label">Confirm New Password</label>
                    <PasswordField
                        className="form-field bg-white/40"
                        attrs={{
                            id: "confirm_password",
                            placeholder: "Password",
                            required: true
                        }}
                        onChange={(password) => setNewPasswordConfirm(password)}
                    />
                </div>
            </div>

            <button
                className={`
                    w-full flex justify-center font-bold rounded-2xl text-[15px] py-4 px-5
                    ${(!isLoading) ? 'bg-uwi-primary-100 border text-white hover:bg-uwi-primary-200' : 'bg-gray-100 text-white'}
                `}
                disabled={isLoading}
            >
                Submit
                {isLoading && (
                    <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                )}
            </button>
        </form>
    );
};

export default ThirdForm;
