import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BarsSolidIcon, XMarkSolidIcon } from 'src/components/icons';
import Menu from 'src/components/layout/Menu';
import { hasProp } from 'src/helpers/object';
import { imageUrl } from 'src/helpers/url';
import { useAppSelector } from 'src/hooks/generic';
import { selectHasBuyerPortal } from 'src/store/features/commonSlice';
import { Toast } from '../common';

const titleMappings: Record<string, string> = {
    'pre-qualification': 'Home Loan Assessment',
    'home-loan-apply': 'Home Loan Application',
    'pre-qualification-view': 'Take Home Loan Assessment',
    'login': 'Log In',
    'signup': 'Sign Up',
    'dashboard-pre-qualifications': 'Home Loan Assessments',
    'dashboard_home_loan_applications': 'Home Loan Applications',
    'forgot_password': 'Forgot Password',
    'book-a-tour': 'Book a Tour',
    'dashboard-bookings': 'Bookings',
    'view-booking': 'Book a Tour',
    'dashboard-payment-reservations': 'Payment Reservations',
    'pay-reservation': 'Reservation Payments',
    'payment-account-selection': 'Reservation Payments',
    'payment-success': 'Reservation Payments',
};

type Props = {
    children?: React.ReactNode;
    onCloseModal?: () => void;
    hideCloseButton?: boolean;
};

export default function Layout({
    onCloseModal,
    children,
    hideCloseButton
}: Props) {
    const location = useLocation();

    const hasBuyerPortal = useAppSelector(selectHasBuyerPortal);

    const [isMenuShown, setIsMenuShown] = useState(false);

    const title = useCallback(() => {
        const path = location.pathname.trim().replace(/\//, '');
        return (hasProp(titleMappings, path)) ? titleMappings[path] : '';
    }, [location]);

    const handleCloseModal = onCloseModal || (() => {});

    return (
        <div
            className={`
                relative md:rounded-5xl overflow-hidden uwi-scrollbar
                w-full h-screen md:h-[768px] md:w-[768px] lg:w-[1024px]
                bg-uwi-primary-100 text-white
            `}
        >
            <Toast />

            <Menu
                visible={isMenuShown}
                onClose={() => setIsMenuShown(false)}
            />

            <div className="flex pb-5 px-6 pt-6 md:pt-8 md:px-8 lg:pt-10 lg:px-10 md:rounded-t-5xl bg-uwi-primary-100">
                <div className="text-left">
                    {(!hasBuyerPortal) && (
                        <button
                            onClick={() => setIsMenuShown(true)}
                            className="text-white/80 hover:text-white hover:bg-transparent"
                        >
                            <BarsSolidIcon className="w-6 h-6" />
                        </button>
                    )}
                </div>
                <div className="flex-grow text-center text-[23px] xs:text-[32px] md:text-[40px] text-white font-arial font-bold">
                    {title()}
                </div>
                <div className="text-right">
                    {(!hideCloseButton) && (
                        <button
                            onClick={() => handleCloseModal()}
                            className="text-white/80 hover:text-white hover:bg-transparent"
                        >
                            <XMarkSolidIcon className="w-6 h-6" />
                        </button>
                    )}
                </div>
            </div>

            <div
                className={`
                    h-[calc(100vh-156px)] max-h-[calc(100vh-156px)]
                    xs:h-[calc(100vh-155px)] xs:max-h-[calc(100vh-155px)]
                    md:h-[calc(100vh-156px)] md:max-h-[min(calc(100vh-156px),612px)]
                    lg:h-[calc(100vh-172px)] lg:max-h-[min(calc(100vh-172px),597px)]
                    overflow-y-auto uwi-scrollbar
                `}
            >
                {children}
            </div>

            <div className="w-full flex justify-center items-center pt-5 pb-10 md:pb-8 lg:pb-10 bg-uwi-content-100 text-uwi-primary-100 md:rounded-b-5xl">
                <div className="mr-2 text-xs">Powered By</div>
                <img
                    src={imageUrl('/logo/uwi-logo-widget.png')}
                    alt="Uwi Logo"
                    className="w-auto h-auto"
                />
            </div>
        </div>
    );
};