import { useEffect, useState } from 'react';
import { Select, SelectOption } from 'src/components/common';
import useBirthdate, { IBirthdate } from 'src/hooks/useBirthdate';
import { hasProp } from 'src/helpers/object';

type Props = {
    onChange?: (val: IBirthdate) => void;
    value?: IBirthdate;
    formError?: string | null;
};

const BirthdateField = ({
    onChange,
    value,
    formError
}: Props) => {
    const {
        month,
        setMonth,
        day,
        setDay,
        year,
        setYear,
        months,
        numDays,
        yearInfo
    } = useBirthdate();

    const [hasChangesMonth, setHasChangesMonth] = useState(false);
    const [hasChangesDay, setHasChangesDay] = useState(false);
    const [hasChangesYear, setHasChangesYear] = useState(false);

    const handleChange = onChange || (() => {});

    useEffect(() => {
        if (value) {
            setMonth(value.month);
            if (typeof value.month === 'number') {
                setHasChangesMonth(true);
            }

            setDay(value.day);
            if (typeof value.day === 'number') {
                setHasChangesDay(true);
            }

            setYear(value.year);
            if (typeof value.year === 'number') {
                setHasChangesYear(true);
            }
        }
    }, [value, setDay, setMonth, setYear]);

    useEffect(() => {
        if (
            (!day || day > numDays)
            && hasChangesDay
        ) {
            setDay(1);
        }
        else if (value) {
            setDay(value.day);
        }
    }, [numDays, hasChangesDay, setDay, value, day]);

    const hasFormError = (field: string) => {
        if (formError) {
            const formErrors = JSON.parse(formError);
            return (formErrors && hasProp(formErrors, field));
        }

        return false;
    }

    return (
        <>
            <Select
                className={`
                    w-[calc(33.333333%-6.666666666666667px)] mr-2.5
                    ${(hasFormError('month')) ? '!border-red-200' : ''}
                `}
                placeholder="Month"
                value={(hasChangesMonth) ? month : undefined}
                onChange={(newVal) => {
                    setMonth(newVal as number);
                    setHasChangesMonth(true);
                    handleChange({month: newVal as number, day, year });
                }}
            >
                {months.map((month, idx) => (
                    <SelectOption
                        value={idx}
                        key={`month_${idx}`}
                    >
                        {month}
                    </SelectOption>
                ))}
            </Select>

            <Select
                className={`
                    w-[calc(33.333333%-6.666666666666667px)] mr-2.5
                    ${(hasFormError('month')) ? '!border-red-200' : ''}
                `}
                placeholder="Day"
                value={(hasChangesDay) ? day : undefined}
                onChange={(newVal) => {
                    setDay(newVal as number);
                    setHasChangesDay(true);
                    handleChange({ month, day: newVal as number, year });
                }}
            >
                {(new Array(numDays)).fill(1).map((val, idx) => (
                    <SelectOption
                        key={`day_${idx}`}
                        value={idx + 1}
                    >
                        {(idx + 1)}
                    </SelectOption>
                ))}
            </Select>

            <Select
                className={`
                    w-[calc(33.333333%-6.666666666666667px)]
                    ${(hasFormError('month')) ? '!border-red-200' : ''}
                `}
                placeholder="Year"
                value={(hasChangesYear) ? year : undefined}
                onChange={(newVal) => {
                    setYear(newVal as number);
                    setHasChangesYear(true);
                    handleChange({ month, day, year: newVal as number });
                }}
            >
                {(new Array(yearInfo.start - yearInfo.end)).fill(1).map((val, idx) => (
                    <SelectOption
                        key={`year_${idx}`}
                        value={yearInfo.start - idx}
                    >
                        {(yearInfo.start - idx)}
                    </SelectOption>
                ))}
            </Select>
        </>
    );
}

export default BirthdateField;
