import { FormFieldWrapper, Select, SelectOption } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectProjectCost,
    setProjectCost,
    selectProjectType,
    setProjectType,
    selectProjectStatus,
    setProjectStatus,
    selectRegion,
    setRegion,
    selectDeveloperName,
    setDeveloperName
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import regions from 'src/data/regions.json';
import projectTypes from 'src/data/projectTypes.json';
import projectStatusesWithDeveloper from 'src/data/projectStatusesWithDeveloper.json';
import projectStatuses from 'src/data/projectStatuses.json';
import PreApprovalFormGroup from './PreApprovalFormGroup';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import usePropertyInfoValidation from '../validations/usePropertyInfoValidation';

const PropertyInfo = () => {
    const {
        formErrors,
        setFormErrors,
        validateForm
    } = usePropertyInfoValidation();

    const dispatch = useAppDispatch();

    const projectCost = useAppSelector(selectProjectCost);
    const projectType = useAppSelector(selectProjectType);
    const projectStatus = useAppSelector(selectProjectStatus);
    const developerName = useAppSelector(selectDeveloperName);
    const region = useAppSelector(selectRegion);

    return (
        <div className="w-full px-4 md:px-10">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[350px]">
                    <PreApprovalFormGroup
                        label="Home Value / Project Cost"
                        tooltipText="Home Value / Project Cost"
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'projectCost')}
                            errorMessage={(hasProp(formErrors, 'projectCost')) ? formErrors.projectCost : ''}
                            className="w-full"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'projectCost')) ? '!border-red-200' : ''}
                                `}
                                type="tel"
                                placeholder="Enter Value"
                                min={1}
                                max={1000000000}
                                value={(projectCost > 0) ? projectCost.toLocaleString('en-US') : ''}
                                onChange={(e) => {
                                    const val = e.target.value !== '' ? parseInt(e.target.value.replace(/,/g, '')) : 0;
                                    dispatch(setProjectCost(val));

                                    const { projectCost, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Project Type"
                        tooltipText="Project Type"
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'projectType')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={projectType}
                            onChange={(val) => {
                                dispatch(setProjectType(val as string));
                                const { projectType, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {projectTypes.map((projectType, idx) => (
                                <SelectOption
                                    key={idx}
                                    value={projectType}
                                >{projectType}</SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Project Status"
                        tooltipText="Project Status"
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'projectStatus')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={projectStatus}
                            onChange={(val) => {
                                dispatch(setProjectStatus(val as string));
                                const { projectStatus, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {projectStatuses.map((projectStatus, idx) => (
                                <SelectOption
                                    key={idx}
                                    value={projectStatus}
                                >{projectStatus}</SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    {(projectStatusesWithDeveloper.includes(projectStatus)) && (
                        <PreApprovalFormGroup
                            label="Developer Name"
                            tooltipText="Developer Name"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'developerName')}
                                errorMessage={(hasProp(formErrors, 'developerName')) ? formErrors.developerName : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'developerName')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Developer Name"
                                    value={developerName}
                                    onChange={(e) => {
                                        dispatch(setDeveloperName(e.target.value));
                                        const { developerName, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                    }}
                                />
                            </FormFieldWrapper>
                        </PreApprovalFormGroup>
                    )}

                    <PreApprovalFormGroup
                        label="Address"
                        tooltipText="Address"
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'region')}
                            errorMessage={(hasProp(formErrors, 'region')) ? formErrors.region : ''}
                            className="w-full"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'region')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select Region"
                                value={region}
                                onChange={(val) => {
                                    dispatch(setRegion(val as string));
                                    const { region, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            >
                                {regions.map((region, idx) => (
                                    <SelectOption
                                        key={idx}
                                        value={region}
                                    >{region}</SelectOption>
                                ))}
                            </Select>
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={() => validateForm()}
            />
        </div>
    );
}

export default PropertyInfo;
