import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PreApproveLoanStaticDataState {
    projectTypes: string[];
    projectStatuses: string[];
}

const initialState: PreApproveLoanStaticDataState = {
    projectTypes: [],
    projectStatuses: [],
};

export const preApproveLoanStaticDataSlice = createSlice({
    name: 'preApproveLoanStaticData',

    initialState,

    reducers: {
        setProjectTypes: (state: PreApproveLoanStaticDataState, action: PayloadAction<string[]>) => {
            state.projectTypes = action.payload;
        },

        setProjectStatuses: (state: PreApproveLoanStaticDataState, action: PayloadAction<string[]>) => {
            state.projectStatuses = action.payload;
        },

        setState: (state: PreApproveLoanStaticDataState, action: PayloadAction<Partial<PreApproveLoanStaticDataState>>) => {
            state.projectTypes = action.payload.projectTypes || state.projectTypes;
            state.projectStatuses = action.payload.projectStatuses || state.projectStatuses;
        }
    }
});

export const {
    setProjectTypes,
    setProjectStatuses,
    setState,
} = preApproveLoanStaticDataSlice.actions;

export const selectProjectTypes = (state: RootState) => state.preApproveLoanStaticData.projectTypes;
export const selectProjectStatuses = (state: RootState) => state.preApproveLoanStaticData.projectStatuses;

export default preApproveLoanStaticDataSlice.reducer;