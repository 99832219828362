import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import PreQualificationResult from 'src/page_components/pre-qualification/PreQualificationResult';
import PreQualificationMainForm from 'src/page_components/pre-qualification/PreQualificationMainForm';
import {
    loadFromStorage as loadFromStoragePropertyInfo,
    selectCities,
    setCity
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    loadFromStorage as loadFromStorageWorkInfo
} from 'src/store/features/preApproveLoanWorkInfoSlice';
import {
    loadFromStorage as loadFromStorageOtherInfo
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import {
    loadFromStorage as loadFromStorageDebtIncomeInfo
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import {
    loadFromStorage as loadFromStorageLoanInfo
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import useAuth from 'src/hooks/useAuth';

const PreApprovalPage = () => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const [searchParams] = useSearchParams();

    const cities = useAppSelector(selectCities);

    const [isResultShown, setIsResultShown] = useState(false);

    useEffect(() => {
        if (searchParams.get('tab') === 'result') {
            setIsResultShown(true);
        }
    }, [searchParams]);

    useEffect(() => {
        dispatch(loadFromStoragePropertyInfo());
        dispatch(loadFromStorageWorkInfo());
        dispatch(loadFromStorageDebtIncomeInfo());
        dispatch(loadFromStorageLoanInfo());

        if (user) {
            dispatch(loadFromStorageOtherInfo({
                email: user.email,
                mobileNumber: (user.mobileNumber) ? user.mobileNumber : undefined
            }));
        }
        else {
            dispatch(loadFromStorageOtherInfo({}));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (cities.length > 0) {
            dispatch(setCity(cities[0]));
        }
    }, [dispatch, cities]);

    return (
        <>
            {
                (isResultShown)
                ? <PreQualificationResult
                    onSomeFormInputInvalid={(tab) => setIsResultShown(false)}
                    onEditForm={() => setIsResultShown(false)}
                />
                : <PreQualificationMainForm
                    routerPushOnChangeTab={true}
                    onFinishForm={() => setIsResultShown(true)} 
                />
            }
        </>
    );
}

export default PreApprovalPage;
