import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import store from 'src/store';
import {
    setProjectCost,
    setProjectType,
    setProjectStatus,
    setRegion,
    setDeveloperName
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    setBirthdate,
    setEmail,
    setMobileNumber,
    setMaritalStatus,
    setCurrentRegion
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import {
    SetPropertyInfoParams,
    SetOtherInfoParams,
    UwiWidgetApiImpl
} from 'src/libs/UwiWidgetApi';
import { hasProp } from 'src/helpers/object';
import projectTypes from 'src/data/projectTypes.json';
import regions from 'src/data/regions.json';
import civilStatuses from 'src/data/civilStatuses.json';
import projectStatuses from 'src/data/projectStatuses.json';
import { convertDateStringToIBirthDate } from 'src/helpers/date';

type Props = {
    children: React.ReactNode;
    onOpenModal?: () => void;
};

function WidgetApiWrapper({ children, onOpenModal }: Props) {
    const navigate = useNavigate();

    useEffect(() => {
        (window as any).UwiWidgetApi = new UwiWidgetApiImpl((ev, params) => {
            switch (ev) {
                case 'setPropertyInfo': {
                    const pars = params as SetPropertyInfoParams;
                    let hasEdits = false;

                    if (
                        hasProp(pars, 'projectCost')
                        && typeof pars.projectCost === 'number'
                    ) {
                        hasEdits = true;
                        store.dispatch(setProjectCost(pars.projectCost));
                    }

                    if (
                        hasProp(pars, 'projectType')
                        && typeof pars.projectType === 'string'
                        && projectTypes.includes(pars.projectType)
                    ) {
                        hasEdits = true;
                        store.dispatch(setProjectType(pars.projectType));
                    }

                    if (
                        hasProp(pars, 'projectStatus')
                        && typeof pars.projectStatus === 'string'
                        && projectStatuses.includes(pars.projectStatus)
                    ) {
                        hasEdits = true;
                        store.dispatch(setProjectStatus(pars.projectStatus));
                    }

                    if (
                        hasProp(pars, 'developerName')
                        && typeof pars.developerName === 'string'
                    ) {
                        hasEdits = true;
                        store.dispatch(setDeveloperName(pars.developerName));
                    }

                    if (
                        hasProp(pars, 'projectAddress')
                        && typeof pars.projectAddress === 'string'
                        && regions.includes(pars.projectAddress)
                    ) {
                        hasEdits = true;
                        store.dispatch(setRegion(pars.projectAddress));
                    }

                    if (hasEdits) {
                        navigate('/pre-qualification');
                    }

                    break;
                }

                case 'setOtherInfo': {
                    const pars = params as SetOtherInfoParams;
                    let hasEdits = false;

                    if (
                        hasProp(pars, 'email')
                        && typeof pars.email === 'string'
                    ) {
                        hasEdits = true;
                        store.dispatch(setEmail(pars.email));
                    }

                    if (
                        hasProp(pars, 'mobileNumber')
                        && typeof pars.mobileNumber === 'string'
                    ) {
                        hasEdits = true;
                        store.dispatch(setMobileNumber(pars.mobileNumber));
                    }

                    if (
                        hasProp(pars, 'maritalStatus')
                        && typeof pars.maritalStatus === 'string'
                        && civilStatuses.includes(pars.maritalStatus)
                    ) {
                        hasEdits = true;
                        store.dispatch(setMaritalStatus(pars.maritalStatus));
                    }

                    if (
                        hasProp(pars, 'birthDate')
                        && typeof pars.birthDate === 'string'
                    ) {
                        const bDate = convertDateStringToIBirthDate(pars.birthDate);
                        if (bDate !== null) {
                            hasEdits = true;
                            store.dispatch(setBirthdate(bDate));
                        }
                    }

                    if (
                        hasProp(pars, 'currentAddress')
                        && typeof pars.currentAddress === 'string'
                        && regions.includes(pars.currentAddress)
                    ) {
                        hasEdits = true;
                        store.dispatch(setCurrentRegion(pars.currentAddress));
                    }

                    if (hasEdits) {
                        navigate('/pre-qualification');
                    }

                    break;
                }

                case 'open': {
                    if (onOpenModal) {
                        onOpenModal();
                    }
                    
                    break;
                }
            }
        });
    }, [onOpenModal, navigate]);

    return (
        <>
            {children}
        </>
    );
}

export default WidgetApiWrapper;
