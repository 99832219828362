import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PreApproveLoanLoanInfoState {
    loanTerm: number;
    percentOfProjectCost: number;
    saveToStorage: boolean;
    formErrors: Record<string, string>;
    percentOfDownpayment:number;
    ownedBanks: string[];
}

const savePreApproveLoanLoanInfoState = (info: PreApproveLoanLoanInfoState) => {
    if (window.sessionStorage) {
        const { formErrors, saveToStorage, ...others } = info;
        window.sessionStorage.setItem('preApproveLoanLoanInfoState', JSON.stringify(others));
    }
};

const loadPreApproveLoanLoanInfoState = (): PreApproveLoanLoanInfoState => {
    try {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            const serializedState = window.sessionStorage.getItem('preApproveLoanLoanInfoState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
                parsedState.formErrors = {};
                parsedState.saveToStorage = true;
    
                return parsedState as PreApproveLoanLoanInfoState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        loanTerm: 0,
        percentOfProjectCost: 95,
        percentOfDownpayment: 5,
        saveToStorage: true,
        formErrors: {},
        ownedBanks: []
    };
}

const initialState: PreApproveLoanLoanInfoState = {
    loanTerm: 0,
    percentOfProjectCost: 95,
    percentOfDownpayment: 5,
    saveToStorage: true,
    formErrors: {},
    ownedBanks: []
};

export const preApproveLoanLoanInfoSlice = createSlice({
    name: 'preApproveLoanLoanInfo',

    initialState,

    reducers: {
        setLoanTerm: (state: PreApproveLoanLoanInfoState, action: PayloadAction<number>) => {
            state.loanTerm = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanLoanInfoState(state);
            }
        },

        setPercentOfProjectCost: (state: PreApproveLoanLoanInfoState, action: PayloadAction<number>) => {
            state.percentOfProjectCost = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanLoanInfoState(state);
            }
        },

        setPercentOfDownpayment:(state: PreApproveLoanLoanInfoState,action:PayloadAction<number>)=>{
            state.percentOfDownpayment = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanLoanInfoState(state);
            }
        },
        
        setOwnedBanks: (state: PreApproveLoanLoanInfoState, action: PayloadAction<string[]>) => {
            state.ownedBanks = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanLoanInfoState(state);
            }
        },

        setSaveToStorage: (state: PreApproveLoanLoanInfoState, action: PayloadAction<boolean>) => {
            state.saveToStorage = action.payload;
        },

        loadFromStorage: () => {
            return loadPreApproveLoanLoanInfoState();
        },

        setDefaultFormValues: (state: PreApproveLoanLoanInfoState) => {
            state.loanTerm = 0;
            state.percentOfProjectCost = 95;
            state.percentOfDownpayment = 5;
            state.ownedBanks = [];

            savePreApproveLoanLoanInfoState(state);
        },

        setState: (state: PreApproveLoanLoanInfoState, action: PayloadAction<Partial<PreApproveLoanLoanInfoState>>) => {
            state.loanTerm = action.payload.loanTerm || state.loanTerm;
            state.percentOfProjectCost = action.payload.percentOfProjectCost || state.percentOfProjectCost;
            state.ownedBanks = action.payload.ownedBanks || state.ownedBanks;
            state.saveToStorage = (action.payload.saveToStorage === undefined) ? state.saveToStorage : action.payload.saveToStorage;
            state.percentOfDownpayment = (action.payload.percentOfDownpayment === undefined) ? state.percentOfDownpayment : action.payload.percentOfDownpayment;
            state.formErrors = {};
        },

        setFormErrors: (state: PreApproveLoanLoanInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        }
    }
});

export const {
    setLoanTerm,
    setPercentOfProjectCost,
    setPercentOfDownpayment,
    setOwnedBanks,
    setSaveToStorage,
    loadFromStorage,
    setDefaultFormValues,
    setState,
    setFormErrors
} = preApproveLoanLoanInfoSlice.actions;

export const selectLoanTerm = (state: RootState) => state.preApproveLoanLoanInfo.loanTerm;
export const selectPercentOfProjectCost = (state: RootState) => state.preApproveLoanLoanInfo.percentOfProjectCost;
export const selectPercentOfDownpayment = (state: RootState) => state.preApproveLoanLoanInfo.percentOfDownpayment;
export const selectOwnedBanks = (state: RootState) => state.preApproveLoanLoanInfo.ownedBanks;
export const selectFormErrors = (state: RootState) => state.preApproveLoanLoanInfo.formErrors;

export default preApproveLoanLoanInfoSlice.reducer;