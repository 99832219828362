import { Container } from 'typedi';
import {
    selectUser,
    selectHasLoadedFromStorage,
    setUser
} from 'src/store/features/userSlice';
import { useAppDispatch, useAppSelector } from './generic';
import { UserSessionService } from 'src/api/lib/UserSessionService';

const useAuth = () => {
    const dispatch = useAppDispatch();
    const selectedUser = useAppSelector(selectUser);
    const selectedHasLoadedFromStorage = useAppSelector(selectHasLoadedFromStorage);

    const login = async (email: string, password: string) => {
        const user = await Container.get(UserSessionService).login(
            email,
            password,
        );

        dispatch(setUser(user));
    };

    const logout = async () => {
        await Container.get(UserSessionService).logout();
        dispatch(setUser(null));
    };

    return {
        login,
        logout,
        user: selectedUser,
        userId: (selectedUser) ? selectedUser.id : null,
        hasLoadedFromStorage: selectedHasLoadedFromStorage
    };
}

export default useAuth;