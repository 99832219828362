import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export type HomeLoanApplicationDocumentStatusType = 'waiting' | 'pending' | 'done';

export interface HomeLoanApplicationDocument {
    id: string;
    homeLoanApplicationId: string;
    documentName: string;
    attachmentName: string;
    attachmentUrl: string;
    documentGroup: string;
    status: HomeLoanApplicationDocumentStatusType;
    helperText: string;
    priorityOrder: number;
    isOptional: boolean;
    optionalGroup: string;
    formUrl: string;
    isNeededWetSignature: boolean;
    createdAt: Date;
    updatedAt: Date;
}

@Service()
export class HomeLoanApplicationDocumentApi extends BaseApi {
    /**
     * API path for creating a home loan application document.
     */
    static readonly PATH_GET_DOCS = '/v1/widget/home-loan-application-document/$appId/docs';

    /**
     * API path to update the home loan application document.
     */
    static readonly PATH_UPDATE = '/v1/widget/home-loan-application-document/$appDocId';

    async getDocs(appId: string): Promise<HomeLoanApplicationDocument[]> {
        const list: HomeLoanApplicationDocument[] = await this.http.get(
            HomeLoanApplicationDocumentApi.PATH_GET_DOCS.replace(/\$appId/, appId),
            {},
            {},
            true
        );

        return list.map(val => ({
            ...val,
            ...{
                createdAt: new Date(val.createdAt),
                updatedAt: new Date(val.updatedAt)
            }
        }));
    }

    update(
        appDocId: string,
        document: File
    ): Promise<boolean> {
        const data = new FormData();
        data.set('document', document);

        return this.http.putWithFile(
            HomeLoanApplicationDocumentApi.PATH_UPDATE.replace(/\$appDocId/, appDocId),
            data,
            {},
            true
        );
    }

}
