import { CircleExclamationSolidIcon } from 'src/components/icons';
import { useNavigate } from 'react-router-dom';

const PaymentError = ({className, isVisible, error, setIsVisible} : {className?: string, isVisible: boolean, error: string, setIsVisible: (visible: false) => void}) => {
    const navigate = useNavigate();

    return (
        <>
            {isVisible && (
                <div className="z-10 absolute w-full h-full left-0 top-0 flex justify-center items-center bg-white">
                    <div className="w-3/4 text-center">
                        <div className="flex justify-center items-center flex-col mb-6">
                            <CircleExclamationSolidIcon className="w-36 mb-4 text-black"/>
                            <h2 className="text-3xl font-bold text-center">{error}</h2>
                        </div>
                        <div className="w-full mt-8 flex justify-center">
                            <button
                                className={`
                                    w-[calc(50%-8px)] md:w-[170px] mr-4
                                    flex justify-center items-center rounded-2xl
                                    px-8 py-4
                                    font-bold text-[15px]
                                    border border-solid
                                    border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                    hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                                `}
                                onClick={() => {
                                    navigate('/pay-reservation');
                                    setIsVisible(false);
                                }}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentError;