import { useAppSelector } from 'src/hooks/generic';
import { selectWidgetType } from 'src/store/features/commonSlice';
import {
    selectTotalSubProjectsQuantity,
    selectSelectedSubProjects,
    selectTotalSubProjectsCost,
    selectProjectCost,
    selectProjectType,
    selectProjectStatus,
    selectDeveloperName,
    selectRegion
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import projectStatusesWithDeveloper from 'src/data/projectStatusesWithDeveloper.json';

type Props = {
    className?: string;
    headerClassName?: string;
    listClassName?: string;
    listEntryClassName?: string;
    listEntryLeftClassName?: string;
    listEntryLeftValueClassName?: string;
};

const PropertyInfo = ({
    className,
    headerClassName,
    listEntryLeftClassName,
    listEntryLeftValueClassName
}: Props) => {
    const widgetType = useAppSelector(selectWidgetType);
    const projectCost = useAppSelector(selectProjectCost);
    const projectType = useAppSelector(selectProjectType);
    const projectStatus = useAppSelector(selectProjectStatus);
    const developerName = useAppSelector(selectDeveloperName);
    const region = useAppSelector(selectRegion);
    const totalSubProjectCost = useAppSelector(selectTotalSubProjectsCost);
    const totalSubProjectQuantity = useAppSelector(selectTotalSubProjectsQuantity);
    const subProjects = useAppSelector(selectSelectedSubProjects);

    return (
        <div className={`w-full ${className || ''}`}>
            <h3 className={`text-base text-white bg-uwi-primary-100 py-4 font-arial font-bold mb-2 ${headerClassName || ''}`}>
                PROPERTY INFORMATION
            </h3>

            <table className="w-full !m-0 !border-none">
                <tbody>
                    {(widgetType === 'advance') && (
                        <>
                            {(subProjects.length === 1) && (
                                <>
                                    <tr>
                                        <td className={listEntryLeftClassName || ''}>Project:</td>
                                        <td className={listEntryLeftValueClassName || ''}>{subProjects[0].name}</td>
                                    </tr>
                                </>
                            )}

                            <tr>
                                <td className={listEntryLeftClassName || ''}>Cost:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {totalSubProjectCost.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                </td>
                            </tr>
                            <tr>
                                <td className={listEntryLeftClassName || ''}>Quantity:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {totalSubProjectQuantity}
                                </td>
                            </tr>  
                        </>
                    )}

                    {(widgetType === 'basic') && (
                        <>
                            <tr>
                                <td className={listEntryLeftClassName || ''}>Project Cost:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {projectCost.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                </td>
                            </tr>

                            <tr>
                                <td className={listEntryLeftClassName || ''}>Project Type:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {projectType}
                                </td>
                            </tr>
                            <tr>
                                <td className={listEntryLeftClassName || ''}>Project Status:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {projectStatus}
                                </td>
                            </tr>

                            {(projectStatusesWithDeveloper.includes(projectStatus)) && (
                                <tr>
                                    <td className={listEntryLeftClassName || ''}>Developer Name:</td>
                                    <td className={listEntryLeftValueClassName || ''}>
                                        {developerName}
                                    </td>
                                </tr>                                
                            )}

                            <tr>
                                <td className={listEntryLeftClassName || ''}>Address:</td>
                                <td className={listEntryLeftValueClassName || ''}>
                                    {region}
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default PropertyInfo;
