import type { marked } from 'marked';

const startReg = new RegExp(`^!!!\\s+block-1(.*)$`);
const endReg = /^!!!\s*$/;

const firstIndentationPlugin: marked.TokenizerExtension | marked.RendererExtension = {
    name: 'firstIndentation',
    level: 'block',
    start(this: marked.TokenizerThis, src: string) {
        const index = src.match(new RegExp(`(^|[\\r\\n])!!!\\s+block-1(.*)`))?.index;

        return index;
    },
    tokenizer(src: string, _tokens): marked.Tokens.Generic | void {
        const lines = src.split(/\n/);
        if (startReg.test(lines[0])) {
            const section = { x: -1, y: -1 };
            const sections = [];
            for (let i = 0, k = lines.length; i < k; i++) {
                if (startReg.test(lines[i])) {
                    section.x = i;
                }
                else if (endReg.test(lines[i])) {
                    section.y = i;
                    if (section.x >= 0) {
                        sections.push({ ...section });
                        section.x = -1;
                        section.y = -1;
                    }
                }
            }

            if (sections.length) {
                const section = sections[0];
                const [_, icon, title] = startReg.exec(lines[section.x]) || [];
                const text = lines.slice(section.x + 1, section.y).join('\n');
                const raw = lines.slice(section.x, section.y + 1).join('\n');
                const token = {
                    type: 'firstIndentation',
                    raw,
                    icon,
                    title,
                    text,
                    titleTokens: [],
                    tokens: [],
                    childTokens: ['title', 'text'],
                };

                this.lexer.inlineTokens(token.title, token.titleTokens);
                this.lexer.blockTokens(token.text, token.tokens);
                return token;
            }
        }
    },
    renderer(this: marked.RendererThis, token) {
        const html = `
        <div class="ml-8">
            ${this.parser.parse(token.tokens!)}
        </div>`;
        return html;
    }
};

const secondIndentationStartReg = new RegExp(`^!!!!\\s+block-2(.*)$`);
const secondIndentationEndReg = /^!!!!\s*$/;

const secondIndentationPlugin: marked.TokenizerExtension | marked.RendererExtension = {
    name: 'secondIndentation',
    level: 'block',
    start(this: marked.TokenizerThis, src: string) {
        const index = src.match(new RegExp(`(^|[\\r\\n])!!!!\\s+block-2(.*)`))?.index;

        return index;
    },
    tokenizer(src: string, _tokens): marked.Tokens.Generic | void {
        const lines = src.split(/\n/);
        if (secondIndentationStartReg.test(lines[0])) {
            const section = { x: -1, y: -1 };
            const sections = [];
            for (let i = 0, k = lines.length; i < k; i++) {
                if (secondIndentationStartReg.test(lines[i])) {
                    section.x = i;
                }
                else if (secondIndentationEndReg.test(lines[i])) {
                    section.y = i;
                    if (section.x >= 0) {
                        sections.push({ ...section });
                        section.x = -1;
                        section.y = -1;
                    }
                }
            }

            if (sections.length) {
                const section = sections[0];
                const [_, icon, title] = startReg.exec(lines[section.x]) || [];
                const text = lines.slice(section.x + 1, section.y).join('\n');
                const raw = lines.slice(section.x, section.y + 1).join('\n');
                const token = {
                    type: 'secondIndentation',
                    raw,
                    icon,
                    title,
                    text,
                    titleTokens: [],
                    tokens: [],
                    childTokens: ['title', 'text'],
                };

                this.lexer.inlineTokens(token.title, token.titleTokens);
                this.lexer.blockTokens(token.text, token.tokens);
                return token;
            }
        }
    },
    renderer(this: marked.RendererThis, token) {
        const html = `
        <div class="ml-8">
            ${this.parser.parse(token.tokens!)}
        </div>`;
        return html;
    }
};

const extensions: (marked.TokenizerExtension | marked.RendererExtension)[] = [firstIndentationPlugin, secondIndentationPlugin];

export default <marked.MarkedExtension> {
    extensions
};