import 'reflect-metadata';
import { Service } from 'typedi';
import { hasProp } from 'src/helpers/object';
import { AuthApi, LoginResponse, SignupForm } from '../AuthApi';

interface PromiseCB {
    resolve: () => void;
    reject: (ex: any) => void;
}

export interface User {
    id?: string;
    email: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    mobileNumber?: string;
}

type ResolveFxn<T> = (val: T) => void;

/**
 * Service class for player session related calls.
 * 
 * Usage:
 *   const player = await Container.get(UserSessionService).getPlayer();
 */
@Service()
export class UserSessionService {
    private static readonly USER_JWT_TOKEN = 'UWI_WIDGET_USER_JWT_TOKEN';
    private static readonly USER_REFRESH_TOKEN = 'UWI_WIDGET_USER_REFRESH_TOKEN';

    private readonly authApi: AuthApi;

    private jwtToken: string|null = null;
    private refreshToken: string|null = null;
    private user: User | null = null;
    private isReloading = false;
    private hasFirstLoadedFromCookie = false;
    private promiseCallbacks: PromiseCB[] = [];

    public constructor() {
        this.authApi = new AuthApi();
    }

    public async getJwtToken() {
        return this.reloadFromCookiePromiseWrapper<string>(
            (resolve) => resolve(this.jwtToken as string)
        );
    }

    public async getUser() {
        return this.reloadFromCookiePromiseWrapper<User>(
            (resolve) => resolve(this.user as User)
        );
    }

    public async login(email: string, password: string) {
        const resp = await this.authApi.login(email, password);
        this.processLoginResponse(resp);

        return this.user;
    }

    public async logout() {
        if (this.refreshToken) {
            await this.authApi.logout(this.refreshToken);
        }

        this.jwtToken = null;
        this.refreshToken = null;
        window.localStorage.removeItem(UserSessionService.USER_JWT_TOKEN);
        window.localStorage.removeItem(UserSessionService.USER_REFRESH_TOKEN);
    }

    public signup(form: SignupForm) {
        return this.authApi.signup(form);
    }

    private async reloadFromCookiePromiseWrapper<T>(cb: (resolve: ResolveFxn<T>) => void): Promise<T> {
        return new Promise<T>(async (resolve, reject) => {
            try {
                if (this.isReloading) {
                    this.promiseCallbacks.push({
                        resolve: () => cb(resolve),
                        reject
                    })
                    return;
                }
                
                this.isReloading = true;
                await this.reloadFromLocalStorage();
                cb(resolve);
            }
            catch (ex) {
                reject(ex);
            }
        });
    }

    private async reloadFromLocalStorage() {
        this.jwtToken = window.localStorage.getItem(UserSessionService.USER_JWT_TOKEN);
        this.refreshToken = window.localStorage.getItem(UserSessionService.USER_REFRESH_TOKEN);

        if (this.jwtToken && this.jwtToken.length > 0) {
            const [header, body] = this.jwtToken.split('.');
            const decoded = atob(body);
            try {
                const jsonObj = JSON.parse(decoded);
                if (
                    this.refreshToken
                    && (
                        !jsonObj
                        || (!hasProp(jsonObj, 'exp'))
                        // if 2 minutes before expiration force a refresh
                        || (
                            hasProp(jsonObj, 'exp')
                            && ((jsonObj.exp as number) - 120) < (new Date().getTime() / 1000)
                        )
                        // if saved jsonObj is invalid force a refresh
                        || (!hasProp(jsonObj, 'id'))
                        // if saved jsonObj is not equal to player id for a refresh
                        || (
                            hasProp(jsonObj, 'id')
                            && this.hasFirstLoadedFromCookie 
                            && this.user
                            && (jsonObj.id !== this.user.id)
                        )
                    )
                ) {
                    const resp = await this.authApi.refreshSession(this.refreshToken);
                    this.processLoginResponse(resp);
                }
                else if (!!this.jwtToken) {
                    this.readUserFromJwtToken();
                }
            }
            catch (ex) {
                // most probably a 403 forbidden error of the refresh token
                this.jwtToken = null;
                this.refreshToken = null;
                this.user = null;
                window.localStorage.removeItem(UserSessionService.USER_JWT_TOKEN);
                window.localStorage.removeItem(UserSessionService.USER_REFRESH_TOKEN);

                this.promiseCallbacks.forEach(cb => cb.reject(ex));
                this.promiseCallbacks.splice(0, this.promiseCallbacks.length);
                this.isReloading = false;
                return;
            }
        }
        else if (this.refreshToken) {
            const resp = await this.authApi.refreshSession(this.refreshToken);
            this.processLoginResponse(resp);
        }
        else {
            this.user = null;
        }

        this.promiseCallbacks.forEach(cb => cb.resolve());
        this.promiseCallbacks.splice(0, this.promiseCallbacks.length);
        this.isReloading = false;
    }

    private async processLoginResponse(resp: LoginResponse) {
        window.localStorage.setItem(UserSessionService.USER_JWT_TOKEN, resp.jwtToken);
        window.localStorage.setItem(UserSessionService.USER_REFRESH_TOKEN, resp.refreshToken);

        this.jwtToken = resp.jwtToken;
        this.refreshToken = resp.refreshToken;

        this.readUserFromJwtToken();
    }

    private readUserFromJwtToken() {
        const url = new URL(window.location.toString());
        const params = new URLSearchParams(url.search);

        let clientId = '';
        if (params.get('clientId') && url.origin === process.env.REACT_APP_BASE_LOADER_URI) {
            clientId = params.get('clientId') || '';
        }
        else if (typeof window !== 'undefined' && hasProp(window, '__UWI_WIDGET_CLIENT_ID__')) {
            clientId = (window as any).__UWI_WIDGET_CLIENT_ID__;
        }

        this.user = null;

        if (this.jwtToken) {
            const [header, body] = this.jwtToken.split('.');
            const decoded = atob(body);
            try {
                const jsonObj = JSON.parse(decoded);

                if (
                    hasProp(jsonObj, 'id')
                    && hasProp(jsonObj, 'email')
                    && hasProp(jsonObj, 'firstName')
                    && hasProp(jsonObj, 'middleName')
                    && hasProp(jsonObj, 'lastName')
                    && hasProp(jsonObj, 'mobileNumber')
                    && hasProp(jsonObj, 'developerClientId')
                    && (jsonObj.developerClientId === clientId)
                ) {
                    this.user = {
                        id: jsonObj.id,
                        email: jsonObj.email,
                        firstName: jsonObj.firstName,
                        middleName: jsonObj.middleName,
                        lastName: jsonObj.lastName,
                        mobileNumber: jsonObj.mobileNumber
                    };
                }
            }
            catch (ex) {
                console.error(ex);
            }
    
            this.hasFirstLoadedFromCookie = true;
        }
    }
}
