import { useContext, useEffect, useMemo, useState } from 'react';
import { SubProject } from 'src/api/SubProjectApi';
import { FormFieldWrapper } from 'src/components/common';
import { CircleLoaderIcon, CirclePlusSolidIcon, MagnifyingGlassSolidIcon } from 'src/components/icons';
import CircleMinusSolidIcon from 'src/components/icons/CircleMinusSolidIcon';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { selectIsProjectOnlyView, selectWidgetType } from 'src/store/features/commonSlice';
import { selectSelectedSubProjects, setSelectedSubProjects } from 'src/store/features/preApproveLoanPropertyInfoSlice';
import Context from '../PreQualificationContext';

type Props = {
    className?: string;
    onClose?: () => void;
    onNext?: () => Promise<boolean>;
    visible: boolean;
    onViewImage?: (subProject: SubProject) => void;
    subProject: SubProject|null;
};

const SubProjectUIDetails = ({
    onViewImage,
    className,
    subProject,
    visible,
    onClose,
    onNext
}: Props) => {
    const dispatch = useAppDispatch();
    const selectedSubProjects = useAppSelector(selectSelectedSubProjects);

    const { page, setPage } = useContext(Context);
    const isProjectOnlyView = useAppSelector(selectIsProjectOnlyView);
    const widgetType = useAppSelector(selectWidgetType);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [hasPriceError, setHasPriceError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const otherCharges = useMemo(() => {
        if (subProject) {
            const additionalCostType = subProject.additionalCost.additionalCostType;
            const additionalCostValue = subProject.additionalCost.additionalCostValue;
            
            if (additionalCostType === 'percent' && additionalCostValue > 0) {
                return (price * (additionalCostValue / 100));
            }
            else if (additionalCostType === 'flat' && additionalCostValue > 0) {
                return subProject.additionalCost.additionalCostValue;
            }
            else {
                return 0;
            }
        }
    }, [subProject, price])

    const fullPrice = useMemo(() => {
        if (otherCharges && otherCharges > 0) {
            return price + otherCharges;
        }
        else {
            return price;
        }
    }, [price, otherCharges])

    useEffect(() => {
        if (subProject && visible) {
            const maxValue = (subProject && subProject.prices.length >= 2) ? subProject.prices[1] : 0;
            const minValue = (subProject && subProject.prices.length >= 2) ? subProject.prices[0] : 0;

            if (subProject.priceType === "min_max") {
                setMinValue(minValue);
                setMaxValue(maxValue);
                setPrice(minValue + ((maxValue - minValue) / 2));
            }
            else {
                setPrice(subProject.prices[0]);
            }
        }
    }, [subProject, visible]);

    const showRemoveBtn = useMemo(() => {
        if (subProject) {
            const idx = selectedSubProjects.findIndex(subProj => (subProj.id === subProject.id));

            return (idx >= 0);
        }

        return false;
    }, [selectedSubProjects, subProject]);

    const subProjectTotalQuantity = useMemo(() => {
        if (subProject) {
            return selectedSubProjects.reduce((prevVal, curVal) => {
                return prevVal + ((curVal.id === subProject.id) ? curVal.quantity : 0);
            }, 0);
        }

        return 0;
    }, [selectedSubProjects, subProject]);

    const handleViewImage = onViewImage || (() => {});
    const handleClose = onClose || (() => {});

    const validate = (price: number) => {
        if (subProject?.priceType === "min_max") {
            if (price < minValue || price > maxValue) {
                setHasPriceError(true);
                return false;
            }
        }

        setHasPriceError(false);
        return true;
    }

    const handleAddToCart = async (price: number, quantity: number, subProject: SubProject) => {
        const subProjects = selectedSubProjects.map(subProj => ({ ...subProj }));
        const idx = subProjects.findIndex(subProj => (subProj.id === subProject.id && price === subProj.price));

        if (validate(price)) {
            if (idx >= 0) {
                subProjects[idx].quantity += quantity;
            }
            else {
                subProjects.push({
                    id: subProject.id,
                    name: `${subProject.projectTitle} - ${subProject.name}`,
                    image: subProject.image,
                    price,
                    quantity,
                    projectStatus: subProject.projectStatus,
                    projectType: subProject.projectType,
                    projectId: subProject.projectId,
                    subProjectReservationFee: 0
                });
            }

            dispatch(setSelectedSubProjects(subProjects));
        }

    };

    const handleRemoveFromCart = async (subProject: SubProject) => {
        const subProjects = selectedSubProjects.filter(subProj => (!(subProj.id === subProject.id)));

        dispatch(setSelectedSubProjects(subProjects));
    };

    if (subProject === null) {
        return <></>;
    }

    const handleNext = async () => {
        if (onNext) {
            try {
                setIsLoading(true);
                const isSuccess = await onNext();

                if (isSuccess) {
                    if (widgetType === 'advance' && !isProjectOnlyView) {
                        setPage((page < 1) ? page + 0.5 : page + 1);
                    }
                    else {
                        setPage(page + 1);
                    }
                }
            }
            finally{
                setIsLoading(false);
            }
        }
        else {
            if (widgetType === 'advance' && !isProjectOnlyView) {
                setPage((page < 1) ? page + 0.5 : page + 1);
            }
            else {
                setPage(page + 1);
            }
        }
    }

    return (
        <>
            {(visible) && (
                <div
                    className={`
                        absolute top-[37px] left-0
                        w-full
                        lg:pl-5 bg-white z-[1]
                        h-[calc(100vh-314px)] md:h-[369px]
                        ${className || ''}
                    `}
                >
                    <div
                        className={`flex flex-wrap pt-10 ${className || ''}`}
                    >
                        <div className="w-full md:w-[250px] mb-6 md:mb-0 flex justify-center md:block relative">
                            <div
                                className="absolute w-full h-[200px] md:w-[250px] md:h-[250px] top-0 left-0 z-[1] flex justify-center items-center cursor-pointer text-white"
                                onClick={() => handleViewImage(subProject)}
                            >
                                <MagnifyingGlassSolidIcon className="h-6 w-6" />
                            </div>
                            <img
                                className="object-cover w-[200px] h-[200px] md:w-[250px] md:h-[250px] rounded-xl"
                                src={subProject.image}
                                alt={`${subProject.name} thumbnail`}
                            />
                        </div>
                        <div className="w-full md:w-[calc(100%-300px)] md:pl-4">
                            <div
                                className={`
                                    border-b border-solid border-gray-50
                                    pb-1 md:pb-2.5
                                `}
                            >
                                <div className="font-bold text-[15px] mb-1">{subProject.projectTitle} - {subProject.name}</div>
                                <div className="text-gray-50 text-xs">{subProject.projectType}</div>
                            </div>
                            <div className="w-full">
                                <div className="w-full flex items-center justify-between pt-3 pb-8">
                                    <div className="flex items-center text-xs">
                                        <span className='mr-2'>Price</span>
                                        {(subProject.priceType === "min_max") ? (
                                        <div className="text-gray-50">{`(`}{minValue.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })} - {maxValue.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}{`)`}</div>
                                        ) : (
                                            <div>{subProject.prices[0].toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</div>
                                        )}
                                    </div>
                                    
                                    {(subProject.priceType === "min_max") &&
                                    <div className='min-w-[300px]'>
                                        <FormFieldWrapper
                                            showError={hasPriceError}
                                            errorMessage={`Price must be between ${minValue.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })} and ${maxValue.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}.`}
                                            className="w-full"
                                            messageClassName="!-left-[180px]"
                                        >
                                            <input
                                                className={`
                                                    form-field
                                                    ${(hasPriceError) ? '!border-red-200' : ''}
                                                `}
                                                type="tel"
                                                placeholder="Price"
                                                value={(typeof price === 'number') ? price.toLocaleString('en-US') : ''}
                                                onChange={(e) => {
                                                    const value = parseInt(e.target.value.replace(/,/g, ''));
                                                    setPrice(isNaN(value) ? 0 : value);
                                                }}
                                            />
                                        </FormFieldWrapper>
                                    </div>
                                    }
                                </div>

                                <div className="w-full flex items-center justify-between pb-8">
                                    <span className='mr-2 text-xs'>Other Charges</span>  
                                    <div className='text-base'>{otherCharges?.toLocaleString('en-US')}</div>
                                </div>

                                <div className="w-full flex items-center justify-between pb-8">
                                    <span className='mr-2 text-xs'>Full Price</span>  
                                    <div className='text-base font-bold'>{fullPrice.toLocaleString('en-US')}</div>
                                </div>
                                
                                <div className="w-full flex justify-between items-center pt-3 border-t border-solid border-gray-50">
                                    <div className="flex items-center">
                                        <div className="text-xs mr-1">Quantity</div>
                                        <div className="text-sm font-bold flex items-center mr-4">
                                            <button
                                                className={`
                                                    mr-2
                                                    ${(quantity === 1) ? 'text-gray-50' : 'text-uwi-primary-100 hover:text-uwi-primary-200 focus:text-uwi-primary-200'}
                                                `}
                                                disabled={(quantity <= 1)}
                                                onClick={() => setQuantity(quantity - 1)}
                                            >
                                                <CircleMinusSolidIcon className="w-6 h-6"/>
                                            </button>
                                            <div className="mr-2">{quantity}</div>
                                            <button
                                                className="text-uwi-primary-100 hover:text-uwi-primary-200 focus:text-uwi-primary-200"
                                                onClick={() => setQuantity(quantity + 1)}
                                            >
                                                <CirclePlusSolidIcon className="w-6 h-6"/>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <button
                                            className={`
                                                text-white bg-uwi-primary-100 hover:bg-uwi-primary-200
                                                text-sm
                                                px-4 md:px-8
                                                py-2 md:py-4
                                                rounded-2xl
                                                font-bold
                                            `}
                                            onClick={() => handleAddToCart(fullPrice, quantity, subProject)}
                                        >
                                            Add to list
                                        </button>

                                        {(showRemoveBtn) && (
                                            <button
                                                className={`
                                                    text-white bg-uwi-primary-100 hover:bg-uwi-primary-200
                                                    text-sm
                                                    px-4 md:px-8
                                                    py-2 md:py-4
                                                    rounded-2xl
                                                    font-bold
                                                    ml-2
                                                `}
                                                onClick={() => handleRemoveFromCart(subProject)}
                                            >
                                                Remove ({subProjectTotalQuantity})
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center gap-4 mt-14">
                        <button
                                className="
                                flex justify-center items-center rounded-2xl
                                px-8 py-4
                                font-bold text-[15px]
                                border border-solid
                                border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white"
                                onClick={() => handleClose()}
                            >
                            Add another property
                        </button>

                        <button
                            className={`
                              text-white bg-uwi-primary-100 hover:bg-uwi-primary-200
                                text-sm
                                px-4 md:px-8
                                py-2 md:py-4
                                rounded-2xl
                                font-bold
                            `}
                            onClick={() => { 
                                handleClose();
                                handleNext();
                             }}
                             disabled={isLoading}
                        >
                            Next
                            {(isLoading) && (
                                <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                            )}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default SubProjectUIDetails;
