import { useEffect, useState } from 'react';
import { FormGroup, Select, SelectOption } from 'src/components/common';
import { Dependent } from 'src/api/HomeLoanApplicationApi';
import relationships from 'src/data/relationships.json';

type Props = {
    onChange?: (val: Dependent[]) => void;
    value?: Dependent[];
    className?: string;
};

const FieldDependents = ({
    onChange,
    value,
    className
}: Props) => {
    const [dependents, setDependents] = useState<Dependent[]>([]);

    useEffect(() => {
        if (value && value.length > 0) {
            setDependents(value);
        }
        else {
            const dependents = [];
            dependents.push({
                name: '',
                age: 0,
                relationship: ''
            });
            setDependents(dependents);
        }
    }, [value]);

    const handleChange = onChange || (() => {});

    const updateName = (newName: string, idx: number) => {
        if (idx >= 0 && idx < dependents.length) {
            const dependentsCopy = [...dependents];
            const obj = {...dependentsCopy[idx]};

            obj.name = newName;
            dependentsCopy[idx] = obj;

            setDependents(dependentsCopy);
            handleChange(dependentsCopy);
        }
    };

    const updateAge = (age: number, idx: number) => {
        if (idx >= 0 && idx < dependents.length) {
            const dependentsCopy = [...dependents];
            const obj = {...dependentsCopy[idx]};

            obj.age = age;
            dependentsCopy[idx] = obj;

            setDependents(dependentsCopy);
            handleChange(dependentsCopy);
        }
    };

    const updateRelationship = (relationship: string, idx: number) => {
        if (idx >= 0 && idx < dependents.length) {
            const dependentsCopy = [...dependents];
            const obj = {...dependentsCopy[idx]};

            obj.relationship = relationship;
            dependentsCopy[idx] = obj;

            setDependents(dependentsCopy);
            handleChange(dependentsCopy);
        }
    };

    const addDependent = () => {
        if (dependents.length < 3) {
            const dependentsCopy = [...dependents];
            dependentsCopy.push({
                name: '',
                age: 0,
                relationship: ''
            });

            setDependents(dependentsCopy);
            handleChange(dependentsCopy);
        }
    };

    return (
        <div className={`w-full mt-4 ${className}`}>
            <div className="w-full mb-2">
                {(dependents.map((dependent, idx) => (
                    <div
                        className="mb-4"
                        key={idx}
                    >
                        <div className="text-uwi-primary-100 text-center">
                            {`Dependent ${idx + 1}`}
                        </div>
                        <div className="flex flex-wrap">
                            <FormGroup
                                label="Name"
                                className="w-full mb-4 md:w-[calc((100%-20px)/2)] md:mb-0 md:mr-2.5"
                            >
                                <input
                                    className="form-field"
                                    type="text"
                                    placeholder="Enter Full Legal Name"
                                    value={dependent.name}
                                    onChange={(e) => updateName(e.target.value, idx)}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Age"
                                className="w-full mb-4 md:w-[calc((100%-20px)/4)] md:mb-0 md:mr-2.5"
                            >
                                <input
                                    className="form-field"
                                    type="number"
                                    placeholder="Enter Age"
                                    min={0}
                                    value={(dependent.age === 0) ? undefined : dependent.age}
                                    onChange={(e) => updateAge(parseInt(e.target.value), idx)}
                                />
                            </FormGroup>

                            <FormGroup
                                label="Relationship"
                                className="w-full md:w-[calc((100%-20px)/4)]"
                            >
                                <Select
                                    className="w-full"
                                    placeholder="Please Select"
                                    value={dependent.relationship}
                                    onChange={(val) => updateRelationship(val as string, idx)}
                                >
                                    {relationships.map(relationship => (
                                        <SelectOption
                                            key={relationship}
                                            value={relationship}
                                        >{relationship}</SelectOption>
                                    ))}
                                </Select>
                            </FormGroup>
                        </div>
                    </div>
                )))}
            </div>

            <div className="w-full flex justify-between">
                <div className="text-xs">Max of 3</div>
                <div>
                    <button
                        className="text-xs hover:text-uwi-primary-100 hover:bg-transparent focus:bg-transparent"
                        onClick={() => addDependent()}
                    >
                        Add Dependent
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FieldDependents;
