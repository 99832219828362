import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectMonthlyIncome,
    selectWorkExperience,
    selectWorkType,
    selectFormErrors,
    setFormErrors,
    selectBusinessProprietorship
} from 'src/store/features/preApproveLoanWorkInfoSlice';

const useWorkInfoValidation = () => {
    const dispatch = useAppDispatch();
    const selectedWorkType = useAppSelector(selectWorkType);
    const selectedWorkExperience = useAppSelector(selectWorkExperience);
    const selectedBusinessProprietorship = useAppSelector(selectBusinessProprietorship);
    const selectedMonthlyIncome = useAppSelector(selectMonthlyIncome);
    const formErrors = useAppSelector(selectFormErrors);

    const validateForm = async () => {
        const errors:Record<string, string> = {};
        if (selectedWorkType.trim().length === 0) {
            errors.workType = 'Work Type is required.';
        }

        if (selectedWorkExperience.trim().length === 0) {
            errors.workExperience = 'Work experience is required'
        }

        if (selectedWorkType === 'Self-Employed' && selectedBusinessProprietorship.trim().length === 0) {
            errors.businessProprietorship = 'Business Proprietorship is required'
        }

        if (selectedMonthlyIncome <= 0) {
            errors.monthlyIncome = 'Monthly income should be greater than 0.';
        }

        if (isNaN(selectedMonthlyIncome)) {
            errors.monthlyIncome = 'Monthly income is required.';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
}

export default useWorkInfoValidation;