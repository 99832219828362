import { useAppSelector } from "src/hooks/generic";
import { selectWidgetType } from "src/store/features/commonSlice";
import useBasicWidgetPreQualification from "./useBasicWidgetPreQualification";
import usePreQualification from "./usePreQualification";

const usePreQualificationApi = () => {
    const widgetType = useAppSelector(selectWidgetType);
    const advanceApis = usePreQualification();
    const basicApis = useBasicWidgetPreQualification();

    return (widgetType === 'advance') ? advanceApis : basicApis;
};

export default usePreQualificationApi;