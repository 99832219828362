import { FormFieldWrapper, FormGroup, Select, SelectOption } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { HomeLoanApplication } from 'src/api/HomeLoanApplicationApi';
import {
    setCompanyIndustry,
    setCompanyName,
    setOfficePhone,
    setOfficeAddress,
    setTin,
    setSss,
    setEmploymentRank,
    setOfficeEmail,
    selectCompanyIndustry,
    selectCompanyName,
    selectOfficePhone,
    selectOfficeAddress,
    selectTin,
    selectSss,
    selectEmploymentRank,
    selectOfficeEmail
} from 'src/store/features/applyLoanEmploymentInfoSlice';
import companyIndustryList from 'src/data/companyIndustryList.json';
import employmentRanks from 'src/data/employmentRanks.json';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import useEmploymentInfo from '../hooks/useEmploymentInfo';

type Props = {
    homeLoanApp?: HomeLoanApplication;
};

const EmploymentInfo = ({
    homeLoanApp
}: Props) => {
    const dispatch = useAppDispatch();
    const {
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    } = useEmploymentInfo(homeLoanApp);

    const companyIndustry = useAppSelector(selectCompanyIndustry);
    const companyName = useAppSelector(selectCompanyName);
    const officePhone = useAppSelector(selectOfficePhone);
    const officeAddress = useAppSelector(selectOfficeAddress);
    const tin = useAppSelector(selectTin);
    const sss = useAppSelector(selectSss);
    const employmentRank = useAppSelector(selectEmploymentRank);
    const officeEmail = useAppSelector(selectOfficeEmail);

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[600px] md:w-[700px]">
                    <div className="flex flex-wrap mb-4">
                        <div className="w-full md:w-[calc(50%-8px)] md:mr-4">
                            <FormGroup
                                label="Company Industry"
                                className="mb-4"
                            >
                                <Select
                                    className={`
                                        w-full
                                        ${(hasProp(formErrors, 'companyIndustry')) ? '!border-red-200' : ''}
                                    `}
                                    placeholder="Please Select"
                                    value={companyIndustry}
                                    onChange={(val) => {
                                        dispatch(setCompanyIndustry(val as string));
                                        const { companyIndustry, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                >
                                    {companyIndustryList.map(companyIndustry => (
                                        <SelectOption
                                            key={companyIndustry}
                                            value={companyIndustry}
                                        >{companyIndustry}</SelectOption>
                                    ))}
                                </Select>
                            </FormGroup>

                            <FormGroup
                                label="Company Name"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'companyName')}
                                    errorMessage={(hasProp(formErrors, 'companyName')) ? formErrors.companyName : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'companyName')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="Enter Company Name"
                                        value={companyName}
                                        minLength={2}
                                        maxLength={100}
                                        onChange={(e) => {
                                            dispatch(setCompanyName(e.target.value));
                                            const { companyName, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>

                            <FormGroup
                                label="Office Phone/Fax"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'officePhone')}
                                    errorMessage={(hasProp(formErrors, 'officePhone')) ? formErrors.officePhone : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'officePhone')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="Enter Office Phone/Fax"
                                        value={officePhone}
                                        minLength={2}
                                        maxLength={20}
                                        onChange={(e) => {
                                            dispatch(setOfficePhone(e.target.value));
                                            const { officePhone, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>

                            <FormGroup
                                label="Office Address"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'officeAddress')}
                                    errorMessage={(hasProp(formErrors, 'officeAddress')) ? formErrors.officeAddress : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'officeAddress')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="Enter Office Address"
                                        value={officeAddress}
                                        minLength={2}
                                        maxLength={250}
                                        onChange={(e) => {
                                            dispatch(setOfficeAddress(e.target.value));
                                            const { officeAddress, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>
                        </div>
                        <div className="w-full md:w-[calc(50%-8px)]">
                            <FormGroup
                                label="Tax Identification Number"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'tin')}
                                    errorMessage={(hasProp(formErrors, 'tin')) ? formErrors.tin : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'tin')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="XXX-XXX-XXX"
                                        value={tin}
                                        minLength={2}
                                        maxLength={30}
                                        onChange={(e) => {
                                            dispatch(setTin(e.target.value));
                                            const { tin, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>

                            <FormGroup
                                label="SSS/GSIS Number"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'sss')}
                                    errorMessage={(hasProp(formErrors, 'sss')) ? formErrors.sss : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'sss')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="XX-XXXXXXX-X"
                                        value={sss}
                                        minLength={1}
                                        maxLength={30}
                                        onChange={(e) => {
                                            dispatch(setSss(e.target.value));
                                            const { sss, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>

                            <FormGroup
                                label="Employment Rank"
                                className="mb-4"
                            >
                                <Select
                                    className={`
                                        w-full
                                        ${(hasProp(formErrors, 'employmentRank')) ? '!border-red-200' : ''}
                                    `}
                                    placeholder="Please Select"
                                    value={employmentRank}
                                    onChange={(val) => {
                                        dispatch(setEmploymentRank(val as string));
                                        const { employmentRank, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                >
                                    {employmentRanks.map(employmentRank => (
                                        <SelectOption
                                            key={employmentRank}
                                            value={employmentRank}
                                        >{employmentRank}</SelectOption>
                                    ))}
                                </Select>
                            </FormGroup>

                            <FormGroup
                                label="Office Email"
                                className="mb-4"
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'officeEmail')}
                                    errorMessage={(hasProp(formErrors, 'officeEmail')) ? formErrors.officeEmail : ''}
                                    className="w-full"
                                >
                                    <input
                                        className={`
                                            form-field
                                            ${(hasProp(formErrors, 'officeEmail')) ? '!border-red-200' : ''}
                                        `}
                                        type="text"
                                        placeholder="Enter Office Email"
                                        value={officeEmail}
                                        minLength={6}
                                        maxLength={50}
                                        onChange={(e) => {
                                            dispatch(setOfficeEmail(e.target.value));
                                            const { officeEmail, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                            setIsDirty(true);
                                        }}
                                    />
                                </FormFieldWrapper>
                            </FormGroup>
                        </div>
                    </div>
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={handleNext}
            />
        </div>
    );
}

export default EmploymentInfo;