import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';
import { AddedSubProject, SubProjectPreQualificationTableRow } from './SubProjectApi';

export type PreQualificationStatusType = 'pending' | 'approved' | 'applied' | 'denied';

export interface PreQualification {
    id: string;
    userId: string;
    projectCost: number;
    projectType: string;
    projectStatus: string;
    developerName: string;
    projectRegion: string;
    workType: string;
    isRegularEmployee: boolean;
    isBusinessProfitable: boolean;
    workExperience: string;
    businessProprietorship?: string;
    monthlyIncome: number;
    nationality: string;
    visaType: string;
    birthdate: Date;
    email: string;
    mobileNumber: string;
    currentAddress: string;
    currentRegion: string;
    maritalStatus: string;
    coBorrowerJob: string;
    coBorrowerMonthlyIncome: number;
    needBroker: boolean;
    needThirdPartyVendor: boolean;
    hasCreditCard: boolean;
    hasCancelledCreditCard: boolean;
    hasFullPaymentCertificate: boolean;
    extraIncomeSource: string;
    extraIncomeAmount: number;
    existingDebtSource: string;
    existingDebtAmount: number;
    loanTerm: number;
    percentOfProjectCost: number;
    referenceNumber: string;
    status: PreQualificationStatusType;
    ownedBanks: string[];
    subProjects?: AddedSubProject[];
    createdAt: Date;
    updatedAt: Date;
}

export interface PreQualificationTableRow {
    id: string;
    userId: string;
    projectCost: number;
    projectType: string;
    projectStatus: string;
    developerName: string;
    projectRegion: string;
    workType: string;
    isRegularEmployee: boolean;
    isBusinessProfitable: boolean;
    workExperience: string;
    businessProprietorship?: string;
    monthlyIncome: number;
    nationality: string;
    visaType: string;
    birthdate: Date;
    email: string;
    mobileNumber: string;
    currentAddress: string;
    currentRegion: string;
    maritalStatus: string;
    coBorrowerJob: string;
    coBorrowerMonthlyIncome: number;
    hasCreditCard: boolean;
    hasCancelledCreditCard: boolean;
    hasFullPaymentCertificate: boolean;
    extraIncomeSource: string;
    extraIncomeAmount: number;
    existingDebtSource: string;
    existingDebtAmount: number;
    loanTerm: number;
    percentOfProjectCost: number;
    referenceNumber: string;
    status: PreQualificationStatusType;
    ownedBanks: string[];
    subProjects: SubProjectPreQualificationTableRow[];
    createdAt: Date;
    updatedAt: Date;
}

export interface PreQualificationForm {
    subProjects: Partial<AddedSubProject>[];
    workType: string;
    isRegularEmployee: boolean;
    isBusinessProfitable: boolean;
    workExperience: string;
    businessProprietorship?: string;
    monthlyIncome: number;
    nationality: string;
    visaType?: string;
    birthdate: string;
    maritalStatus: string;
    coBorrowerMonthlyIncome: number;
    hasCancelledCreditCard: boolean;
    hasFullPaymentCertificate: boolean;
    extraIncomeAmount: number;
    existingDebtAmount: number;
    loanTerm: number;
    percentOfProjectCost: number;
    ownedBanks: string[];
}

@Service()
export class PreQualificationApi extends BaseApi {
    /**
     * API path to get the info of the pre qualification info.
     */
    static readonly PATH_GET_INFO = '/v1/widget/pre-qualification/$preQualificationId';

    /**
     * API path to save the pre qualification.
     */
    static readonly PATH_CREATE = '/v1/widget/pre-qualification/create';

    /**
     * API path to temporarily save the info of the pre qualification.
     */
    static readonly PATH_SAVE_INFO = '/v1/widget/pre-qualification/save-info';

    /**
     * API path to get the list of pre qualifications of the logged user.
     */
    static readonly PATH_LIST = '/v1/widget/pre-qualification/list';

    /**
     * API path to get the filtered list of pre qualifications of the logged user.
     */
    static readonly PATH_SEARCH = '/v1/widget/pre-qualification/search';

    /**
     * API path to update the pre qualification.
     */
    static readonly PATH_UPDATE = '/v1/widget/pre-qualification/$preQualificationId';

    create(
        form: PreQualificationForm
    ): Promise<PreQualification> {
        const subProjects = form.subProjects.map(subProj => ({
            subProjectId: subProj.id,
            price: subProj.price,
            quantity: subProj.quantity
        }));

        const data = {
            ...form,
            ...{
                ownedBanks: JSON.stringify(form.ownedBanks),
                subProjects: JSON.stringify(subProjects)
            }
        };

        return this.http.post(
            PreQualificationApi.PATH_CREATE,
            data,
            {},
            true
        );
    }

    saveInfo(
        form: PreQualificationForm
    ): Promise<{ key: string; developerPortalUrl: string; }> {
        const subProjects = form.subProjects.map(subProj => ({
            subProjectId: subProj.id,
            price: subProj.price,
            quantity: subProj.quantity
        }));

        const data = {
            ...form,
            ...{
                ownedBanks: JSON.stringify(form.ownedBanks),
                subProjects: JSON.stringify(subProjects)
            }
        };

        return this.http.post(
            PreQualificationApi.PATH_SAVE_INFO,
            data
        );
    }

    async getInfo(preQualificationId: string): Promise<PreQualification> {
        const obj:PreQualification = await this.http.get(
            PreQualificationApi.PATH_GET_INFO.replace(/\$preQualificationId/, preQualificationId),
            {},
            {},
            true
        );

        obj.birthdate = new Date(obj.birthdate);
        obj.createdAt = new Date(obj.createdAt);
        obj.updatedAt = new Date(obj.updatedAt);

        return obj;
    }

    async list(): Promise<PreQualificationTableRow[]> {
        const rawList = await this.http.get(
            PreQualificationApi.PATH_LIST,
            {},
            {},
            true
        );

        const list = rawList as PreQualificationTableRow[];

        return list.map(req => {
            req.birthdate = new Date(req.birthdate);
            req.createdAt = new Date(req.createdAt);
            req.updatedAt = new Date(req.updatedAt);

            return req;
        });
    }

    async search(projectIds: string[]): Promise<PreQualificationTableRow[]> {
        const rawList = await this.http.post(
            PreQualificationApi.PATH_SEARCH,
            { projectIds },
            {},
            true
        );

        const list = rawList as PreQualificationTableRow[];

        return list.map(req => {
            req.birthdate = new Date(req.birthdate);
            req.createdAt = new Date(req.createdAt);
            req.updatedAt = new Date(req.updatedAt);

            return req;
        });
    };

    update(
        id: string,
        form: PreQualificationForm
    ): Promise<{ status: boolean }> {
        const subProjects = form.subProjects.map(subProj => ({
            subProjectId: subProj.id,
            price: subProj.price,
            quantity: subProj.quantity
        }));

        const data = {
            ...form,
            ...{
                ownedBanks: JSON.stringify(form.ownedBanks),
                subProjects: JSON.stringify(subProjects)
            }
        };

        return this.http.put(
            PreQualificationApi.PATH_UPDATE.replace(/\$preQualificationId/, id),
            data,
            {},
            true
        );
    }
}
