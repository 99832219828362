import { Container } from 'typedi';
import { HttpRequest } from './lib/HttpRequest';
import { HttpRequestFactory } from './lib/HttpRequestFactory';

abstract class BaseApi {
    /**
     * The HTTP request object.
     */
    protected readonly http: HttpRequest;

    constructor() {
        this.http = Container.get(HttpRequestFactory).getImplementation();
    }
}

export default BaseApi;