import {
    ChangeEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import { CloudArrowUpSolidIcon, XMarkSolidIcon } from '../icons';

type Props = {
    className?: string;
    onChange?: (file: File|null) => void;
    value?: string;
    // sample .png,.jpeg,.jpg,.pdf
    accept?: string;
};

export default function InputFile({
    className,
    onChange,
    value,
    accept
}: Props) {
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (value) {
            setFileName(value);
        }
    }, [value]);

    useEffect(() => {
        if (inputFileRef.current && accept) {
            inputFileRef.current.accept = accept;
        }
    }, [inputFileRef.current]);

    const handleChange = onChange || (() => {});

    const handleClick = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    };

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
            if (accept) {
                const fileExts = accept.split(',');
                const selectedFilePcs = e.target.files[0].name.split('.');
                if (fileExts.includes(`.${selectedFilePcs[selectedFilePcs.length - 1]}`)) {
                    setFileName(e.target.files[0].name)
                    handleChange(e.target.files[0]);
                }
            }
            else {
                setFileName(e.target.files[0].name)
                handleChange(e.target.files[0]);
            }
        }
    };
    
    return (
        <>
            <div
                className={`md:max-w-[300px] w-full rounded-xl flex flex-nowrap items-center border border-solid border-gray-200 text-gray-200 px-3 py-1 cursor-pointer ${className || ''}`}
                onClick={() => handleClick()}
            >
                <CloudArrowUpSolidIcon className="w-7 h-7 mr-2" />
                <div className="mr-2">Choose File</div>
                <div
                    className={`
                        w-[calc(100%-133px)] overflow-hidden text-ellipsis whitespace-nowrap
                        ${(fileName !== '') ? 'text-black' : ''}
                    `}
                >
                    {(fileName !== '') ? fileName : 'no file selected'}
                </div>
            </div>

            <input
                ref={inputFileRef}
                type="file"
                className="hidden"
                accept={accept}
                onChange={onFileChange}
            />
        </>
    );
}