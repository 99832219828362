import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectNationality,
    selectVisaType,
    selectBirthdate,
    selectMaritalStatus,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/preApproveLoanOtherInfoSlice';

const useOtherInfoValidation = () => {
    const dispatch = useAppDispatch();
    const selectedNationality = useAppSelector(selectNationality);
    const selectedVisaType = useAppSelector(selectVisaType);
    const selectedBirthdate = useAppSelector(selectBirthdate);
    const selectedMaritalStatus = useAppSelector(selectMaritalStatus);
    const formErrors = useAppSelector(selectFormErrors);

    const validateForm = async () => {
        const errors:Record<string, string> = {};

        if (selectedNationality.trim().length === 0) {
            errors.nationality = 'Nationality is required';
        }
        else if (selectedNationality === 'Foreigner' && selectedVisaType.trim().length === 0) {
            errors.visaType = 'Visa type is required';
        }

        if (!selectedBirthdate) {
            errors.birthdate = JSON.stringify({
                year: 'Birthdate year is required',
                month: 'Birthdate month is required',
                day: 'Birthdate day is required'
            });
        }

        if (selectedBirthdate) {
            const birthDateError: Record<string, string> = {};
            if (!selectedBirthdate.year || isNaN(selectedBirthdate.year)) {
                birthDateError.year = 'Birthdate year is required';
            }

            if (typeof selectedBirthdate.month === 'undefined' || isNaN(selectedBirthdate.month)) {
                birthDateError.month = 'Birthdate month is required';
            }

            if (!selectedBirthdate.day) {
                birthDateError.day = 'Birthdate day is required';
            }

            if (Object.keys(birthDateError).length > 0) {
                errors.birthdate = JSON.stringify(birthDateError);
            }
        }

        if (selectedNationality.trim().length === 0) {
            errors.nationality = 'Nationality is required';
        }

        if (selectedMaritalStatus.trim().length === 0) {
            errors.maritalStatus = 'Marital Status is required';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
}

export default useOtherInfoValidation;
