import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBirthdate } from 'src/hooks/useBirthdate';
import { RootState } from '../index';

interface ApplyLoanPersonalInfoState {
    firstName: string;
    middleName: string;
    lastName: string;
    nameSuffix: string;
    gender: string;
    placeOfBirth: string;
    educationalBG: string;
    civilStatus: string;
    motherMaidenName: string;
    spouseFirstName: string;
    spouseMiddleName: string;
    spouseLastName: string;
    spouseMotherMaidenName: string;
    spouseBirthdate: IBirthdate | undefined;
    formErrors: Record<string, string>;
}

const initialState: ApplyLoanPersonalInfoState = {
    firstName: '',
    middleName: '',
    lastName: '',
    nameSuffix: '',
    gender: '',
    placeOfBirth: '',
    educationalBG: '',
    civilStatus: '',
    motherMaidenName: '',
    spouseFirstName: '',
    spouseMiddleName: '',
    spouseLastName: '',
    spouseMotherMaidenName: '',
    spouseBirthdate: undefined,
    formErrors: {}
};

export const applyLoanPersonalInfoSlice = createSlice({
    name: 'applyLoanPersonalInfo',

    initialState,

    reducers: {
        setFirstName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.firstName = action.payload;
        },

        setMiddleName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.middleName = action.payload;
        },

        setLastName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.lastName = action.payload;
        },

        setNameSuffix: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.nameSuffix = action.payload;
        },

        setGender: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.gender = action.payload;
        },

        setPlaceOfBirth: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.placeOfBirth = action.payload;
        },

        setEducationalBG: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.educationalBG = action.payload;
        },

        setCivilStatus: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.civilStatus = action.payload;
        },

        setMotherMaidenName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.motherMaidenName = action.payload;
        },

        setSpouseFirstName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.spouseFirstName = action.payload;
        },

        setSpouseMiddleName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.spouseMiddleName = action.payload;
        },

        setSpouseLastName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.spouseLastName = action.payload;
        },

        setSpouseMotherMaidenName: (state: ApplyLoanPersonalInfoState, action: PayloadAction<string>) => {
            state.spouseMotherMaidenName = action.payload;
        },

        setSpouseBirthdate: (state: ApplyLoanPersonalInfoState, action: PayloadAction<IBirthdate>) => {
            state.spouseBirthdate = action.payload;
        },

        setState: (state: ApplyLoanPersonalInfoState, action: PayloadAction<Partial<ApplyLoanPersonalInfoState>>) => {
            state.firstName = action.payload.firstName || state.firstName;
            state.middleName = action.payload.middleName || state.middleName;
            state.lastName = action.payload.lastName || state.lastName;
            state.nameSuffix = action.payload.nameSuffix || state.nameSuffix;
            state.gender = action.payload.gender || state.gender;
            state.placeOfBirth = action.payload.placeOfBirth || state.placeOfBirth;
            state.educationalBG = action.payload.educationalBG || state.educationalBG;
            state.civilStatus = action.payload.civilStatus || state.civilStatus;
            state.motherMaidenName = action.payload.motherMaidenName || state.motherMaidenName;
            state.spouseFirstName = action.payload.spouseFirstName || state.spouseFirstName;
            state.spouseMiddleName = action.payload.spouseMiddleName || state.spouseMiddleName;
            state.spouseLastName = action.payload.spouseLastName || state.spouseLastName;
            state.spouseMotherMaidenName = action.payload.spouseMotherMaidenName || state.spouseMotherMaidenName;
            state.spouseBirthdate = action.payload.spouseBirthdate || state.spouseBirthdate;
        },

        setFormErrors: (state: ApplyLoanPersonalInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        },

        setDefaultFormValues: (state: ApplyLoanPersonalInfoState) => {
            state.firstName = '';
            state.middleName = '';
            state.lastName = '';
            state.nameSuffix = '';
            state.gender = '';
            state.placeOfBirth = '';
            state.educationalBG = '';
            state.civilStatus = '';
            state.motherMaidenName = '';
            state.spouseFirstName = '';
            state.spouseMiddleName = '';
            state.spouseLastName = '';
            state.spouseMotherMaidenName = '';
            state.spouseBirthdate = undefined;
        }
    }
});

export const {
    setFirstName,
    setMiddleName,
    setLastName,
    setNameSuffix,
    setGender,
    setPlaceOfBirth,
    setEducationalBG,
    setCivilStatus,
    setMotherMaidenName,
    setSpouseFirstName,
    setSpouseMiddleName,
    setSpouseLastName,
    setSpouseMotherMaidenName,
    setSpouseBirthdate,
    setState,
    setFormErrors,
    setDefaultFormValues
} = applyLoanPersonalInfoSlice.actions;

export const selectFirstName = (state: RootState) => state.applyLoanPersonalInfo.firstName;
export const selectMiddleName = (state: RootState) => state.applyLoanPersonalInfo.middleName;
export const selectLastName = (state: RootState) => state.applyLoanPersonalInfo.lastName;
export const selectNameSuffix = (state: RootState) => state.applyLoanPersonalInfo.nameSuffix;
export const selectGender = (state: RootState) => state.applyLoanPersonalInfo.gender;
export const selectPlaceOfBirth = (state: RootState) => state.applyLoanPersonalInfo.placeOfBirth;
export const selectEducationBG = (state: RootState) => state.applyLoanPersonalInfo.educationalBG;
export const selectCivilStatus = (state: RootState) => state.applyLoanPersonalInfo.civilStatus;
export const selectMotherMaidenName = (state: RootState) => state.applyLoanPersonalInfo.motherMaidenName;
export const selectSpouseFirstName = (state: RootState) => state.applyLoanPersonalInfo.spouseFirstName;
export const selectSpouseMiddleName = (state: RootState) => state.applyLoanPersonalInfo.spouseMiddleName;
export const selectSpouseLastName = (state: RootState) => state.applyLoanPersonalInfo.spouseLastName;
export const selectSpouseMotherMaidenName = (state: RootState) => state.applyLoanPersonalInfo.spouseMotherMaidenName;
export const selectSpouseBirthdate = (state: RootState) => state.applyLoanPersonalInfo.spouseBirthdate;
export const selectFormErrors = (state: RootState) => state.applyLoanPersonalInfo.formErrors;

export default applyLoanPersonalInfoSlice.reducer;