import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AngleDownSolidIcon, CircleLoaderIcon } from 'src/components/icons';
import useAuth from 'src/hooks/useAuth';
import { ConfirmModal } from 'src/components/common';
import { PreQualification } from 'src/api/PreQualificationApi';
import { useAppSelector } from 'src/hooks/generic';
import { selectHasBuyerPortal } from 'src/store/features/commonSlice';
import DebtIncomeInfo from './result/DebtIncomeInfo';
import LoanInfo from './result/LoanInfo';
import OtherInfo from './result/OtherInfo';
import PropertyInfo from './result/PropertyInfo';
import SearchResults from './result/SearchResults';
import WorkInfo from './result/WorkInfo';
import PreQualificationResultFormValuesModal from './PreQualificationResultFormValuesModal';
import useValidation from './validations/useValidation';
import usePreQualificationApi from './usePreQualificationApi';

type Props = {
    onEditForm?: () => void;
    preQualification?: PreQualification;
    onSomeFormInputInvalid?: (tab: string) => void;
};

const PreQualificationResult = ({
    onEditForm,
    onSomeFormInputInvalid,
    preQualification
}: Props) => {
    const {
        createPreQualification,
        updatePreQualification,
        savePreQualificationInfo,
        isSavingPreQualification
    } = usePreQualificationApi();
    
    const navigate = useNavigate();
    const { user } = useAuth();
    const { validate } = useValidation();
    const hasBuyerPortal = useAppSelector(selectHasBuyerPortal);

    const [showAllInfo, setShowAllInfo] = useState(false);
    const [isPreApprovedResultFormValuesModal, setIsPreApprovedResultFormValuesModal] = useState(false);
    const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [resultsCount, setResultsCount] = useState(0);
    const [confirmModalInfo, setConfirmModalInfo] = useState({
        message: 'Please login or create an account to save the Home Loan Assessment information.',
        title: 'Save Home Loan Assessment Information',
        btn1Text: 'Login',
        btn2Text: 'Sign Up'
    });

    const handleEditForm = onEditForm || (() => {});
    const handleSomeFormInputInvalid = onSomeFormInputInvalid || (() => {});

    const handleSaveForm = async () => {
        const [isValid, tab] = await validate();
        if (!isValid) {
            handleSomeFormInputInvalid(tab as string);
            return;
        }

        if (hasBuyerPortal) {
            setIsSaving(true);
            savePreQualificationInfo('save')
                .finally(() => {
                    setIsSaving(false);
                });

            return;
        }
        
        if (!user) {
            setConfirmModalInfo({
                message: 'Please login or create an account to save the Home Loan Assessment information.',
                title: 'Save Home Loan Assessment Information',
                btn1Text: 'Login',
                btn2Text: 'Sign Up'
            });
            setIsConfirmModalShown(true);
            return;
        }

        setIsSaving(true);
        if (preQualification) {
            await updatePreQualification(preQualification.id);
        }
        else {
            await createPreQualification();
        }
        setIsSaving(false);
    };

    const handleOnConfirm = (hasBtn1Clicked: boolean) => {
        setIsConfirmModalShown(false);

        if (hasBtn1Clicked) {
            navigate(`/login?referrer=${encodeURIComponent('/pre-qualification?tab=result')}`);
        }
        else {
            navigate(`/signup?referrer=${encodeURIComponent('/pre-qualification?tab=result')}`);
        }
    };

    const handleOnApplyShowConfirmModal = () => {
        setConfirmModalInfo({
            message: 'Please login or create an account to apply for the target home loan.',
            title: 'Apply Home Loan',
            btn1Text: 'Login',
            btn2Text: 'Sign Up'
        });
        setIsConfirmModalShown(true);
    };

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] px-4 md:px-0">
                        <div className="flex flex-wrap md:flex-nowrap">
                            <div className="hidden md:!block md:!visible md:w-2/5 md:px-8">
                                <div
                                    className={`
                                        w-full overflow-hidden transition-all text-center text-xs
                                        ${(showAllInfo) ? 'max-h-[1000px]' : 'max-h-[385px]'}
                                    `}
                                >
                                    <PropertyInfo
                                        className="pb-3 border-x border-solid border-[#f4f4f4]"
                                        headerClassName="w-full text-center rounded-t-xl"
                                        listClassName="w-full"
                                        listEntryClassName="w-full flex pt-1"
                                        listEntryLeftClassName="w-2/5 text-gray-50 text-right border-0 border-none p-0 pr-2 py-1 align-baseline"
                                        listEntryLeftValueClassName="w-3/5 text-left font-bold border-0 border-none p-0 py-1"
                                    />
                                    <WorkInfo
                                        className="pb-3 border-x border-solid border-[#f4f4f4]"
                                        headerClassName="w-full text-center"
                                        listClassName="w-full"
                                        listEntryClassName="w-full flex pt-1"
                                        listEntryLeftClassName="w-2/5 text-gray-50 text-right border-0 border-none p-0 pr-2 py-1 align-baseline"
                                        listEntryLeftValueClassName="w-3/5 text-left font-bold border-0 border-none p-0 py-1"
                                    />
                                    <OtherInfo 
                                        className="pb-3 border-x border-solid border-[#f4f4f4]"
                                        headerClassName="w-full text-center"
                                        listClassName="w-full"
                                        listEntryClassName="w-full flex pt-1"
                                        listEntryLeftClassName="w-2/5 text-gray-50 text-right border-0 border-none p-0 pr-2 py-1 align-baseline"
                                        listEntryLeftValueClassName="w-3/5 text-left font-bold border-0 border-none p-0 py-1"
                                    />
                                    <DebtIncomeInfo
                                        className="pb-3 border-x border-solid border-[#f4f4f4]"
                                        headerClassName="w-full text-center"
                                        listClassName="w-full"
                                        listEntryClassName="w-full flex pt-1"
                                        listEntryLeftClassName="w-2/5 text-gray-50 text-right border-0 border-none p-0 pr-2 py-1 align-baseline"
                                        listEntryLeftValueClassName="w-3/5 text-left font-bold border-0 border-none p-0 py-1"
                                    />
                                    <LoanInfo
                                        className="pb-3 border-x border-solid border-[#f4f4f4]"
                                        headerClassName="w-full text-center"
                                        listClassName="w-full"
                                        listEntryClassName="w-full flex pt-1"
                                        listEntryLeftClassName="w-2/5 text-gray-50 text-right border-0 border-none p-0 pr-2 py-1 align-baseline"
                                        listEntryLeftValueClassName="w-3/5 text-left font-bold border-0 border-none p-0 py-1"
                                    />
                                </div>
                                <div className="w-full pb-4">
                                    <div className="w-full flex justify-center pt-4 pb-2 rounded-b-xl border-x border-b border-solid border-[#f4f4f4] mb-4">
                                        <button
                                            className="flex flex-col items-center text-gray-50 hover:text-gray-100 hover:bg-transparent focus:bg-transparent focus-visible:border-none"
                                            onClick={() => setShowAllInfo(!showAllInfo)}
                                        >
                                            {(showAllInfo) ? 'Show Less' : 'View More'}
                                            <AngleDownSolidIcon
                                                className={`
                                                    w-8 h-8
                                                    ${(showAllInfo) ? 'rotate-180' : ''}
                                                `}
                                            />
                                        </button>
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <button
                                            className={`
                                                w-[calc((100%-16px)/2)] md:w-[150px] py-4 mr-2
                                                flex justify-center items-center rounded-2xl
                                                font-bold text-[15px]
                                                border border-solid
                                                ${
                                                    (
                                                        !isSavingPreQualification
                                                        && !isSaving
                                                        && (!preQualification || (preQualification && preQualification.status !== 'applied'))
                                                    )
                                                    ? `
                                                        border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                                        hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                                                    `
                                                    : 'bg-gray-100 text-white'
                                                }
                                            `}
                                            onClick={() => handleEditForm()}
                                            disabled={isSavingPreQualification || isSaving || (!!preQualification && preQualification.status === 'applied')}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className={`
                                                w-[calc((100%-16px)/2)] md:w-[150px] py-4
                                                flex justify-center items-center rounded-2xl
                                                font-bold text-[15px]
                                                border border-solid
                                                ${
                                                    (
                                                        !isSavingPreQualification
                                                        && !isSaving
                                                        && (resultsCount > 0)
                                                        && (!preQualification || (preQualification && preQualification.status !== 'applied'))
                                                    )
                                                    ? `
                                                        border-uwi-primary-100 hover:border-uwi-primary-200
                                                        bg-uwi-primary-100 hover:bg-uwi-primary-200 
                                                        text-white
                                                    `
                                                    : 'bg-gray-100 text-white'
                                                }
                                            `}
                                            onClick={() => handleSaveForm()}
                                            disabled={isSavingPreQualification || isSaving || (resultsCount === 0) || (!!preQualification && preQualification.status === 'applied')}
                                        >
                                            Save
                                            {isSavingPreQualification && (
                                                <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-3/5 pr-0 md:pr-8 pb-4">
                                <SearchResults
                                    onUserNotLoggedIn={() => handleOnApplyShowConfirmModal()}
                                    preQualificationId={(preQualification) ? preQualification.id : undefined}
                                    onResult={(count) => setResultsCount(count)}
                                />
                            </div>

                            <div className="w-full flex mt-4 pb-2 md:hidden">
                                <button
                                    className={`
                                        w-[calc((100%-8px)/2)] rounded-xl py-4 flex justify-center mr-2
                                        font-bold text-[15px]
                                        border border-solid
                                        ${
                                            (!isSavingPreQualification)
                                            ? `
                                                border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                                hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                                            `
                                            : 'bg-gray-100 text-white'
                                        }
                                    `}
                                    onClick={() => setIsPreApprovedResultFormValuesModal(true)}
                                >
                                    View Details
                                </button>
                                <button
                                    className={`
                                        w-[calc((100%-8px)/2)] rounded-xl  py-4 flex justify-center
                                        font-bold text-[15px]
                                        border border-solid
                                        ${
                                            (
                                                !isSavingPreQualification
                                                && !isSaving
                                                && (resultsCount > 0)
                                                && (!preQualification || (preQualification && preQualification.status !== 'applied'))
                                            )
                                            ? `
                                                border-uwi-primary-100 hover:border-uwi-primary-200
                                                bg-uwi-primary-100 hover:bg-uwi-primary-200 
                                                text-white
                                            `
                                            : 'bg-gray-100 text-white'
                                        }
                                    `}
                                    onClick={() => handleSaveForm()}
                                    disabled={isSavingPreQualification || isSaving || (resultsCount === 0) || (!!preQualification && preQualification.status === 'applied')}
                                >
                                    Save Results
                                    {isSavingPreQualification && (
                                        <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal
                visible={isConfirmModalShown}
                onClose={() => setIsConfirmModalShown(false)}
                onConfirm={handleOnConfirm}
                message={confirmModalInfo.message}
                title={confirmModalInfo.title}
                btn1Text={confirmModalInfo.btn1Text}
                btn2Text={confirmModalInfo.btn2Text}
            />

            <PreQualificationResultFormValuesModal
                visible={isPreApprovedResultFormValuesModal}
                onClose={() => setIsPreApprovedResultFormValuesModal(false)}
                onEdit={() => handleEditForm()}
            />
        </>
    )
};

export default PreQualificationResult;
