type Props = {
  className?: string;
};

const CardIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        stroke="#5D7077"
        d="M27 7.5H5A1.5 1.5 0 003.5 9v14A1.5 1.5 0 005 24.5h22a1.5 1.5 0 001.5-1.5V9A1.5 1.5 0 0027 7.5z"
      ></path>
      <path fill="#5D7077" d="M29 12H3v4h26v-4z"></path>
      <path stroke="#5D7077" strokeLinecap="round" d="M6 20h8"></path>
      <path fill="#FFF" d="M26 17h-5v5h5v-5z"></path>
      <path stroke="#5D7077" d="M25.5 17.5h-4v4h4v-4z"></path>
    </svg>
  );
}

export default CardIcon;