import { useEffect, useMemo, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate } from 'react-router-dom';
import { CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { Booking, BookingApi, BookingCalendarTimeSlot } from 'src/api/BookingApi';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import { ErrorList, Select, SelectOption } from 'src/components/common';
import useToast from 'src/hooks/useToast';
import { BookingCalendar } from './booking_calendar';
import { useAppSelector } from 'src/hooks/generic';
import { selectAgents, selectIsAgentSelectionEnabled } from 'src/store/features/commonSlice';
import { formatBookingTimeTo12HourFormat } from 'src/helpers/booking';

type Props = {
    subProject: AddedSubProject|null;
    subProjectId: string;
    preQualificationId: string;
    selectedSubProjectIndex: number;
    totalSubProjects: number;
    onNextProperty: () => void;
};

const TabContent = ({
    subProject,
    subProjectId,
    preQualificationId,
    selectedSubProjectIndex,
    totalSubProjects,
    onNextProperty
}: Props) => {
    const isAgentSelectionEnabled = useAppSelector(selectIsAgentSelectionEnabled);
    const agents = useAppSelector(selectAgents);

    const {
        commonErrorHandler,
        setCommonErrors,
        errors
    } = useGenericErrorHandler();
    const toast = useToast();
    const navigate = useNavigate();

    const [hasUpdated, setHasUpdated] = useState(false);
    const [timeSlotList, setTimeSlotList] = useState<BookingCalendarTimeSlot[]>([]);
    const [isLoadingTimeSlotList, setIsLoadingTimeSlotList] = useState(false);
    const [isTimeSlotFirstDataLoaded, setIsTimeSlotFirstDataLoaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date|null>(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<BookingCalendarTimeSlot|null>(null);
    const [bookingInfo, setBookingInfo] = useState<Booking | null>(null);
    const [isSubProjectBookingInfoLoaded, setIsSubProjectBookingInfoLoaded] = useState(false);
    const [agentId, setAgentId] = useState('');
    const [hasSelectedBookingExpired, setHasSelectedBookingExpired] = useState(false);

    const agentOpts = useMemo(() => {
        const agentsCopy = [
            ...[{
                id: 'system',
                name: 'Auto Select'
            }],
            ...agents
        ];

        return agentsCopy;
    }, [agents]);

    useEffect(() => {
        if (subProjectId !== '') {
            setIsSubProjectBookingInfoLoaded(false);

            if (!isAgentSelectionEnabled && agentOpts.length !== 0) {
                setAgentId(agentOpts[0].id);
            }

            Container
                .get(BookingApi)
                .subProjectBookingInfo(subProjectId, preQualificationId)
                .then(booking => {
                    setIsSubProjectBookingInfoLoaded(true);
                    setBookingInfo(booking);

                    if (booking) {
                        setSelectedDate(booking.bookingDate);
                        setAgentId(booking.developerAccountId);

                        setIsLoadingTimeSlotList(true);
                        Container
                            .get(BookingApi)
                            .subProjectTimeSlotsInfoForDate(subProjectId, {
                                date: booking.bookingDate,
                                agentId: booking.developerAccountId
                            })
                            .then(timeSlotList => {    
                                if (booking) {
                                    const timeSlot = timeSlotList.find(ts => (ts.startTime === booking.startTime));
                                    if (timeSlot) {
                                        setSelectedTimeSlot(timeSlot);
                                    }
                                }
                                else {
                                    setSelectedTimeSlot(null);
                                }
        
                                setTimeSlotList(timeSlotList);
                                setIsTimeSlotFirstDataLoaded(true);
                            })
                            .catch(commonErrorHandler)
                            .finally(() => {
                                setIsLoadingTimeSlotList(false);
                            });
                    }
                    else {
                        setSelectedDate(null);
                        setSelectedTimeSlot(null);
                    }
                })
                .catch(commonErrorHandler);
        }
    }, [subProjectId, preQualificationId, isAgentSelectionEnabled, agentOpts, commonErrorHandler]);

    useEffect(()=>{
        if(selectedDate && selectedTimeSlot) {
            const currentDate = new Date();  
            const bookingEndDateTime = selectedDate;
            const timeSplit = selectedTimeSlot.endTime.split(':')

            bookingEndDateTime.setHours(Number(timeSplit[0]));
            bookingEndDateTime.setMinutes(Number(timeSplit[1]));
            bookingEndDateTime.setSeconds(Number(timeSplit[2]));

            if (currentDate > bookingEndDateTime) {
                setHasSelectedBookingExpired(true);
            }
            else {
                setHasSelectedBookingExpired(false)
            }
        }        
    },[selectedDate, selectedTimeSlot])

    const handleClickDate = (date: Date) => {
        if (
            (
                selectedDate === null
                || formatDateToYYYYMMDD(date) !== formatDateToYYYYMMDD(selectedDate)
            )
            && (
                bookingInfo === null
                || (bookingInfo.status === 'active')
            )
        ) {
            setSelectedDate(date);
            setHasUpdated(true);
            setCommonErrors([]);
    
            if (subProject) {
                setIsLoadingTimeSlotList(true);
                Container
                    .get(BookingApi)
                    .subProjectTimeSlotsInfoForDate(subProject.id, {
                        date,
                        agentId: (agentId.trim().length > 0 && agentId !== 'system') ? agentId : undefined
                    })
                    .then(timeSlotList => {    
                        if (
                            bookingInfo
                            && formatDateToYYYYMMDD(bookingInfo.bookingDate) === formatDateToYYYYMMDD(date)
                            && isTimeSlotFirstDataLoaded
                        ) {
                            const timeSlot = timeSlotList.find(ts => (ts.startTime === bookingInfo.startTime));
                            if (timeSlot) {
                                setSelectedTimeSlot(timeSlot);
                            }
                        }
                        else {
                            setSelectedTimeSlot(null);
                        }

                        setTimeSlotList(timeSlotList);
                        setIsTimeSlotFirstDataLoaded(true);
                    })
                    .catch(commonErrorHandler)
                    .finally(() => {
                        setIsLoadingTimeSlotList(false);
                    });
            }
        }
    };

    const handleChangeSelectedTimeSlot = (val: string) => {
        const timeSlot = timeSlotList.find(ts => (ts.id === val));
        if (timeSlot) {
            setSelectedTimeSlot(timeSlot);
            setHasUpdated(true);
        }
    };

    const handleSubmit = () => {
        const errors: string[] = [];

        if (selectedDate === null) {
            errors.push('Please select a date first.');
        }

        if (selectedTimeSlot === null) {
            errors.push('Please select a time slot first.');
        }

        if (isAgentSelectionEnabled && agentId === '') {
            errors.push('Please select an agent first.');
        }

        setCommonErrors(errors);

        if (subProject && selectedTimeSlot && selectedDate && errors.length === 0) {
            const tmpAgentId = (isAgentSelectionEnabled && agentId !== '') ? agentId : 'system'; 

            setIsSubmitting(true);
            Container
                .get(BookingApi)
                .subProjectBookDateAndTimeSlot(subProject.id, {
                    preQualificationId,
                    date: selectedDate,
                    bookingScheduleId: selectedTimeSlot.id,
                    agentId: tmpAgentId
                })
                .then(booking => {
                    setBookingInfo(booking);
                    setHasUpdated(false);
                    toast('Great, your booking for this date has been successfully added.', 'success')
                })
                .catch(commonErrorHandler)
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const handleChangeAgent = (agentId: string) => {
        setAgentId(agentId);
        setSelectedDate(null);
        setSelectedTimeSlot(null);
        setTimeSlotList([]);
        setIsTimeSlotFirstDataLoaded(false);
    };

    if (!subProject) {
        return <></>;
    }

    return (
        <>
            <h1 className="text-sm mb-5 hidden lg:block">{subProject.name}</h1>
            <div className="md:flex md:px-4 lg:px-0">
                <div className="w-full md:w-1/2 md:mr-10">
                    {(isAgentSelectionEnabled && !hasSelectedBookingExpired) && (
                        <Select
                            value={agentId}
                            onChange={(agentId) => handleChangeAgent(agentId as string)}
                            className="mb-4"
                            placeholder="Select An Agent For This Booking"
                        >
                            {agentOpts.map(agent => (
                                <SelectOption
                                    key={agent.id}
                                    value={agent.id}
                                >
                                    {agent.name}
                                </SelectOption>
                            ))}
                        </Select>
                    )}

                    <BookingCalendar
                        onClickDate={handleClickDate}
                        selectedDate={selectedDate}
                        isSubProjectBookingInfoLoaded={isSubProjectBookingInfoLoaded}
                        bookingDate={(bookingInfo) ? formatDateToYYYYMMDD(bookingInfo.bookingDate) : null}
                        bookingStatus={(bookingInfo) ? bookingInfo.status : 'active'}
                        subProjectId={subProject.id}
                        agentId={(agentId.trim().length > 0 && agentId !== 'system') ? agentId : undefined}
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <div className="">
                        <ErrorList
                            errors={errors}
                            visible={true}
                        />

                        {(!isTimeSlotFirstDataLoaded && !isLoadingTimeSlotList) && (
                            <div className="w-full py-4 px-4 text-center bg-yellow-100 text-yellow-800 leading-5">
                                Kindly choose a date on the calendar to display the available time slots.
                            </div>
                        )}

                        {(isTimeSlotFirstDataLoaded && !isLoadingTimeSlotList && timeSlotList.length === 0) && (
                            <div className="w-full py-4 text-center px-4 bg-red-300 text-red-800 leading-5">
                                Sorry, there are no time slots available for this day.
                            </div>
                        )}
                    </div>

                    {(isLoadingTimeSlotList) && (
                        <div className="w-full flex justify-center items-center h-[100px]">
                            <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                        </div>
                    )}

                    {(!isLoadingTimeSlotList && timeSlotList.length > 0 && agentId !== '' && !hasSelectedBookingExpired) && (
                        <>
                            {timeSlotList.map((timeSlot, idx) => (
                                <div key={idx}>
                                    {
                                        (timeSlot.isAvailable)
                                        ?  <div className="border border-solid border-gray-50 py-1.5 pl-6 flex items-center mb-1">
                                                <input
                                                    type="radio"
                                                    className="w-[18px] h-[18px] mr-3 uwi-input"
                                                    value={timeSlot.id}
                                                    checked={selectedTimeSlot?.id === timeSlot.id}
                                                    onChange={(e) => handleChangeSelectedTimeSlot(e.target.value)}
                                                />
                                                <div className="mr-8 text-black text-sm">{formatBookingTimeTo12HourFormat(timeSlot.startTime)} - {formatBookingTimeTo12HourFormat(timeSlot.endTime)}</div>
                                                <div className="text-green-600 text-sm">Available</div>
                                            </div>
                                        :   <div className="border border-solid border-gray-50 text-gray-50 py-1.5 pl-6 flex items-center mb-1">
                                                <div className="w-[18px] h-[18px] bg-gray-50 rounded-full mr-3"></div>
                                                <div className="mr-8 text-sm">{formatBookingTimeTo12HourFormat(timeSlot.startTime)} - {formatBookingTimeTo12HourFormat(timeSlot.endTime)}</div>
                                                <div className="text-sm">Fully Booked</div>
                                            </div>
                                    }
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>

            <div className="flex justify-center mt-8 md:mt-4 lg:mt-8">
                {(!bookingInfo) && (
                    <button
                        className={`
                            flex justify-center
                            rounded-2xl
                            text-[15px] font-bold
                            px-4 md:px-8 py-4
                            ${(!isSubmitting) ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white'}
                        `}
                        onClick={() => handleSubmit()}
                    >
                        Book a Tour
                        {isSubmitting && (
                            <CircleLoaderIcon className="h-4 w-4 animate-spin ml-3 text-white" />
                        )}
                    </button>
                )}
                
                {(bookingInfo && bookingInfo.status === 'active') && (
                    <>
                        <button
                            className={`
                                flex justify-center
                                rounded-2xl
                                text-[15px] font-bold
                                px-4 md:px-8 py-4
                                ${(!isSubmitting && hasUpdated) ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white'}
                            `}
                            onClick={() => handleSubmit()}
                            disabled={!hasUpdated}
                        >
                            Update Booking
                            {isSubmitting && (
                                <CircleLoaderIcon className="h-4 w-4 animate-spin ml-3 text-white" />
                            )}
                        </button>
                        
                        {
                            (selectedSubProjectIndex === totalSubProjects - 1)
                              ? <button
                                    className={`
                                        flex justify-center items-center
                                        rounded-2xl
                                        text-[15px] font-bold
                                        px-4 md:px-8 py-4 ml-4
                                        border border-uwi-primary-100 bg-white text-uwi-primary-100 hover:bg-uwi-primary-100 hover:text-white
                                    `}
                                    onClick={() => navigate('/dashboard-bookings')}
                                >
                                    Go To Bookings
                                </button>
                              : <button
                                    className={`
                                        flex justify-center items-center
                                        rounded-2xl
                                        text-[15px] font-bold
                                        px-4 md:px-8 py-4 ml-4
                                        border border-uwi-primary-100 bg-white text-uwi-primary-100 hover:bg-uwi-primary-100 hover:text-white
                                    `}
                                    onClick={() => onNextProperty()}
                                >
                                    Next
                                </button>
                        }
                    </>
                )}

                {(bookingInfo && ['cancelled_by_agent', 'cancelled_by_user'].includes(bookingInfo.status)) && (
                    <div className="rounded-lg text-center border border-red-800 bg-red-50 text-red-800 py-4 w-full">
                        {(bookingInfo.status === 'cancelled_by_agent') ? 'Your booking has been cancelled by our agent.' : 'You cancelled this booking'}
                    </div>
                )}
            </div>
        </>
    );
};

export default TabContent;
