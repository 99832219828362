import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'typedi';
import { CircleLoaderIcon } from 'src/components/icons';
import useAuth from 'src/hooks/useAuth';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import { PaymentReservation, PaymentReservationApi } from 'src/api/PaymentReservationsApi';
import paymentMethods from 'src/data/paymentMethods.json';

const DashboardPaymentReservationsPage = () => {
    const {
        errorHandlerWithToast
    } = useGenericErrorHandler();
    
    const navigate = useNavigate();
    const { user, hasLoadedFromStorage } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [paymentReservations, setPaymentReservations] = useState<PaymentReservation[]>([]);

    useEffect(() => {
        setIsLoading(true);

        Container
            .get(PaymentReservationApi)
            .list()
            .then(resp => setPaymentReservations(resp))
            .catch(errorHandlerWithToast)
            .finally(() => {
                setIsLoading(false);
            });
    }, [errorHandlerWithToast]);

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent('/dashboard-payment-reservations')}`);
        }
    }, [user, navigate, hasLoadedFromStorage]);

    const getPaymentMethodLabel = (key: string) => {
        for (let i = 0; paymentMethods.length > i; i++) {

            for (let j = 0; paymentMethods[i].options.length > j; j++) {
                if (paymentMethods[i].options[j].channelCode === key) {
                    return paymentMethods[i].options[j].title;
                }
            }
        }
    };

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl min-h-[456px] shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                        <div className="flex mb-4 pb-4 border-b border-solid border-gray-50">
                            <div className="w-full flex items-baseline flex-wrap md:flex-nowrap">
                                <div className="text-xl text-uwi-primary-100 mr-1 font-bold">Hi {(user) ? user.firstName : ''}!</div>
                                <div className="text-sm text-black mt-1 md:mt-0">Here are your payment reservations below.</div>
                            </div>
                        </div>

                        <table className="w-full !m-0 !border-none">
                            <thead className="border-b border-solid border-gray-50">
                                <tr>
                                    <th className="font-normal text-left text-sm border-0 border-none p-0 pb-4 pl-4 whitespace-nowrap">Date</th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Reference No.</th>
                                    <th className="hidden md:!table-cell md:!visible font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Property</th>
                                    <th className="hidden lg:!table-cell md:!visible font-normal text-center text-sm border-none p-0 pb-4 whitespace-nowrap">Payment Method</th>
                                    <th className="font-normal text-center text-sm border-none p-0 pb-4 whitespace-nowrap">Amount</th>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {(isLoading) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none !p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white inline-block" />
                                            &nbsp;Fetching records...
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading && paymentReservations.length === 0) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            No records found
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading) && (
                                    paymentReservations.map(pm => (
                                        <tr
                                            key={pm.id}
                                            className={`w-full h-full items-center even:bg-gray-75`}
                                        >
                                            <td className="text-left text-sm border-0 border-none align-middle p-0 py-4 pl-4 whitespace-nowrap">
                                                {formatDateToYYYYMMDD(pm.paymentDate)}
                                            </td>
                                            <td className="text-left text-uwi-primary-100 text-sm align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                {pm.referenceNumber}
                                            </td>
                                            <td className="hidden md:!table-cell md:!visible text-left text-sm items-center align-middle border-0 border-none p-0 py-4 whitespace-nowrap max-w-[140px] overflow-hidden overflow-ellipsis md:max-w-[200px]">
                                                {pm.projectTitle} - {pm.subProjectName}
                                            </td>
                                            <td className="hidden lg:!table-cell md:!visible text-sm align-middle text-center border-0 border-none p-0 py-4 whitespace-nowrap uppercase">
                                                {getPaymentMethodLabel(pm.paymentMethod)}
                                            </td>
                                            <td className="text-center text-sm items-center align-middle border-0 border-none p-0 py-4 whitespace-nowrap max-w-[140px] overflow-hidden overflow-ellipsis md:max-w-[200px]">
                                                {parseFloat(pm.amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardPaymentReservationsPage;