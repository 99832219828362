import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PreApproveLoanDebtIncomeInfoState {
    hasCreditCard: boolean;
    hasCancelledCreditCard: boolean;
    hasFullPaymentCertificate: boolean;
    extraIncomeAmount: number;
    existingDebtAmount: number;
    coBorrowerMonthlyIncome: number;
    saveToStorage: boolean;
    formErrors: Record<string, string>;
}

const savePreApproveLoanDebtIncomeInfoState = (info: PreApproveLoanDebtIncomeInfoState) => {
    if (window.sessionStorage) {
        const { formErrors, saveToStorage, ...others } = info;
        window.sessionStorage.setItem('preApproveLoanDebtIncomeInfoState', JSON.stringify(others));
    }
};

const loadPreApproveLoanDebtIncomeInfoState = (): PreApproveLoanDebtIncomeInfoState => {
    try {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            const serializedState = window.sessionStorage.getItem('preApproveLoanDebtIncomeInfoState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
                parsedState.formErrors = {};
                parsedState.saveToStorage = true;

                return parsedState as PreApproveLoanDebtIncomeInfoState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        hasCreditCard: false,
        hasCancelledCreditCard: false,
        hasFullPaymentCertificate: false,
        extraIncomeAmount: 0,
        existingDebtAmount: 0,
        coBorrowerMonthlyIncome: 0,
        saveToStorage: true,
        formErrors: {}
    };
}

const initialState: PreApproveLoanDebtIncomeInfoState = {
    hasCreditCard: false,
    hasCancelledCreditCard: false,
    hasFullPaymentCertificate: false,
    extraIncomeAmount: 0,
    existingDebtAmount: 0,
    coBorrowerMonthlyIncome: 0,
    saveToStorage: true,
    formErrors: {}
};

export const preApproveLoanDebtIncomeInfoSlice = createSlice({
    name: 'preApproveLoanDebtIncomeInfo',

    initialState,

    reducers: {
        setHasCreditCard: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<boolean>) => {
            state.hasCreditCard = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setHasCancelledCreditCard: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<boolean>) => {
            state.hasCancelledCreditCard = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setHasFullPaymentCertificate: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<boolean>) => {
            state.hasFullPaymentCertificate = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setExtraIncomeAmount: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<number>) => {
            state.extraIncomeAmount = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setExistingDebtAmount: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<number>) => {
            state.existingDebtAmount = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setCoBorrowerMonthlyIncome: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<number>) => {
            state.coBorrowerMonthlyIncome = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanDebtIncomeInfoState(state);
            }
        },

        setSaveToStorage: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<boolean>) => {
            state.saveToStorage = action.payload;
        },

        loadFromStorage: () => {
            return loadPreApproveLoanDebtIncomeInfoState();
        },

        setDefaultFormValues: (state: PreApproveLoanDebtIncomeInfoState) => {
            state.hasCreditCard = false;
            state.hasCancelledCreditCard = false;
            state.hasFullPaymentCertificate = false;
            state.extraIncomeAmount = 0;
            state.existingDebtAmount = 0;
            state.coBorrowerMonthlyIncome = 0;

            savePreApproveLoanDebtIncomeInfoState(state);
        },

        setState: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<Partial<PreApproveLoanDebtIncomeInfoState>>) => {
            state.hasCreditCard = action.payload.hasCreditCard || state.hasCreditCard;
            state.hasCancelledCreditCard = action.payload.hasCancelledCreditCard || state.hasCancelledCreditCard;
            state.hasFullPaymentCertificate = action.payload.hasFullPaymentCertificate || state.hasFullPaymentCertificate;
            state.extraIncomeAmount = action.payload.extraIncomeAmount || state.extraIncomeAmount;
            state.existingDebtAmount = action.payload.existingDebtAmount || state.existingDebtAmount;
            state.coBorrowerMonthlyIncome = action.payload.coBorrowerMonthlyIncome || state.coBorrowerMonthlyIncome;
            state.saveToStorage = action.payload.saveToStorage || state.saveToStorage;
        },

        setFormErrors: (state: PreApproveLoanDebtIncomeInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        }
    }
});

export const {
    setHasCreditCard,
    setHasCancelledCreditCard,
    setHasFullPaymentCertificate,
    setExtraIncomeAmount,
    setExistingDebtAmount,
    setCoBorrowerMonthlyIncome,
    setDefaultFormValues,
    setSaveToStorage,
    loadFromStorage,
    setState,
    setFormErrors
} = preApproveLoanDebtIncomeInfoSlice.actions;

export const selectHasCreditCard = (state: RootState) => state.preApproveLoanDebtIncomeInfo.hasCreditCard;
export const selectHasCancelledCreditCard = (state: RootState) => state.preApproveLoanDebtIncomeInfo.hasCancelledCreditCard;
export const selectHasFullPaymentCertificate = (state: RootState) => state.preApproveLoanDebtIncomeInfo.hasFullPaymentCertificate;
export const selectExtraIncomeAmount = (state: RootState) => state.preApproveLoanDebtIncomeInfo.extraIncomeAmount;
export const selectExistingDebtAmount = (state: RootState) => state.preApproveLoanDebtIncomeInfo.existingDebtAmount;
export const selectCoBorrowerMonthlyIncome = (state: RootState) => state.preApproveLoanDebtIncomeInfo.coBorrowerMonthlyIncome;
export const selectFormErrors = (state: RootState) => state.preApproveLoanDebtIncomeInfo.formErrors;

export default preApproveLoanDebtIncomeInfoSlice.reducer;
