import {
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    ArrowRightLongSolidIcon,
    ArrowRotateRightSolid,
    CircleLoaderIcon,
    XMarkSolidIcon
} from '../../icons';
import useCaptcha from './useCaptcha';

type Props = {
    onChange?: (val: string) => void;
    hasVerified?: boolean;
};

const CaptchaField = ({
    onChange,
    hasVerified
}: Props) => {
    const {
        captchaKey,
        isLoading,
        isVerifying,
        isVerified,
        isErrored,
        bgImgSrc,
        croppedImgSrc,
        posY,
        bgImageWidth,
        trackerImageWidth,
        loadNewCaptchaImage,
        submitValidate
    } = useCaptcha();

    const [posX, setPosX] = useState(0);
    const [startPageX, setStartPageX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const captchaContent = useRef<HTMLDivElement>(null);

    const handleChange = useMemo(() => onChange || (() => {}), [onChange]);

    useEffect(() => {
        if (
            (typeof hasVerified === 'boolean' && !hasVerified)
            || typeof hasVerified === 'undefined'
        ) {
            loadNewCaptchaImage()
                .then(() => {
                    setPosX(0);
                    setStartPageX(0);
                    setIsDragging(false);
                });
        }
    }, [loadNewCaptchaImage, hasVerified]);

    useEffect(() => {
        const windowMouseMove = (e: MouseEvent) => {
            if (!isDragging) {
                return;
            }

            let newXPos = e.pageX - startPageX;
            newXPos = Math.max(newXPos, 0);
            newXPos = Math.min(newXPos, bgImageWidth - trackerImageWidth - 2);
    
            setPosX(newXPos);
        };

        const windowMouseUp = async () => {
            if (!isDragging) {
                return;
            }

            setIsDragging(false);

            const submitResp = await submitValidate(posX);
            if (submitResp.isValid) {
                handleChange(captchaKey);
            }
            else {
                setTimeout(async () => {
                    await loadNewCaptchaImage();

                    setPosX(0);
                }, 1000);
            }
        };

        window.addEventListener('mouseup', windowMouseUp);
        window.addEventListener('mousemove', windowMouseMove);

        return () => {
            window.removeEventListener('mouseup', windowMouseUp);
            window.removeEventListener('mousemove', windowMouseMove);
        };
    }, [
        isDragging,
        startPageX,
        posX,
        bgImageWidth,
        trackerImageWidth,
        captchaKey,
        handleChange,
        loadNewCaptchaImage,
        submitValidate
    ]);

    useEffect(() => {
        const windowTouchMove = (e: TouchEvent) => {
            if (!isDragging) {
                return;
            }

            const touchLocation = e.targetTouches[0];
        
            let newXPos = touchLocation.pageX - startPageX;
            newXPos = Math.max(newXPos, 0);
            newXPos = Math.min(newXPos, bgImageWidth - trackerImageWidth);
    
            setPosX(newXPos);
        };

        const windowTouchEnd = async () => {
            if (!isDragging) {
                return;
            }

            setIsDragging(false);

            const submitResp = await submitValidate(posX);
            if (submitResp.isValid) {
                handleChange(captchaKey);
            }
            else {
                setTimeout(async () => {
                    await loadNewCaptchaImage();

                    setPosX(0);
                }, 1000);
            }
        };

        window.addEventListener('touchend', windowTouchEnd);
        window.addEventListener('touchmove', windowTouchMove);

        return () => {
            window.removeEventListener('touchend', windowTouchEnd);
            window.removeEventListener('touchmove', windowTouchMove);
        };
    }, [
        isDragging,
        startPageX,
        posX,
        bgImageWidth,
        trackerImageWidth,
        captchaKey,
        handleChange,
        loadNewCaptchaImage,
        submitValidate
    ]);

    const handleTouchStart = (pageX: number) => {
        if (isVerifying || isVerified || isLoading) {
            return;
        }

        setStartPageX(pageX);
        setIsDragging(true);
    };

    const onSliderMouseDown = (pageX: number) => {
        if (isVerifying || isVerified || isLoading) {
            return;
        }

        setStartPageX(pageX);
        setIsDragging(true);
    };

    const onImgAreaMouseDown = (offsetY: number, pageX: number) => {
        if (isVerifying || isVerified || isLoading) {
            return;
        }

        if (
            offsetY >= posY
            && offsetY <= (posY + 50)
        ) {
            setStartPageX(pageX);
            setIsDragging(true);
        }
    };

    const onImgAreaTouchStart = (clientY: number, pageX: number) => {
        if (isVerifying || isVerified || isLoading) {
            return;
        }

        if (captchaContent.current) {
            const contentTop = captchaContent.current.getBoundingClientRect().top;
            if (
                clientY >= (posY + contentTop)
                && clientY <= (posY + contentTop + 50)
            ) {
                setStartPageX(pageX);
                setIsDragging(true);
            }
        }
    };

    return (
        <div
            className="flex flex-col w-full rounded-xl p-4 bg-white/40 border border-solid border-gray-200"
        >
            <div
                ref={captchaContent}
                className="w-[300px] h-[200px] relative bg-gray-50"
                onMouseDown={(e) => onImgAreaMouseDown(e.nativeEvent.offsetY, e.pageX)}
                onTouchStart={(e) => onImgAreaTouchStart(e.targetTouches[0].clientY, e.targetTouches[0].pageX)}
            >
                {(!isVerified) && (
                    <button
                        className="absolute z-[3] top-2 right-2 rounded-full px-1.5 py-1.5 bg-white border border-gray-50 text-black hover:text-black"
                        type="button"
                        onClick={() => loadNewCaptchaImage()}
                    >
                        <ArrowRotateRightSolid
                            className={`
                                w-4 h-4
                                ${(isLoading) ? 'animate-spin' : ''}
                            `}
                        />
                    </button>  
                )}

                {(bgImgSrc.length > 0) && (
                    <img
                        className="absolute top-0 left-0 z-[1] w-[300px] h-[200px] pointer-events-none"
                        src={bgImgSrc}
                        alt="Captcha Tracker"
                    />
                )}

                {(croppedImgSrc.length > 0) && (
                    <img
                        className="absolute top-0 left-0 z-[2] w-[50px] h-[50px] border border-white pointer-events-none"
                        src={croppedImgSrc}
                        style={{
                            left: `${posX}px`,
                            top: `${posY}px` 
                        }}
                        alt="Captcha Background"
                    />
                )}
            </div>

            {(!isVerified) && (
                <div
                    className="relative text-center h-[40px] w-[300px] bg-[#eeeeee] border-y border-r border-solid border-gray-200"
                >
                    <div className="absolute left-0 top-0 bg-white border-x border-gray-200 h-[40px]"></div>
                    <div
                        className={`
                            absolute top-0 left-0 border-solid w-10 h-[38px] border-gray-200 rounded-sm
                            ${(isDragging || isErrored) ? 'border' : ''}
                            ${(isErrored) ? 'bg-red-100 border-red-200 text-white-100' : 'bg-gray-50'}
                        `}
                        style={{ 
                            width: `${posX + 5}px`
                        }}
                    >
                        <div
                            className={`
                                absolute top-0 left-0 bg-white border-x border-gray-200 flex items-center justify-center cursor-pointer rounded-sm h-[38px] w-12
                                ${(isDragging) ? 'bg-gray-400 text-white' : ''}
                                ${(isErrored) ? 'bg-red-200' : ''}
                                ${(isDragging || isErrored) ? '-top-px' : ''}
                            `}
                            style={{
                                left: `${posX}px`
                            }}
                            onMouseDown={(e) => onSliderMouseDown(e.pageX)}
                            onTouchStart={(e) => handleTouchStart(e.targetTouches[0].pageX)}
                        >
                            {(isErrored && !isLoading) && (
                                <XMarkSolidIcon className="w-4 h-4 text-white" />
                            )}

                            {(isVerifying || isLoading) && (
                                <CircleLoaderIcon
                                    className="animate-spin w-4 h-4 text-green-100"
                                    bgColor="#fff"
                                />
                            )}

                            {!(isVerifying || isLoading) && (!isErrored) && (
                                <ArrowRightLongSolidIcon
                                    className={`
                                        w-4 h-4 
                                        ${(isDragging) ? 'text-white' : 'text-gray-50'}
                                    `}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {(isVerified) && (
                <div
                    className={`
                        bg-green-100 text-white
                        uppercase h-[40px] w-[300px] text-[20px]
                        flex items-center justify-center
                    `}
                >
                    VERIFIED
                </div>
            )}
        </div>
    );
}

export default CaptchaField;