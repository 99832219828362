import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PreQualification, PreQualificationApi } from 'src/api/PreQualificationApi';
import useAuth from 'src/hooks/useAuth';
import { AngleLeftSolidIcon, CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import TabContent from 'src/page_components/view-booking/TabContent';
import { AddedSubProject } from 'src/api/SubProjectApi';

const ViewBookingPage = () => {
    const navigate = useNavigate();
    const { user, userId, hasLoadedFromStorage} = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [preQualification, setPreQualification] = useState<PreQualification>();
    const [subProject, setSubProject] = useState<AddedSubProject|null>(null);

    const preQualificationId = searchParams.get('preQualificationId');
    const subProjectId = searchParams.get('subProjectId');
    
    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent(`/view-booking?preQualificationId=${preQualificationId}&subProjectId=${subProjectId}`)}`);
        }
    }, [user, preQualificationId, subProjectId, hasLoadedFromStorage, navigate]);

    useEffect(() => {        
        if (preQualificationId && userId !== null) {
            setIsLoading(true);

            Container
                .get(PreQualificationApi)
                .getInfo(preQualificationId)
                .then(preQual => {
                    setPreQualification(preQual);

                    if (preQual.subProjects && preQual.subProjects.length > 0) {
                        const subProj = preQual.subProjects.find(subProj => (subProj.id === subProjectId));
                        setSubProject(subProj || null);
                    }
                })
                .catch(errorHandlerWithToast)
                .finally(() => {
                    setIsLoading(false)
                });
        }
        else {
            setIsLoading(false);
        }
    }, [preQualificationId, userId, subProjectId, errorHandlerWithToast]);

    if (!subProject) {
        return (
            <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                        <div className="mb-3 px-4">
                            <div className="w-full flex justify-center">
                                <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
            <div className="pt-10">
                <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                    {isLoading && (
                        <div className="mb-3 px-4">
                            <div className="w-full flex justify-center">
                                <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                            </div>
                        </div>
                    )}


                    {!isLoading && !preQualification && (
                        <div>Page not found</div>
                    )}

                    {!isLoading && preQualification && preQualification.subProjects && preQualification.subProjects.length > 0 && (
                        <>
                            <div className="flex justify-end">
                                <button
                                    className="text-sm flex justify-center items-center"
                                    onClick={() => navigate(`/dashboard-bookings`)}
                                >
                                    <AngleLeftSolidIcon
                                        className="w-5 h-5 mr-2"
                                    />
                                    Back To Bookings
                                </button>
                            </div>

                            <TabContent
                                subProject={subProject}
                                subProjectId={subProject.id}
                                preQualificationId={preQualification.id}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ViewBookingPage;
