type Props = {
  className?: string;
};

const VisaSmIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="18"
      version="1"
      viewBox="0 0 600 292"
      className={className}
    >
      <g fill="#1434CB">
        <path
          d="M3575 2209c-193-28-361-127-438-259-38-66-60-162-54-237 11-143 108-253 318-363 68-36 140-81 161-101 33-31 38-42 38-81 0-57-24-88-88-116-106-46-267-37-427 23-49 19-91 33-92 31-6-6-66-306-62-310 14-14 191-56 280-67 346-40 638 77 741 297 26 56 32 82 36 163 3 81 1 105-18 154-41 108-110 167-364 308-91 51-136 97-136 139 0 36 41 86 86 105 84 35 236 27 373-20l69-23 31 144c17 79 31 147 31 152 0 12-130 50-215 62-82 11-186 11-270-1zM686 2174c-4-9-5-18-4-19 2-2 48-15 103-30 114-31 260-88 291-113 11-10 28-29 36-42 9-14 80-272 158-575 77-302 148-574 156-603l15-52 196 2 197 3 298 710c164 391 300 716 302 723 4 9-39 12-194 12-117 0-200-4-200-9s-65-173-144-373-163-414-187-475c-24-62-46-113-49-113s-41 188-84 418c-85 459-95 492-162 529-38 22-46 23-380 23-308 0-342-2-348-16zM2590 2133c-7-32-75-350-151-708-76-357-139-658-139-667 0-17 15-18 185-18 105 0 185 4 185 9s68 325 150 711c83 386 150 708 150 716 0 12-32 14-184 14h-185l-11-57zM4615 2171c-16-10-38-27-47-38-10-10-108-235-219-499-299-714-369-882-369-888 0-4 87-6 194-6h194l38 110 39 110h483l17-77c9-43 20-91 24-108l7-30 164-3c90-1 167 1 170 5 4 5-46 262-111 573s-133 634-150 718l-32 152h-186c-164 0-190-2-216-19zm194-633c28-139 51-260 51-268 0-13-22-15-147-12-82 2-149 5-151 7-6 5 182 525 189 525 3 0 29-114 58-252z"
          transform="matrix(.1 0 0 -.1 0 292)"
        ></path>
      </g>
    </svg>
  );
}

export default VisaSmIcon;