import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PreQualification, PreQualificationApi } from 'src/api/PreQualificationApi';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import useAuth from 'src/hooks/useAuth';
import { selectCities, selectProjects, setCity, setState as setPropertyInfoState } from 'src/store/features/preApproveLoanPropertyInfoSlice';
import { setState as setWorkInfoState } from 'src/store/features/preApproveLoanWorkInfoSlice';
import { setState as setOtherInfoState } from 'src/store/features/preApproveLoanOtherInfoSlice';
import { setState as setDebtIncomeState } from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import { setState as setLoanInfoState } from 'src/store/features/preApproveLoanLoanInfoSlice';
import { CircleLoaderIcon } from 'src/components/icons';
import PreQualificationResult from 'src/page_components/pre-qualification/PreQualificationResult';
import PreQualificationMainForm from 'src/page_components/pre-qualification/PreQualificationMainForm';
import { selectWidgetType } from 'src/store/features/commonSlice';
import { BasicWidgetPreQualificationApi } from 'src/api/BasicWidgetPreQualificationApi';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const PreApprovalViewPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { user, hasLoadedFromStorage} = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const [searchParams] = useSearchParams();

    const id = searchParams.get('id');

    const widgetType = useAppSelector(selectWidgetType);
    const projects = useAppSelector(selectProjects);
    const cities = useAppSelector(selectCities);

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent(`/pre-qualification-view?id=${id}`)}`);
        }
    }, [user, id, hasLoadedFromStorage, navigate]);

    const [isLoading, setIsLoading] = useState(true);
    const [preQualification, setPreQualification] = useState<PreQualification>();
    const [isResultShown, setIsResultShown] = useState(true);

    useEffect(() => {
        if (id && user) {
            setIsLoading(true);

            if (widgetType === 'advance') {
                Container
                    .get(PreQualificationApi)
                    .getInfo(id as string)
                    .then(request => {
                        const projectIds: string[] = [];
                        if (request.subProjects) {
                            request.subProjects.forEach(subProj => {
                                if (!projectIds.includes(subProj.projectId)) {
                                    projectIds.push(subProj.projectId);
                                }
                            });
                        }

                        dispatch(setPropertyInfoState({
                            selectedSubProjects: (request.subProjects) ? request.subProjects : [],
                            saveToStorage: false,
                            projectIds
                        }));
                        dispatch(setWorkInfoState({
                            workType: request.workType,
                            isBusinessProfitable: request.isBusinessProfitable,
                            workExperience: request.workExperience,
                            businessProprietorship: request.businessProprietorship,
                            monthlyIncome: request.monthlyIncome,
                            saveToStorage: false
                        }));
                        dispatch(setOtherInfoState({
                            nationality: request.nationality,
                            birthdate: {
                                month: request.birthdate.getMonth(),
                                day: request.birthdate.getDate(),
                                year: request.birthdate.getFullYear()
                            },
                            email: request.email,
                            mobileNumber: request.mobileNumber,
                            currentAddress: request.currentAddress,
                            currentRegion: request.currentRegion,
                            maritalStatus: request.maritalStatus,
                            saveToStorage: false
                        }));
                        dispatch(setDebtIncomeState({
                            hasCreditCard: request.hasCreditCard,
                            hasCancelledCreditCard: request.hasCancelledCreditCard,
                            hasFullPaymentCertificate: request.hasFullPaymentCertificate,
                            extraIncomeAmount: request.extraIncomeAmount,
                            existingDebtAmount: request.existingDebtAmount,
                            coBorrowerMonthlyIncome: request.coBorrowerMonthlyIncome,
                            saveToStorage: false
                        }));
                        dispatch(setLoanInfoState({
                            loanTerm: request.loanTerm,
                            percentOfProjectCost: request.percentOfProjectCost,
                            ownedBanks: request.ownedBanks,
                            percentOfDownpayment: (100 - request.percentOfProjectCost),
                            saveToStorage: false
                        }));

                        setPreQualification(request);

                        if (projectIds.length > 0) {
                            const proj = projects.find(proj => (proj.id === projectIds[0]));
                            if (proj) {
                                dispatch(setCity(proj.city));
                            }
                        }
                    })
                    .catch(errorHandlerWithToast)
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
            else {
                Container
                    .get(BasicWidgetPreQualificationApi)
                    .getInfo(id as string)
                    .then(request => {
                        dispatch(setPropertyInfoState({
                            selectedSubProjects: (request.subProjects) ? request.subProjects : [],
                            projectCost: request.projectCost,
                            projectType: request.projectType,
                            projectStatus: request.projectStatus,
                            developerName: request.developerName,
                            region: request.projectRegion,
                            saveToStorage: false
                        }));
                        dispatch(setWorkInfoState({
                            workType: request.workType,
                            workExperience: request.workExperience,
                            businessProprietorship: request.businessProprietorship,
                            monthlyIncome: request.monthlyIncome,
                            saveToStorage: false
                        }));
                        dispatch(setOtherInfoState({
                            nationality: request.nationality,
                            birthdate: {
                                month: request.birthdate.getMonth(),
                                day: request.birthdate.getDate(),
                                year: request.birthdate.getFullYear()
                            },
                            email: request.email,
                            mobileNumber: request.mobileNumber,
                            currentAddress: request.currentAddress,
                            currentRegion: request.currentRegion,
                            maritalStatus: request.maritalStatus,
                            saveToStorage: false
                        }));
                        dispatch(setDebtIncomeState({
                            hasCreditCard: request.hasCreditCard,
                            hasCancelledCreditCard: request.hasCancelledCreditCard,
                            hasFullPaymentCertificate: request.hasFullPaymentCertificate,
                            extraIncomeAmount: request.extraIncomeAmount,
                            existingDebtAmount: request.existingDebtAmount,
                            coBorrowerMonthlyIncome: request.coBorrowerMonthlyIncome,
                            saveToStorage: false
                        }));
                        dispatch(setLoanInfoState({
                            loanTerm: request.loanTerm,
                            percentOfProjectCost: request.percentOfProjectCost,
                            ownedBanks: request.ownedBanks,
                            saveToStorage: false
                        }));

                        setPreQualification(request);
                    })
                    .catch((e) => {
                        errorHandlerWithToast(e);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
        else {
            setIsLoading(false);
        }
    }, [id, user, widgetType, projects, cities, dispatch, errorHandlerWithToast]);

    return (
        <>
            {isLoading && (
                <div className="w-full flex justify-center">
                    <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                </div>
            )}

            {!isLoading && !preQualification && (
                <div>Page not found</div>
            )}

            {!isLoading && preQualification && (
                (isResultShown)
                ? <PreQualificationResult
                    preQualification={preQualification}
                    onEditForm={() => setIsResultShown(false)}
                />
                : <PreQualificationMainForm
                    routerPushOnChangeTab={false}
                    onFinishForm={() => setIsResultShown(true)}
                />
            )}
        </>
    );
}

export default PreApprovalViewPage;
