type Props = {
  className?: string;
};

const DirectDebitIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        stroke="#5D7077"
        d="M7.684 12.5h-3v13h3v-13zM15.684 12.5h-3v13h3v-13zM23.684 12.5h-3v13h3v-13zM3.184 25.5h22a1.5 1.5 0 011.5 1.5v1a.5.5 0 01-.5.5h-24a.5.5 0 01-.5-.5v-1a1.5 1.5 0 011.5-1.5v0zM26.684 9.5h-25v3h25v-3z"
      ></path>
      <path
        fill="#5D7077"
        d="M14.184 2.147L2.002 9h24.365L14.184 2.147zm0-1a1 1 0 01.49.129l12.183 6.853c.897.504.539 1.871-.49 1.871H2.002c-1.03 0-1.388-1.367-.49-1.871l12.182-6.853a1 1 0 01.49-.129z"
      ></path>
      <circle cx="24" cy="24" r="7.5" fill="#FFF" stroke="#5D7077"></circle>
      <path
        fill="#5D7077"
        stroke="#5D7077"
        strokeWidth="0.2"
        d="M27.912 23.8l-1.8-1.8-.424.424 1.288 1.288H19v.6h7.976L25.688 25.6l.424.424 1.8-1.8a.3.3 0 000-.424z"
      ></path>
    </svg>
  );
}

export default DirectDebitIcon;