import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BankAccount, PaymentApi } from 'src/api/PaymentApi';
import { CircleExclamationSolidIcon, CircleLoaderIcon, XMarkSolidIcon } from 'src/components/icons';
import Container from 'typedi';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { Select, SelectOption } from 'src/components/common';
import LockIcon from 'src/components/icons/LockIcon';
import PaymentError from 'src/page_components/pay-reservation/PaymentError';

type Props = {
    data: {
        id: string;
        accountDetails: string;
        accountType: string;
        channelCode: string;
    };
    image: string;
    disabled: boolean;
    onSelect: (option: string, bank: string) => void;
    selectedOption: string;
};

type OtpPopUpProps = {
    onSubmit: (otp: string) => void;
    onResend: () => void;
    isVisible: boolean;
    setVisible: (v: boolean) => void;
    isLoading: boolean;
    otpMobileNumber: string;
};

type SuccessPopUpProps = {
    isVisible: boolean;
    confirmationData: {
        amount: string,
        referenceId: string,
        currency: string
    };
};

const url = new URL(window.location.toString());
const params = new URLSearchParams(url.search);

const BankOption = ({data, disabled, image, onSelect, selectedOption} : Props) => {

    return (
        <div className="w-full flex gap-4 py-6 border-b-2 border-solid border-color-gray ">
            <label className={`w-full flex items-center justify-between ${disabled ? 'cursor-not-allowed opacity-25' : 'cursor-pointer'}`}>
                <div className="flex items-center gap-4">
                    <input
                        disabled={disabled}
                        type="radio"
                        className="w-5 h-5 uwi-input"
                        onChange={(e) => {
                            onSelect(e.target.value, data.channelCode);
                        }}
                        checked={data.id === selectedOption}
                        value={data.id}
                        name="bankOption"
                    />
                    {`${data.accountDetails} - ${data.accountType}`}
                </div>
            </label>
        </div>
    )
}

const OtpPopUp = ({isVisible, onSubmit, isLoading, onResend, otpMobileNumber, setVisible}: OtpPopUpProps) => {
    const [otp, setOtp] = useState<string>('');
    const [maxOtpLength, setMaxOtpLength] = useState<number>(6);

    useEffect(() => {
        if (otp.length === 6) {
            onSubmit(otp);
        }
    },[otp])

    return (
        <div className={`rounded-3xl z-10 w-full h-full absolute left-0 top-0 flex justify-center bg-white items-center ${isVisible ? 'flex': 'hidden'}`}>
            <div className="w-full md:w-3/4 h-full flex justify-center p-5 flex-col items-center gap-y-4">
                <button className="absolute right-10 top-10" onClick={() => setVisible(false)}><XMarkSolidIcon className="text-uwi-primary-100 w-6 h-6"/></button>
                <LockIcon className="w-36 text-uwi-primary-100 "/>
                <div className="text-2xl font-bold">OTP Verification</div>

                <div className="text-sm">Enter OTP code sent to <span className="font-bold">{otpMobileNumber}</span></div>

                <div className="flex justify-center w-full h-9 relative">
                    <div className="absolute w-full h-full">
                        <div className="flex left-0 top-0 justify-center">
                            {[...Array(maxOtpLength)].map((x, i) =>
                                <div className="border-[1px] w-10 mr-4 h-10 rounded-lg" key={`item_${i}`}></div>
                            )}
                        </div>
                    </div>
                    <div className="absolute w-full h-full">
                        <div className="flex justify-center top-0 pl-[19px] md:pl-[35px]">
                            <input
                                type="text"
                                className={`bg-white/40 outline-none tracking-[43px] w-[350px] h-9 text-2xl`}
                                id="otp"
                                value={otp}
                                onChange={(e) => {
                                    setOtp(e.target.value);
                                }}
                                required={true}
                                maxLength={maxOtpLength}
                                disabled={isLoading}
                                ref={function(input) {
                                    if (input != null) {
                                        input.focus();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="text-center mt-3 mb-4">
                    <div className="text-sm text-gray-50">Didn&#39;t receive OTP code?</div>

                    <button className="text-xs text-uwi-primary-100 font-bold mt-2" onClick={() => onResend()}>
                        Resend Code
                    </button>
                </div>

                <button
                    className={`
                        w-[calc(55%)] md:w-[185px]
                        flex justify-center items-center rounded-2xl
                        text-[15px] font-bold
                        text-white px-8 py-4
                        bg-uwi-primary-100 hover:bg-uwi-primary-200
                        disabled:opacity-20
                        relative
                    `}
                    onClick={() => onSubmit(otp)}
                    disabled={isLoading}
                >
                    Verify &amp; Proceed

                    {(isLoading) && (
                        <CircleLoaderIcon className="animate-spin ml-3 text-white absolute" />
                    )}
                </button>
            </div>
        </div>
    )
}

const PaymentAccountSelectionPage = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [isOtpVisible, setIsOtpVisible] = useState(false);
    const [bankOption, setBankOption] = useState<string>('');
    const [bank, setBank] = useState<string>('');
    const [accounts, setAccounts] = useState<BankAccount[]>([]);
    const [directDebitId, setDirectDebitId] = useState<string>('');
    const [otpMobileNumber, setOtpMobileNumber] = useState<string>('');
    const [idempotenceKey, setIdempotenceKey] = useState<string>('');
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState('');

    useEffectOnce(() => {
        setIdempotenceKey(`${Date.now()}${Math.floor((Math.random()*100))}`);

        Container
            .get(PaymentApi)
            .accounts(params.get('linked_account_token_id'))
            .then(accounts => {
                setAccounts(accounts);
                setBankOption(accounts[0].id);
                setBank(accounts[0].channelCode);
                setIsLoadingAccounts(false);
                window.localStorage.removeItem('payment_channel');
            })
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
                setError(error.data[0].errors[0]);
            });
    });

    const cleanUp = () => {
        window.sessionStorage.removeItem('xendit_pm_id');
        window.sessionStorage.removeItem('xendit_customer_id');
    }

    const handlePayFee = async () => {
        setIsLoading(true);

        const customerId = window.sessionStorage.getItem('xendit_customer_id');
        const pmId = window.sessionStorage.getItem('xendit_pm_id') || '';

        if (pmId) {
            setIdempotenceKey(`${Date.now()}${Math.floor((Math.random()*100))}`);
        }

        const payment = await Container.get(PaymentApi)
            .directDebitPay({
                customerId: customerId ? customerId : '',
                linkedAccountToken: bankOption,
                bank: bank,
                paymentMethodId: pmId
            }, idempotenceKey)
            .catch((error) => {
                setIsError(true);
                setIsLoading(false);
                setError(`${error.data[0].field} - ${error.data[0].errors[0]}`);
            });

        if (payment) {
            setDirectDebitId(payment.id);
            window.sessionStorage.setItem('xendit_pm_id', payment.paymentMethodId);
            setOtpMobileNumber(payment.OtpMobileNumber);
            setIsOtpVisible(true);
            setIsLoading(false);
        }
    }

    const handleSubmitOtp = async (otp:string) => {
        setIsOtpLoading(true);

        const preQualificationId = window.localStorage.getItem('pre_qualification_id') || '';
        const subProjectId = window.localStorage.getItem('sub_project_id') || '';

        await Container.get(PaymentApi).directDebitConfirm({
            otp,
            directDebitId,
            preQualificationId,
            subProjectId
        })
        .then(confirmation => {
            if (confirmation) {
                setIsOtpLoading(false);
                setIsOtpVisible(false);

                window.localStorage.setItem('payment_reference_id', confirmation.referenceId);
                window.localStorage.setItem('payment_amount', confirmation.amount);
                window.localStorage.setItem('payment_date', confirmation.paymentDate);
                window.localStorage.setItem('payment_channel', confirmation.channelCode);

                cleanUp();
                navigate('/payment-success');
            }
        })
        .catch((error) => {
            setIsError(true);
            setIsLoading(false);
            cleanUp();
            setError(error.data[0].errors[0]);
        });

        setIsOtpLoading(false);
    }

    return (
        <>
            <div
                className={`
                    bg-uwi-content-100 shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]
                    text-black rounded-t-3xl
                    h-[calc(100vh-156px)] xs:h-[calc(100vh-155px)] md:h-[612px] lg:h-[597px] w-full
                    flex justify-center flex-col items-center relative
                    px-4 md:px-10
                `}
            >
                <OtpPopUp setVisible={(v) => setIsOtpVisible(v)} otpMobileNumber={otpMobileNumber} onResend={handlePayFee} onSubmit={(otp) =>handleSubmitOtp(otp)} isVisible={isOtpVisible} isLoading={isOtpLoading}/>

                <PaymentError isVisible={isError} error={error} setIsVisible={(visible) => setIsError(visible)}/>

                <div className="w-full md:w-[400px] flex flex-col md:p-5 justify-center items-center">
                    <div>Select Account to Pay</div>

                    {isLoadingAccounts && (
                        <div className="my-4 flex flex-col gap-4">
                            Loading...
                            <CircleLoaderIcon className="animate-spin ml-3" />
                        </div>
                    )}

                    <Select
                        className={`${isLoadingAccounts ? 'hidden' : ''} w-full text-left mt-6`}
                        placeholder="Select Account"
                        value={0}
                        onChange={(idx) => {
                            setBankOption(accounts[idx as number].id);
                            console.log(accounts[idx as number].channelCode)
                            setBank(accounts[idx as number].channelCode);
                        }}
                    >
                        {accounts.map((account, idx) => (
                            <SelectOption
                                value={idx}
                                key={`account_${idx}`}
                            >
                                {`${account.accountDetails} - ${account.accountType}`}
                            </SelectOption>
                        ))}
                    </Select>

                    <div className="text-sm text-gray-50 mt-4">
                        *all fields are required
                    </div>
                </div>

                <div className="w-full flex justify-center mt-8">
                    <button
                        className={`
                            w-[calc(50%-8px)] md:w-[170px] mr-4
                            flex justify-center items-center rounded-2xl
                            px-8 py-4
                            font-bold text-[15px]
                            border border-solid
                            border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                            hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                        `}
                        onClick={() => {
                            navigate('/pay-reservation')
                        }}
                    >
                        Back
                    </button>
                    <button
                        className={`
                            w-[calc(50%-8px)] md:w-[170px]
                            flex justify-center items-center rounded-2xl
                            text-[15px] font-bold
                            text-white px-8 py-4
                            bg-uwi-primary-100 hover:bg-uwi-primary-200
                            disabled:opacity-20
                        `}
                        onClick={handlePayFee}
                        disabled={isLoading || isLoadingAccounts || !bankOption}
                    >
                        Pay Fee

                        {(isLoading) && (
                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                        )}
                    </button>
                </div>
            </div>
        </>
    );
}

export default PaymentAccountSelectionPage;
