import 'reflect-metadata';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import { Service } from 'typedi';
import BaseApi from './BaseApi';
import { HomeLoanApplicationDocument } from './HomeLoanApplicationDocumentApi';

export type HomeLoanApplicationStatusType = 'draft' | 'inactive' | 'active';
export type HomeLoanApplicationViewStatusType = 'draft' | 'inactive' | 'active';

export interface Dependent {
    name: string;
    age: number;
    relationship: string;
};

export interface HomeLoanApplication {
    id: string;
    userId: string;
    lenderLoanId: string;
    preQualificationId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    nameSuffix: string;
    gender: string;
    placeOfBirth: string;
    educationalBg: string;
    civilStatus: string;
    motherMaidenName: string;
    spouseFirstName: string;
    spouseMiddleName: string;
    spouseLastName: string;
    spouseBirthdate: Date|null;
    spouseMotherMaidenName: string;
    phoneNumber: string;
    mobileNumber: string;
    email: string;
    currentAddress: string;
    currentAddressHomeOwnership: string;
    currentAddressResidenceSince: number;
    permanentAddress: string;
    permanentAddressHomeOwnership: string;
    permanentAddressResidenceSince: number;
    previousAddress: string;
    previousAddressHomeOwnership: string;
    previousAddressResidenceSince: number;
    companyIndustry: string;
    companyName: string;
    officePhone: string;
    officeAddress: string;
    tin: string;
    sss: string;
    employmentRank: string;
    officeEmail: string;
    dependents: Dependent[];
    projectContactPersonName: string;
    projectAddress: string;
    projectContactPersonNumber: string;
    attorneyInfactName: string;
    attorneyInfactAddress: string;
    attorneyInfactContactNumber: string;
    attorneyInfactRelationship: string;
    status: HomeLoanApplicationStatusType;
    createdAt: Date;
    updatedAt: Date;
    homeLoanApplicationDocuments?: HomeLoanApplicationDocument[];
}

export interface HomeLoanApplicationView {
    id: string;
    userId: string;
    lenderLoanId: string;
    preQualificationId: string;
    status: HomeLoanApplicationViewStatusType;
    projectCost: number;
    loanTerm: number;
    percentOfProjectCost: number;
    fixedInterestPercentage: number;
    lenderName: string;
    lenderImage: string;
    lenderThumbnail: string;
    loanableAmount: number;
    referenceNumber?: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface HomeLoanApplicationCreateParam {
    preQualificationId: string;
    lenderLoanId: string;
    percentOfProjectCost?: number;
    loanTerm?: number;
}

export interface HomeLoanApplicationForm {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    nameSuffix?: string;
    gender?: string;
    placeOfBirth?: string;
    educationalBg?: string;
    civilStatus?: string;
    motherMaidenName?: string;
    spouseFirstName?: string;
    spouseMiddleName?: string;
    spouseLastName?: string;
    spouseBirthdate?: Date;
    spouseMotherMaidenName?: string;
    phoneNumber?: string;
    mobileNumber?: string;
    email?: string;
    currentAddress?: string;
    currentAddressHomeOwnership?: string;
    currentAddressResidenceSince?: number;
    permanentAddress?: string;
    permanentAddressHomeOwnership?: string;
    permanentAddressResidenceSince?: number;
    previousAddress?: string;
    previousAddressHomeOwnership?: string;
    previousAddressResidenceSince?: number;
    companyIndustry?: string;
    companyName?: string;
    officePhone?: string;
    officeAddress?: string;
    tin?: string;
    sss?: string;
    employmentRank?: string;
    officeEmail?: string;
    dependents?: Dependent[];
    projectContactPersonName?: string;
    projectAddress?: string;
    projectContactPersonNumber?: string;
    attorneyInfactName?: string;
    attorneyInfactAddress?: string;
    attorneyInfactContactNumber?: string;
    attorneyInfactRelationship?: string;
}

@Service()
export class HomeLoanApplicationApi extends BaseApi {
    /**
     * API path for creating a draft home loan application.
     */
    static readonly PATH_CREATE = '/v1/widget/home-loan-application/create';

    /**
     * API path to get the info of the home loan application.
     */
    static readonly PATH_GET_INFO = '/v1/widget/home-loan-application/$appId';

    /**
     * API path to get the summary of the home loan application.
     */
     static readonly PATH_GET_SUMMARY = '/v1/widget/home-loan-application/$appId/summary';

    /**
     * API path to get the list of home loan application of the logged user.
     */
    static readonly PATH_LIST = '/v1/widget/home-loan-application/list';

    /**
     * API path to update the home loan application.
     */
    static readonly PATH_UPDATE = '/v1/widget/home-loan-application/$appId';

    create(data: HomeLoanApplicationCreateParam): Promise<HomeLoanApplication> {
        return this.http.post(
            HomeLoanApplicationApi.PATH_CREATE,
            data,
            {},
            true
        );
    }

    async getInfo(appId: string): Promise<HomeLoanApplication> {
        const obj:HomeLoanApplication = await this.http.get(
            HomeLoanApplicationApi.PATH_GET_INFO.replace(/\$appId/, appId),
            {},
            {},
            true
        );

        obj.createdAt = new Date(obj.createdAt);
        obj.updatedAt = new Date(obj.updatedAt);

        if (obj.spouseBirthdate) {
            obj.spouseBirthdate = new Date(obj.spouseBirthdate);
        }

        if (obj.homeLoanApplicationDocuments) {
            const docs = obj.homeLoanApplicationDocuments.map(doc => ({
                ...doc,
                ...{
                    createdAt: new Date(doc.createdAt),
                    updatedAt: new Date(doc.updatedAt)
                }
            }));

            obj.homeLoanApplicationDocuments = docs;
        }

        return obj;
    }

    async getSummary(appId: string): Promise<HomeLoanApplicationView> {
        const obj:HomeLoanApplicationView = await this.http.get(
            HomeLoanApplicationApi.PATH_GET_SUMMARY.replace(/\$appId/, appId),
            {},
            {},
            true
        );

        return obj;
    }

    async list(): Promise<HomeLoanApplicationView[]> {
        const list:HomeLoanApplicationView[] = await this.http.get(
            HomeLoanApplicationApi.PATH_LIST,
            {},
            {},
            true
        );

        return list.map((obj) => {
            obj.createdAt = new Date(obj.createdAt);
            obj.updatedAt = new Date(obj.updatedAt);

            return obj;
        });
    }

    update(
        appId: string,
        form: HomeLoanApplicationForm
    ): Promise<HomeLoanApplication> {
        let dependents;
        if (form.dependents) {
            dependents = JSON.stringify(form.dependents);
        }

        let spouseBirthdate;
        if (form.spouseBirthdate) {
            spouseBirthdate = formatDateToYYYYMMDD(form.spouseBirthdate);
        }

        const data = {
            ...form,
            ...{ dependents, spouseBirthdate }
        };

        return this.http.put(
            HomeLoanApplicationApi.PATH_GET_INFO.replace(/\$appId/, appId),
            data,
            {},
            true
        );
    }

}
