import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

export type ToastObjectType = 'error' | 'success';

export interface ToastObject {
    id: string;
    message: string;
    type: ToastObjectType;
}

interface ToastDataState {
    toastObjects: ToastObject[];
}

const initialState: ToastDataState = {
    toastObjects: []
}

export const toastSlice = createSlice({
    name: 'toast',

    initialState,

    reducers: {
        
        setToastObjects: (state: ToastDataState, action: PayloadAction<ToastObject[]>) => {
            state.toastObjects = action.payload;
        },

        addToastObject: (state: ToastDataState, action: PayloadAction<ToastObject>) => {
            state.toastObjects.push(action.payload);
        }

    }
});

export const {
    setToastObjects,
    addToastObject
} = toastSlice.actions;

export const selectToastObjects = (state: RootState) => state.toast.toastObjects;

export default toastSlice.reducer;