import { useMemo, useState } from 'react';
import useClickoutside from 'src/hooks/useClickoutside';
import { AngleDownSolidIcon, XMarkSolidIcon } from '../icons';

export interface MultiOption {
    id: string;
    text: string;
}

type Props = {
    options: MultiOption[];
    value: string[];
    placeholder?: string;
    className?: string;
    arrowIconClassName?: string;
    dropdownClassName?: string;
    dropdownItemClassName?: string;
    selectedOptionClassName?: string;
    onChange: (val: string[]) => void;
}

const Multiselect = ({
    className,
    options,
    value,
    placeholder,
    arrowIconClassName,
    dropdownClassName,
    dropdownItemClassName,
    selectedOptionClassName,
    onChange
}: Props) => {
    const [dropdown, setDropdown] = useState(false);
    const [searchVal, setSearchVal] = useState('');

    const handleSearch = (val: string) => {
        setSearchVal(val);
    };

    const toggleDropdown = () => {
        setDropdown(!dropdown);
    };

    const handleAddTag = (option: string) => {
        if (!value.includes(option)) {
            const copyValue = [...value];
            copyValue.push(option);
    
            setDropdown(false);
            setSearchVal('');
            onChange(copyValue);
        }
    };

    const handleRemoveTag = (option: string) => {
        onChange(value.filter(e => (e !== option)));
    }

    const clickRef = useClickoutside(() => {
        setDropdown(false);
    });

    const selectedOptions = useMemo(() => {
        const tmpOpts: MultiOption[] = [];
        value.forEach(val => {
            const option = options.find(opt => (opt.id == val));
            if (option) {
                tmpOpts.push(option);
            }
        });

        return tmpOpts;
    }, [value, options]);

    const unSelectedOptions = useMemo(() => {
        return options.filter(opt => (!value.includes(opt.id) && opt.text.toLowerCase().startsWith(searchVal.toLowerCase())));
    }, [value, options, searchVal]);

    return (
        <div className="autcomplete-wrapper" ref={clickRef}>
            <div className="autcomplete">
                <div className="w-full flex flex-col options-center mx-auto">
                    <div className="w-full">
                        <div className="flex flex-col options-center relative">
                            <div className="w-full">
                                <div className={`p-1 flex rounded-xl border border-solid border-gray-200 cursor-pointer ${className || ''}`}>
                                    <div className="flex flex-auto flex-wrap w-[calc(100%-32px)]">
                                        {selectedOptions.map((option, index) => (
                                            <div key={index}
                                                className={`
                                                    flex gap-2 items-center justify-center options-center m-1 font-medium py-1 px-2 rounded-full text-teal-700 bg-teal-100 border border-teal-300
                                                    ${selectedOptionClassName || ''}
                                                `}
                                            >
                                                <div className="text-xs font-normal leading-none max-w-full flex-initial">{ option.text }</div>
                                                <div className="flex flex-auto flex-row-reverse">
                                                    <div onClick={() => handleRemoveTag(option.id)}>
                                                        <XMarkSolidIcon className={`w-3 h-3 feather feather-x cursor-pointer hover:text-teal-400 rounded-full`} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="flex-1">
                                            <input placeholder={placeholder || 'Select'} className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800"
                                                value={searchVal}
                                                onChange={(e) => handleSearch(e.target.value)}
                                                onFocus={(e) => setDropdown(true)}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-8 py-1 pl-2 pr-1 flex options-center" onClick={toggleDropdown}>
                                        <button className={`cursor-pointer w-6 h-6 outline-none focus:outline-none ${selectedOptions.length > 0 ? '' : 'text-gray-150'}`}>
                                            <AngleDownSolidIcon className={`w-4 h-4 ${dropdown ? 'rotate-180' : ''} ${arrowIconClassName || ''}`} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`
                                absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-60 overflow-y-auto uwi-scrollbar
                                ${dropdownClassName || ''}
                                ${dropdown ? '' : 'hidden'}
                            `}
                        >
                            <div className="flex flex-col w-full">
                                {unSelectedOptions.map((option, idx) => (
                                    <div
                                        key={idx} 
                                        className={`cursor-pointer w-full border-gray-10 rounded-t border-b hover:bg-teal-100 
                                            ${dropdownItemClassName || ''}
                                        `} 
                                        onClick={() => handleAddTag(option.id)}
                                    >
                                        <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100" >
                                            <div className="w-full items-center flex">
                                                <div className="mx-2 leading-6">{ option.text }</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Multiselect;
