import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleLoaderIcon } from 'src/components/icons';
import ApplyContext from '../ApplyContext';

type Props = {
    onNext?: () => Promise<boolean>;
};

const FieldGroupBottomButtons = ({
    onNext
}: Props) => {
    const navigate = useNavigate();

    const { page, setPage } = useContext(ApplyContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleNext = async () => {
        if (onNext) {
            try {
                setIsLoading(true);
                const isSuccess = await onNext();
                if (isSuccess) {
                    setPage(page + 1);
                }
            }
            finally{
                setIsLoading(false);
            }
        }
        else {
            setPage(page + 1);
        }
    };

    return (
        <div className="w-full mb-8 flex justify-center">
            <button
                className={`
                    w-[calc(50%-8px)] md:w-[150px] mr-4
                    flex justify-center items-center rounded-2xl
                    px-8 py-4
                    font-bold text-[15px]
                    border border-solid
                    border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                    hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                `}
                onClick={() => {
                    if (page === 0) {
                        navigate('/dashboard_home_loan_applications');
                    }
                    else {
                        setPage(page - 1);
                    }
                }}
            >
                {(page === 0) ? 'Dashboard' : 'Back'}
            </button>
            <button
                className={`
                    w-[calc(50%-8px)] md:w-[150px]
                    flex justify-center items-center rounded-2xl
                    text-[15px] font-bold
                    px-8 py-4
                    text-white
                    ${(isLoading) ? 'bg-gray-100' : 'bg-uwi-primary-100 hover:bg-uwi-primary-200'}
                `}
                disabled={isLoading}
                onClick={() => handleNext()}
            >
                {(page === 4) ? 'Finish' : 'Next'}

                {(isLoading) && (
                    <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                )}
            </button>
        </div>
    );
};

export default FieldGroupBottomButtons;
