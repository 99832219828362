import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export interface LenderLoan {
    id: string;
    lenderId: string;
    lenderName: string;
    lenderImage: string;
    minimumLoanableAmount: number;
    minimumDepositPercentage: number;
    minimumLoanTerm: number;
    fixedInterestPercentage: number;
    fixedInterestYear: number;
    loanablePercentageOfMonthlyReceivable: number;
    loanType: string;
    bankFees: string;
    loanApplicationFee: number;
    insurances: string;
    processingTime: string;
}

export interface LenderLoanWithMoreInfo extends LenderLoan {
    totalPayments: number;
    percentageOfProjectCost: number;
    loanTerm: number;
    monthlyPayments: number;
}

interface SearchResultResponse {
    isModifiedData: boolean;
    list: LenderLoanWithMoreInfo[];
    modifiedData: string[];
}

export interface SearchParams {
    loanTerm: number;
    percentageOfProjectCost: number;
    monthlyReceivable: number;
    projectCost: number;
    ownedBanks: string[];
    monthlyDebt: number;
    age: number;
    workType: string;
    workExperience: number;
    projectType: string;
    projectStatus: string;
    isRegularEmployment: boolean;
    visaType: string;
    nationality: string;
    projectId: string;
}

@Service()
export class LenderLoanApi extends BaseApi {
    /**
     * API path to search for the lender loans used for pre qualification
     */
    static readonly PATH_SEARCH_LOANS_FOR_PRE_QUALIFICATION = '/v1/widget/lender-loan/search-for-pre-qualification';

    searchPreQualificationLenderLoans(params: SearchParams): Promise<SearchResultResponse> {
        const data = {
            ...params,
            ...{
                ownedBanks: JSON.stringify(params.ownedBanks),
                isRegularEmployment: (params.isRegularEmployment) ? 'true' : 'false'
            }
        };

        return this.http.get(
            LenderLoanApi.PATH_SEARCH_LOANS_FOR_PRE_QUALIFICATION,
            data
        );
    }

}
