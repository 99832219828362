import { useCallback, useState } from 'react';
import { LinePagination } from 'src/components/common';
import { useAppSelector } from 'src/hooks/generic';
import { selectIsProjectOnlyView, selectWidgetType } from 'src/store/features/commonSlice';
import DebtIncomeInfo from './field_groups/DebtIncomeInfo';
import LoanInfo from './field_groups/LoanInfo';
import OtherInfo from './field_groups/OtherInfo';
import WorkInfo from './field_groups/WorkInfo';
import PreApproveContext from './PreQualificationContext';
import { paginationItemValues } from './data/paginationItemsData';
import ProjectInfoScreen1 from './field_groups/ProjectInfoScreen1';
import ProjectInfoScreen2 from './field_groups/ProjectInfoScreen2';
import PropertyInfo from './field_groups/PropertyInfoScreen';
import ProjectInfoScreen3 from './field_groups/ProjectInfoScreen3';

type Props = {
    onFinishForm?: () => void;
    routerPushOnChangeTab?: boolean;
};

const PreQualificationMainForm = ({
    onFinishForm
}: Props) => {
    const widgetType = useAppSelector(selectWidgetType);
    const isProjectOnlyView = useAppSelector(selectIsProjectOnlyView);

    const [page, setPage] = useState(0);

    const changePage = useCallback((page: number) => {
        if (page === 5) {
            if (onFinishForm) {
                onFinishForm();
            }
        }
        else {
            setPage(page);
        }
    }, [onFinishForm]);

    return (
        <PreApproveContext.Provider value={{ page, setPage: changePage }}>
            <LinePagination
                selectedPage={page}
                setPage={changePage}
                paginationItems={paginationItemValues}
                className="mb-4"
            />

            <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-258px)] xs:h-[calc(100vh-257px)] md:h-[510px] lg:h-[495px]">
                        <div className="w-full pb-4">
                            {(page === 0) && (widgetType === 'advance') && !isProjectOnlyView && (
                                <ProjectInfoScreen1 />
                            )}

                            {(page === 0.5) && (widgetType === 'advance') && !isProjectOnlyView && (
                                <ProjectInfoScreen2 />
                            )}

                            {(page === 0) && (widgetType === 'advance') && isProjectOnlyView && (
                                <ProjectInfoScreen3 />
                            )}

                            {(page === 0) && (widgetType === 'basic') && (
                                <PropertyInfo />
                            )}

                            {(page === 1) && (
                                <WorkInfo />
                            )}

                            {(page === 2) && (
                                <OtherInfo />
                            )}

                            {(page === 3) && (
                                <DebtIncomeInfo />
                            )}

                            {(page === 4) && (
                                <LoanInfo />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </PreApproveContext.Provider>
    );
};

export default PreQualificationMainForm;
