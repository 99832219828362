import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HttpRequestException } from 'src/api/lib/HttpRequestException';
import { ErrorCode } from 'src/api/types';
import { ErrorList, PasswordField } from 'src/components/common';
import { CircleLoaderIcon } from 'src/components/icons';
import useAuth from 'src/hooks/useAuth';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const LoginPage = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const [searchParams] = useSearchParams();
    const {
        commonErrorHandler,
        errors
    } = useGenericErrorHandler();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorListVisible, setIsErrorListVisible] = useState(false);
    const [isAccountLocked, setIsAccountLocked] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsAccountLocked(false);

        try {
            await login(formData.email, formData.password);
            const referrer = searchParams.get('referrer');

            if (referrer) {
                navigate(referrer);
            }
            else {
                navigate('/dashboard-pre-qualifications');
            }
        }
        catch (ex) {
            if (
                ex instanceof HttpRequestException
                && ex.getCode() === ErrorCode.ACCOUNT_LOCKED
            ) {
                setIsAccountLocked(true);
            }
            else {
                commonErrorHandler(ex);
                setIsErrorListVisible(true);
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <>  
            <div
                className={`
                    bg-uwi-content-100 shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]
                    text-black rounded-t-3xl
                    h-[calc(100vh-156px)] xs:h-[calc(100vh-155px)] md:h-[612px] lg:h-[597px] w-full
                    flex justify-center 
                `}
            >
                <div className="w-[400px] flex flex-col p-5 justify-center items-center">
                    <form
                        className="w-full flex flex-col items-center md:mb-0 gap-y-3"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >

                        {isAccountLocked && (
                            <div className="gap-y-2 text-sm rounded-lg p-4 w-full mb-4 bg-red-50 border border-solid border-red-100 text-red-100">
                                You have reached the maximum number of authentication attempts. Your account has been temporarily locked. Please contact customer support for assistance.
                            </div>
                        )}

                        {!isAccountLocked && (
                            <ErrorList
                                errors={errors}
                                visible={isErrorListVisible}
                                onClose={() => setIsErrorListVisible(false)}
                                floatInMobile={false}
                            />  
                        )}

                        <div className="w-full flex flex-col items-center">
                            <label htmlFor="email" className="mb-2 block text-center form-label">Email</label>
                            <input
                                type="email"
                                className="form-field bg-white/40"
                                placeholder="Email"
                                autoComplete="username"
                                id="email"
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        ...{ email: e.target.value }
                                    });
                                    setIsErrorListVisible(false);
                                }}
                                required={true}
                            />
                        </div>
                        <div className="w-full flex flex-col items-center">
                            <label htmlFor="password" className="mb-2 block text-center form-label">Password</label>
                            <PasswordField
                                className="form-field bg-white/40"
                                attrs={{
                                    id: "password",
                                    placeholder: "Password",
                                    autoComplete: "current-password",
                                    required: true,
                                    minLength: 8
                                }}
                                onChange={(password) => {
                                    setFormData({
                                        ...formData,
                                        ...{ password }
                                    });
                                    setIsErrorListVisible(false);
                                }}
                            />
                        </div>
                        <button
                            className="text-xs text-uwi-primary-100 hover:bg-transparent hover:text-uwi-primary-200 mb-2"
                            type="button"
                            onClick={() => navigate('/forgot_password')}
                        >
                            Forgot Password?
                        </button>
                        <button 
                            className={`
                                flex justify-center rounded-2xl
                                w-full py-4
                                font-bold text-[15px]
                                border border-solid
                                ${
                                    (!isLoading)
                                    ? 'border-uwi-primary-100 hover:border-uwi-primary-200 bg-uwi-primary-100 hover:bg-uwi-primary-200 border text-white'
                                    : 'bg-gray-100 text-white'
                                }
                            `}
                        >
                            Login
                            {isLoading && (
                                <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                            )}
                        </button>
                        <div className="w-full text-sm text-center">
                            Don&apos;t have an account? &nbsp;
                            <button
                                className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                type="button"
                                onClick={() => navigate('/signup')}
                            >
                                Sign up now
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LoginPage;
