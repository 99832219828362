import { createContext } from 'react';

export interface IPreQualificationContext {
    page: number;
    setPage: (page: number) => void;
}

const PreQualificationContext = createContext<IPreQualificationContext>({
    page: 0,
    setPage: () => {}
});

export default PreQualificationContext;
