import 'reflect-metadata';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Container } from 'typedi';
import { ApiListener, HttpRequest } from './HttpRequest';
import { HttpRequestException } from './HttpRequestException';
import { UserSessionService } from './UserSessionService';

export class HttpRequestImpl implements HttpRequest {
    private readonly apiUrl: string;
    private readonly clientId: string;

    private listener: ApiListener;

    constructor(apiUrl: string, clienId: string) {
        this.apiUrl = apiUrl;
        this.clientId = clienId;

        this.listener = (() => {});
    }

    /**
     * Sets the listener for the http request
     */
    public setListener(listener: ApiListener) {
        this.listener = listener;
    }

    /**
     * @see HttpRequest::delete
     */
    public async delete(path:string, params:Record<string, any> = {}, headers:Record<string, string> = {}, withUser = false): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;
        return this.processPromise(
            axios.delete(url, {
                headers: processedHeaders,
                params: this.formatParameter(params)
            })
        );
    }

    /**
     * @see HttpRequest::get
     */
    public async get(
        path: string,
        params: Record<string, any> = {},
        headers: Record<string, string> = {},
        withUser = false
    ): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;
        return this.processPromise(
            axios.get(url, {
                headers: processedHeaders,
                params: this.formatParameter(params)
            })
        );
    }

    /**
     * @see HttpRequest::getBaseUrl
     */
    public getBaseUrl():string {
        return this.apiUrl;
    }

    /**
     * @see HttpRequest::post
     */
    public async post(
        path: string,
        data: Record<string, any> = {},
        headers: Record<string, string> = {},
        withUser = false
    ): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;

        return this.processPromise(
            axios.post(url, this.formatParameter(data), { headers: processedHeaders })
        );
    }

    /**
     * @see HttpRequest::postWithFile
     */
    public async postWithFile(
        path: string,
        data: FormData,
        headers: Record<string, string> = {},
        withUser = false
    ): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;
        return this.processPromise(
            axios.post(url, data, { headers: processedHeaders })
        );
    }

    /**
     * @see HttpRequest::put
     */
    public async put(
        path: string,
        data: Record<string, any> = {},
        headers: Record<string, string> = {},
        withUser = false
    ): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;
        return this.processPromise(
            axios.put(url, this.formatParameter(data), { headers: processedHeaders })
        );
    }

    /**
     * @see HttpRequest::putWithFile
     */
    public async putWithFile(
        path: string,
        data: FormData,
        headers: Record<string, string> = {},
        withUser = false
    ): Promise<any> {
        const processedHeaders = await this.processHeaders(path, headers, withUser);

        const url = `${this.apiUrl}${path}`;
        return this.processPromise(
            axios.put(url, data, { headers: processedHeaders })
        );
    }

    private formatParameter(data: Record<string, any>) {
        const params: Record<string, any> = {};

        const keys = Object.keys(data);
        keys.forEach(key => {
            if (typeof data[key] === 'object') {
                params[key] = JSON.stringify(data[key]);
            }
            else {
                params[key] = data[key];
            }
        });

        return params;
    }

    private async processHeaders(
        path: string,
        headers: Record<string, string>,
        withUser: boolean
    ): Promise<Record<string, string>> {
        if (!headers['Content-Type']) {
            headers['Content-Type'] = 'application/json';
        }

        headers['x-developer-client-id'] = this.clientId;
        
        if (withUser) {
            const token = await this.getUserToken(path);
            headers["Authorization"] = `Bearer ${token}`;
        }
    
        return headers;
    }

    private processPromise(promise:Promise<AxiosResponse<any>>):Promise<unknown> {
        return new Promise((resolve, reject) => {
            promise
                .then((response) => resolve(response.data))
                .catch((error: AxiosError<any>) => {
                    if (error.response && error.response.status > 400) {
                        this.listener(error);
                    }

                    if (error.response) {
                        const data = error.response.data;
                        if (
                            typeof data === 'object'
                            && Object.prototype.hasOwnProperty.call(data, 'code')
                            && Object.prototype.hasOwnProperty.call(data, 'data')
                        ) {
                            reject(new HttpRequestException(data.data, data.code, error.message));
                            return;
                        }
                    }
                    
                    reject(error);
                });
        });
    }

    private async getUserToken(path: string):Promise<string> {
        const token = await Container.get(UserSessionService).getJwtToken();
        if (!token) {
            const resp = {
                data: '',
                status: 403,
                statusText: '403 Forbidden',
                headers: {},
                config: {},
                request: null
            };
            const err = new AxiosError(`Please log in to continue.`, '403', undefined, null, resp);

            this.listener(err);

            throw err;
        }
    
        return token;
    }
}
