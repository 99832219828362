import {
    BirthdateField,
    FormFieldWrapper,
    Select,
    SelectOption,
    YesNoField
} from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import {
    selectNationality,
    selectVisaType,
    selectBirthdate,
    selectMaritalStatus,
    selectIsSpouseCosign,
    setNationality as setNationalityAct,
    setVisaType as setVisaTypeAct,
    setBirthdate as setBirthdateAct,
    setMaritalStatus as setMaritalStatusAct,
    setIsSpouseCosign as setIsSpouseCosignAct,
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import visaTypes from 'src/data/visaTypes.json';
import nationalities from 'src/data/nationalities.json';
import civilStatuses from 'src/data/civilStatuses.json';
import PreApprovalFormGroup from './PreApprovalFormGroup';
import useOtherInfoValidation from '../validations/useOtherInfoValidation';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';

const OtherInfo = () => {
    const {
        formErrors,
        setFormErrors,
        validateForm
    } = useOtherInfoValidation();

    const dispatch = useAppDispatch();
    const nationality = useAppSelector(selectNationality);
    const visaType = useAppSelector(selectVisaType);
    const birthdate = useAppSelector(selectBirthdate);
    const maritalStatus = useAppSelector(selectMaritalStatus);
    const isSpouseCosign = useAppSelector(selectIsSpouseCosign);

    return (
        <div className="w-full px-4 md:px-10">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[350px]">
                    <PreApprovalFormGroup
                        label="Birthdate"
                        tooltipText="Your Legal Date of Birth."
                        showTooltip={true}
                    >
                        <div className="w-full flex">
                            <BirthdateField
                                value={birthdate}
                                formError={(hasProp(formErrors, 'birthdate')) ? formErrors.birthdate : undefined}
                                onChange={(val) => {
                                    dispatch(setBirthdateAct(val));
                                    const { birthdate, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </div>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Nationality"
                        tooltipText="Your legal affiliation or citizenship."
                        showTooltip={true}
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'nationality')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={nationality}
                            onChange={(val) => {
                                dispatch(setNationalityAct(val as string));
                                const { nationality, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {nationalities.map(nationality => (
                                <SelectOption
                                    value={nationality}
                                    key={nationality}
                                >
                                    {nationality}
                                </SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        className={`${nationality === 'Foreigner' ? '' : 'hidden'}`}
                        label="Visa Type"
                        tooltipText="(For Foreigners) Specific category or class of your visa."
                        showTooltip={true}
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'visaType')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={visaType}
                            onChange={(val) => {
                                dispatch(setVisaTypeAct(val as string));
                                const { visaType, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {visaTypes.map((visaType, idx) => (
                                <SelectOption
                                    key={idx}
                                    value={visaType}
                                >{visaType}</SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Marital Status"
                        tooltipText="Your Current legal civil status."
                        showTooltip={true}
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'maritalStatus')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={maritalStatus}
                            onChange={(val) => {
                                dispatch(setMaritalStatusAct(val as string));
                                const { maritalStatus, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {civilStatuses.map(civilStatus => (
                                    <SelectOption
                                    key={civilStatus}
                                    value={civilStatus}
                                >
                                    {civilStatus}
                                </SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    {maritalStatus === 'Separated (Legally Married)' && (
                        <PreApprovalFormGroup
                        label="Will your spouse co-sign the loan with you?"
                        tooltipText=""
                        >
                            <YesNoField
                                value={isSpouseCosign}
                                onChange={(val) => {
                                    dispatch(setIsSpouseCosignAct(val));
                                }}
                            />
                        </PreApprovalFormGroup>
                    )}
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={validateForm}
            />
        </div>
    );
}

export default OtherInfo;
