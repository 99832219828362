type Props = {
  className?: string;
};

const RcbcIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="64"
      fill="none"
      viewBox="0 0 104 86"
      className={className}
    >
      <path
        fill="#3783D9"
        fillRule="evenodd"
        d="M22.506 38.28h9.206c2.597 0 4.634.761 5.961 2.053 1.092 1.114 1.712 2.64 1.712 4.43v.058c0 3.374-2.036 5.398-4.899 6.22l5.578 7.774h-4.308l-5.076-7.159h-4.545v7.16h-3.629V38.278zm8.941 10.179c2.597 0 4.25-1.35 4.25-3.432v-.06c0-2.199-1.595-3.402-4.28-3.402h-5.281v6.894h5.311zm11.143.147v-.058c0-5.839 4.397-10.622 10.653-10.622 3.808 0 6.108 1.321 8.087 3.198l-2.333 2.67c-1.651-1.526-3.422-2.551-5.784-2.551-3.924 0-6.816 3.226-6.816 7.246v.059c0 4.018 2.864 7.275 6.816 7.275 2.538 0 4.162-.997 5.933-2.64l2.33 2.347c-2.154 2.23-4.514 3.637-8.38 3.637-6.02 0-10.506-4.664-10.506-10.561zm22.104-10.327h9.265c2.36 0 4.22.646 5.4 1.82.915.908 1.387 2.023 1.387 3.402v.058c0 2.464-1.416 3.786-2.95 4.578 2.419.821 4.1 2.2 4.1 4.987v.06c0 3.665-3.038 5.631-7.642 5.631h-9.56V38.28zm12.423 5.81c0-1.614-1.299-2.613-3.63-2.613h-5.224v5.457h4.959c2.331 0 3.895-.91 3.895-2.786v-.059zm-3.099 5.896h-5.755v5.633h6.02c2.48 0 3.984-.968 3.984-2.816v-.06c0-1.73-1.386-2.757-4.25-2.757h.001zm11.096-1.38v-.057c0-5.839 4.397-10.622 10.653-10.622 3.807 0 6.109 1.321 8.085 3.198l-2.33 2.67c-1.652-1.526-3.423-2.551-5.785-2.551-3.924 0-6.816 3.226-6.816 7.246v.059c0 4.018 2.862 7.275 6.816 7.275 2.539 0 4.162-.997 5.933-2.64L104 55.53c-2.153 2.23-4.514 3.637-8.38 3.637-6.02 0-10.506-4.664-10.506-10.561zM37.454 86L0 64.503V21.508L37.47 0l37.437 22.387V36.68h-3.21V24.192L37.437 3.704 3.21 23.35v39.31l34.245 19.657L71.697 62.66v-2.247h3.21v4.089L37.454 86z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default RcbcIcon;