import { useNavigate } from 'react-router-dom';
import { marked } from 'marked';
import termsOfUseContent from 'src/data/termsOfUse.md';
import indentationMarkup from 'src/libs/indentation-markup';

marked.use(indentationMarkup);

const TermsOfUsePage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl w-full flex justify-center shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="w-full pt-10">
                    <div className="w-full overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px]">
                        <div className="flex flex-col items-center w-full px-6 md:px-12 pb-6">
                            <h1 className="text-center font-bold text-4xl mb-12">Terms Of Use</h1>

                            <div
                                id="static-data"
                                className="w-full"
                                dangerouslySetInnerHTML={{
                                    __html: marked.parse(termsOfUseContent)
                                }}
                            />

                            <p>
                                <small>
                                    I agree to UWI's
                                    <button
                                        className="mt-5 text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent ml-2"
                                        type="button"
                                        onClick={() => {
                                            navigate('/privacy-policy');
                                        }}
                                    >Privacy Policy</button>
                                </small>
                            </p>

                            <button
                                className="mt-5 text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent ml-2"
                                type="button"
                                onClick={() => {
                                    navigate('/signup');
                                }}
                            >Back to Sign Up page</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TermsOfUsePage;