import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

interface CaptchaCreateResponse {
    croppedImg: string;
    bgImg: string;
    y: number;
    captchaKey: string;
}

@Service()
export class CaptchaApi extends BaseApi {
    /**
     * API path for creating a captcha
     */
     static readonly PATH_CREATE_CAPTCHA = '/v1/captcha/create';

    /**
     * API path for verifying the captcha key
     */
    static readonly PATH_VERIFY = '/v1/captcha/verify';

    create(): Promise<CaptchaCreateResponse> {
        return this.http.get(CaptchaApi.PATH_CREATE_CAPTCHA);
    }

    verify(captchaKey: string, x: number): Promise<{ isValid: boolean; }> {
        return this.http.post(CaptchaApi.PATH_VERIFY, { captchaKey, x });
    }

}
