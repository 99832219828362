import { useState } from 'react';
import { FormFieldWrapper, FormGroup, Select, SelectOption } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { HomeLoanApplication } from 'src/api/HomeLoanApplicationApi';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectPhoneNumber,
    selectMobileNumber,
    selectEmail,
    selectCurrentAddress,
    selectCurrentAddressHomeOwnership,
    selectCurrentAddressResidenceSince,
    selectPermanentAddress,
    selectPermanentAddressHomeOwnership,
    selectPermanentAddressResidenceSince,
    selectPreviousAddress,
    selectPreviousAddressHomeOwnership,
    selectPreviousAddressResidenceSince,
    setPhoneNumber,
    setMobileNumber,
    setEmail,
    setCurrentAddress,
    setCurrentAddressHomeOwnership,
    setCurrentAddressResidenceSince,
    setPermanentAddress,
    setPermanentAddressHomeOwnership,
    setPermanentAddressResidenceSince,
    setPreviousAddress,
    setPreviousAddressHomeOwnership,
    setPreviousAddressResidenceSince,
    setPermanentAddressMerged,
    setPreviousAddressMerged
} from 'src/store/features/applyLoanContactInfoSlice';
import homeOwnershipList from 'src/data/homeOwnershipList.json';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import useContactInfo from './../hooks/useContactInfo';

type Props = {
    homeLoanApp?: HomeLoanApplication;
};

const ContactInfo = ({
    homeLoanApp
}: Props) => {
    const dispatch = useAppDispatch();
    const {
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    } = useContactInfo(homeLoanApp);

    const phoneNumber = useAppSelector(selectPhoneNumber);
    const mobileNumber = useAppSelector(selectMobileNumber);
    const email = useAppSelector(selectEmail);
    const currentAddress = useAppSelector(selectCurrentAddress);
    const currentAddressHomeOwnership = useAppSelector(selectCurrentAddressHomeOwnership);
    const currentAddressResidenceSince = useAppSelector(selectCurrentAddressResidenceSince);
    const permanentAddress = useAppSelector(selectPermanentAddress);
    const permanentAddressHomeOwnership = useAppSelector(selectPermanentAddressHomeOwnership);
    const permanentAddressResidenceSince = useAppSelector(selectPermanentAddressResidenceSince);
    const previousAddress = useAppSelector(selectPreviousAddress);
    const previousAddressHomeOwnership = useAppSelector(selectPreviousAddressHomeOwnership);
    const previousAddressResidenceSince = useAppSelector(selectPreviousAddressResidenceSince);

    const [permanentAddressCopy, setPermanentAddressCopy] = useState<string>('');
    const [permanentAddressHomeOwnershipCopy, setPermanentAddressHomeOwnershipCopy] = useState<string>('');
    const [permanentAddressResidenceSinceCopy, setPermanentAddressResidenceSinceCopy] = useState<number>(0);
    const [previousAddressCopy, setPreviousAddressCopy] = useState<string>('');
    const [previousAddressHomeOwnershipCopy, setPreviousAddressHomeOwnershipCopy] = useState<string>('');
    const [previousAddressResidenceSinceCopy, setPreviousAddressResidenceSinceCopy] = useState<number>(0);

    const handlePermanentAddressCheckboxChange = (shouldCopy: boolean) => {
        if (shouldCopy) {
            setPermanentAddressCopy(permanentAddress);
            setPermanentAddressHomeOwnershipCopy(permanentAddressHomeOwnership)
            setPermanentAddressResidenceSinceCopy(permanentAddressResidenceSince);
            
            dispatch(setPermanentAddressMerged({
                permanentAddress: currentAddress,
                permanentAddressHomeOwnership: currentAddressHomeOwnership,
                permanentAddressResidenceSince: currentAddressResidenceSince
            }));
        }
        else {
            dispatch(setPermanentAddressMerged({
                permanentAddress: permanentAddressCopy,
                permanentAddressHomeOwnership: permanentAddressHomeOwnershipCopy,
                permanentAddressResidenceSince: permanentAddressResidenceSinceCopy
            }));
        }
    }

    const handlePreviousAddressCheckboxChange = (shouldCopy: boolean) => {
        if (shouldCopy) {
            setPreviousAddressCopy(previousAddress)
            setPreviousAddressHomeOwnershipCopy(previousAddressHomeOwnership);
            setPreviousAddressResidenceSinceCopy(previousAddressResidenceSince);

            dispatch(setPreviousAddressMerged({
                previousAddress: currentAddress,
                previousAddressHomeOwnership: currentAddressHomeOwnership,
                previousAddressResidenceSince: currentAddressResidenceSince
            }));
        }
        else {
            dispatch(setPreviousAddressMerged({
                previousAddress: previousAddressCopy,
                previousAddressHomeOwnership: previousAddressHomeOwnershipCopy,
                previousAddressResidenceSince: previousAddressResidenceSinceCopy
            }));
        }
    }

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[600px] md:w-[700px]">
                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Phone Number (Optional)"
                            className="md:w-[calc(25%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'phoneNumber')}
                                errorMessage={(hasProp(formErrors, 'phoneNumber')) ? formErrors.phoneNumber : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'phoneNumber')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Phone Number"
                                    maxLength={20}
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        dispatch(setPhoneNumber(e.target.value));
                                        const { phoneNumber, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Mobile Number"
                            className="md:w-[calc(25%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'mobileNumber')}
                                errorMessage={(hasProp(formErrors, 'mobileNumber')) ? formErrors.mobileNumber : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'mobileNumber')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Mobile Number"
                                    maxLength={20}
                                    value={mobileNumber}
                                    onChange={(e) => {
                                        dispatch(setMobileNumber(e.target.value));
                                        const { mobileNumber, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Email Address"
                            className="md:w-[calc(50%-10px)]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'email')}
                                errorMessage={(hasProp(formErrors, 'email')) ? formErrors.email : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'email')) ? '!border-red-200' : ''}
                                    `}
                                    type="email"
                                    placeholder="Enter Email Address"
                                    maxLength={50}
                                    value={email}
                                    onChange={(e) => {
                                        dispatch(setEmail(e.target.value));
                                        const { email, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Current Address"
                            className="md:w-[calc(50%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'currentAddress')}
                                errorMessage={(hasProp(formErrors, 'currentAddress')) ? formErrors.currentAddress : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'currentAddress')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Current Address"
                                    value={currentAddress}
                                    maxLength={250}
                                    onChange={(e) => {
                                        dispatch(setCurrentAddress(e.target.value));
                                        const { currentAddress, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Home Ownership"
                            className="md:w-[calc(25%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'currentAddressHomeOwnership')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={currentAddressHomeOwnership}
                                onChange={(val) => {
                                    dispatch(setCurrentAddressHomeOwnership(val as string));
                                    const { currentAddressHomeOwnership, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {homeOwnershipList.map(homeOwnership => (
                                    <SelectOption
                                        key={homeOwnership}
                                        value={homeOwnership}
                                    >{homeOwnership}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>

                        <FormGroup
                            label="Years of Residence"
                            className="md:w-[calc(25%-10px)]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'currentAddressResidenceSince')}
                                errorMessage={(hasProp(formErrors, 'currentAddressResidenceSince')) ? formErrors.currentAddressResidenceSince : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'currentAddressResidenceSince')) ? '!border-red-200' : ''}
                                    `}
                                    type="number"
                                    placeholder="No. of Years"
                                    max={100}
                                    value={(currentAddressResidenceSince) ? currentAddressResidenceSince : ''}
                                    onChange={(e) => {
                                        dispatch(setCurrentAddressResidenceSince(parseInt(e.target.value)));
                                        const { currentAddressResidenceSince, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Permanent Address"
                            className="md:w-[calc(50%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'permanentAddress')}
                                errorMessage={(hasProp(formErrors, 'permanentAddress')) ? formErrors.permanentAddress : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'permanentAddress')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Permanent Address"
                                    maxLength={250}
                                    value={permanentAddress}
                                    onChange={(e) => {
                                        dispatch(setPermanentAddress(e.target.value));
                                        const { permanentAddress, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Home Ownership"
                            className="md:w-[calc(25%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'permanentAddressHomeOwnership')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={permanentAddressHomeOwnership}
                                onChange={(val) => {
                                    dispatch(setPermanentAddressHomeOwnership(val as string));
                                    const { permanentAddressHomeOwnership, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {homeOwnershipList.map(homeOwnership => (
                                    <SelectOption
                                        key={homeOwnership}
                                        value={homeOwnership}
                                    >{homeOwnership}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>

                        <FormGroup
                            label="Years of Residence"
                            className="md:w-[calc(25%-10px)]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'permanentAddressResidenceSince')}
                                errorMessage={(hasProp(formErrors, 'permanentAddressResidenceSince')) ? formErrors.permanentAddressResidenceSince : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'permanentAddressResidenceSince')) ? '!border-red-200' : ''}
                                    `}
                                    type="number"
                                    placeholder="No. of Years"
                                    max={100}
                                    value={(permanentAddressResidenceSince) ? permanentAddressResidenceSince : ''}
                                    onChange={(e) => {
                                        dispatch(setPermanentAddressResidenceSince(parseInt(e.target.value)));
                                        const { permanentAddressResidenceSince, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <input
                            type="checkbox"
                            className="w-5 h-5 uwi-input rounded-4xl border-2 border-gray-200 mr-4"
                            onChange={(e) => handlePermanentAddressCheckboxChange(e.target.checked) }
                            id="permanent_address_checkbox"
                        />
                        <label className="form-label" htmlFor="permanent_address_checkbox">
                            Same as Current Address
                        </label>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Previous Address"
                            className="md:w-[calc(50%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'previousAddress')}
                                errorMessage={(hasProp(formErrors, 'previousAddress')) ? formErrors.previousAddress : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'previousAddress')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Previous Address"
                                    maxLength={250}
                                    value={previousAddress}
                                    onChange={(e) => {
                                        dispatch(setPreviousAddress(e.target.value));
                                        const { previousAddress, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Home Ownership"
                            className="md:w-[calc(25%-10px)] mb-4 md:mb-0 md:mr-2.5"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'previousAddressHomeOwnership')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={previousAddressHomeOwnership}
                                onChange={(val) => {
                                    dispatch(setPreviousAddressHomeOwnership(val as string));
                                    const { previousAddressHomeOwnership, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {homeOwnershipList.map(homeOwnership => (
                                    <SelectOption
                                        key={homeOwnership}
                                        value={homeOwnership}
                                    >{homeOwnership}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>

                        <FormGroup
                            label="Years of Residence"
                            className="md:w-[calc(25%-10px)]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'previousAddressResidenceSince')}
                                errorMessage={(hasProp(formErrors, 'previousAddressResidenceSince')) ? formErrors.previousAddressResidenceSince : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'previousAddressResidenceSince')) ? '!border-red-200' : ''}
                                    `}
                                    type="number"
                                    placeholder="No. of Years"
                                    max={100}
                                    value={(previousAddressResidenceSince) ? previousAddressResidenceSince : ''}
                                    onChange={(e) => {
                                        dispatch(setPreviousAddressResidenceSince(parseInt(e.target.value)));
                                        const { previousAddressResidenceSince, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <input
                            type="checkbox"
                            className="w-5 h-5 uwi-input rounded-4xl border-2 border-gray-200 mr-4"
                            onChange={(e) => handlePreviousAddressCheckboxChange(e.target.checked) }
                            id="previous_address_checkbox"
                        />
                        <label className="form-label" htmlFor="previous_address_checkbox">
                            Same as Current Address
                        </label>
                    </div>
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>
            
            <FieldGroupBottomButtons
                onNext={handleNext}
            />
        </div>
    );
}

export default ContactInfo;
