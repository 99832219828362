import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/hooks/generic';
import {
    selectNationality,
    selectBirthdate,
    selectEmail,
    selectMobileNumber,
    selectCurrentRegion,
    selectMaritalStatus,
    selectVisaType
} from 'src/store/features/preApproveLoanOtherInfoSlice';

type Props = {
    className?: string;
    headerClassName?: string;
    listClassName?: string;
    listEntryClassName?: string;
    listEntryLeftClassName?: string;
    listEntryLeftValueClassName?: string;
};

const OtherInfo = ({
    className,
    headerClassName,
    listEntryLeftClassName,
    listEntryLeftValueClassName
}: Props) => {
    const nationality = useAppSelector(selectNationality);
    const birthdate = useAppSelector(selectBirthdate);
    const email = useAppSelector(selectEmail);
    const mobileNumber = useAppSelector(selectMobileNumber);
    const currentRegion = useAppSelector(selectCurrentRegion);
    const maritalStatus = useAppSelector(selectMaritalStatus);
    const visaType = useAppSelector(selectVisaType);

    const [birthdate2, setBirthdate2] = useState('');

    useEffect(() => {
        if (
            birthdate
            && typeof birthdate.year === 'number'
            && typeof birthdate.month === 'number'
            && typeof birthdate.day === 'number'
        ) {
            const date = new Date(birthdate.year, birthdate.month, birthdate.day);
            const formattedDate = new Intl.DateTimeFormat(
                'en-US',
                { year: 'numeric', month: 'long', day: 'numeric' }
            ).format(date);

            setBirthdate2(formattedDate);
        }
    }, [birthdate]);

    return (
        <div className={`w-full ${className || ''}`}>
            <h3 className={`text-base text-white bg-uwi-primary-100 py-4 font-arial font-bold mb-2 ${headerClassName || ''}`}>OTHER INFORMATION</h3>
            <table className="w-full !m-0 !border-none">
                <tbody>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Birthdate:</td>
                        <td className={listEntryLeftValueClassName || ''}>{birthdate2}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Nationality:</td>
                        <td className={listEntryLeftValueClassName || ''}>{nationality}</td>
                    </tr>

                    {(nationality !== 'Filipino') && (
                        <tr>
                            <td className={listEntryLeftClassName || ''}>Visa Type:</td>
                            <td className={listEntryLeftValueClassName || ''}>{visaType}</td>
                        </tr>
                    )}
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Marital Status:</td>
                        <td className={listEntryLeftValueClassName || ''}>{maritalStatus}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default OtherInfo;
