import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export interface PaymentReservation {
    id: string;
    developerId: string;
    userId: string;
    preQualificationId: string;
    subProjectId: string;
    referenceNumber: string;
    paymentMethod: string;
    paymentDate: Date;
    amount: string;
    subProjectName: string;
    subProjectImage: string;
    projectId: string;
    projectTitle: string;
    createdAt: Date;
    updatedAt: Date;
}

@Service()
export class PaymentReservationApi extends BaseApi {
    /**
     * API path for getting the list of payment reservations made by user.
     */
    static readonly PATH_LIST = '/v1/widget/payment-reservations/list';

    async list(): Promise<PaymentReservation[]> {
        const paymentReservations: PaymentReservation[] = await this.http.get(
            PaymentReservationApi.PATH_LIST,
            {},
            {},
            true
        );

        return paymentReservations.map(pm => ({
            ...pm,
            ...{
                paymentDate: new Date(pm.paymentDate),
                createdAt: new Date(pm.createdAt),
                updatedAt: new Date(pm.updatedAt)
            }
        }));
    }
}
