import { useMemo } from 'react';
import { SubProject } from 'src/api/SubProjectApi';
import { MagnifyingGlassSolidIcon } from 'src/components/icons';
import { useAppSelector } from 'src/hooks/generic';
import { selectSelectedSubProjects } from 'src/store/features/preApproveLoanPropertyInfoSlice';

type Props = {
    onView?: (subProject: SubProject) => void;
    subProject: SubProject;
};

const SubProjectUI = ({
    subProject,
    onView
}: Props) => {
    const selectedSubProjects = useAppSelector(selectSelectedSubProjects);

    const subProjectTotalQuantity = useMemo(() => {
        if (subProject) {
            return selectedSubProjects.reduce((prevVal, curVal) => {
                return prevVal + ((curVal.id === subProject.id) ? curVal.quantity : 0);
            }, 0);
        }

        return 0;
    }, [selectedSubProjects, subProject]);

    const handleView = onView || (() => {});

    return (
        <div
            className={`
                group relative w-[165px] sm:w-full sm:max-w-[223px] cursor-pointer rounded-xl
                shadow-[0px_0px_10px_2px_rgb(0,0,0,0.1)] md:shadow-[0px_0px_20px_2px_rgb(0,0,0,0.1)]
                hover:bg-uwi-primary-100
                ${(subProjectTotalQuantity > 0) ? 'bg-uwi-primary-100' : ''}
            `}
            onClick={() => handleView(subProject)}
        >
            {(subProjectTotalQuantity > 0) && (
                <div className="absolute top-2 right-2 rounded-full px-2 py-1 bg-red-100 text-white leading-none text-sm">
                    {subProjectTotalQuantity}
                </div>
            )}

            <img
                src={subProject.image}
                alt={`${subProject.name} actual location`}
                className="object-cover rounded-xl w-[165px] h-[97px] sm:w-[223px] sm:h-[130px]"
                width={223}
                height={130}
            />
            <div className="p-2 flex items-center gap-2 text-xs rounded-b-xl">
                <div className="w-4/5 flex-1">
                    <div
                        className={`
                            overflow-hidden whitespace-nowrap text-ellipsis
                            ${(subProjectTotalQuantity > 0) ? 'text-white' : 'text-black group-hover:text-white'}
                        `}
                    >{subProject.projectTitle}</div>
                    <div
                        className={`
                            overflow-hidden whitespace-nowrap text-ellipsis mt-1
                            ${(subProjectTotalQuantity > 0) ? 'text-white' : 'text-gray-50 group-hover:text-white'}
                        `}
                    >{subProject.name}</div>
                </div>
                <div className={`
                    ${(subProjectTotalQuantity > 0) ? 'text-white' : 'text-black group-hover:text-white'}
                `}>
                    <MagnifyingGlassSolidIcon className="h-6 w-6" />
                </div>
            </div>
        </div>
    );
}

export default SubProjectUI;
