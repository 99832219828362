import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import useToast from 'src/hooks/useToast';
import {
    selectFormErrors,
    setFormErrors,
    selectSelectedSubProjects
} from 'src/store/features/preApproveLoanPropertyInfoSlice';

const useProjectInfoScreen2Validation = () => {
    const toast = useToast();
    const dispatch = useAppDispatch();
    const formErrors = useAppSelector(selectFormErrors);
    const subProjects = useAppSelector(selectSelectedSubProjects);

    const validateForm = async () => {
        const errors:Record<string, string> = {};
        if (subProjects.length === 0) {
            errors.subProjects = 'Select at least 1 property.';

            toast(errors.subProjects, 'error');
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
}

export default useProjectInfoScreen2Validation;
