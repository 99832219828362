import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';
import { PreQualification } from './PreQualificationApi';

export interface PreQualificationForm {
    projectCost: number;
    projectType: string;
    projectStatus: string;
    developerName: string;
    projectRegion: string;
    workType: string;
    isRegularEmployee: boolean;
    isBusinessProfitable: boolean;
    workExperience: string;
    businessProprietorship?: string;
    monthlyIncome: number;
    nationality: string;
    visaType?: string;
    birthdate: string;
    maritalStatus: string;
    coBorrowerMonthlyIncome: number;
    hasCreditCard: boolean;
    hasCancelledCreditCard: boolean;
    hasFullPaymentCertificate: boolean;
    extraIncomeAmount: number;
    existingDebtAmount: number;
    loanTerm: number;
    percentOfProjectCost: number;
    ownedBanks: string[];
}

@Service()
export class BasicWidgetPreQualificationApi extends BaseApi {
    /**
     * API path to get the info of the pre qualification info.
     */
    static readonly PATH_GET_INFO = '/v1/widget/basic-widget-pre-qualification/$preQualificationId';

    /**
     * API path to save the pre qualification.
     */
    static readonly PATH_CREATE = '/v1/widget/basic-widget-pre-qualification/create';

    /**
     * API path to get the list of pre qualifications of the logged user.
     */
    static readonly PATH_LIST = '/v1/widget/basic-widget-pre-qualification/list';

    /**
     * API path to update the pre qualification.
     */
    static readonly PATH_UPDATE = '/v1/widget/basic-widget-pre-qualification/$preQualificationId';

    create(
        form: PreQualificationForm
    ): Promise<PreQualification> {
        const data = {
            ...form,
            ...{
                ownedBanks: JSON.stringify(form.ownedBanks)
            }
        };

        return this.http.post(
            BasicWidgetPreQualificationApi.PATH_CREATE,
            data,
            {},
            true
        );
    }

    async getInfo(preQualificationId: string): Promise<PreQualification> {
        const obj: PreQualification = await this.http.get(
            BasicWidgetPreQualificationApi.PATH_GET_INFO.replace(/\$preQualificationId/, preQualificationId),
            {},
            {},
            true
        );

        obj.birthdate = new Date(obj.birthdate);
        obj.createdAt = new Date(obj.createdAt);
        obj.updatedAt = new Date(obj.updatedAt);

        return obj;
    }

    async list(): Promise<PreQualification[]> {
        const rawList = await this.http.get(
            BasicWidgetPreQualificationApi.PATH_LIST,
            {},
            {},
            true
        );

        const list = rawList as PreQualification[];

        return list.map(req => {
            req.birthdate = new Date(req.birthdate);
            req.createdAt = new Date(req.createdAt);
            req.updatedAt = new Date(req.updatedAt);

            return req;
        });
    }

    update(
        id: string,
        form: PreQualificationForm
    ): Promise<{ status: boolean }> {
        const data = {
            ...form,
            ...{
                ownedBanks: JSON.stringify(form.ownedBanks)
            }
        };

        return this.http.put(
            BasicWidgetPreQualificationApi.PATH_UPDATE.replace(/\$preQualificationId/, id),
            data,
            {},
            true
        );
    }
}
