import { Project } from 'src/api/ProjectApi';
import CircleCheckSolidIcon from 'src/components/icons/CircleCheckSolidIcon';

type Props = {
    project: Project;
    isSelected?: boolean;
    onClick?: () => void;
};

const ProjectUI = ({
    project,
    isSelected,
    onClick
}: Props) => {
    const handleClick = onClick || (() => {});

    return (
        <div
            className={`
                group relative cursor-pointer
                rounded-xl mx-1 sm:mx-2 my-2 shadow-[0px_0px_20px_2px_rgb(0,0,0,0.1)]
                hover:bg-uwi-primary-100
            `}
            onClick={() => handleClick()}
        >
            <img
                src={project.image}
                alt={`${project.title} actual location`}
                className={`
                    object-cover rounded-xl w-[165px] h-[97px] sm:w-[228px] sm:h-[130px]
                `}
            />
            <div className="px-2 py-2 text-xs group-hover:text-white rounded-b-xl">
                <div
                    className={`
                        text-black group-hover:text-white
                        overflow-hidden whitespace-nowrap text-ellipsis
                        w-[148px] sm:w-[185px]
                    `}
                >{project.title}</div>
                <div
                    className={`
                        text-gray-50 group-hover:text-white
                        overflow-hidden whitespace-nowrap text-ellipsis mt-1
                        w-[148px] sm:w-[212px]
                    `}
                >{project.address}</div>
            </div>

            <div className="absolute bottom-[48px] right-[5px] xs:bottom-7 xs:right-3 z-[1]">
                <div
                    className={`
                        rounded-full bg-white shadow-[0px_0px_20px_5px_rgb(0,0,0,0.1)]
                        ${(isSelected) ? 'group-hover:bg-uwi-primary-100' : ''}
                    `}
                >
                    <CircleCheckSolidIcon
                        className={`
                            text-uwi-primary-100 w-[30px] h-[30px] xs:w-10 xs:h-10
                            ${(isSelected) ? 'visible group-hover:text-white' : 'invisible'}
                        `}
                    />
                </div>
            </div>

            {(project.title.length > 34 || project.address.length > 34) && (
                <div className="hidden group-hover:!block absolute top-[calc(100%+5px)] left-0 z-[2]">
                    <div
                        className={`
                            bg-uwi-primary-100 border-uwi-primary-200 rounded-xl
                            shadow-[0px_0px_20px_5px_rgb(0,0,0,0.1)]
                            p-3 text-xs
                            text-white
                        `}
                    >
                        <div className="text-white">{project.title}</div>
                        <div className="mt-1 text-white/80">{project.address}</div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default ProjectUI;