export function projectName(name: string) {
    const splitName = name.split('-');

    return (splitName.length > 0) ? splitName[0] : '';
}

export function subProjectName(name: string) {
    const splitName = name.split('-');

    return (splitName.length > 1) ? splitName[1] : '';
}

export function formatBookingTime(time: string) {
    const timePcs = time.split(':');

    return `${timePcs[0]}:${timePcs[1]}`;
}

export function formatBookingTimeTo12HourFormat(time: string) {
    const timePcs = time.split(':');

    const hours = parseInt(timePcs[0]);
    const mins = parseInt(timePcs[1]);

    const hourInfo = ((hours > 12) ? (hours - 12) : hours).toString().padStart(2, '0');

    return `${hourInfo}:${mins.toString().padStart(2, '0')} ${(hours >= 12) ? 'PM' : 'AM'}`;
}