import {
    useEffect,
    useState
} from 'react';
import { tween } from 'src/helpers/animation';

type Props = {
    dimension?: number;
    innerDimension?: number;
    contentClassName?: string;
    progress: number
};

export default function CircularProgressComponent({
    dimension,
    innerDimension,
    progress,
    contentClassName
}: Props) {
    const calcWidth = dimension || 100;
    const calcHeight = dimension || 100;
    const calcInnerWidth = innerDimension || 80;
    const calcInnerHeight = innerDimension || 80;
    const top = (calcWidth - calcInnerWidth) / 2;
    const left = (calcHeight - calcInnerHeight) / 2;

    const [leftProgressRotation, setLeftProgressRotation] = useState(0);
    const [rightProgressRotation, setRightProgressRotation] = useState(0);
    const [currentProgress, setCurrentProgress] = useState(0);

    useEffect(() => {
        if (!!currentProgress) {
            const rightProgress = (currentProgress < 50) ? (((currentProgress % 50) * 2) / 100) * 180 : 180;
            setRightProgressRotation(rightProgress);

            let leftProgress = (((currentProgress % 50) * 2) / 100) * 180;
            if (currentProgress < 50) {
                leftProgress = 0;
            }
            else if (currentProgress === 100) {
                leftProgress = 180;
            }

            setLeftProgressRotation(leftProgress);
        }
    }, [currentProgress]);

    useEffect(() => {
        if (!!progress) {
            tween(currentProgress, progress, 300, (newProgress) => {
                setCurrentProgress(Math.round(newProgress));
            });
        }
    }, [progress]);

    return (
        <div
            className="relative"
            style={{
                width: `${calcWidth}px`,
                height: `${calcHeight}px`
            }}
        >
            <div
                className="absolute bg-white rounded-full z-[4]"
                style={{
                    width: `${calcInnerWidth}px`,
                    height: `${calcInnerHeight}px`,
                    top: `${top}px`,
                    left: `${left}px`
                }}
            ></div>
            <div
                className={`
                    absolute flex justify-center items-center z-[5] text-2xl 
                    ${contentClassName || ''}
                    ${(currentProgress < 100) ? 'text-red-100' : 'text-green-100'}
                `}
                style={{
                    width: `${calcInnerWidth}px`,
                    height: `${calcInnerHeight}px`,
                    top: `${top}px`,
                    left: `${left}px`
                }}
            >{`${currentProgress}%`}</div>
            <div>
                <div
                    className="absolute w-full h-full rounded-full bg-red-300 rotate-180"
                    style={{ clip: `rect(0px, ${calcWidth}px, ${calcHeight}px, ${calcWidth / 2}px)` }}
                >
                    <div
                        className={`
                            absolute w-full h-full rounded-full z-[1] transition-transform
                            ${(currentProgress < 100) ? 'bg-red-100' : 'bg-green-100'}
                        `}
                        style={{
                            clip: `rect(0px, ${calcWidth / 2}px, ${calcHeight}px, 0px)`,
                            transform: `rotate(${leftProgressRotation}deg)`
                        }}
                    ></div>
                </div>
                <div
                    className="absolute w-full h-full rounded-full bg-red-300 z-[3]"
                    style={{ clip: `rect(0px, ${calcWidth}px, ${calcHeight}px, ${calcWidth/2}px)` }}
                >
                    <div
                        className={`
                            absolute w-full h-full rounded-full transition-transform
                            ${(currentProgress < 100) ? 'bg-red-100' : 'bg-green-100'}
                        `}
                        style={{
                            clip: `rect(0px, ${calcWidth / 2}px, ${calcHeight}px, 0px)`,
                            transform: `rotate(${rightProgressRotation}deg)`
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
}