type ProjectType = 'Apartment' | 'Condominium' | 'House & Lot' | 'Townhouse' | 'Vacant Lot';
type ProjectStatus = 'Foreclosed' | 'Pre-Selling' | 'Ready For Occupancy' | 'Reselling' | 'I Own It - Refinancing Loan' | 'I Own It - Home Equity' | 'New Construction' | 'Renovation';
type Address = 'NCR (National Capital Region)' | 'Region I (Ilocos Region)' | 'Region II (Cagayan Valley)' | 'Region III (Central Luzon)' | 'Region IV A (CALABARZON)' 
    | 'Region IV B (MIMAROPA)' | 'Region V (Bicol Region)' | 'Region VI (Western Visayas)' | 'Region VII (Central Visayas)' | 'Region VIII (Eastern Visayas)' | 'Region IX (Zamboanga Peninsula)' 
    | 'Region X (Northern Mindanao)' | 'Region XI (Davao Region)' | 'Region XII (SOCCSKSARGEN)' | 'Region XIII (Caraga)' | 'CAR (Cordillera Administrative Region)' 
    | 'BARMM (Bangsamoro Autonomous Region in Muslim Mindanao)' | 'Other';
type MaritalStatus = 'Legally Married' | 'Legally Single' | 'Legally Separate' | 'Marriage Annulled' | 'Separated (Legally Married)';

export interface SetPropertyInfoParams {
    projectCost?: number;
    projectType?: ProjectType;
    projectStatus?: ProjectStatus;
    projectAddress?: Address;
    developerName?: string;
}

export interface SetOtherInfoParams {
    birthDate?: string; // in YYYY-MM-DD format
    email?: string;
    mobileNumber?: string;
    maritalStatus?: MaritalStatus;
    currentAddress?: Address;
}

/**
 * How to call
 * 
 * if (window.UwiWidgetApi) {
 *   window
 *       .UwiWidgetApi
 *       .setPropertyInfo({
 *           projectCost: 2500000,
 *           projectType: 'Condominium',
 *           projectStatus: 'Ready For Occupancy',
 *           developerName: 'Amaia Skies Avenida',
 *           projectAddress: 'NCR (National Capital Region)'
 *       })
 *       .setOtherInfo({
 *           email: 'test@test.com',
 *           mobileNumber: '09112626010'
 *       })
 *       .open();
 * }
 */
interface UwiWidgetApi {
    setPropertyInfo(params: SetPropertyInfoParams): UwiWidgetApi;
    setOtherInfo(params: SetOtherInfoParams): UwiWidgetApi;
    open(): UwiWidgetApi;
}

export type UwiWidgetCallback = (event: string, params?: any) => void;

export class UwiWidgetApiImpl implements UwiWidgetApi {
    private readonly callback: UwiWidgetCallback;

    constructor(cb: UwiWidgetCallback) {
        this.callback = cb;
    }

    setPropertyInfo(params: SetPropertyInfoParams) {
        this.callback('setPropertyInfo', params);

        return this;
    }

    setOtherInfo(params: SetOtherInfoParams) {
        this.callback('setOtherInfo', params);

        return this;
    }

    open() {
        this.callback('open');

        return this;
    }

}
