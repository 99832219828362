type Props = {
  className?: string;
};

const UnionBankSmIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="18"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M10.352 8.37a108.36 108.36 0 00-.872-.107V6.167l1.138.269c.605.116.913.542.913 1.053 0 .512-.47.97-1.18.88zm2.437.866s.701-.675.701-1.947c0-1.062-.738-2.24-2.04-2.683l-4.277-1.47-.013 7.192s.001 1.042-1.198 1.262c-.802.147-1.585-.557-1.585-1.432.001 0 .005-5.668.001-7.991L1 1v9.468c0 3.382 3.166 4.813 5.111 4.487 2.322-.39 3.369-2.128 3.369-4.01v-.668l1.638.055c.542 0 .944.457.944.937 0 .426-.306.884-.937.937l-.9.067a5.128 5.128 0 01-1.16 2.159l2.638-.452C12.765 13.794 14 12.9 14 11.424c0-.63-.22-1.55-1.21-2.188z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default UnionBankSmIcon;