import { createContext } from 'react';

export interface IApplyContext {
    page: number;
    setPage: (page: number) => void;
}

const ApplyContext = createContext<IApplyContext>({
    page: 0,
    setPage: () => {}
});

export default ApplyContext;
