import {
    useEffect,
    useState
} from 'react';
import { EyeSlashSolidIcon, EyeSolidIcon } from 'src/components/icons';

type Props = {
    className?: string;
    attrs?: Record<string, unknown>;
    fieldClassName?: string;
    iconClassName?: string;
    onChange?: (val: string) => void;
    value?: string;
    focusedBorderClass?: string;
};

export default function PasswordField({
    className,
    attrs,
    fieldClassName,
    iconClassName,
    value,
    onChange,
    focusedBorderClass
}: Props) {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [passwordVal, setPasswordVal] = useState('');

    useEffect(() => {
        if (value) {
            setPasswordVal(value);
        }
    }, [value]);

    const handleChange = (val: string) => {
        setPasswordVal(val);
        if (onChange) {
            onChange(val);
        }
    };

    return (
        <div
            className={`
                flex flex-nowrap rounded-xl py-2 px-3 border border-gray-200 
                ${className}
                ${(isFocused) ? (focusedBorderClass || 'outline-gray-300 !outline-2 outline') : ''}
            `}
        >
            <input
                type={isPasswordShown ? 'text': 'password'}
                className={`
                    flex-grow mr-2 text-[15px] focus-visible:!outline-none !bg-transparent
                    !p-0 !border-none 
                    ${fieldClassName || ''}
                `}
                onChange={(e) => handleChange(e.target.value)}
                onFocus={(e) => setIsFocused(true)}
                onBlur={(e) => setIsFocused(false)}
                value={passwordVal}
                { ...attrs }
            />

            <button
                onClick={() => setIsPasswordShown(!isPasswordShown)}
                type="button"
                tabIndex={-1}
                className="hover:bg-transparent focus:bg-transparent"
            >
                {
                    (isPasswordShown)
                    ? <EyeSlashSolidIcon className={`text-gray-100 w-6 ${iconClassName || ''}`} />
                    : <EyeSolidIcon className={`text-gray-100 w-6 ${iconClassName || ''}`} />
                }
            </button>
        </div>
    );
}