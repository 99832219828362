import { configureStore } from '@reduxjs/toolkit';
import savedQuerySliceReducer from './features/savedQuerySlice';
import applyLoanPersonalInfoSliceReducer from './features/applyLoanPersonalInfoSlice';
import applyLoanContactInfoSliceReducer from './features/applyLoanContactInfoSlice';
import preApproveLoanPropertyInfoSliceReducer from './features/preApproveLoanPropertyInfoSlice';
import preApproveLoanWorkInfoSliceReducer from './features/preApproveLoanWorkInfoSlice';
import preApproveLoanOtherInfoSliceReducer from './features/preApproveLoanOtherInfoSlice';
import preApproveLoanDebtIncomeInfoSliceReducer from './features/preApproveLoanDebtIncomeInfoSlice';
import preApproveLoanLoanInfoSliceReducer from './features/preApproveLoanLoanInfoSlice';
import preApproveLoanStaticDataSliceReducer from './features/preApproveLoanStaticDataSlice';
import applyLoanEmploymentInfoSliceReducer from './features/applyLoanEmploymentInfoSlice';
import applyLoanOtherInfoSliceReducer from './features/applyLoanOtherInfoSlice';
import userSliceReducer from './features/userSlice';
import commonSliceReducer from './features/commonSlice';
import toastDataSliceReducer from './features/toastDataSlice';
import paymentSliceReducer from './features/paymentSlice';

const store = configureStore({
    reducer: {
        savedQuery: savedQuerySliceReducer,
        applyLoanPersonalInfo: applyLoanPersonalInfoSliceReducer,
        applyLoanContactInfo: applyLoanContactInfoSliceReducer,
        applyLoanEmploymentInfo: applyLoanEmploymentInfoSliceReducer,
        applyLoanOtherInfo: applyLoanOtherInfoSliceReducer,
        preApproveLoanPropertyInfo: preApproveLoanPropertyInfoSliceReducer,
        preApproveLoanWorkInfo: preApproveLoanWorkInfoSliceReducer,
        preApproveLoanOtherInfo: preApproveLoanOtherInfoSliceReducer,
        preApproveLoanDebtIncomeInfo: preApproveLoanDebtIncomeInfoSliceReducer,
        preApproveLoanLoanInfo: preApproveLoanLoanInfoSliceReducer,
        preApproveLoanStaticData: preApproveLoanStaticDataSliceReducer,
        user: userSliceReducer,
        common: commonSliceReducer,
        toast: toastDataSliceReducer,
        payment: paymentSliceReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;