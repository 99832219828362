import { useState } from 'react';
import { Container } from 'typedi';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { HomeLoanApplication, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import {
    selectDependents,
    selectProjectContactPersonName,
    selectProjectAddress,
    selectProjectContactPersonNumber,
    selectAttorneyInfactName,
    selectAttorneyInfactAddress,
    selectAttorneyInfactContactNumber,
    selectAttorneyInfactRelationship,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/applyLoanOtherInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const useOtherInfo = (homeLoanApp?: HomeLoanApplication) => {
    const { extractFormErrors } = useGenericErrorHandler();
    
    const dispatch = useAppDispatch();
    const selectedDependents = useAppSelector(selectDependents);
    const selectedProjectContactPersonName = useAppSelector(selectProjectContactPersonName);
    const selectedProjectAddress = useAppSelector(selectProjectAddress);
    const selectedProjectContactPersonNumber = useAppSelector(selectProjectContactPersonNumber);
    const selectedAttorneyInfactName = useAppSelector(selectAttorneyInfactName);
    const selectedAttorneyInfactAddress = useAppSelector(selectAttorneyInfactAddress);
    const selectedAttorneyInfactContactNumber = useAppSelector(selectAttorneyInfactContactNumber);
    const selectedAttorneyInfactRelationship = useAppSelector(selectAttorneyInfactRelationship);
    const formErrors = useAppSelector(selectFormErrors);

    const [isDirty, setIsDirty] = useState(false);

    const validateForm = () => {
        const errors:Record<string, string> = {};

        if (selectedProjectContactPersonName.trim().length > 0 && selectedProjectContactPersonName.trim().length < 2) {
            errors.projectContactPersonName = 'Contact Person must be longer than or equal to 2 characters';
        }

        if (selectedProjectAddress.trim().length > 0 && selectedProjectAddress.trim().length < 2) {
            errors.projectAddress = 'Project Address must be longer than or equal to 2 characters';
        }

        if (selectedProjectContactPersonNumber.trim().length > 0 && selectedProjectContactPersonNumber.trim().length < 2) {
            errors.projectContactPersonNumber = 'Contact Number must be longer than or equal to 2 characters';
        }

        if (selectedAttorneyInfactName.trim().length > 0 && selectedAttorneyInfactName.trim().length < 2) {
            errors.attorneyInfactName = 'Name must be longer than or equal to 2 characters';
        }

        if (selectedAttorneyInfactAddress.trim().length > 0 && selectedAttorneyInfactAddress.trim().length < 2) {
            errors.attorneyInfactAddress = 'Address must be longer than or equal to 2 characters';
        }

        if (selectedAttorneyInfactContactNumber.trim().length > 0 && selectedAttorneyInfactContactNumber.trim().length < 2) {
            errors.attorneyInfactContactNumber = 'Contact Number must be longer than or equal to 2 characters';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    const handleNext = async () => {
        if (!validateForm()) {
            return false;
        }

        if (!isDirty) {
            return true;
        }

        if (homeLoanApp) {
            try {
                const data: Record<string, unknown> = {
                    dependents: selectedDependents
                };

                if (selectedProjectContactPersonName) {
                    data.projectContactPersonName = selectedProjectContactPersonName;
                }

                if (selectedProjectAddress) {
                    data.projectAddress = selectedProjectAddress;
                }

                if (selectedProjectContactPersonNumber) {
                    data.projectContactPersonNumber = selectedProjectContactPersonNumber;
                }

                if (selectedAttorneyInfactName) {
                    data.attorneyInfactName = selectedAttorneyInfactName;
                }

                if (selectedAttorneyInfactAddress) {
                    data.attorneyInfactAddress = selectedAttorneyInfactAddress;
                }

                if (selectedAttorneyInfactContactNumber) {
                    data.attorneyInfactContactNumber = selectedAttorneyInfactContactNumber;
                }

                if (selectedAttorneyInfactRelationship) {
                    data.attorneyInfactRelationship = selectedAttorneyInfactRelationship;
                }

                await Container.get(HomeLoanApplicationApi).update(
                    homeLoanApp.id,
                    data
                );

                return true;
            }
            catch (ex) {
                const errors = extractFormErrors(ex);
                dispatch(setFormErrors(errors));
            }
        }

        return false;
    };

    return {
        validateForm,
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    };
};

export default useOtherInfo;