import { useState } from 'react';
import { Container } from 'typedi';
import { HomeLoanApplication, HomeLoanApplicationApi, HomeLoanApplicationForm } from 'src/api/HomeLoanApplicationApi';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectFirstName,
    selectMiddleName,
    selectLastName,
    selectNameSuffix,
    selectGender,
    selectPlaceOfBirth,
    selectEducationBG,
    selectCivilStatus,
    selectMotherMaidenName,
    selectSpouseFirstName,
    selectSpouseMiddleName,
    selectSpouseLastName,
    selectSpouseMotherMaidenName,
    selectSpouseBirthdate,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/applyLoanPersonalInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const usePersonalInfo = (homeLoanApp?: HomeLoanApplication) => {
    const { extractFormErrors } = useGenericErrorHandler();

    const dispatch = useAppDispatch();
    const selectedFirstName = useAppSelector(selectFirstName);
    const selectedMiddleName = useAppSelector(selectMiddleName);
    const selectedLastName = useAppSelector(selectLastName);
    const selectedNameSuffix = useAppSelector(selectNameSuffix);
    const selectedGender = useAppSelector(selectGender);
    const selectedPlaceOfBirth = useAppSelector(selectPlaceOfBirth);
    const selectedEducationBG = useAppSelector(selectEducationBG);
    const selectedCivilStatus = useAppSelector(selectCivilStatus);
    const selectedMotherMaidenName = useAppSelector(selectMotherMaidenName);
    const selectedSpouseFirstName = useAppSelector(selectSpouseFirstName);
    const selectedSpouseMiddleName = useAppSelector(selectSpouseMiddleName);
    const selectedSpouseLastName = useAppSelector(selectSpouseLastName);
    const selectedSpouseMotherMaidenName = useAppSelector(selectSpouseMotherMaidenName);
    const selectedSpouseBirthdate = useAppSelector(selectSpouseBirthdate);
    const formErrors = useAppSelector(selectFormErrors);

    const [isDirty, setIsDirty] = useState(false);

    const validateForm = () => {
        const errors:Record<string, string> = {};
        if (selectedFirstName.trim().length === 0) {
            errors.firstName = 'First Name is required';
        }

        if (selectedLastName.trim().length === 0) {
            errors.lastName = 'Last Name is required';
        }

        if (selectedGender === '' || selectedGender === 'male') {
            errors.gender = 'Gender is required';
        }

        console.log(selectedGender);

        if (selectedPlaceOfBirth === '') {
            errors.placeOfBirth = 'Place of Birth is required';
        }

        if (selectedEducationBG === '') {
            errors.educationalBg = 'Educational Background is required';
        }

        if (selectedCivilStatus === '') {
            errors.civilStatus = 'Civil Status is required';
        }

        if (selectedMotherMaidenName === '') {
            errors.motherMaidenName = 'Mother\'s Maiden Name is required';
        }

        if (selectedCivilStatus === 'Legally Married' || selectedCivilStatus === 'Separated (Legally Married)') {
            if (selectedSpouseFirstName === '') {
                errors.spouseFirstName = 'Spouse (First Name) is required';
            }

            if (selectedSpouseMiddleName === '') {
                errors.spouseMiddleName = 'Spouse (Middle Name) is required';
            }

            if (selectedSpouseLastName === '') {
                errors.spouseLastName = 'Spouse (Last Name) is required';
            }

            if (selectedSpouseMotherMaidenName === '') {
                errors.spouseMotherMaidenName = 'Spouse (Mother\'s Maiden Name) is required';
            }

            if (!selectedSpouseBirthdate) {
                errors.spouseBirthdate = JSON.stringify({
                    year: 'Birthdate year is required',
                    month: 'Birthdate month is required',
                    day: 'Birthdate day is required'
                });
            }

            if (selectedSpouseBirthdate) {
                const birthDateError: Record<string, string> = {};
                if (!selectedSpouseBirthdate.year || isNaN(selectedSpouseBirthdate.year)) {
                    birthDateError.year = 'Birthdate year is required';
                }

                if (typeof selectedSpouseBirthdate.month === 'undefined' || isNaN(selectedSpouseBirthdate.month)) {
                    birthDateError.month = 'Birthdate month is required';
                }

                if (!selectedSpouseBirthdate.day) {
                    birthDateError.day = 'Birthdate day is required';
                }

                if (Object.keys(birthDateError).length > 0) {
                    errors.spouseBirthdate = JSON.stringify(birthDateError);
                }
            }
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    const handleNext = async () => {
        if (!validateForm()) {
            return false;
        }

        if (!isDirty) {
            return true;
        }

        if (homeLoanApp) {
            try {
                let spouseBirthdate = new Date();
                if (
                    selectedSpouseBirthdate
                    && typeof selectedSpouseBirthdate.year === 'number'
                    && typeof selectedSpouseBirthdate.month === 'number'
                    && typeof selectedSpouseBirthdate.day === 'number'
                ) {
                    spouseBirthdate = new Date(selectedSpouseBirthdate.year, selectedSpouseBirthdate.month, selectedSpouseBirthdate.day);
                }

                const data: HomeLoanApplicationForm = {
                    firstName: selectedFirstName,
                    middleName: selectedMiddleName,
                    lastName: selectedLastName,
                    nameSuffix: selectedNameSuffix,
                    gender: selectedGender,
                    placeOfBirth: selectedPlaceOfBirth,
                    educationalBg: selectedEducationBG,
                    civilStatus: selectedCivilStatus,
                    motherMaidenName: selectedMotherMaidenName
                };

                if (selectedCivilStatus === 'Legally Married' || selectedCivilStatus === 'Separated (Legally Married)') {
                    data.spouseFirstName = selectedSpouseFirstName;
                    data.spouseMiddleName = selectedSpouseMiddleName;
                    data.spouseLastName = selectedSpouseLastName;
                    data.spouseMotherMaidenName = selectedSpouseMotherMaidenName;
                    data.spouseBirthdate = spouseBirthdate;
                }

                await Container.get(HomeLoanApplicationApi).update(
                    homeLoanApp.id,
                    data
                );

                return true;
            }
            catch (ex) {
                const errors = extractFormErrors(ex);
                dispatch(setFormErrors(errors));
            }
        }

        return false;
    };

    return {
        validateForm,
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    };
}

export default usePersonalInfo;