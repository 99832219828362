import { FormGroup, Tooltip } from 'src/components/common';

type Props = {
    label: string;
    children: JSX.Element;
    tooltipText: string;
    showTooltip?: boolean;
    className?: string;
};

const PreApprovalFormGroup = ({
    children,
    label,
    tooltipText,
    showTooltip,
    className
}: Props) => {

    return (
        <FormGroup
            label={label}
            className={`mb-4 ${className || ''}`}
            showTooltip={showTooltip}
            tooltipText={tooltipText}
        >
            {children}
        </FormGroup>
    );
};

export default PreApprovalFormGroup;
