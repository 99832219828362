import { useEffect, useMemo, useState } from 'react';
import { Container } from 'typedi';
import { CircularProgressComponent, InputFile, Tooltip } from 'src/components/common';
import { HomeLoanApplicationDocument, HomeLoanApplicationDocumentApi } from 'src/api/HomeLoanApplicationDocumentApi';
import useWindowSize from 'src/hooks/useWindowSize';
import { AngleDownSolidIcon, FileLinesSolidIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { useAppSelector } from 'src/hooks/generic';
import { selectContactEmail } from 'src/store/features/commonSlice';

interface DocsObj {
    file: File|null;
    doc: HomeLoanApplicationDocument;
}

type Props = {
    className?: string;
    title: string;
    helperText: string;
    docs: HomeLoanApplicationDocument[];
};

const FieldDocuments = ({
    className,
    title,
    helperText,
    docs
}: Props) => {
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const contactEmail = useAppSelector(selectContactEmail);
    
    const { width } = useWindowSize();
    const [progressRefreshKey, setProgressRefreshKey] = useState(`progressRefreshKey_${Math.random()}`);
    const [documents, setDocuments] = useState<DocsObj[]>([]);
    const [isDetailsShown, setIsDetailsShown] = useState(false);

    const optionalDocs = useMemo(() => {
        return docs.filter(val => val.isOptional);
    }, [docs]);

    const notOptionalDocs = useMemo(() => {
        return docs.filter(val => (val.isOptional === false));
    }, [docs]);

    const optionalGroups = useMemo(() => {
        const groups: string[] = [];
        optionalDocs.forEach(doc => {
            if (!groups.includes(doc.optionalGroup)) {
                groups.push(doc.optionalGroup);
            }
        });

        return groups;
    }, [optionalDocs]);

    const progress = useMemo(() => {
        if (optionalDocs && notOptionalDocs && optionalGroups) {
            const totalCnt = notOptionalDocs.length + optionalGroups.length;

            const notOptionalDocsDoneCnt = notOptionalDocs.reduce((preVal, curVal) => {
                const val = (curVal.status === 'done') ? 1 : 0;
                return preVal + val;
            }, 0);

            const countedList: string[] = [];
            const optionalDocsDoneCnt = optionalDocs.reduce((preVal, curVal) => {
                let val = 0;
                if (curVal.status === 'done' && !countedList.includes(curVal.optionalGroup)) {
                    countedList.push(curVal.optionalGroup);
                    val = 1;
                }

                return preVal + val;
            }, 0);

            const doneCnt = notOptionalDocsDoneCnt + optionalDocsDoneCnt;
            return Math.round((doneCnt / totalCnt) * 100);
        }

        return 0;
    }, [optionalDocs, notOptionalDocs, optionalGroups, progressRefreshKey]);

    useEffect(() => {
        if (docs) {
            setDocuments(
                docs.map(doc => ({
                    file: null,
                    doc
                }))
            );
        }
    }, [optionalDocs, notOptionalDocs, docs]);

    const handleChangeFile = (file: File|null, idx: number) => {
        const docs = [...documents];

        if (idx >= 0 && idx < docs.length) {
            docs[idx].doc.status = 'pending';
            docs[idx].file = file;
            setDocuments(docs);

            setProgressRefreshKey(`progressRefreshKey_${Math.random()}`);

            if (file !== null) {
                Container
                    .get(HomeLoanApplicationDocumentApi)
                    .update(docs[idx].doc.id, file)
                    .then(() => {
                        const docs = [...documents];
        
                        docs[idx].doc.status = 'done';
                        setDocuments(docs);

                        setProgressRefreshKey(`progressRefreshKey_${Math.random()}`);
                    })
                    .catch(ex => {
                        docs[idx].doc.status = 'waiting';
                        setDocuments(docs);

                        errorHandlerWithToast(ex);
                    });
            }
        }
    };

    return (
        <div className={`w-full flex flex-wrap ${className || ''}`}>
            <div className={`
                w-full lg:w-[250px]
                flex justify-center lg:flex-col 
                py-4 lg:py-8 px-4 lg:mr-4
                bg-gray-25
                rounded-t-xl lg:rounded-tr-none lg:rounded-l-xl
                ${(isDetailsShown) ? '' : 'rounded-b-xl'}
            `}>
                <div
                    className={`
                        text-uwi-primary-100
                        text-base font-bold
                        w-full lg:mb-8
                        flex items-center lg:justify-center
                    `}
                >
                    {title}
                </div>

                <div className="w-full flex justify-end lg:justify-center lg:mb-8">
                    <CircularProgressComponent
                        progress={progress}
                        dimension={(width >= 1024) ? 160 : 40}
                        innerDimension={(width >= 1024) ? 130 : 34}
                        contentClassName={(width >= 1024) ? 'text-4xl' : 'text-xs'}
                    />

                    <button
                        className="lg:hidden ml-3"
                        onClick={() => setIsDetailsShown(!isDetailsShown)}
                    >
                        <AngleDownSolidIcon
                            className={`
                                ${(isDetailsShown) ? 'rotate-180' : ''}
                                h-5 w-5
                            `}
                        />
                    </button>
                </div>

                <p className="hidden lg:!block lg:!visible text-xs w-full text-center">{helperText}</p>
            </div>

            <div className={`
                w-full lg:w-[calc(100%-266px)] lg:flex-grow
                lg:min-h-[300px] lg:max-h-[1000px]
                px-3 lg:px-0
                transition-all overflow-hidden
                border border-t-0 border-solid rounded-b-xl lg:border-0 border-gray-25
                bg-[#fafafa] lg:bg-white
                ${(isDetailsShown) ? 'max-h-[1000px]' : 'max-h-[0px]'}
            `}>
                <table className="w-full mt-4 mb-4 lg:mb-0 !border-none">
                    <thead className="border-b mb-4 hidden md:!table-row-group md:!visible">
                        <tr>
                            <td className="w-[50px] border-0 border-none p-0 text-center text-[15px] !py-4 whitespace-nowrap">S. No.</td>
                            <td className="w-[calc(45%-90px)] border-0 border-none p-0 text-[15px] py-4">Document Name</td>
                            <td className="w-[calc(30%-90px)] text-center border-0 border-none p-0 text-[15px] !py-4">Attachment</td>
                            <td className="w-[100px] text-center border-0 border-none p-0 text-[15px] !py-4">Status</td>
                            <td className="w-[30px] text-center py-2">Action</td>
                        </tr>
                    </thead>
                    <tbody className="pt-4">
                        {documents.map((doc, idx) => (
                            <tr
                                key={`${doc.doc.id}_idx`}
                            >
                                <td className="text-center text-sm align-baseline !py-3 md:align-middle border-0 border-none p-0 md:!mt-0 md:!py-2">
                                    { (idx + 1) }
                                </td>
                                <td className="text-sm border-0 border-none p-0 !py-2 !pl-2 lg:!pl-0 md:align-middle">
                                    <div className="flex items-center justify-center gap-2">
                                        <div className="flex-grow">
                                            { doc.doc.documentName }
                                        </div>
                                        {(doc.doc.isNeededWetSignature) && (
                                            <Tooltip
                                                text={`Please contact ${contactEmail} to facilitate physical submission of this document`}
                                                iconClassName="!text-green-100"
                                                iconType="question"
                                            />
                                        )}
                                        <Tooltip
                                            text={doc.doc.helperText}
                                        />
                                    </div>

                                    <div className="flex md:hidden justify-center border-0 border-none p-0 items-center mt-2 md:align-middle">
                                        <div className="flex-grow mr-2.5">
                                            <InputFile
                                                onChange={(file) => handleChangeFile(file, idx)}
                                                value={doc.doc.attachmentName}
                                                accept=".jpg,.jpeg,.png,.pdf"
                                            />
                                        </div>

                                        <div>
                                            { doc.doc.status }
                                        </div>
                                    </div>
                                </td>
                                <td className="hidden md:!table-cell md:!visible border-0 border-none p-0 text-center text-sm !py-2 !px-2 md:align-middle">
                                    <div className="flex items-center justify-center">
                                        <InputFile
                                            onChange={(file) => handleChangeFile(file, idx)}
                                            value={doc.doc.attachmentName}
                                            accept=".jpg,.jpeg,.png,.pdf"
                                        />
                                    </div>
                                </td>
                                <td
                                    className={`
                                        hidden md:!table-cell md:!visible
                                        text-center text-sm !py-2 capitalize
                                        border-0 border-none p-0
                                        md:align-middle
                                        ${(doc.doc.status === 'pending') ? 'text-red-100' : ''}
                                    `}
                                >
                                    { doc.doc.status }
                                </td>
                                <td
                                    className={`
                                        hidden md:!table-cell md:!visible
                                        text-center text-sm !py-2
                                        border-0 border-none p-0
                                        md:align-middle
                                    `}
                                >
                                    {(doc.doc.formUrl && doc.doc.formUrl.length > 0) && (
                                        <div
                                            className="flex justify-center"
                                        >
                                            <a
                                                className="text-green-100 hover:text-green-200"
                                                target="_blank"
                                                rel="noreferrer"
                                                title="Download Form"
                                                href={doc.doc.formUrl}
                                            >
                                                <FileLinesSolidIcon className="w-5 h-5" />
                                            </a>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FieldDocuments;
