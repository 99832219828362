import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

@Service()
export class ForgotPasswordApi extends BaseApi {
    /**
     * API path for creating a forgot password and sending the forgot password key to email.
     */
    static readonly PATH_FORGOT_PASSWORD = '/v1/widget/forgot-password';

     /**
      * API path to change the password by using a forgot password key.
      */
    static readonly PATH_CHANGE_PASSWORD = '/v1/widget/change-password';

    forgotPassword(email: string): Promise<string> {
        return this.http.post(
            ForgotPasswordApi.PATH_FORGOT_PASSWORD,
            { email }
        );
    }

    changePassword(email: string, password: string, otp: string): Promise<string> {
        return this.http.post(
            ForgotPasswordApi.PATH_CHANGE_PASSWORD,
            { email, password, otp }
        );
    }
}
