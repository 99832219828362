import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export interface Invoice {
    external_id: string;
    amount: number;
    payer_email: string;
    description: string;
    invoice_url: string;
    expiry_date: string;
}

export interface PaymentInfo {
    amount: number;
    referenceId: string;
    paidProperties?: PaidProperty[];
}

export interface PaidProperty {
    subProjectId: string,
    amount: number,
    referenceId: string
}

export interface Payment {
    referenceId: string;
    mobileUrl: string;
    desktopUrl: string;
    amount: string;
    channelCode: string;
    paymentDate: string;
}

export interface BankLink {
    url: string;
    customerId: string;
}

export interface BankAccount {
    id: string;
    accountDetails: string;
    accountType: string;
    currency: string;
    channelCode: string;
}

export interface DirectDebitPayment {
    id: string;
    paymentMethodId: string;
    OtpMobileNumber: string;
}

export interface DirectDebitConfirm {
    referenceId: string;
    amount: string;
    currency: string;
    channelCode: string;
    paymentDate: string;
}

@Service()
export class PaymentApi extends BaseApi {
    /**
     * API path for getting payment info
     */
    static readonly PATH_PAYMENT_INFO = '/v1/widget/payment/info/$preQualificationId';

    /**
     * API path for generating a invoice link
     */
    static readonly PATH_BOOKING_PAYMENT = '/v1/widget/payment/link/$paymentOption';

    /**
     * API path for e-wallet payment
     */
    static readonly PATH_EWALLET = '/v1/widget/payment/e-wallet/$paymentOption';

    /**
     * API path for getting the redirect url for a bank
     */
    static readonly PATH_BANK_REDIRECT = '/v1/widget/payment/direct-debit/$bank/link';

    /**
     * API path for getting the redirect url for a bank
     */
    static readonly PATH_ACCOUNTS = '/v1/widget/payment/direct-debit/$token';

    /**
     * API path for getting the redirect url for a bank
     */
    static readonly PATH_DIRECT_DEBIT_PAY = '/v1/widget/payment/direct-debit/pay';

    /**
     * API path for confirming direct debit
     */
    static readonly PATH_DIRECT_DEBIT_CONFIRM = '/v1/widget/payment/direct-debit/confirm';

    /**
     * API path charging a credit card
     */
    static readonly PATH_CREDIT_CARD_CHARGE = '/v1/widget/payment/credit-card';

    paymentInfo(preQualificationId: string): Promise<PaymentInfo> {
        return this.http.get(
            PaymentApi.PATH_PAYMENT_INFO.replace(/\$preQualificationId/, preQualificationId),
            {},
            {},
            true
        );
    }

    bookingPayment(paymentOption: string): Promise<Invoice> {
        return this.http.get(
            PaymentApi.PATH_BOOKING_PAYMENT.replace(/\$paymentOption/, paymentOption),
            {},
            {},
            true
        );
    }

    eWalletpayment(
        paymentOption: string,
        subProjectId: string,
        preQualificationId: string,
        currentUrl: string
    ): Promise<Payment> {

        return this.http.post(
            PaymentApi.PATH_EWALLET.replace(/\$paymentOption/, paymentOption),
            {
                subProjectId,
                preQualificationId,
                currentUrl
            },
            {},
            true
        );
    }

    bankRedirect(
        bank: string,
        subProjectId: string,
        preQualificationId: string,
        currentUrl: string
    ): Promise<BankLink> {
        return this.http.post(
            PaymentApi.PATH_BANK_REDIRECT.replace(/\$bank/, bank),
            {
                subProjectId,
                preQualificationId,
                currentUrl
            },
            {},
            true
        );
    }

    accounts(token: string | null): Promise<BankAccount[]> {
        return this.http.get(
            PaymentApi.PATH_ACCOUNTS.replace(/\$token/, (token ? token : '')),
            {},
            {},
            true
        );
    }

    directDebitPay(data: {
        customerId: string,
        linkedAccountToken: string,
        bank: string,
        paymentMethodId: string
    }, idempotenceKey: string): Promise<DirectDebitPayment> {
        return this.http.post(
            PaymentApi.PATH_DIRECT_DEBIT_PAY,
            data,
            {
                'x-idempotence-key': idempotenceKey
            },
            true
        );
    }

    directDebitConfirm(data: {
        otp: string,
        directDebitId: string,
        preQualificationId: string,
        subProjectId: string,
    }): Promise<DirectDebitConfirm> {
        return this.http.post(
            PaymentApi.PATH_DIRECT_DEBIT_CONFIRM,
            data,
            {},
            true
        );
    }

    creditCardCharge(data: {
        preQualificationId: string,
        subProjectId: string,
        token: string,
        referenceId: string,
        amount: number
    }): Promise<DirectDebitConfirm> {
        return this.http.post(
            PaymentApi.PATH_CREDIT_CARD_CHARGE,
            data,
            {},
            true
        );
    }



}
