import { useEffect, useMemo } from 'react';
import { Project } from 'src/api/ProjectApi';
import { FormGroup, Select, SelectOption } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectProjects,
    selectProjectIds,
    setProjectIds,
    setProjectStatuses,
    setProjectTypes,
    selectCities,
    selectCity,
    setCity
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import ProjectUI from '../fields/ProjectUI';
import usePropertyInfoScreen1Validation from '../validations/useProjectInfoScreen1Validation';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import PreApprovalFormGroup from './PreApprovalFormGroup';

const ProjectInfoScreen1 = () => {
    const dispatch = useAppDispatch();
    const { validateForm } = usePropertyInfoScreen1Validation();

    const projects = useAppSelector(selectProjects);
    const projectIds = useAppSelector(selectProjectIds);
    const cities = useAppSelector(selectCities);
    const city = useAppSelector(selectCity);

    useEffect(() => {
        dispatch(setProjectStatuses([]));
        dispatch(setProjectTypes([]));
    }, []);

    const onNext = async () => {
        const isValid = await validateForm();

        if (!isValid) {
            return false;
        }

        return true;
    };

    const onClickProject = (projectId: string) => {
        const projIds = [...projectIds];

        const index = projIds.indexOf(projectId);
        if (index > -1) {
            projIds.splice(index, 1);
        }
        else {
            projIds.push(projectId);
        }

        dispatch(setProjectIds(projIds));
    }

    const selectedProjects = useMemo(() => {
        const rawProjects: Project[] = [];
        projects.forEach((p) => {
            if (p.city === city) {
                rawProjects.push(p);
            }
        });

        return rawProjects;
    }, [city, projects]);

    return (
        <>
            <div className="w-full px-4">
                <div className="w-full flex flex-col items-center mb-8">
                    <div className="w-full sm:w-[350px] gap-2">
                        <PreApprovalFormGroup
                            label="Select City"
                            tooltipText="Select City"
                        >
                            <Select
                                className="w-full"
                                placeholder="Please Select"
                                value={city}
                                onChange={(val) => {
                                    dispatch(setCity(val as string));
                                }}
                            >
                                {cities.map(cityOption => (
                                    <SelectOption
                                        key={cityOption}
                                        value={cityOption}
                                    >
                                        {cityOption}
                                    </SelectOption>
                                ))}
                            </Select>
                        </PreApprovalFormGroup>
                    </div>
                    <FormGroup
                        label="Select Projects"
                        contentsWrapperClassName="flex flex-wrap justify-center"
                    >
                        {selectedProjects.map(project => (
                            <ProjectUI
                                key={project.id}
                                project={project}
                                onClick={() => onClickProject(project.id)}
                                isSelected={projectIds.includes(project.id)}
                            />
                        ))}
                    </FormGroup>
                </div>

                <FieldGroupBottomButtons
                    onNext={onNext}
                />
            </div>
        </>
    );
}

export default ProjectInfoScreen1;
