import { FormFieldWrapper, FormGroup, Select, SelectOption } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { HomeLoanApplication } from 'src/api/HomeLoanApplicationApi';
import {
    setDependents,
    setProjectContactPersonName,
    setProjectAddress,
    setProjectContactPersonNumber,
    setAttorneyInfactName,
    setAttorneyInfactAddress,
    setAttorneyInfactContactNumber,
    setAttorneyInfactRelationship,
    selectDependents,
    selectProjectContactPersonName,
    selectProjectAddress,
    selectProjectContactPersonNumber,
    selectAttorneyInfactName,
    selectAttorneyInfactAddress,
    selectAttorneyInfactContactNumber,
    selectAttorneyInfactRelationship
} from 'src/store/features/applyLoanOtherInfoSlice';
import relationships from 'src/data/relationships.json';
import FieldDependents from '../fields/FieldDependents';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import useOtherInfo from '../hooks/useOtherInfo';
import { hasProp } from 'src/helpers/object';

type Props = {
    homeLoanApp?: HomeLoanApplication;
};

const OtherInfo = ({
    homeLoanApp
}: Props) => {
    const dispatch = useAppDispatch();
    const {
        formErrors,
        setFormErrors,
        setIsDirty,
        handleNext
    } = useOtherInfo(homeLoanApp);

    const dependents = useAppSelector(selectDependents);
    const projectContactPersonName = useAppSelector(selectProjectContactPersonName);
    const projectAddress = useAppSelector(selectProjectAddress);
    const projectContactPersonNumber = useAppSelector(selectProjectContactPersonNumber);
    const attorneyInfactName = useAppSelector(selectAttorneyInfactName);
    const attorneyInfactAddress = useAppSelector(selectAttorneyInfactAddress);
    const attorneyInfactContactNumber = useAppSelector(selectAttorneyInfactContactNumber);
    const attorneyInfactRelationship = useAppSelector(selectAttorneyInfactRelationship);

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[600px] md:w-[700px]">
                    <h3 className="block text-uwi-primary-100 text-center font-arial">DEPENDENTS</h3>
                    <div className="text-xs text-gray-50 text-center">(Optional)</div>

                    <FieldDependents
                        className="mb-4"
                        value={dependents}
                        onChange={(val) => {
                            dispatch(setDependents(val));
                            const { dependents, ...others } = formErrors;
                            dispatch(setFormErrors(others));
                            setIsDirty(true);
                        }}
                    />

                    <h3 className="block text-uwi-primary-100 text-center font-arial">PROJECT INFORMATION</h3>
                    <div className="text-xs text-gray-50 text-center">(Optional)</div>

                    <div className="flex flex-wrap mb-4 mt-2">
                        <FormGroup
                            label="Contact Person"
                            className="w-full mb-4 md:w-[calc((100%-20px)/2)] md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'projectContactPersonName')}
                                errorMessage={(hasProp(formErrors, 'projectContactPersonName')) ? formErrors.projectContactPersonName : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'projectContactPersonName')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Full Legal Name"
                                    maxLength={150}
                                    value={projectContactPersonName}
                                    onChange={(e) => {
                                        dispatch(setProjectContactPersonName(e.target.value));
                                        const { projectContactPersonName, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Project Address"
                            className="w-full mb-4 md:w-[calc((100%-20px)/4)] md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'projectAddress')}
                                errorMessage={(hasProp(formErrors, 'projectAddress')) ? formErrors.projectAddress : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'projectAddress')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Address"
                                    maxLength={250}
                                    value={projectAddress}
                                    onChange={(e) => {
                                        dispatch(setProjectAddress(e.target.value));
                                        const { projectAddress, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Contact Number"
                            className="w-full md:w-[calc((100%-20px)/4)]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'projectContactPersonNumber')}
                                errorMessage={(hasProp(formErrors, 'projectContactPersonNumber')) ? formErrors.projectContactPersonNumber : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'projectContactPersonNumber')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Contact Number"
                                    maxLength={30}
                                    value={projectContactPersonNumber}
                                    onChange={(e) => {
                                        dispatch(setProjectContactPersonNumber(e.target.value));
                                        const { projectContactPersonNumber, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    <h3 className="block text-uwi-primary-100 text-center font-arial">LOAN ADMIN / ATTORNEY INFACT</h3>
                    <div className="text-xs text-gray-50 text-center">(Optional)</div>

                    <div className="flex flex-wrap mb-4 mt-2">
                        <FormGroup
                            label="Name"
                            className="w-full md:w-[calc((66.6666666667%-5px))] mb-2.5 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'attorneyInfactName')}
                                errorMessage={(hasProp(formErrors, 'attorneyInfactName')) ? formErrors.attorneyInfactName : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'attorneyInfactName')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Full Legal Name"
                                    maxLength={150}
                                    value={attorneyInfactName}
                                    onChange={(e) => {
                                        dispatch(setAttorneyInfactName(e.target.value));
                                        const { attorneyInfactName, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Relationship"
                            className="w-full md:w-[calc((33.33333333%-5px))]"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'attorneyInfactRelationship')) ? 'border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={attorneyInfactRelationship}
                                onChange={(val) => {
                                    dispatch(setAttorneyInfactRelationship(val as string));
                                    const { attorneyInfactRelationship, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {relationships.map(relationship => (
                                    <SelectOption
                                        key={relationship}
                                        value={relationship}
                                    >{relationship}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>
                    </div>
                    <div className="flex flex-wrap">
                        <FormGroup
                            label="Address"
                            className="w-full md:w-[calc((66.6666666667%-5px))] mb-2.5 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'attorneyInfactAddress')}
                                errorMessage={(hasProp(formErrors, 'attorneyInfactAddress')) ? formErrors.attorneyInfactAddress : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'attorneyInfactAddress')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Enter Address"
                                    maxLength={250}
                                    value={attorneyInfactAddress}
                                    onChange={(e) => {
                                        dispatch(setAttorneyInfactAddress(e.target.value));
                                        const { attorneyInfactAddress, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>

                        <FormGroup
                            label="Contact Number"
                            className="w-full md:w-[calc((33.33333333%-5px))]"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'attorneyInfactContactNumber')}
                                errorMessage={(hasProp(formErrors, 'attorneyInfactContactNumber')) ? formErrors.attorneyInfactContactNumber : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'attorneyInfactContactNumber')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Contact Number"
                                    maxLength={30}
                                    value={attorneyInfactContactNumber}
                                    onChange={(e) => {
                                        dispatch(setAttorneyInfactContactNumber(e.target.value));
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8 mt-2">*all fields are required unless indicated as optional</div>
            </div>

            <FieldGroupBottomButtons
                onNext={handleNext}
            />
        </div>
    );
}

export default OtherInfo;