import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectLoanTerm,
    selectPercentOfProjectCost,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/preApproveLoanLoanInfoSlice';

const useLoanInfoValidation = () => {
    const dispatch = useAppDispatch();
    const loanTerm = useAppSelector(selectLoanTerm);
    const selectedPercentOfProjectCost = useAppSelector(selectPercentOfProjectCost);
    const formErrors = useAppSelector(selectFormErrors);

    const validateForm = async () => {
        const errors:Record<string, string> = {};

        if (loanTerm <= 0) {
            errors.loanTerm = 'Loan term should be greater than 0.';
        }

        if (isNaN(loanTerm)) {
            errors.loanTerm = 'Loan term is required.';
        }

        if (selectedPercentOfProjectCost <= 0) {
            errors.percentOfProjectCost = 'Percent Of Project Cost should be greater than 0.';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
};

export default useLoanInfoValidation;