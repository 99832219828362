import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'src/helpers/string';
import useAuth from 'src/hooks/useAuth';
import { HomeLoanApplicationView, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import {
    MagnifyingGlassSolidIcon,
    CircleLoaderIcon,
    CirclePlusSolidIcon
} from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { formatDateToYYYYMMDD } from 'src/helpers/date';

const DashboardHomeLoanApplicationPage = () => {
    const navigate = useNavigate();
    const { user, hasLoadedFromStorage } = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const [isLoading, setIsLoading] = useState(true);
    const [homeLoanApps, setHomeLoanApps] = useState<HomeLoanApplicationView[]>([]);

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent('/dashboard_home_loan_applications')}`);
        }
    }, [user, hasLoadedFromStorage, navigate]);

    useEffect(() => {
        setIsLoading(true);

        Container
            .get(HomeLoanApplicationApi)
            .list()
            .then(apps => setHomeLoanApps(apps))
            .catch(errorHandlerWithToast)
            .finally(() => {
                setIsLoading(false);
            });
    }, [errorHandlerWithToast]);

    return (
        <>
            <div className="w-full bg-uwi-content-100 text-black rounded-t-3xl min-h-[456px] shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                        <div className="flex mb-4 pb-4 border-b border-solid border-gray-50">
                            <div className="w-3/4 flex items-baseline flex-wrap md:flex-nowrap">
                                <div className="text-xl text-uwi-primary-100 mr-1 font-bold">Hi {(user) ? user.firstName : ''}!</div>
                                <div className="text-sm text-black mt-1 md:mt-0">Here are your home loan applications below.</div>
                            </div>
                            <div className="w-1/4 flex justify-end">
                                <button
                                    className={`
                                        flex justify-center items-center
                                        text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent 
                                    `}
                                    onClick={() => navigate('/pre-qualification')}
                                >
                                    <CirclePlusSolidIcon className="w-6 h-6 mr-1.5" />
                                    <span className="text-sm hidden md:!inline-block md:!visible">New Home Loan</span>
                                    <span className="text-sm !inline-block md:!hidden">New</span>
                                </button>
                            </div>
                        </div>

                        <table className="w-full !m-0 !border-none">
                            <thead className="border-b border-solid border-gray-50">
                                <tr>
                                    <th className="font-normal text-left text-sm border-0 border-none p-0 pb-4 pl-4 whitespace-nowrap lg:w-[200px]">Date</th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap lg:w-[300px]">Reference No.</th>
                                    <th className="hidden md:!table-cell md:!visible font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap lg:w-[200px]">Loan Amount</th>
                                    <th className="hidden md:!table-cell md:!visible font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap lg:w-[150px]">Status</th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Action</th>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {(isLoading) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none !py-12 !px-6 whitespace-nowrap"
                                        >
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white inline-block" />
                                            &nbsp;Fetching records...
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading && homeLoanApps.length === 0) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none !py-12 !px-6 whitespace-nowrap"
                                        >
                                            No records found
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading) && (
                                    homeLoanApps.map(hla => (
                                        <tr
                                            key={hla.id}
                                            className={`w-full h-full items-center even:bg-gray-75`}
                                        >
                                            <td className="text-left text-sm border-0 border-none align-middle p-0 py-4 pl-4 whitespace-nowrap">
                                                {formatDateToYYYYMMDD(hla.createdAt)}
                                            </td>
                                            <td className="text-left text-uwi-primary-100 text-sm align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                <button
                                                    className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                                    onClick={() => navigate(`/home-loan-apply?id=${hla.id}`)}
                                                >
                                                    {hla.referenceNumber || hla.id}
                                                </button>
                                            </td>
                                            <td className="hidden md:!table-cell md:!visible text-left text-sm items-center align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                {(hla.loanableAmount) ? hla.loanableAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) : ''}
                                            </td>
                                            <td className={`hidden md:!table-cell md:!visible text-sm align-middle text-left border-0 border-none !p-0 !py-4 whitespace-nowrap uppercase ${(hla.status !== 'active') ? 'text-red-100' : 'text-gray-50' }`}>
                                                {capitalize(hla.status)}
                                            </td>
                                            <td className="font-normal border-0 border-none align-middle !p-0 !py-4 whitespace-nowrap">
                                                <button
                                                    className="w-6 h-6 text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                                    onClick={() => navigate(`/home-loan-apply?id=${hla.id}`)}
                                                >
                                                    <MagnifyingGlassSolidIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardHomeLoanApplicationPage;
