import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'typedi';
import { HttpRequestException } from 'src/api/lib/HttpRequestException';
import { ErrorCode } from 'src/api/types';
import { ErrorList } from 'src/components/common';
import { CircleLoaderIcon } from 'src/components/icons';
import { ForgotPasswordApi } from 'src/api/ForgotPasswordApi';

type Props = {
    onEmailSent?: (email: string) => void;
};

const FirstForm = ({
    onEmailSent
}: Props) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [email, setEmail] = useState('');

    const handleEmailSent = onEmailSent || (() => {});

    const validate = () => {
        const errors = [];

        if (!email || email.toString().trim().length === 0) {
            errors.push('Email is required.');
        }

        setErrors(errors);

        return (errors.length === 0)
    }

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }

        setIsLoading(true);

        try {
            await Container.get(ForgotPasswordApi).forgotPassword(email);
            handleEmailSent(email);
        }
        catch (ex) {
            if (ex instanceof HttpRequestException) {
                if (ex.getCode() === ErrorCode.VALIDATION_ERROR) {
                    const errors = ex.getData() as { errors: string[]; field: string }[];

                    setErrors(errors.reduce(
                        (prevVal, curVal) => prevVal.concat(...curVal.errors), 
                        [] as string[]
                    ));
                }
                else {
                    setErrors([ex.message]);
                }
            }
            else {
                setErrors([(ex as any).message]);
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <form
            className="w-full flex flex-col items-center md:mb-0 gap-y-3"
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <ErrorList
                errors={errors}
            />

            <p className="py-4 px-4 bg-yellow-100 border-yellow-400 border border-solid rounded-xl text-sm leading-4 mb-3">
                Enter the email address associated with your account.
            </p>

            <div className="w-full flex flex-col items-center mb-3">
                <label htmlFor="email" className="mb-2 block text-center text-xs form-label">Email</label>
                <input
                    type="email"
                    className="form-field bg-white/40"
                    placeholder="Email"
                    autoComplete="username"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                />
            </div>
            <button 
                className={`
                    w-full flex justify-center font-bold rounded-2xl text-[15px] py-4 px-5
                    ${(!isLoading) ? 'bg-uwi-primary-100 border text-white hover:bg-uwi-primary-200' : 'bg-gray-100 text-white'}
                `}
                disabled={isLoading}
            >
                Continue
                {isLoading && (
                    <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                )}
            </button>
            <div className="w-full text-sm text-center">
                Back to &nbsp;
                <button
                    className="font-bold text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                    type="button"
                    onClick={() => navigate('/login')}
                >
                    Login
                </button>
            </div>
        </form>
    );
};

export default FirstForm;
