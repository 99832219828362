import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Agent, CTAPosition, DeveloperWidgetType } from 'src/api/WidgetApi';
import { RootState } from '../index';

interface CommonState {
    ctaPosition: CTAPosition;
    widgetType: DeveloperWidgetType;
    contactEmail: string;
    hasBookingFeature: boolean;
    isAgentSelectionEnabled: boolean;
    agents: Agent[];
    hasReservationPaymentsFeature: boolean;
    hasXenditPayment: boolean;
    isFromRedirect: boolean;
    isProjectOnlyView: boolean;
    projectId: string;
    hasBuyerPortal: boolean;
}

const initialState: CommonState = {
    ctaPosition: 'none',
    widgetType: 'advance',
    contactEmail: '',
    hasBookingFeature: false,
    isAgentSelectionEnabled: false,
    agents: [],
    hasReservationPaymentsFeature: false,
    hasXenditPayment: false,
    isFromRedirect: false,
    isProjectOnlyView: false,
    projectId: '',
    hasBuyerPortal: false
}

export const commonSlice = createSlice({
    name: 'common',

    initialState,

    reducers: {
        setCtaPosition: (state: CommonState, action: PayloadAction<CTAPosition>) => {
            state.ctaPosition = action.payload;
        },

        setWidgetType: (state: CommonState, action: PayloadAction<DeveloperWidgetType>) => {
            state.widgetType = action.payload;
        },

        setContactEmail: (state: CommonState, action: PayloadAction<string>) => {
            state.contactEmail = action.payload;
        },

        setHasBookingFeature: (state: CommonState, action: PayloadAction<boolean>) => {
            state.hasBookingFeature = action.payload;
        },

        setIsAgentSelectionEnabled: (state: CommonState, action: PayloadAction<boolean>) => {
            state.isAgentSelectionEnabled = action.payload;
        },

        setAgents: (state: CommonState, action: PayloadAction<Agent[]>) => {
            state.agents = action.payload;
        },

        setHasReservationPaymentsFeature: (state: CommonState, action: PayloadAction<boolean>) => {
            state.hasReservationPaymentsFeature = action.payload;
        },

        setHasXenditPayment: (state: CommonState, action: PayloadAction<boolean>) => {
            state.hasXenditPayment = action.payload;
        },
        
        setIsFromRedirect: (state: CommonState, action: PayloadAction<boolean>) => {
            state.isFromRedirect = action.payload;
        },

        setIsProjectOnlyView: (state: CommonState, action: PayloadAction<boolean>) => {
            state.isProjectOnlyView = action.payload;
        },

        setProjectId: (state: CommonState, action: PayloadAction<string>) => {
            state.projectId = action.payload;
        },

        setHasBuyerPortal: (state: CommonState, action: PayloadAction<boolean>) => {
            state.hasBuyerPortal = action.payload;
        }
    }
});

export const {
    setCtaPosition,
    setWidgetType,
    setContactEmail,
    setHasBookingFeature,
    setIsAgentSelectionEnabled,
    setAgents,
    setHasReservationPaymentsFeature,
    setHasXenditPayment,
    setIsFromRedirect,
    setIsProjectOnlyView,
    setProjectId,
    setHasBuyerPortal
} = commonSlice.actions;

export const selectCtaPosition = (state: RootState) => state.common.ctaPosition;
export const selectWidgetType = (state: RootState) => state.common.widgetType;
export const selectContactEmail = (state: RootState) => state.common.contactEmail;
export const selectHasBookingFeature = (state: RootState) => state.common.hasBookingFeature;
export const selectIsAgentSelectionEnabled = (state: RootState) => state.common.isAgentSelectionEnabled;
export const selectAgents = (state: RootState) => state.common.agents;
export const selectHasReservationPaymentsFeature = (state: RootState) => state.common.hasReservationPaymentsFeature;
export const selectHasXenditPayment = (state: RootState) => state.common.hasXenditPayment;
export const selectIsFromRedirect = (state: RootState) => state.common.isFromRedirect;
export const selectIsProjectOnlyView = (state: RootState) => state.common.isProjectOnlyView;
export const selectProjectId = (state: RootState) => state.common.projectId;
export const selectHasBuyerPortal = (state: RootState) => state.common.hasBuyerPortal;

export default commonSlice.reducer;