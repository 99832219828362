import { FormFieldWrapper, Select, SelectOption, YesNoField } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectMonthlyIncome,
    selectWorkExperience,
    selectWorkType,
    selectIsRegularEmployee,
    selectIsBusinessProfitable,
    selectBusinessProprietorship,
    setWorkType as setWorkTypeAct,
    setIsRegularEmployee as setIsRegularEmployeeAct,
    setIsBusinessProfitable as setIsBusinessProfitableAct,
    setWorkExperience as setWorkExperienceAct,
    setBusinessProprietorship as setBusinessProprietorshipAct,
    setMonthlyIncome as setMonthlyIncomeAct,
} from 'src/store/features/preApproveLoanWorkInfoSlice';
import workTypes from 'src/data/workTypes.json';
import workExperiences from 'src/data/workExperiences.json';
import businessProprietorships from 'src/data/businessProprietorships.json';
import PreApprovalFormGroup from './PreApprovalFormGroup';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import useWorkInfoValidation from '../validations/useWorkInfoValidation';

const WorkInfo = () => {
    const {
        formErrors,
        setFormErrors,
        validateForm
    } = useWorkInfoValidation();

    const dispatch = useAppDispatch();
    const workType = useAppSelector(selectWorkType);
    const isRegularEmployee = useAppSelector(selectIsRegularEmployee);
    const isBusinessProfitable = useAppSelector(selectIsBusinessProfitable);
    const workExperience = useAppSelector(selectWorkExperience);
    const businessProprietorship = useAppSelector(selectBusinessProprietorship);
    const monthlyIncome = useAppSelector(selectMonthlyIncome);

    const handleOnChangeWorkType = (val: string) => {
        dispatch(setWorkTypeAct(val as string));
        const { workType, ...others } = formErrors;
        dispatch(setFormErrors(others));

        if (val === 'Self-Employed') {
            dispatch(setIsRegularEmployeeAct(false));
        }
    };

    return (
        <div className="w-full px-4 md:px-10">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[350px]">
                    <div className="flex gap-2 justify-evenly items-center">
                        <PreApprovalFormGroup
                            label="Work Type"
                            tooltipText="Category or nature of your employment."
                            showTooltip={true}
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'workType')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={workType}
                                onChange={(val) => handleOnChangeWorkType(val as string)}
                            >
                                {workTypes.map(workType => (
                                    <SelectOption
                                        key={workType}
                                        value={workType}
                                    >
                                        {workType}
                                    </SelectOption>
                                ))}
                            </Select>
                        </PreApprovalFormGroup>
                    </div>

                    {(workType !== 'Self-Employed' && workType !== '') && (
                        <div className="w-full flex justify-center items-center mb-4 whitespace-nowrap">
                            <input
                                type="checkbox"
                                className="w-5 h-5 rounded-4xl border-2 border-gray-200 mr-4 accent-green-100 uwi-input"
                                checked={isRegularEmployee}
                                onChange={() => dispatch(setIsRegularEmployeeAct(!isRegularEmployee))}
                                id="is_regular_employee"
                            />
                            <label htmlFor="is_regular_employee" className="form-label">
                                Regular Employee
                            </label>
                        </div>
                    )}

                    <PreApprovalFormGroup
                        label="Work Experience"
                        tooltipText="The total number of years that you are working."
                        showTooltip={true}
                    >
                        <Select
                            className={`
                                w-full
                                ${(hasProp(formErrors, 'workExperience')) ? '!border-red-200' : ''}
                            `}
                            placeholder="Please Select"
                            value={workExperience}
                            onChange={(val) => {
                                dispatch(setWorkExperienceAct(val as string));
                                const { workExperience, ...others } = formErrors;
                                dispatch(setFormErrors(others));
                            }}
                        >
                            {workExperiences.map(workExp => (
                                <SelectOption
                                    key={workExp}
                                    value={workExp}
                                >
                                    {workExp}
                                </SelectOption>
                            ))}
                        </Select>
                    </PreApprovalFormGroup>

                    <PreApprovalFormGroup
                        label="Gross Monthly Income"
                        tooltipText="Your gross monthly income including allowances."
                        showTooltip={true}
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'monthlyIncome')}
                            errorMessage={(hasProp(formErrors, 'monthlyIncome')) ? formErrors.monthlyIncome : ''}
                            className="w-full"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'monthlyIncome')) ? '!border-red-200' : ''}
                                `}
                                type="tel"
                                placeholder="Enter Amount"
                                min={1}
                                max={1000000000}
                                value={(monthlyIncome > 0) ? monthlyIncome.toLocaleString('en-US') : ''}
                                onChange={(e) => {
                                    dispatch(setMonthlyIncomeAct(parseInt(e.target.value.replace(/,/g, ''))));
                                    const { monthlyIncome, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                }}
                            />
                        </FormFieldWrapper>
                    </PreApprovalFormGroup>

                    {(workType === 'Self-Employed') && (
                        <>
                            <PreApprovalFormGroup
                            label="Business Proprietorship"
                            tooltipText="(For Self-employed) Your ownership of the business."
                            showTooltip={true}
                            >
                                <Select
                                    className={`
                                        w-full
                                        ${(hasProp(formErrors, 'businessProprietorship')) ? '!border-red-200' : ''}
                                    `}
                                    placeholder="Please Select"
                                    value={businessProprietorship}
                                    onChange={(val) => {
                                        dispatch(setBusinessProprietorshipAct(val as string));
                                        const { businessProprietorship, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                    }}
                                >
                                {businessProprietorships.map(proprietorship => (
                                    <SelectOption
                                        key={proprietorship}
                                        value={proprietorship}
                                    >
                                        {proprietorship}
                                    </SelectOption>
                                ))}
                                </Select>
                            </PreApprovalFormGroup>
                            <PreApprovalFormGroup
                                label="Is your business profitable in the last 2-3 years?"
                                tooltipText="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                                >
                                <YesNoField
                                    value={isBusinessProfitable}
                                    onChange={(val) => {
                                        dispatch(setIsBusinessProfitableAct(val));
                                    }}
                                />
                            </PreApprovalFormGroup>
                        </>
                    )}
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={validateForm}
            />
        </div>
    );
}

export default WorkInfo;
