import { BirthdateField, FormFieldWrapper, FormGroup, Select, SelectOption } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { hasProp } from 'src/helpers/object';
import { HomeLoanApplication } from 'src/api/HomeLoanApplicationApi';
import {
    setFirstName,
    setMiddleName,
    setLastName,
    setNameSuffix,
    setGender,
    setPlaceOfBirth,
    setEducationalBG,
    setCivilStatus,
    setMotherMaidenName,
    setSpouseFirstName,
    setSpouseMiddleName,
    setSpouseLastName,
    setSpouseMotherMaidenName,
    setSpouseBirthdate,
    selectFirstName,
    selectMiddleName,
    selectLastName,
    selectNameSuffix,
    selectGender,
    selectPlaceOfBirth,
    selectEducationBG,
    selectCivilStatus,
    selectMotherMaidenName,
    selectSpouseFirstName,
    selectSpouseMiddleName,
    selectSpouseLastName,
    selectSpouseMotherMaidenName,
    selectSpouseBirthdate
} from 'src/store/features/applyLoanPersonalInfoSlice';
import civilStatuses from 'src/data/civilStatuses.json';
import educationalBackground from 'src/data/educationalBackground.json';
import genderList from 'src/data/genderList.json';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import usePersonalInfo from '../hooks/usePersonalInfo';

type Props = {
    homeLoanApp?: HomeLoanApplication;
};

const PersonalInfo = ({
    homeLoanApp
}: Props) => {
    const dispatch = useAppDispatch();
    const {
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    } = usePersonalInfo(homeLoanApp);

    const firstName = useAppSelector(selectFirstName);
    const middleName = useAppSelector(selectMiddleName);
    const lastName = useAppSelector(selectLastName);
    const nameSuffix = useAppSelector(selectNameSuffix);
    const gender = useAppSelector(selectGender);
    const placeOfBirth = useAppSelector(selectPlaceOfBirth);
    const educationalBG = useAppSelector(selectEducationBG);
    const civilStatus = useAppSelector(selectCivilStatus);
    const motherMaidenName = useAppSelector(selectMotherMaidenName);
    const spouseFirstName = useAppSelector(selectSpouseFirstName);
    const spouseMiddleName = useAppSelector(selectSpouseMiddleName);
    const spouseLastName = useAppSelector(selectSpouseLastName);
    const spouseMotherMaidenName = useAppSelector(selectSpouseMotherMaidenName);
    const spouseBirthdate = useAppSelector(selectSpouseBirthdate);

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center">
                <div className="w-full sm:w-[600px] md:w-[700px]">
                    <FormGroup
                        label="Full Legal Name"
                        className="mb-4"
                        contentsWrapperClassName="w-full flex flex-wrap md:flex-nowrap"
                    >
                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'firstName')}
                            errorMessage={(hasProp(formErrors, 'firstName')) ? formErrors.firstName : ''}
                            className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2.5"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'firstName')) ? '!border-red-200' : ''}
                                `}
                                type="text"
                                placeholder="First Name"
                                maxLength={50}
                                required={true}
                                value={firstName}
                                onChange={(e) => {
                                    dispatch(setFirstName(e.target.value));
                                    const { firstName, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            />
                        </FormFieldWrapper>

                        <FormFieldWrapper
                            showError={false}
                            errorMessage={''}
                            className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2.5"
                        >
                            <input
                                className={`form-field`}
                                type="text"
                                placeholder="Middle Name"
                                maxLength={50}
                                required={false}
                                value={middleName}
                                onChange={(e) => {
                                    dispatch(setMiddleName(e.target.value));
                                    const { middleName, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            />
                        </FormFieldWrapper>

                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'lastName')}
                            errorMessage={(hasProp(formErrors, 'lastName')) ? formErrors.lastName : ''}
                            className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2.5"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'lastName')) ? '!border-red-200' : ''}
                                `}
                                type="text"
                                placeholder="Last Name"
                                maxLength={50}
                                required={true}
                                value={lastName}
                                onChange={(e) => {
                                    dispatch(setLastName(e.target.value));
                                    const { lastName, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            />
                        </FormFieldWrapper>

                        <FormFieldWrapper
                            showError={hasProp(formErrors, 'nameSuffix')}
                            errorMessage={(hasProp(formErrors, 'nameSuffix')) ? formErrors.nameSuffix : ''}
                            className="w-full md:w-1/3"
                        >
                            <input
                                className={`
                                    form-field
                                    ${(hasProp(formErrors, 'nameSuffix')) ? '!border-red-200' : ''}
                                `}
                                type="text"
                                placeholder="Suffix"
                                maxLength={50}
                                required={false}
                                value={nameSuffix}
                                onChange={(e) => {
                                    dispatch(setNameSuffix(e.target.value));
                                    const { nameSuffix, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            />
                        </FormFieldWrapper>
                    </FormGroup>
                    <div className="w-full text-center text-xs text-gray-50 mb-8">
                        *Please input your full legal name as it appears on your birth certificate
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Sex"
                            className="md:w-1/3 mb-4 md:mb-0 md:mr-2.5"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'gender')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={gender}
                                onChange={(val) => {
                                    dispatch(setGender(val as string));
                                    const { gender, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {genderList.map(gender => (
                                    <SelectOption
                                        key={gender}
                                        value={gender}
                                    >{gender}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup
                            label="Place of Birth"
                            className="md:w-1/3 mb-4 md:mb-0 md:mr-2.5"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'placeOfBirth')}
                                errorMessage={(hasProp(formErrors, 'placeOfBirth')) ? formErrors.placeOfBirth : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'placeOfBirth')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Place of Birth"
                                    maxLength={150}
                                    required={true}
                                    value={placeOfBirth}
                                    onChange={(e) => {
                                        dispatch(setPlaceOfBirth(e.target.value));
                                        const { placeOfBirth, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                        <FormGroup
                            label="Educational Background"
                            className="md:w-1/3"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'educationalBg')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={educationalBG}
                                onChange={(val) => {
                                    dispatch(setEducationalBG(val as string));
                                    const { educationalBG, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {educationalBackground.map(bg => (
                                    <SelectOption
                                        key={bg}
                                        value={bg}
                                    >{bg}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>
                    </div>

                    <div className="flex flex-wrap md:flex-nowrap mb-4">
                        <FormGroup
                            label="Civil Status"
                            className="md:w-1/3 mb-4 md:mb-0 md:mr-2.5"
                        >
                            <Select
                                className={`
                                    w-full
                                    ${(hasProp(formErrors, 'civilStatus')) ? '!border-red-200' : ''}
                                `}
                                placeholder="Please Select"
                                value={civilStatus}
                                onChange={(val) => {
                                    dispatch(setCivilStatus(val as string));
                                    const { civilStatus, ...others } = formErrors;
                                    dispatch(setFormErrors(others));
                                    setIsDirty(true);
                                }}
                            >
                                {civilStatuses.map(civilStatus => (
                                    <SelectOption
                                        key={civilStatus}
                                        value={civilStatus}
                                    >{civilStatus}</SelectOption>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup
                            label="Mother's Maiden Name"
                            className="md:w-2/3"
                        >
                            <FormFieldWrapper
                                showError={hasProp(formErrors, 'motherMaidenName')}
                                errorMessage={(hasProp(formErrors, 'motherMaidenName')) ? formErrors.motherMaidenName : ''}
                                className="w-full"
                            >
                                <input
                                    className={`
                                        form-field
                                        ${(hasProp(formErrors, 'motherMaidenName')) ? '!border-red-200' : ''}
                                    `}
                                    type="text"
                                    placeholder="Fullname"
                                    maxLength={150}
                                    value={motherMaidenName}
                                    onChange={(e) => {
                                        dispatch(setMotherMaidenName(e.target.value));
                                        const { motherMaidenName, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                        setIsDirty(true);
                                    }}
                                />
                            </FormFieldWrapper>
                        </FormGroup>
                    </div>

                    {(civilStatus === 'Legally Married' || civilStatus === 'Separated (Legally Married)') && (
                        <>
                            <FormGroup
                                label="Spouse Full Name"
                                className="mb-4"
                            >
                                <div className="flex flex-wrap md:flex-nowrap">
                                    <FormFieldWrapper
                                        showError={hasProp(formErrors, 'spouseFirstName')}
                                        errorMessage={(hasProp(formErrors, 'spouseFirstName')) ? formErrors.spouseFirstName : ''}
                                        className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2.5"
                                    >
                                        <input
                                            className={`
                                                form-field
                                                ${(hasProp(formErrors, 'spouseFirstName')) ? '!border-red-200' : ''}
                                            `}
                                            type="text"
                                            placeholder="First Name"
                                            maxLength={50}
                                            value={spouseFirstName}
                                            onChange={(e) => {
                                                dispatch(setSpouseFirstName(e.target.value));
                                                const { spouseFirstName, ...others } = formErrors;
                                                dispatch(setFormErrors(others));
                                                setIsDirty(true);
                                            }}
                                        />
                                    </FormFieldWrapper>
                                    <FormFieldWrapper
                                        showError={hasProp(formErrors, 'spouseMiddleName')}
                                        errorMessage={(hasProp(formErrors, 'spouseMiddleName')) ? formErrors.spouseMiddleName : ''}
                                        className="w-full md:w-1/3 mb-2 md:mb-0 md:mr-2.5"
                                    >
                                        <input
                                            className={`
                                                form-field
                                                ${(hasProp(formErrors, 'spouseMiddleName')) ? '!border-red-200' : ''}
                                            `}
                                            type="text"
                                            placeholder="Middle Name"
                                            maxLength={50}
                                            value={spouseMiddleName}
                                            onChange={(e) => {
                                                dispatch(setSpouseMiddleName(e.target.value));
                                                const { spouseMiddleName, ...others } = formErrors;
                                                dispatch(setFormErrors(others));
                                                setIsDirty(true);
                                            }}
                                        />
                                    </FormFieldWrapper>
                                    <FormFieldWrapper
                                        showError={hasProp(formErrors, 'spouseLastName')}
                                        errorMessage={(hasProp(formErrors, 'spouseLastName')) ? formErrors.spouseLastName : ''}
                                        className=" w-full md:w-1/3"
                                    >
                                        <input
                                            className={`
                                                form-field
                                                ${(hasProp(formErrors, 'spouseLastName')) ? '!border-red-200' : ''}
                                            `}
                                            placeholder="Last Name"
                                            maxLength={50}
                                            value={spouseLastName}
                                            onChange={(e) => {
                                                dispatch(setSpouseLastName(e.target.value));
                                                const { spouseLastName, ...others } = formErrors;
                                                dispatch(setFormErrors(others));
                                                setIsDirty(true);
                                            }}
                                        />
                                    </FormFieldWrapper>
                                </div>
                            </FormGroup>

                            <div className="flex flex-wrap md:flex-nowrap mb-4">
                                <FormGroup
                                    label="Spouse Birthdate"
                                    className="md:w-2/3 mb-4 md:mb-0 md:mr-2.5"
                                >
                                    <div
                                        className=" w-full flex"
                                    >
                                        <BirthdateField
                                            value={spouseBirthdate}
                                            formError={(hasProp(formErrors, 'spouseBirthdate')) ? formErrors.spouseBirthdate : undefined}
                                            onChange={(val) => {
                                                dispatch(setSpouseBirthdate(val));
                                                const { spouseBirthdate, ...others } = formErrors;
                                                dispatch(setFormErrors(others));
                                                setIsDirty(true);
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup
                                    label="Spouse Mother's Maiden Name"
                                    className="md:w-1/3"
                                >
                                    <FormFieldWrapper
                                        showError={hasProp(formErrors, 'spouseMotherMaidenName')}
                                        errorMessage={(hasProp(formErrors, 'spouseMotherMaidenName')) ? formErrors.spouseMotherMaidenName : ''}
                                        className=" w-full"
                                    >
                                        <input
                                            className={`
                                                form-field
                                                ${(hasProp(formErrors, 'spouseMotherMaidenName')) ? '!border-red-200' : ''}
                                            `}
                                            type="text"
                                            placeholder="Full Legal Name"
                                            maxLength={150}
                                            value={spouseMotherMaidenName}
                                            onChange={(e) => {
                                                dispatch(setSpouseMotherMaidenName(e.target.value));
                                                const { spouseMotherMaidenName, ...others } = formErrors;
                                                dispatch(setFormErrors(others));
                                                setIsDirty(true);
                                            }}
                                        />
                                    </FormFieldWrapper>
                                </FormGroup>
                            </div>
                        </>
                    )}
                </div>

                <div className="w-full text-center text-xs text-gray-50 mb-8">*all fields are required</div>
            </div>

            <FieldGroupBottomButtons
                onNext={handleNext}
             />
        </div>
    );
}

export default PersonalInfo;
