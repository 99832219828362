import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PreQualification, PreQualificationApi } from 'src/api/PreQualificationApi';
import useAuth from 'src/hooks/useAuth';
import { AngleLeftSolidIcon, CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import TabNavigation from 'src/page_components/book-a-tour/TabNavigation';
import TabContent from 'src/page_components/book-a-tour/TabContent';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { Select, SelectOption } from 'src/components/common';

const BookATourPage = () => {
    const navigate = useNavigate();
    const { user, userId, hasLoadedFromStorage} = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const [searchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(true);
    const [preQualification, setPreQualification] = useState<PreQualification>();
    const [selectedSubProject, setSelectedSubProject] = useState<AddedSubProject|null>(null);
    const [selectedSubProjectIndex, setSelectedSubProjectIndex] = useState(-1);

    const preQualificationId = searchParams.get('preQualificationId');
    const subProjectId = searchParams.get('subProjectId');
    const referrer = searchParams.get('referrer');
    
    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent(`/book-a-tour?preQualificationId=${preQualificationId}&subProjectId=${subProjectId}`)}`);
        }
    }, [user, preQualificationId, subProjectId, hasLoadedFromStorage, navigate]);

    useEffect(() => {        
        if (preQualificationId && userId !== null) {
            setIsLoading(true);

            Container
                .get(PreQualificationApi)
                .getInfo(preQualificationId)
                .then(preQual => {
                    setPreQualification(preQual);

                    if (preQual.subProjects && preQual.subProjects.length > 0) {
                        setSelectedSubProject(preQual.subProjects[0]);
                        setSelectedSubProjectIndex(0);

                        if (subProjectId) {
                            const idx = preQual.subProjects.findIndex(o => o.id === subProjectId);

                            if (idx !== -1) {
                                setSelectedSubProject(preQual.subProjects[idx]);
                                setSelectedSubProjectIndex(idx);
                            }
                        }
                    }
                })
                .catch(errorHandlerWithToast)
                .finally(() => {
                    setIsLoading(false)
                });
        }
        else {
            setIsLoading(false);
        }
    }, [preQualificationId, userId, errorHandlerWithToast]);

    const handleSelectSubProject = (subProj: AddedSubProject, index: number) => {
        setSelectedSubProject(subProj);
        setSelectedSubProjectIndex(index);
    };

    const handleSelectOnChangeSubProject = (subProjectId: string) => {
        if (preQualification && preQualification.subProjects) {
            const subProjectIdx = preQualification.subProjects.findIndex(subProj => (subProj.id === subProjectId));
            if (subProjectIdx > -1) {
                setSelectedSubProject(preQualification.subProjects[subProjectIdx]);
                setSelectedSubProjectIndex(subProjectIdx);
            }
        }
    };

    const handleNextProperty = () => {
        if (preQualification && preQualification.subProjects) {
            if (selectedSubProjectIndex + 1 < preQualification.subProjects.length) {
                setSelectedSubProject(preQualification.subProjects[selectedSubProjectIndex + 1]);
                setSelectedSubProjectIndex(selectedSubProjectIndex + 1);
            }
        }
    };

    const handleBack = () => {
        if (referrer) {
            navigate(referrer);
        }
        else {
            navigate(`/pre-qualification-view?id=${preQualificationId}`);
        }
    };

    return (
        <div className="bg-uwi-content-100 text-black rounded-t-3xl shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
            <div className="pt-10">
                <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                    {isLoading && (
                        <div className="mb-3 px-4">
                            <div className="w-full flex justify-center">
                                <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                            </div>
                        </div>
                    )}

                    {!isLoading && !preQualification && (
                        <div>Page not found</div>
                    )}

                    {!isLoading && preQualification && preQualification.subProjects && preQualification.subProjects.length > 0 && selectedSubProject !== null && (
                        <>
                            <div className="flex justify-end">
                                <button
                                    className="text-sm flex justify-center items-center"
                                    onClick={() => handleBack()}
                                >
                                    <AngleLeftSolidIcon
                                        className="w-5 h-5 mr-2"
                                    />
                                    {(referrer) ? 'Back To Home Loan Assessments' : 'Back to Result Page'}
                                </button>
                            </div>

                            <div className="flex">
                                <div className="w-[230px] border-r hidden lg:block">
                                    <TabNavigation
                                        subProjects={preQualification.subProjects}
                                        subProjectId={selectedSubProject.id}
                                        onSelect={handleSelectSubProject}
                                    />
                                </div>
                                <div className="w-full lg:w-[calc(100%-230px)] pl-5 pt-4 pb-4">
                                    <div className="block lg:hidden px-4 mb-5">
                                        <Select
                                            value={selectedSubProject.id}
                                            onChange={(val) => handleSelectOnChangeSubProject(val as string)}
                                        >
                                            {(preQualification.subProjects && preQualification.subProjects.map(subProj => (
                                                <SelectOption
                                                    value={subProj.id}
                                                    key={subProj.id}
                                                >
                                                    {subProj.name}
                                                </SelectOption>
                                            )))}
                                        </Select>
                                    </div>

                                    <TabContent
                                        subProject={selectedSubProject}
                                        subProjectId={selectedSubProject.id}
                                        preQualificationId={preQualification.id}
                                        selectedSubProjectIndex={selectedSubProjectIndex}
                                        totalSubProjects={preQualification.subProjects.length}
                                        onNextProperty={handleNextProperty}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default BookATourPage;
