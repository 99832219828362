import { useEffect, useState } from 'react';
import ModalPortal from './ModalPortal';

type Props = {
    children?: JSX.Element | JSX.Element[] | string | number;
    visible: boolean;
    onClose?: () => void;
    className?: string;
}

export default function Modal({
    children,
    visible,
    onClose,
    className
}: Props) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(visible);

        if (visible) {
            document.body.classList.add('body-overflow-hidden');
        }
        else {
            document.body.classList.remove('body-overflow-hidden');
        }

        return () => {
            document.body.classList.remove('body-overflow-hidden');
        };
    }, [visible]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        else {
            setIsVisible(false);
        }
    };

    if (!isVisible) {
        return <></>;
    }

    return (
        <ModalPortal>
            <div className="fixed top-0 left-0 right-0 bottom-0 overflow-auto z-[999999]">
                <div
                    className="fixed top-0 left-0 w-screen h-screen bg-black/80 -z-[1] pointer-events-[all]"
                    onClick={() => handleClose()}
                ></div>

                <div className={`
                    ${(className) ? className : 'w-full overflow-y-hidden max-w-[90%] sm:max-w-[500px] md:max-w-[960px] mx-auto rounded-5xl bg-white'}
                `}>
                    {children || ''}
                </div>
            </div>
        </ModalPortal>
    );
};
