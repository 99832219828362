import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'src/hooks/generic';
import { selectProjectCost, selectTotalSubProjectsCost } from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    selectLoanTerm,
    selectPercentOfProjectCost,
    selectOwnedBanks
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import { selectWidgetType } from 'src/store/features/commonSlice';
import Container from 'typedi';
import { LenderApi } from 'src/api/LenderApi';

type Props = {
    className?: string;
    headerClassName?: string;
    listClassName?: string;
    listEntryClassName?: string;
    listEntryLeftClassName?: string;
    listEntryLeftValueClassName?: string;
};

const LoanInfo = ({
    className,
    headerClassName,
    listEntryLeftClassName,
    listEntryLeftValueClassName
}: Props) => {
    const widgetType = useAppSelector(selectWidgetType);
    const projectCost = useAppSelector(selectProjectCost);
    const totalSubProjectsCost = useAppSelector(selectTotalSubProjectsCost);
    const loanTerm = useAppSelector(selectLoanTerm);
    const percentOfProjectCost = useAppSelector(selectPercentOfProjectCost);
    const selectedOwnedBanks = useAppSelector(selectOwnedBanks);

    const [selectedBanks, setSelectedBanks] = useState('');

    useEffect(() => {
        Container
            .get(LenderApi)
            .list()
            .then(lenders => {
                let userLenderNames:string[] = [];
                let userLenders = lenders.filter(lender => selectedOwnedBanks.indexOf(lender.id) !== -1);
                userLenders.forEach(lender => {
                    let lenderName = lender.name;
                    if (lender.shortName) {
                        lenderName = lender.shortName;
                    }
                    userLenderNames.push(lenderName);
                });
                setSelectedBanks(userLenderNames.join(', '));
            });
    }, [selectedOwnedBanks]);

    const loanableAmount = useMemo(() => {
        if (widgetType === 'advance') {
            return Math.round(totalSubProjectsCost * (percentOfProjectCost / 100));
        }

        return Math.round(projectCost * (percentOfProjectCost / 100));
    }, [
        totalSubProjectsCost,
        percentOfProjectCost,
        projectCost,
        widgetType
    ]);

    const downpaymentAmount = useMemo(() => {
        if (widgetType === 'advance') {
            return Math.round(totalSubProjectsCost * ((100 - percentOfProjectCost) / 100));
        }

        return Math.round(projectCost * ((100 - percentOfProjectCost) / 100));
    }, [
        totalSubProjectsCost,
        percentOfProjectCost,
        projectCost,
        widgetType
    ]);

    return (
        <div className={`w-full ${className || ''}`}>
            <h3 className={`text-base font-arial text-white bg-uwi-primary-100 py-4 font-bold mb-2 ${headerClassName || ''}`}>LOAN INFORMATION</h3>
            <table className="w-full !m-0 !border-none">
                <tbody>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Preferred Banks:</td>
                        <td className={listEntryLeftValueClassName || ''}>{selectedBanks}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Loan Term:</td>
                        <td className={listEntryLeftValueClassName || ''}>{loanTerm}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Downpayment:</td>
                        <td className={listEntryLeftValueClassName || ''}>{downpaymentAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Loanable Amount:</td>
                        <td className={listEntryLeftValueClassName || ''}>{loanableAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default LoanInfo;
