import { GCashSmIcon, GCashIcon, MayaSmIcon, MayaIcon, UnionBankSmIcon, UnionBankIcon, RcbcSmIcon, RcbcIcon, ChinaBankSmIcon, ChinaBankIcon, MasterCardSmIcon, BpiSmIcon, BpiIcon } from 'src/components/icons';
import VisSmIcon from 'src/components/icons/VisSmIcon';

const PaymentOptIcons = ({className, icon, isSmall} : {className?: string, icon: string, isSmall?: boolean}) => {
    return (
        <>
            {icon === 'PH_GCASH' && (
                (isSmall) ?
                    <GCashSmIcon className="w-5 h-5"/>
                :
                    <GCashIcon className={className}/>
            )}

            {icon === 'PH_PAYMAYA' && (
                (isSmall) ?
                    <MayaSmIcon className={className}/>
                :
                    <MayaIcon className={className}/>
            )}

            {icon === 'BA_UBP' && (
                (isSmall) ?
                    <UnionBankSmIcon className={className}/>
                :
                    <UnionBankIcon className={className}/>
            )}

            {icon === 'BA_RCBC' && (
                (isSmall) ?
                    <RcbcSmIcon className={className}/>
                :
                    <RcbcIcon className={className}/>
            )}

            {icon === 'BA_CHINABANK' && (
                (isSmall) ?
                    <ChinaBankSmIcon className={className}/>
                :
                    <ChinaBankIcon className={className}/>
            )}

            {icon === 'BA_BPI' && (
                (isSmall) ?
                    <BpiSmIcon className={className}/>
                :
                    <BpiIcon className={className}/>
            )}

            {icon === 'MASTERCARD' && (
                <MasterCardSmIcon className={className}/>
            )}

            {icon === 'VISA' && (
                <VisSmIcon className={className}/>
            )}
        </>
    );
}

export default PaymentOptIcons;
