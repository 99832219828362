import { useEffect, useState } from 'react';
import { XMarkSolidIcon } from 'src/components/icons';
import Modal from './Modal';

type Props = {
    visible: boolean;
    onClose?: () => void;
    title?: string;
    message: string;
    btn1Text?: string;
    btn2Text?: string;
    onConfirm?: (hasBtn1Clicked: boolean) => void;
}

export default function ConfirmModal({
    visible,
    onClose,
    title,
    message,
    btn1Text,
    btn2Text,
    onConfirm
}: Props) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        else {
            setIsVisible(false);
        }
    };

    const handleConfirm = (hasBtn1Clicked: boolean) => {
        if (onConfirm) {
            onConfirm(hasBtn1Clicked);
        }
        else {
            setIsVisible(false);
        }
    };

    return (
        <Modal
            visible={isVisible}
            onClose={handleClose}
            className="w-full flex justify-center items-center h-screen"
        >
            <div className="w-full max-w-[90%] sm:max-w-[400px] md:max-w-[500px] mx-auto rounded-lg bg-white">
                <div className="text-center w-full !pt-7 !pb-4 border-b !text-lg relative">
                    <button
                        className="absolute top-3 right-3 text-gray-50 hover:text-black hover:bg-transparent"
                        onClick={() => handleClose()}
                    >
                        <XMarkSolidIcon className="w-5 h-5" />
                    </button>

                    <div className="font-bold">{title || 'Confirm'}</div>
                </div>
                <div className="!pt-6 !pb-10 !px-7 text-center text-[15px] leading-normal">
                    {message}
                </div>
                <div className="pt-4 pb-7 px-7 flex justify-center text-lg">
                    <button
                        className={`
                            flex justify-center
                            w-[150px] py-4 rounded-xl mr-4
                            font-bold text-[15px]
                            border border-solid
                            border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                            hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                        `}
                        onClick={() => handleConfirm(true)}
                    >
                        {btn1Text || 'OK'}
                    </button>
                    <button
                        className={`
                            flex justify-center
                            w-[150px] py-4 rounded-xl
                            font-bold text-[15px]
                            border border-solid
                            border-uwi-primary-100 hover:border-uwi-primary-200
                            bg-uwi-primary-100 hover:bg-uwi-primary-200 
                            text-white
                        `}
                        onClick={() => handleConfirm(false)}
                    >
                        {btn2Text || 'Cancel'}
                    </button>
                </div>
            </div>
        </Modal>
    );
};
