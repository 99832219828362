import { Container } from 'typedi';
import { useEffect, useState } from 'react';
import { AngleLeftSolidIcon, AngleRightSolidIcon, CircleLoaderIcon } from 'src/components/icons';
import { BookingApi } from 'src/api/BookingApi';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { hasProp } from 'src/helpers/object';
import { CalendarDayInfo, daysOfWeekShort, getLastDayOfMonth, getMonthCalendar, months } from './helper';

type Props = {
    subProjectId: string | null;
    onClickDate: (date: Date) => void;
    selectedDate: Date | null;
    isSubProjectBookingInfoLoaded: boolean;
    bookingDate: string | null;
    bookingStatus: string;
    agentId?: string;
};

const BookingCalendar = ({
    subProjectId,
    onClickDate,
    selectedDate,
    isSubProjectBookingInfoLoaded,
    bookingDate,
    agentId,
    bookingStatus
}: Props) => {    
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const date =  new Date();

    const [calendarData, setCalendarData] = useState<CalendarDayInfo[][]>(getMonthCalendar(date.getMonth(), date.getFullYear()));
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const [isLoading, setIsLoading] = useState(false);

    const changeCurrentDate = (date: Date) => {
        if (bookingStatus !== 'active') {
            return;
        }

        const calendarData = getMonthCalendar(date.getMonth(), date.getFullYear());

        setCurrentDate(date);
        setCalendarData(calendarData);

        let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const now = new Date();
        const nextDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        if (nextDay.getTime() > startDate.getTime()) {
            startDate = nextDay;
        }

        const lastDayOfPreviousMonth = getLastDayOfMonth(date.getMonth(), date.getFullYear());
        const endDate = new Date(date.getFullYear(), date.getMonth(), lastDayOfPreviousMonth);

        if (subProjectId) {
            setIsLoading(true);
            Container
                .get(BookingApi)
                .subProjectCalendarInfo(subProjectId, {
                    startDate,
                    endDate,
                    agentId
                })
                .then(calendarInfo => {
                    for (let i = 0; i < calendarData.length; i += 1) {
                        for (let j = 0; j < calendarData[i].length; j += 1) {
                            const dt = formatDateToYYYYMMDD(calendarData[i][j].date);
                            if (hasProp(calendarInfo, dt)) {
                                calendarData[i][j].hasSchedule = calendarInfo[dt].hasSchedule;
                                calendarData[i][j].isAvailable = calendarInfo[dt].isAvailable;
                            }
                        }
                    }
    
                    setCalendarData(calendarData);
                })
                .catch(errorHandlerWithToast)
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        if (isSubProjectBookingInfoLoaded && subProjectId) {
            const date = (bookingDate) ? new Date(bookingDate) : new Date();

            const calendarData = getMonthCalendar(date.getMonth(), date.getFullYear());
    
            setCurrentDate(date);
            setCalendarData(calendarData);
    
            let startDate = new Date(date.getFullYear(), date.getMonth(), 1);
            const now = new Date();
            const nextDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            if (nextDay.getTime() > startDate.getTime()) {
                startDate = nextDay;
            }
    
            const lastDayOfPreviousMonth = getLastDayOfMonth(date.getMonth(), date.getFullYear());
            const endDate = new Date(date.getFullYear(), date.getMonth(), lastDayOfPreviousMonth);

            if (endDate.getTime() > now.getTime() && bookingStatus === 'active') {
                setIsLoading(true);
                Container
                    .get(BookingApi)
                    .subProjectCalendarInfo(subProjectId, {
                        startDate,
                        endDate,
                        agentId
                    })
                    .then(calendarInfo => {
                        for (let i = 0; i < calendarData.length; i += 1) {
                            for (let j = 0; j < calendarData[i].length; j += 1) {
                                const dt = formatDateToYYYYMMDD(calendarData[i][j].date);
                                if (hasProp(calendarInfo, dt)) {
                                    calendarData[i][j].hasSchedule = calendarInfo[dt].hasSchedule;
                                    calendarData[i][j].isAvailable = calendarInfo[dt].isAvailable;
                                }
                            }
                        }
        
                        setCalendarData(calendarData);
                    })
                    .catch(errorHandlerWithToast)
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    }, [isSubProjectBookingInfoLoaded, bookingDate, bookingStatus, subProjectId, agentId, errorHandlerWithToast])

    const onNextMonth = () => {
        changeCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
    };

    const onPreviousMonth = () => {
        changeCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
    };

    const isCurrentDate = (date: Date) => {
        const now = new Date();

        return (
            (now.getFullYear() === date.getFullYear())
            && (now.getMonth() === date.getMonth())
            && (now.getDate() === date.getDate())
        );
    }
    
    return (
        <div className="relative">
            {(isLoading) && (
                <div className="absolute w-full flex justify-center items-center h-[370px]">
                    <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
                </div>
            )}

            <div className="flex items-center justify-between w-full px-4 pb-5 border-b border-gray-200">
                <div className="test text-xl font-bold">{months[currentDate.getMonth()]} {currentDate.getFullYear()}</div>
                <div className="flex">
                    <button
                        className={`
                            rounded-md px-3 py-2 mr-2
                            ${((bookingStatus === 'active') ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white')} 
                        `}
                        onClick={() => onPreviousMonth()}
                    >
                        <AngleLeftSolidIcon className="w-3.5 h-3.5" />
                    </button>
                    <button
                        className={`
                            rounded-md px-3 py-2
                            ${((bookingStatus === 'active') ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white' : 'bg-gray-100 text-white')} 
                        `}
                        onClick={() => onNextMonth()}
                    >
                        <AngleRightSolidIcon className="w-3.5 h-3.5" />
                    </button>
                </div>
            </div>

            <div className="px-4 pt-5 pb-3 md:pb-6">
                <table className="w-full border-none">
                    <tbody>
                        <tr>
                            {daysOfWeekShort.map(day => (
                                <td key={day} className="font-bold text-lg pb-4 text-center border-none">{day}</td>
                            ))}
                        </tr>

                        {(calendarData.map((rowData, idx) => (
                            <tr className="border-none" key={idx}>
                                {(rowData.map((cellData, idx2) => (
                                    <td
                                        key={idx2}
                                        className={`
                                            text-base text-center px-0 py-[1px] border-none
                                            ${(cellData.isDisabled) ? 'text-gray-50' : ''}
                                            ${isCurrentDate(cellData.date) ? 'text-uwi-primary-100' : ''}
                                        `}
                                    >
                                        {(cellData.hasSchedule && cellData.isAvailable) && (
                                            <button
                                                className={`
                                                    rounded-lg w-[28px] h-[28px]
                                                    text-white
                                                    ${(selectedDate && formatDateToYYYYMMDD(cellData.date) === formatDateToYYYYMMDD(selectedDate)) ? 'bg-green-800' : 'bg-green-600 hover:bg-green-800'}
                                                `}
                                                onClick={() => onClickDate(cellData.date)}
                                            >
                                                {cellData.label}
                                            </button>
                                        )}
                                        {(cellData.hasSchedule && !cellData.isAvailable && (!selectedDate || formatDateToYYYYMMDD(cellData.date) !== formatDateToYYYYMMDD(selectedDate))) && (
                                            <span
                                                className="bg-red-400 text-white w-[28px] h-[28px] inline-flex justify-center items-center mx-auto"
                                            >
                                                {cellData.label}
                                            </span>
                                        )}
                                        {(!cellData.hasSchedule && (!selectedDate || formatDateToYYYYMMDD(cellData.date) !== formatDateToYYYYMMDD(selectedDate))) && (
                                            <span
                                                className="bg-white w-[28px] h-[28px] inline-flex justify-center items-center mx-auto"
                                            >
                                                {cellData.label}
                                            </span>
                                        )}
                                        {(!cellData.isAvailable && (selectedDate && formatDateToYYYYMMDD(cellData.date) === formatDateToYYYYMMDD(selectedDate))) && (
                                            <span
                                                className="bg-green-800 w-[28px] h-[28px] text-white inline-flex justify-center items-center mx-auto"
                                            >
                                                {cellData.label}
                                            </span>
                                        )}
                                    </td>
                                )))}
                            </tr>
                        )))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-center md:justify-start">
                <div className="mr-4 flex">
                    <div className="w-3.5 h-3 bg-green-600 mr-2"></div>
                    <div className="text-green-600 text-xs">Available</div>
                </div>
                <div className="flex">
                    <div className="w-3.5 h-3 bg-red-400 mr-2"></div>
                    <div className="text-red-400 text-xs">Fully Booked</div>
                </div>
            </div>
        </div>
    );
};

export default BookingCalendar;
