import { useEffect, useMemo, useState } from 'react';
import { Container } from 'typedi';
import { SubProject, SubProjectApi } from 'src/api/SubProjectApi';
import { FilterIcon, ListSolidIcon } from 'src/components/icons';
import { useAppSelector } from 'src/hooks/generic';
import {
    selectProjectIds,
    selectSelectedSubProjects
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import SubProjectUI from '../fields/SubProjectUI';
import ViewImage from '../fields/ViewImage';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import SubProjectUIDetails from '../fields/SubProjectUIDetails';
import usePropertyInfoScreen2Validation from '../validations/useProjectInfoScreen2Validation';
import SummarySlidePanel from '../fields/SummarySlidePanel';
import FilterSlidePanel from '../fields/FilterSlidePanel';

const ProjectInfoScreen2 = () => {
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const { validateForm } = usePropertyInfoScreen2Validation();

    const selectedSubProjects = useAppSelector(selectSelectedSubProjects);
    const projectIds = useAppSelector(selectProjectIds);
    const [subProjects, setSubProjects] = useState<SubProject[]>([]);

    useEffect(() => {
        Container
            .get(SubProjectApi)
            .search(projectIds)
            .then(subProjects => setSubProjects(subProjects))
            .catch(errorHandlerWithToast);
    }, []);

    const [selectedSubProject, setSelectedSubProject] = useState<SubProject|null>(null);
    const [isViewImageVisible, setIsViewImageVisible] = useState(false);
    const [isFilterShown, setIsFilterShown] = useState(false);
    const [isSummaryShown, setIsSummaryShown] = useState(false);
    const [isSubProjectUIDetailShown, setIsSubProjectUIDetailShown] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const totalAmount = useMemo(() => {
        return selectedSubProjects.reduce((prev, curVal) => {
            return prev + (curVal.quantity * curVal.price)
        }, 0);
    }, [selectedSubProjects]);

    const orderedSubProjects = useMemo(() => {
        const orig = [...subProjects];
        const selectedSubProjectIds = selectedSubProjects.map(sp => sp.id);

        return orig.sort((a: SubProject, b: SubProject) => {
            if (selectedSubProjectIds.includes(a.id)) {
                return -1;
            }
            
            if (selectedSubProjectIds.includes(b.id)) {
                return 1;
            }

            return 0;
        });
    }, [subProjects, selectedSubProjects]);

    const handleViewSubProject = (subProject: SubProject) => {
        setSelectedSubProject(subProject);
        setIsSubProjectUIDetailShown(true);
    };

    const handleViewImage = (subProject: SubProject) => {
        setSelectedSubProject(subProject);
        setIsViewImageVisible(true);
    };

    const onNext = async () => {
        const isValid = await validateForm();
    
        if (!isValid) {
            return false;   
        }
        return true;
    }

    return (
        <>
            <div className="w-full px-4">
                <div className="relative w-full flex md:max-h-[430px] md:min-h-[430px] overflow-x-hidden">
                    <div
                        className={`
                            absolute z-[6]
                            bg-white/80
                            w-full
                            h-[calc(100vh-258px)] md:h-[500px]
                            ${(isFilterShown || isSummaryShown) ? 'lg:hidden' : 'hidden'}
                        `}
                        onClick={() => {
                            setIsFilterShown(false);
                            setIsSummaryShown(false);
                        }}
                    />

                    {!isSubProjectUIDetailShown && (
                        <SummarySlidePanel
                            isSummaryShown={isSummaryShown}
                            onClose={() => setIsSummaryShown(false)}
                        />
                    )}

                    {!isSubProjectUIDetailShown && (
                        <FilterSlidePanel
                            visible={isFilterShown}
                            onClose={() => setIsFilterShown(false)}
                            subProjects={subProjects}
                            setSubProjects={(sp) => setSubProjects(sp)}
                        />
                    )}

                    <div className="w-full relative">
                        <div className="flex justify-between items-center text-sm">
                            <div className="lg:hidden">
                                <button
                                    className={`
                                        flex items-center hover:bg-transparent focus:bg-transparent text-xs
                                        ${(!isSubProjectUIDetailShown) ? 'text-uwi-primary-100 hover:text-uwi-primary-200' : 'text-gray-50'}
                                    `}
                                    onClick={() => setIsSummaryShown(true)}
                                    disabled={isSubProjectUIDetailShown}
                                >
                                    <ListSolidIcon className="w-4 h-4 mr-2" />
                                    <span>Summary</span>
                                </button>
                            </div>

                            <div className="flex items-baseline">
                                <div className="text-xs mr-1.5 md:mr-3">Total Amount</div>
                                <div className="text-sm md:text-base text-uwi-primary-100 font-bold">{totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) }</div>
                            </div>

                            <div className="">
                                <button
                                    className={`
                                        relative
                                        flex items-center
                                        hover:bg-transparent focus:bg-transparent
                                        text-xs
                                        ${(!isSubProjectUIDetailShown) ? 'text-uwi-primary-100 hover:text-uwi-primary-200' : 'text-gray-50'}
                                    `}
                                    onClick={() => setIsFilterShown(true)}
                                    disabled={isSubProjectUIDetailShown}
                                >
                                    <FilterIcon className="w-4 h-4 mr-2" />
                                    <span>Filters</span>
                                </button>
                            </div>
                        </div>
                        <div
                            className={`
                                mt-5 py-3 lg:p-3
                                mb-2 md:mb-0
                                border-t border-solid border-gray-50
                                min-h-[calc(100vh-370px)] max-h-[calc(100vh-370px)] md:max-h-[370px] md:min-h-[370px]
                                overflow-auto uwi-scrollbar
                            `}
                        >
                            <div className="flex flex-wrap lg:grid lg:grid-cols-3 gap-3 lg:gap-4 justify-center">
                                {orderedSubProjects.map(subProj => (
                                    <SubProjectUI
                                        key={subProj.id}
                                        subProject={subProj}
                                        onView={handleViewSubProject}
                                    />
                                ))}
                            </div>
                        </div>

                        <SubProjectUIDetails
                            subProject={selectedSubProject}
                            visible={isSubProjectUIDetailShown}
                            onViewImage={handleViewImage}
                            onClose={() => setIsSubProjectUIDetailShown(false)}
                            onNext={onNext}
                        />

                        <ViewImage
                            visible={isViewImageVisible}
                            subProject={selectedSubProject}
                            onClose={() => setIsViewImageVisible(false)}
                        />
                    </div>
                </div>

                {!isSubProjectUIDetailShown &&
                    <FieldGroupBottomButtons
                        onNext={onNext}
                    />
                }
            </div>
        </>
    );
}

export default ProjectInfoScreen2;
