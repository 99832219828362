import { useState } from 'react';
import { Container } from 'typedi';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { Booking, BookingApi } from 'src/api/BookingApi';
import { formatBookingTimeTo12HourFormat } from 'src/helpers/booking';
import useToast from 'src/hooks/useToast';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { CircleLoaderIcon } from 'src/components/icons';
import { ConfirmModal } from 'src/components/common';

type Props = {
    subProject: AddedSubProject;
    booking: Booking;
    onClickUpdateBooking: () => void;
    onBookingUpdated: (booking: Booking) => void;
};

const BookingSummary = ({
    subProject,
    booking,
    onClickUpdateBooking,
    onBookingUpdated
}: Props) => {
    const {
        errorHandlerWithToast
    } = useGenericErrorHandler();
    const toast = useToast();

    const [isCancellingBooking, setIsCancellingBooking] = useState(false);
    const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);

    const formatDate = (date: Date) => {
        return new Intl.DateTimeFormat(
            'en-US',
            { year: 'numeric', month: 'short', day: 'numeric' }
        ).format(date);
    };

    const handleCancelBooking = () => {
        setIsCancellingBooking(true);
        Container
            .get(BookingApi)
            .cancelBooking(booking.id)
            .then(() => {
                toast('Your booking has been cancelled successfully.', 'success');
                onBookingUpdated({
                    ...booking,
                    ...{
                        status: 'cancelled_by_user'
                    }
                });
            })
            .catch(errorHandlerWithToast)
            .finally(() => setIsCancellingBooking(false));
    };

    const handleOnConfirm = (isConfirmed: boolean) => {
        if (isConfirmed) {
            handleCancelBooking()
        }

        setIsConfirmModalShown(false);
    };

    return (
        <>
            <div className="w-full mt-4">
                <div className="w-full md:w-[500px] mx-auto">
                    <div className="text-center text-sm py-5 border-b border-gray-50">
                        {subProject.name}
                    </div>

                    <div className="grid md:grid-cols-2 gap-4 my-8">
                        <div className="w-full">
                            <div className="w-full text-center text-white bg-uwi-primary-100 py-4 text-sm">
                                Booking Date
                            </div>
                            <div className="w-full text-center border-b border-x border-gray-50 py-4">
                                {formatDate(booking.bookingDate)}
                            </div>
                        </div>
                        
                        <div className="w-full">
                            <div className="w-full text-center text-white bg-uwi-primary-100 py-4 text-sm">
                                Booking Time
                            </div>
                            <div className="w-full text-center border-b border-x border-gray-50 py-4">
                                {`${formatBookingTimeTo12HourFormat(booking.startTime)} - ${formatBookingTimeTo12HourFormat(booking.endTime)}`}
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="w-full text-center text-white bg-uwi-primary-100 py-4 text-sm">
                                Agent
                            </div>
                            <div className="w-full text-center border-b border-x border-gray-50 py-4">
                                {booking.agentName}
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="w-full text-center text-white bg-uwi-primary-100 py-4 text-sm">
                                Reference Number
                            </div>
                            <div className="w-full text-center border-b border-x border-gray-50 py-4">
                                {booking.referenceNumber}
                            </div>
                        </div>
                    </div>

                    {(booking.status === 'active') && (
                        <div className="flex justify-center gap-4">
                            <button
                                className={`
                                    flex justify-center
                                    rounded-2xl
                                    text-[15px] font-bold
                                    px-4 md:px-8 py-4
                                    bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white
                                `}
                                onClick={() => onClickUpdateBooking()}
                            >
                                Update Booking
                            </button>

                            <button
                                className={`
                                    group
                                    flex justify-center items-center
                                    rounded-2xl
                                    text-[15px] font-bold
                                    px-4 md:px-8 py-4
                                    border border-uwi-primary-100 bg-white text-uwi-primary-100 hover:bg-uwi-primary-100 hover:text-white
                                `}
                                onClick={() => setIsConfirmModalShown(true)}
                            >
                                Cancel Booking
                                {(isCancellingBooking) && (
                                    <CircleLoaderIcon className="h-4 w-4 animate-spin ml-3 text-uwi-primary-100 group-hover:text-white" />
                                )}
                            </button>
                        </div>
                    )}

                    {(['cancelled_by_agent', 'cancelled_by_user'].includes(booking.status)) && (
                        <div className="rounded-lg text-center border border-red-800 bg-red-50 text-red-800 py-4 w-full">
                            {(booking.status === 'cancelled_by_agent') ? 'Your booking has been cancelled by our agent.' : 'You cancelled this booking'}
                        </div>
                    )}
                </div>
            </div>

            <ConfirmModal
                visible={isConfirmModalShown}
                onClose={() => setIsConfirmModalShown(false)}
                onConfirm={handleOnConfirm}
                message="Are you sure you want to cancel this booking?"
                title="Cancel Booking?"
                btn1Text="Yes"
                btn2Text="No"
            />
        </>
    );
};

export default BookingSummary;
