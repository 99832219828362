import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PaymentState {
    referenceId: string;
    amount: string;
    linkedAccountToken: string | null;
}

const initialState: PaymentState = {
    referenceId: '',
    amount: '',
    linkedAccountToken: null
}

export const paymentSlice = createSlice({
    name: 'payment',

    initialState,

    reducers: {
        setReferenceId: (state: PaymentState, action: PayloadAction<string>) => {
            state.referenceId = action.payload;
        },

        setAmount: (state: PaymentState, action: PayloadAction<string>) => {
            state.amount = action.payload;
        },

        setLinkedAccountToken: (state: PaymentState, action: PayloadAction<string | null>) => {
            state.linkedAccountToken = action.payload;
        },
    }
});

export const {
    setReferenceId,
    setAmount,
    setLinkedAccountToken
} = paymentSlice.actions;

export const selectReferenceId = (state: RootState) => state.payment.referenceId;
export const selectAmount = (state: RootState) => state.payment.amount;
export const selectLinkedAccountToken = (state: RootState) => state.payment.linkedAccountToken;

export default paymentSlice.reducer;