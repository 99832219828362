import { CircleExclamationSolidIcon, XMarkSolidIcon } from "../icons";

type Props = {
    errors: string[];
    onClose?: () => void;
    visible?: boolean;
    floatInMobile?: boolean;
};

const ErrorList = ({
    errors,
    onClose,
    visible,
    floatInMobile
}: Props) => {
    const handleClose = onClose || (() => {});

    return (
        <>
            {(errors.length > 0) && (visible) && (
                <div
                    className={`
                        w-[calc(100%-16px)] md:w-[calc(100%-24px)] md:mx-auto lg:w-full
                        bg-[#fff3cd] text-[#664d03] border-[#ffecb5]
                        rounded-xl
                        mb-4
                        ${(floatInMobile) ? 'fixed z-10 top-[70px] left-2 md:static' : ''}
                    `}
                >
                    <div
                        className={`
                            ${(floatInMobile) ? 'absolute right-3 top-3 md:hidden' : 'hidden'}
                        `}
                    >
                        <button
                            type="button"
                            onClick={() => handleClose()}
                            className="hover:bg-transparent focus:bg-transparent"
                        >
                            <XMarkSolidIcon className="w-5 h-5" />
                        </button>
                    </div>
                    <ul className="flex flex-col gap-y-2 text-sm rounded-lg p-4 pl-10 w-full">
                        {errors.map((err, idx) => (
                            <li
                                key={idx}
                                className="flex gap-3 items-center"
                            >
                                <span className="-ml-6">
                                    <CircleExclamationSolidIcon className="w-5 h-5"/>
                                </span>
                                <span>{err}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default ErrorList;