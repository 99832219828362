import { AxiosError } from 'axios';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'typedi';
import { useAppSelector, useAppDispatch } from 'src/hooks/generic';
import { HttpRequestFactory } from 'src/api/lib/HttpRequestFactory';
import { selectIsFromRedirect, setIsFromRedirect } from 'src/store/features/commonSlice';
import { selectLinkedAccountToken, setLinkedAccountToken } from 'src/store/features/paymentSlice';
import { setUser } from 'src/store/features/userSlice';
import store from 'src/store';

type Props = {
    children: React.ReactNode;
    onOpenModal?: () => void;
};

const url = new URL(window.location.toString());
const params = new URLSearchParams(url.search);

function ExternalLinkWrapper({ children, onOpenModal }: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const lat = useAppSelector(selectLinkedAccountToken);
    const isFromRedirect = useAppSelector(selectIsFromRedirect);

    const tempVal = useRef(false);

    useEffect(() => {
        if (params.get('clientId') || params.get('linked_account_token_id') || params.get('uwi_redirect')) {
            if (onOpenModal) {
                onOpenModal();

                if (params.get('linked_account_token_id')) {
                    if (!lat) {
                        store.dispatch(setLinkedAccountToken(params.get('linked_account_token_id')));
                        navigate('payment-account-selection');
                    }
                }

                if (params.get('uwi_redirect')) {
                    if (!isFromRedirect) {
                        store.dispatch(setIsFromRedirect(true));
                        if (params.get('uwi_redirect') === 'payment-success') {
                            navigate('payment-success');
                        }

                        if (params.get('uwi_redirect') === 'payment-options' || params.get('uwi_redirect') === 'payment-fail') {
                            navigate('/pay-reservation');
                        }
                    }
                }
            }
        }
    }, [onOpenModal, navigate, lat, isFromRedirect]);

    useEffect(() => {
        if (tempVal.current) {
            return;
        }

        tempVal.current = true;

        Container
            .get(HttpRequestFactory)
            .getImplementation()
            .setListener((error: AxiosError<any>) => {
                if (error?.response?.status === 403) {
                    dispatch(setUser(null));
                    navigate('/login');
                }
            });
    }, [navigate, dispatch]);

    return (
        <>
            {children}
        </>
    );
}

export default ExternalLinkWrapper;
