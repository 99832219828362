type Props = {
    className?: string;
};

const ArrowRightLongSolidIcon = ({ className }: Props) => {
    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M502.6,278.6l-128,128c-12.5,12.5-32.8,12.5-45.2,0c-12.5-12.5-12.5-32.8,0-45.2l73.4-73.4H32
                c-17.7,0-32-14.3-32-32.9S14.3,224,32,224h370.8l-73.4-73.4c-12.5-12.5-12.5-32.8,0-45.2s32.8-12.5,45.2,0l128,128
                C515.1,245.9,515.1,266.1,502.6,278.6z"
                fill="currentColor"
            />
        </svg>
    );
  }
  
  export default ArrowRightLongSolidIcon;