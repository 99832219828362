import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordField from 'src/components/common/PasswordField';
import { ErrorList, MobileNumberField } from 'src/components/common';
import { CircleLoaderIcon } from 'src/components/icons';
import { UserSessionService } from 'src/api/lib/UserSessionService';
import CaptchaField from 'src/components/common/captcha/CaptchaField';
import { useAppSelector } from 'src/hooks/generic';
import { capitalize } from 'src/helpers/string';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import {
    selectEmail,
    selectMobileNumber
} from 'src/store/features/preApproveLoanOtherInfoSlice';

const SignupPage = () => {
    const navigate = useNavigate();
    const { commonErrorHandler, setCommonErrors, errors } = useGenericErrorHandler(); 
    const [searchParams] = useSearchParams();

    const selectedEmail = useAppSelector(selectEmail);
    const selectedMobileNumber = useAppSelector(selectMobileNumber);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [hasChecked, setHasChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasCaptchaVerified, setHasCaptchaVerified] = useState(false);
    const [isErrorListVisible, setIsErrorListVisible] = useState(false);

    useEffect(() => {
        if (selectedMobileNumber) {
            setMobileNumber(selectedMobileNumber);
        } 

        if (selectedEmail) {
            setEmail(selectedEmail);

            const [namePart] = selectedEmail.split('@');
            if (namePart) {
                const namePartPcs = namePart.split('.');
                if (namePartPcs.length === 2) {
                    setFirstName(capitalize(namePartPcs[0]));
                    setLastName(capitalize(namePartPcs[1]));
                }
            }
        }
    }, [selectedMobileNumber, selectedEmail]);

    const validate = () => {
        const errors: string[] = [];

        if (password) {
            const passwordRule = new RegExp(/(?:(?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/);

            if (!passwordRule.test(password)) {
                errors.push('Password should contain at least one uppercase, one lowercase, one numeric and one special character.');
            }
        }

        if (password !== confirmPassword) {
            errors.push('Confirm password should be equal to password.');
        }

        if (!hasChecked) {
            errors.push('You should accept first the Terms of Use.')
        }

        if (captcha.length === 0) {
            errors.push('Verify captcha first!')
        }

        setCommonErrors(errors);
        setIsErrorListVisible(errors.length > 0);

        return (errors.length === 0);
    };

    const handleSubmit = async () => {
        if (!validate()) {
            return;
        }

        setIsSubmitting(true);

        try {
            await Container.get(UserSessionService).signup({
                email,
                password,
                firstName,
                lastName,
                mobileNumber,
                captcha
            });

            try {
                await Container.get(UserSessionService).login(
                    email,
                    password
                );

                const referrer = searchParams.get('referrer');

                if (referrer) {
                    navigate(referrer);
                }
                else {
                    navigate('/dashboard-pre-qualifications');
                }
            }
            catch (err) {
                commonErrorHandler(err);
            }
        }
        catch (ex) {
            commonErrorHandler(ex);
            setIsErrorListVisible(true);
            setHasCaptchaVerified(false);
            setCaptcha('');
        }
        finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl w-full flex justify-center shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="w-full pt-10">
                    <div className="w-full overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px]">
                        <div className="flex flex-col items-center w-full px-6 md:px-0 pb-2">
                            <form
                                className="flex flex-col w-fit"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <ErrorList
                                    errors={errors}
                                    visible={isErrorListVisible}
                                    onClose={() => setIsErrorListVisible(false)}
                                    floatInMobile={true}
                                />

                                <div className="w-full flex flex-col md:flex-row mb-8 gap-4 justify-center">
                                    <div className="flex flex-col w-full md:w-1/2">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="mb-2 block text-center form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-field bg-white/40"
                                                placeholder="Email"
                                                autoComplete="username"
                                                id="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                required={true}
                                                value={email}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="password" className="mb-2 block text-center form-label">Password</label>
                                            <PasswordField
                                                className="form-field bg-white/40"
                                                attrs={{
                                                    id: "password",
                                                    placeholder: "Password",
                                                    autoComplete: "current-password",
                                                    required: true,
                                                    minLength: 8
                                                }}
                                                onChange={(password) => setPassword(password)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="confirmPassword" className="mb-2 block text-center form-label">Confirm Password</label>
                                            <PasswordField
                                                className="form-field bg-white/40"
                                                attrs={{
                                                    id: "confirmPassword",
                                                    placeholder: "Confirm Password",
                                                    required: true,
                                                    minLength: 8
                                                }}
                                                onChange={(confirmPassword) => setConfirmPassword(confirmPassword)}
                                            />
                                        </div>
                                        <div className="flex gap-4">
                                            <div className="flex-1 mb-3">
                                                <label htmlFor="first_name" className="mb-2 block text-center form-label">First Name</label>
                                                <input
                                                    type="text"
                                                    className="form-field bg-white/40"
                                                    placeholder="First Name"
                                                    id="first_name"
                                                    required={true}
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </div>
                                            <div className="flex-1 mb-3">
                                                <label htmlFor="last_name" className="mb-2 block text-center form-label">Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-field bg-white/40"
                                                    placeholder="Last Name"
                                                    id="last_name"
                                                    required={true}
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="last_name" className="mb-2 block text-center form-label">Mobile Number</label>
                                            <MobileNumberField
                                                attrs={{
                                                    placeholder: 'Mobile',
                                                    required: true
                                                }}
                                                onChange={(val) => setMobileNumber(val)}
                                                value={mobileNumber}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1 flex-grow-0 w-full gap-3 items-center md:items-stretch">
                                        <div className="flex flex-col max-w-fit md:mt-1">
                                            <label htmlFor="captcha" className="mb-2 block text-center form-label">
                                                Slide Puzzle/Slider to Verify
                                            </label>
                                            <CaptchaField
                                                onChange={(val) => {
                                                    setCaptcha(val);
                                                    setHasCaptchaVerified(true);
                                                }}
                                                hasVerified={hasCaptchaVerified}
                                            />
                                        </div>
                                        <div className="flex items-center mt-1">
                                            <input
                                                type="checkbox"
                                                className="w-8 h-8 uwi-input rounded-4xl border-2 border-gray-200 mr-4"
                                                checked={hasChecked}
                                                onChange={() => setHasChecked(!hasChecked)}
                                                id="terms_checkbox"
                                            />
                                            <label className="form-label !text-sm" htmlFor="terms_checkbox">
                                                I have read and understood the&nbsp;
                                                <button
                                                    className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                                    type="button"
                                                    onClick={() => navigate('/terms-of-use')}
                                                >
                                                    Terms of Use
                                                </button>
                                                &nbsp;and&nbsp;
                                                <button
                                                    className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                                    type="button"
                                                    onClick={() => navigate('/privacy-policy')}
                                                >
                                                    Privacy Policy
                                                </button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="w-full flex justify-center">
                                    <button
                                        className={`
                                            flex justify-center items-center
                                            rounded-2xl
                                            w-full max-w-[360px] py-4
                                            text-[15px] font-bold
                                            ${(!isSubmitting) ? 'bg-uwi-primary-100 hover:bg-uwi-primary-200 border text-white' : 'bg-gray-100 text-white'}
                                        `}
                                        disabled={isSubmitting}
                                    >
                                        Register
                                        {isSubmitting && (
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                                        )}
                                    </button>
                                </div>

                                <div className="flex justify-center text-[15px] mt-4">
                                    Already have an account?
                                    <button
                                        className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent ml-2"
                                        type="button"
                                        onClick={() => {
                                            navigate('/login');
                                        }}
                                    >Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignupPage;
