import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'typedi';
import {
    CircleLoaderIcon,
    CirclePlusSolidIcon,
    AngleDownSolidIcon,
    AngleRightSolidIcon
} from 'src/components/icons';
import useAuth from 'src/hooks/useAuth';
import { PreQualificationApi, PreQualificationTableRow } from 'src/api/PreQualificationApi';
import { formatDateToYYYYMMDD } from 'src/helpers/date';
import ButtonWithMenu from 'src/page_components/dashboard-pre-qualifications/ButtonWithMenu';
import { useAppSelector } from 'src/hooks/generic';
import {
    selectHasBookingFeature,
    selectHasReservationPaymentsFeature,
    selectProjectId,
    selectIsProjectOnlyView
} from 'src/store/features/commonSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { hasProp } from 'src/helpers/object';

interface PreQualificationTableRowWithControl {
    preQualification: PreQualificationTableRow;
    isShowProperty: boolean;
};

const DashboardPreQualificationsPage = () => {
    const navigate = useNavigate();
    const { errorHandlerWithToast } = useGenericErrorHandler();
    const { user, hasLoadedFromStorage } = useAuth();

    const projectId = useAppSelector(selectProjectId);
    const isProjectOnlyView = useAppSelector(selectIsProjectOnlyView);
    const hasBookingFeature = useAppSelector(selectHasBookingFeature);
    const hasReservationPaymentsFeature = useAppSelector(selectHasReservationPaymentsFeature);

    const [isLoading, setIsLoading] = useState(true);
    const [preQualifications, setPreQualifications] = useState<PreQualificationTableRowWithControl[]>([]);
    const [childTableRowColSpan, setChildTableRowColSpan] = useState(5);

    useEffectOnce(() => {
        const mediaQuery = window.matchMedia('(min-width: 768px)');
        if (mediaQuery.matches) {
            setChildTableRowColSpan(5);
        }
        else {
            setChildTableRowColSpan(3);
        }

        const handleResize = () => {
            if (mediaQuery.matches) {
                setChildTableRowColSpan(5);
            }
            else {
                setChildTableRowColSpan(3);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useEffect(() => {
        setIsLoading(true);

        if (!isProjectOnlyView || !projectId) {
            Container
                .get(PreQualificationApi)
                .list()
                .then((resp) => {
                    const orig:PreQualificationTableRowWithControl[] = resp.map((o) => ({
                        preQualification: o,
                        isShowProperty: false
                    }));

                    setPreQualifications(orig);
                })
                .catch(errorHandlerWithToast)
                .finally(() => {
                    setIsLoading(false);
                });   
        }
        else {
            Container
                .get(PreQualificationApi)
                .search([projectId])
                .then((resp) => {
                    const orig:PreQualificationTableRowWithControl[] = resp.map((o) => ({
                        preQualification: o,
                        isShowProperty: false
                    }));

                    setPreQualifications(orig);
                })
                .catch(errorHandlerWithToast)
                .finally(() => {
                    setIsLoading(false);
                });   
        }
    }, [errorHandlerWithToast, projectId, isProjectOnlyView]);

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent('/dashboard-pre-qualifications')}`);
        }
    }, [user, navigate, hasLoadedFromStorage]);

    const handleShowChildTableRow = (idx: number, isShow: boolean) => {
        const orig = [...preQualifications];

        if (idx !== -1) {
            orig[idx] = {
                ...orig[idx],
                ...{ isShowProperty: isShow }
            };
        }

        setPreQualifications(orig);
    };

    const handlePayReservation = (preQualificationId: string, subProjectId: string) => {
        window.localStorage.setItem('pre_qualification_id', (preQualificationId ? preQualificationId : ''));
        window.localStorage.setItem('sub_project_id', (subProjectId ? subProjectId : ''));

        navigate('/pay-reservation');
    }

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl min-h-[456px] shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                        <div className="flex mb-4 pb-4 border-b border-solid border-gray-50">
                            <div className="w-3/4 flex items-baseline flex-wrap md:flex-nowrap">
                                <div className="text-xl text-uwi-primary-100 mr-1 font-bold">Hi {(user) ? user.firstName : ''}!</div>
                                <div className="text-sm text-black mt-1 md:mt-0">Here are your Home Loan Assessments below.</div>
                            </div>
                            <div className="w-1/4 flex justify-end">
                                <button
                                    className={`
                                        flex justify-center items-center
                                        text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent
                                    `}
                                    onClick={() => navigate('/pre-qualification')}
                                >
                                    <CirclePlusSolidIcon className="w-6 h-6 mr-1.5" />
                                    <span className="text-sm hidden md:!inline-block md:!visible">New Home Loan Assessment</span>
                                    <span className="text-sm !inline-block md:!hidden">New</span>
                                </button>
                            </div>
                        </div>

                        <table className="w-full !m-0 !border-none">
                            <thead className="border-b border-solid border-gray-50">
                                <tr>
                                    <th></th>
                                    <th
                                        className={`
                                            font-normal text-left text-sm whitespace-nowrap
                                            border-0 border-none p-0 pb-4 pl-4
                                            ${(hasBookingFeature) ? 'lg:w-[180px]' : 'lg:w-[200px]'}
                                        `}
                                    >
                                        Date
                                    </th>
                                    <th
                                        className={`
                                            font-normal text-left text-sm whitespace-nowrap
                                            border-none p-0 pb-4
                                            ${(hasBookingFeature) ? 'lg:w-[280px]' : 'lg:w-[320px]' }
                                        `}
                                    >
                                        Reference No.
                                    </th>
                                    <th
                                        className={`
                                            hidden md:!table-cell md:!visible font-normal text-left text-sm
                                            border-none p-0 pb-4 whitespace-nowrap
                                            ${(hasBookingFeature) ? 'lg:w-[180px]' : 'lg:w-[200px]'}
                                        `}
                                    >
                                        Project Value
                                    </th>
                                    <th
                                        className={`
                                            hidden md:!table-cell md:!visible font-normal text-left text-sm
                                            border-none p-0 pb-4 whitespace-nowrap
                                            ${(hasBookingFeature) ? 'lg:w-[120px]' : 'lg:w-[150px]'}
                                        `}
                                    >
                                        Status
                                    </th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Action</th>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {(isLoading) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none !p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white inline-block" />
                                            &nbsp;Fetching records...
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading && preQualifications.length === 0) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            No records found
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading) && (
                                    preQualifications.map((pq, idx) => (
                                        <React.Fragment key={pq.preQualification.id}>
                                            <tr
                                                key={`parent_${pq.preQualification.id}`}
                                                className={`
                                                    w-full h-full items-center
                                                    ${((idx % 2) === 1) ? 'bg-gray-75' : '' }
                                                `}
                                            >
                                                <td className="p-0 py-4 pl-4">
                                                    <button
                                                        onClick={() => handleShowChildTableRow(idx, !pq.isShowProperty)}
                                                        className="text-gray-100 hover:text-gray-300 hover:bg-transparent focus:bg-transparent"
                                                    >
                                                        {(pq.isShowProperty) ? (
                                                            <AngleDownSolidIcon
                                                                className="w-4 h-4"
                                                            />
                                                        ) : (
                                                            <AngleRightSolidIcon
                                                                className="w-4 h-4"
                                                            />
                                                        )}
                                                    </button>
                                                </td>
                                                <td className="text-left text-sm border-0 border-none align-middle py-4 px-6 whitespace-nowrap">
                                                    {formatDateToYYYYMMDD(pq.preQualification.createdAt)}
                                                </td>
                                                <td className="text-left text-uwi-primary-100 text-sm align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                    <span className="text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent">
                                                        {pq.preQualification.referenceNumber}
                                                    </span>
                                                </td>
                                                <td className="hidden md:!table-cell md:!visible text-left text-sm items-center align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                    {pq.preQualification.projectCost.toLocaleString('en-US')}
                                                </td>
                                                <td className="hidden md:!table-cell md:!visible text-sm align-middle text-left border-0 border-none p-0 py-4 whitespace-nowrap uppercase">
                                                    {pq.preQualification.status}
                                                </td>
                                                <td className="border-0 border-none p-0 py-3 pr-4 align-middle whitespace-nowrap">
                                                    <div className="lg:!flex items-center !hidden gap-3">
                                                        <button
                                                            className="w-full px-4 py-2 text-sm border border-uwi-primary-100 text-uwi-primary-100 hover:text-uwi-primary-200 hover:border-uwi-primary-200 hover:bg-transparent focus:bg-transparent"
                                                            onClick={() => {
                                                                navigate(`/pre-qualification-view?id=${pq.preQualification.id}`);
                                                            }}
                                                        >
                                                            View Result
                                                        </button>
                                                        <button
                                                            className="w-full px-4 py-2 text-sm border border-uwi-primary-100 text-uwi-primary-100 hover:text-uwi-primary-200 hover:border-uwi-primary-200 hover:bg-transparent focus:bg-transparent"
                                                            onClick={() => handleShowChildTableRow(idx, !pq.isShowProperty)}
                                                        >
                                                            {(pq.isShowProperty) ? 'Hide Property' : 'Show Property'}
                                                        </button>
                                                    </div>

                                                    <div className="!flex items-center lg:!hidden relative">
                                                        <ButtonWithMenu
                                                            preQualificationId={pq.preQualification.id}
                                                            isShowProperty={pq.isShowProperty}
                                                            onToggleIsShowProperty={(isShow) => handleShowChildTableRow(idx, isShow)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            {(pq.preQualification.subProjects) && (
                                                pq.preQualification.subProjects.map((sp) => (
                                                    <React.Fragment key={`child_${pq.preQualification.id}_${sp.id}`}>
                                                        <tr
                                                            className={`
                                                                w-full h-full items-center bg-gray-200 transition-all
                                                                ${(pq.isShowProperty) ? 'table-row' : 'hidden'}
                                                            `}
                                                        >
                                                            <td
                                                                colSpan={childTableRowColSpan}
                                                                className="p-0 py-2 pl-6"
                                                            >
                                                                    <div className="flex gap-4 items-center">
                                                                    <div className="w-8 h-8 bg-gray-100">
                                                                        <img
                                                                            src={sp.image}
                                                                            alt="test img"
                                                                            className="w-full h-full"
                                                                        />
                                                                    </div>
                                                                    <span className="text-sm whitespace-nowrap">{sp.projectTitle} - {sp.name}</span>
                                                                </div>
                                                            </td>
                                                            <td className="border-0 border-none p-0 py-2 pr-4 align-middle whitespace-nowrap flex gap-3">
                                                                {(hasBookingFeature) && (
                                                                    <button
                                                                        className="px-4 py-2 text-sm border border-uwi-primary-100 bg-uwi-primary-100 hover:bg-uwi-primary-200 hover:border-uwi-primary-200 text-white"
                                                                        onClick={() => {
                                                                            navigate(`/book-a-tour?preQualificationId=${pq.preQualification.id}&subProjectId=${sp.id}&referrer=${encodeURIComponent('/dashboard-pre-qualifications')}`);
                                                                        }}
                                                                    >
                                                                        Book a Tour
                                                                    </button>
                                                                )}

                                                                {(hasReservationPaymentsFeature) && (
                                                                    <button
                                                                        className="px-4 py-2 text-sm border border-uwi-primary-100 bg-uwi-primary-100 hover:bg-uwi-primary-200 hover:border-uwi-primary-200 text-white"
                                                                        onClick={() => handlePayReservation(pq.preQualification.id, sp.id)}
                                                                    >
                                                                        Pay Reservation
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardPreQualificationsPage;
