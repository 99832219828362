import { useAppSelector } from 'src/hooks/generic';
import {
    selectMonthlyIncome,
    selectWorkExperience,
    selectWorkType,
    selectIsRegularEmployee,
    selectIsBusinessProfitable,
    selectBusinessProprietorship
} from 'src/store/features/preApproveLoanWorkInfoSlice';

type Props = {
    className?: string;
    headerClassName?: string;
    listClassName?: string;
    listEntryClassName?: string;
    listEntryLeftClassName?: string;
    listEntryLeftValueClassName?: string;
};

const WorkInfo = ({
    className,
    headerClassName,
    listEntryLeftClassName,
    listEntryLeftValueClassName
}: Props) => {
    const workType = useAppSelector(selectWorkType);
    const isRegularEmployee = useAppSelector(selectIsRegularEmployee);
    const isBusinessProfitable = useAppSelector(selectIsBusinessProfitable);
    const workExperience = useAppSelector(selectWorkExperience);
    const businessProprietorship = useAppSelector(selectBusinessProprietorship);
    const monthlyIncome = useAppSelector(selectMonthlyIncome);

    return (
        <div className={`w-full ${className || ''}`}>
            <h3 className={`text-base text-white bg-uwi-primary-100 py-4 font-arial font-bold mb-2 ${headerClassName || ''}`}>WORK INFORMATION</h3>
            <table className="w-full !m-0 !border-none">
                <tbody>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Type:</td>
                        <td className={listEntryLeftValueClassName || ''}>{workType}</td>
                    </tr>
                    {(workType !== 'Self-Employed') && (
                        <tr>
                            <td className={listEntryLeftClassName || ''}>Regular Employee?:</td>
                            <td className={listEntryLeftValueClassName || ''}>{(isRegularEmployee) ? 'Yes' : 'No'}</td>
                        </tr>
                    )}
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Experience:</td>
                        <td className={listEntryLeftValueClassName || ''}>{workExperience}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Gross Monthly Income:</td>
                        <td className={listEntryLeftValueClassName || ''}>{monthlyIncome.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</td>
                    </tr>
                    {(workType === 'Self-Employed') && (
                        <>
                            <tr>
                                <td className={listEntryLeftClassName || ''}>Is your business profitable in the last 2-3 years?:</td>
                                <td className={listEntryLeftValueClassName || ''}>{(isBusinessProfitable) ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td className={listEntryLeftClassName || ''}>Business Proprietorship</td>
                                <td className={listEntryLeftValueClassName || ''}>{businessProprietorship}</td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default WorkInfo;
