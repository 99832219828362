type Props = {
    className?: string;
};

const BookATourIcon = ({ className }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className={className}
        >
            <g>
                <path d="M165.9,348.5c4.9-4,10.1-7.7,15.4-11l-5.9-9.4c-5.7,3.5-11.2,7.5-16.5,11.8L165.9,348.5z" fill="currentColor"/>
                <path d="M198,328.5c5.7-2.6,11.6-4.9,17.6-6.8l-3.4-10.6c-6.4,2.1-12.8,4.5-18.9,7.3L198,328.5z" fill="currentColor"/>
                <path d="M139.3,375.5c4-5,8.2-9.8,12.6-14.3l-7.9-7.8c-4.6,4.7-9.1,9.8-13.4,15.2L139.3,375.5z" fill="currentColor"/>
                <path d="M118.7,407.6c2.9-5.7,6.1-11.2,9.5-16.6l-9.3-6c-3.6,5.6-7,11.5-10,17.5L118.7,407.6z" fill="currentColor"/>
                <path d="M364.4,216.3l-11-1.1c-0.7,6.4-1.8,12.6-3.3,18.5l10.7,2.8C362.5,230,363.7,223.3,364.4,216.3z" fill="currentColor"/>
                <path d="M334.4,267l8.8,6.7c4.1-5.4,7.7-11.4,10.7-17.8l-10.1-4.7C341.2,256.9,338,262.2,334.4,267z" fill="currentColor"/>
                <path d="M321.8,280.2c-4.6,3.8-9.8,7.3-15.3,10.2l5.1,9.8c6.3-3.3,12.1-7.1,17.3-11.5L321.8,280.2z" fill="currentColor"/>
                <path d="M289.3,297.4c-5.7,1.8-11.9,3.2-18.3,4.2l1.7,11c7-1.1,13.7-2.6,19.9-4.5L289.3,297.4z" fill="currentColor"/>
                <path d="M233.9,317c6.1-1.2,12.4-2,18.8-2.5l-0.9-11.1c-6.8,0.5-13.5,1.5-20,2.7L233.9,317z" fill="currentColor"/>
                <path
                    d="M510.2,420.7l-73.9-239c-4-12.9-16.7-22.2-30.1-22.2h-82.5l-1.8,3.7c-1.3,2.7-2.4,4.8-3.6,6.7l-22.1,40.6h95.6l60.8,196.4
                    H59.5l60.8-196.4h32.5l-21.9-40.3l-0.2-0.3c-1.2-1.9-2.3-4.1-3.6-6.7l-1.8-3.7h-19.4c-13.5,0-26.1,9.3-30.1,22.2l-73.9,239
                    c-2.8,8.9-1.2,18.3,4.2,25.7C11.5,453.7,20,458,29.4,458h453.3c9.4,0,17.8-4.2,23.3-11.6C511.4,439,512.9,429.6,510.2,420.7z"
                    fill="currentColor"
                />
                <path
                    d="M224.4,278.2l61.8-113.7c6.8-11,10.7-24,10.7-37.9c0-40.1-32.5-72.5-72.5-72.5s-72.5,32.5-72.5,72.5
                    c0,13.9,3.9,26.9,10.7,37.9L224.4,278.2z M224.4,96.9c16.4,0,29.7,13.3,29.7,29.7c0,16.4-13.3,29.7-29.7,29.7
                    c-16.4,0-29.7-13.3-29.7-29.7C194.8,110.2,208,96.9,224.4,96.9z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
  }
  
  export default BookATourIcon;