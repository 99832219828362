import PhoneSolidIcon from "./PhoneSolidIcon";

type Props = {
    innerClassName?: string;
    className?: string;
};

const PhoneIcon = ({
    innerClassName,
    className
}: Props) => {
    return (
        <div
            className={`rounded-full ${className}`}
        >
            <PhoneSolidIcon
                className={innerClassName}
            />
        </div>
    );
  }
  
  export default PhoneIcon;