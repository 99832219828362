import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import { Modal } from 'src/components/common';
import store from 'src/store';
import { refreshLoggedUserFromLocalStorage } from 'src/store/features/userSlice';
import CtaButton from 'src/components/layout/CtaButton';
import useLoadWidget from 'src/hooks/useLoadWidget';
import Layout from 'src/components/layout/Layout';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import DashboardHomeLoanApplicationsPage from './pages/dashboard_home_loan_applications';
import PreQualificationPage from './pages/pre-qualification';
import SignupPage from './pages/signup';
import TermsOfUsePage from './pages/terms_of_use';
import PrivacyPolicyPage from './pages/privacy_policy';
import PreQualificationViewPage from './pages/pre-qualification-view';
import HomeLoanApplicationPage from './pages/home_loan_application';
import ForgotPassword from './pages/forgot_password';
import WidgetApiWrapper from './components/layout/WidgetApiWrapper';
import ExternalLinkWrapper from './components/layout/ExternalLinkWrapper';
import BookATourPage from './pages/book-a-tour';
import DashboardBookingsPage from './pages/dashboard-bookings';
import DashboardPreQualificationsPage from './pages/dashboard-pre-qualifications';
import ViewBookingPage from './pages/view-booking';
import PayReservationPage from './pages/pay-reservation';
import PaymentAccountSelectionPage from './pages/payment-account-selection';
import PaymentSuccessPage from './pages/payment-success';
import DashboardPaymentReservationsPage from './pages/dashboard-payment-reservations';

function App() {
    const loadWidget = useLoadWidget(store);

    const tempVal = useRef(false);

    const [isModalShown, setIsModalShown] = useState(false);
    const [isCtaButtonVisible, setIsCtaButtonVisible] = useState(true);

    useEffect(() => {
        if (tempVal.current) {
            return;
        }

        tempVal.current = true;

        store.dispatch(refreshLoggedUserFromLocalStorage());

        loadWidget();

    }, [loadWidget]);

    return (
        <Provider store={store}>
            <div className="uwi-content font-arial">
                <div className="relative">
                    {(isCtaButtonVisible) && (
                        <CtaButton
                            onClick={() => setIsModalShown(true)}
                        />
                    )}

                    <MemoryRouter>
                        <ExternalLinkWrapper
                            onOpenModal={() => {
                                setIsModalShown(true);
                                setIsCtaButtonVisible(false);
                            }}
                        >
                            <WidgetApiWrapper
                                onOpenModal={() => setIsModalShown(true)}
                            >
                                <Modal
                                    visible={isModalShown}
                                    onClose={() => setIsModalShown(false)}
                                    className={`
                                        w-screen h-screen md:flex md:flex-col md:justify-center md:items-center
                                    `}
                                >
                                    <Layout
                                        onCloseModal={() => setIsModalShown(false)}
                                        hideCloseButton={!isCtaButtonVisible}
                                    >
                                        <Routes>
                                            <Route path="/" element={<HomePage />} />
                                            <Route path="pre-qualification" element={<PreQualificationPage />} />
                                            <Route path="pre-qualification-view" element={<PreQualificationViewPage />} />
                                            <Route path="home-loan-apply" element={<HomeLoanApplicationPage />} />
                                            <Route path="login" element={<LoginPage />} />
                                            <Route path="signup" element={<SignupPage />} />
                                            <Route path="terms-of-use" element={<TermsOfUsePage />} />
                                            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
                                            <Route path="dashboard-pre-qualifications" element={<DashboardPreQualificationsPage />} />
                                            <Route path="dashboard_home_loan_applications" element={<DashboardHomeLoanApplicationsPage />} />
                                            <Route path="forgot_password" element={<ForgotPassword />} />
                                            <Route path="book-a-tour" element={<BookATourPage />} />
                                            <Route path="dashboard-bookings" element={<DashboardBookingsPage />} />
                                            <Route path="view-booking" element={<ViewBookingPage />} />
                                            <Route path="pay-reservation" element={<PayReservationPage />} />
                                            <Route path="payment-account-selection" element={<PaymentAccountSelectionPage />} />
                                            <Route path="payment-success" element={<PaymentSuccessPage />} />
                                            <Route path="dashboard-payment-reservations" element={<DashboardPaymentReservationsPage />} />
                                        </Routes>
                                    </Layout>
                                </Modal>
                            </WidgetApiWrapper>
                        </ExternalLinkWrapper>
                    </MemoryRouter>

                    <div id="uwi_widget_high_portal"></div>
                </div>
            </div>
        </Provider>
    );
}

export default App;
