import { useCallback } from 'react';
import { useAppDispatch } from 'src/hooks/generic';
import { addToastObject, ToastObjectType } from 'src/store/features/toastDataSlice';

const useToast = () => {
    const dispatch = useAppDispatch();

    const addToast = useCallback((message: string, type: ToastObjectType) => {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);

        dispatch(addToastObject({
            id: `toast_${new Date().getTime()}_${(array.length > 0) ? array[0] : ''}`,
            message,
            type
        }));
    }, [dispatch]);

    return addToast;
};

export default useToast;
