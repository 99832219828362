import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface SavedQueryState {
    currentAddress: string;
    loanType: string;
    projectCost: number;
    nationality: string;
    grossMonthlyIncome: number;
    workExperience: {
        job: string;
        years: number;
    };
    extraIncome: {
        source: string;
        amount: number;
    };
    birthDate: {
        month: number;
        day: number;
        year: number;
    };
    debtInfo: {
        debtType: string;
        amount: number;
    };
    coBorrowerWorkExperience: {
        job: string;
        monthlyIncomeType: string;
    };
}

const saveSavedQueryState = (queryState: SavedQueryState) => {
    localStorage.setItem('savedQueryState', JSON.stringify(queryState));
};

const loadSavedQuery = (): SavedQueryState => {
    try {
        if (typeof window !== 'undefined' && window.localStorage) {
            const serializedState = window.localStorage.getItem('savedQueryState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
    
                return parsedState as SavedQueryState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        currentAddress: '',
        loanType: '',
        projectCost: 0,
        nationality: '',
        grossMonthlyIncome: 0,
        workExperience: {
            job: '',
            years: 0
        },
        extraIncome: {
            source: '',
            amount: 0
        },
        birthDate: {
            month: 0,
            day: 1,
            year: 2004
        },
        debtInfo: {
            debtType: '',
            amount: 0
        },
        coBorrowerWorkExperience: {
            job: '',
            monthlyIncomeType: ''
        }
    };
}

const initialState = loadSavedQuery();

export const savedQuerySlice = createSlice({
    name: 'savedQuery',

    initialState,

    reducers: {
        setCurrentAddress: (state: SavedQueryState, action: PayloadAction<string>) => {
            state.currentAddress = action.payload;
            saveSavedQueryState(state);
        },

        setBirthDate: (state: SavedQueryState, action: PayloadAction<{ month: number; day: number; year: number; }>) => {
            state.birthDate = action.payload;
            saveSavedQueryState(state);
        },

        setLoanType: (state: SavedQueryState, action: PayloadAction<string>) => {
            state.loanType = action.payload;
            saveSavedQueryState(state);
        },

        setProjectCost: (state: SavedQueryState, action: PayloadAction<number>) => {
            state.projectCost = action.payload;
            saveSavedQueryState(state);
        },

        setNationality: (state: SavedQueryState, action: PayloadAction<string>) => {
            state.nationality = action.payload;
            saveSavedQueryState(state);
        },

        setWorkExperience: (state: SavedQueryState, action: PayloadAction<{ job: string; years: number; }>) => {
            state.workExperience = action.payload;
            saveSavedQueryState(state);
        },

        setGrossMonthlyIncome: (state: SavedQueryState, action: PayloadAction<number>) => {
            state.grossMonthlyIncome = action.payload;
            saveSavedQueryState(state);
        },

        setExtraIncome: (state: SavedQueryState, action: PayloadAction<{ source: string; amount: number; }>) => {
            state.extraIncome = action.payload;
            saveSavedQueryState(state);
        },

        setDebtInfo: (state: SavedQueryState, action: PayloadAction<{ debtType: string; amount: number; }>) => {
            state.debtInfo = action.payload;
            saveSavedQueryState(state);
        },

        setCoBorrowerWorkExperience: (state: SavedQueryState, action: PayloadAction<{ job: string; monthlyIncomeType: string; }>) => {
            state.coBorrowerWorkExperience = action.payload;
            saveSavedQueryState(state);
        }
    }
});

export const {
    setCurrentAddress,
    setBirthDate,
    setLoanType,
    setProjectCost,
    setNationality,
    setWorkExperience,
    setGrossMonthlyIncome,
    setExtraIncome,
    setDebtInfo,
    setCoBorrowerWorkExperience
} = savedQuerySlice.actions;

export const selectCurrentAddress = (state: RootState) => state.savedQuery.currentAddress;
export const selectLoanType = (state: RootState) => state.savedQuery.loanType;
export const selectProjectCost = (state: RootState) => state.savedQuery.projectCost;
export const selectBirthdate = (state: RootState) => state.savedQuery.birthDate;
export const selectNationality = (state: RootState) => state.savedQuery.nationality;
export const selectWorkExperience = (state: RootState) => state.savedQuery.workExperience;
export const selectGrossMonthlyIncome = (state: RootState) => state.savedQuery.grossMonthlyIncome;
export const selectExtraIncome = (state: RootState) => state.savedQuery.extraIncome;
export const selectDebtInfo = (state: RootState) => state.savedQuery.debtInfo;
export const selectCoBorrowerWorkExperience = (state: RootState) => state.savedQuery.coBorrowerWorkExperience;

export default savedQuerySlice.reducer;