import { CircleExclamationSolidIcon, CircleQuestionSolid } from '../icons';

type IconType = 'exclamation' | 'question';

type Props = {
    className?: string;
    iconClassName?: string;
    iconType?: IconType;
    text: string;
    messageClassName?: string;
    messageCaretClassName?: string;
};

export default function Tooltip({
    className,
    iconClassName,
    text,
    iconType = 'exclamation',
    messageClassName,
    messageCaretClassName
}: Props) {
    return (
        <div className={`group relative ${className || ''}`}>
            <div className="rounded-full cursor-pointer">
                {(iconType === 'exclamation') && (
                    <CircleExclamationSolidIcon
                        className={`w-5 h-5 text-uwi-primary-100 ${iconClassName || ''}`}
                    />
                )}
                {(iconType === 'question') && (
                    <CircleQuestionSolid
                        className={`w-5 h-5 text-uwi-primary-100 ${iconClassName || ''}`}
                    />
                )}
            </div>
            <div className={`
                absolute w-[200px] md:w-[250px] rounded-lg p-2 z-[1]
                hidden group-hover:!block
                top-[calc(100%+10px)]
                left-[calc(50%)]
                -translate-x-[50%]
                border-2 border-uwi-primary-100
                bg-white
                ${messageClassName || ''}
            `}>
                <span
                    className={`
                    absolute w-0 h-0
                    border-t-[6px] border-t-uwi-primary-100 border-x-[6px] border-x-transparent border-solid
                    bottom-[calc(100%)]
                    left-[calc(50%-6px)]
                    rotate-180
                    ${messageCaretClassName || ''}
                `}
                />
                <div className="text-black text-center text-xs leading-tight">
                    {text}
                </div>
            </div>
        </div>
    );
}