import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectDeveloperName,
    selectProjectCost,
    selectProjectStatus,
    selectProjectType,
    selectRegion,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import projectStatusesWithDeveloper from 'src/data/projectStatusesWithDeveloper.json';

const usePropertyInfoValidation = () => {
    const dispatch = useAppDispatch();
    const projectCost = useAppSelector(selectProjectCost);
    const projectType = useAppSelector(selectProjectType);
    const projectStatus = useAppSelector(selectProjectStatus);
    const developerName = useAppSelector(selectDeveloperName);
    const region = useAppSelector(selectRegion);
    const formErrors = useAppSelector(selectFormErrors);

    const validateForm = async () => {
        const errors:Record<string, string> = {};

        if (projectCost <= 0) {
            errors.projectCost = 'Project cost should be greater than 0.';
        }

        if (isNaN(projectCost)) {
            errors.projectCost = 'Project cost is required.';
        }

        if (projectType.trim().length === 0) {
            errors.projectType = 'Project type is required.';
        }

        if (projectStatus.trim().length === 0) {
            errors.projectStatus = 'Project status is required';
        }

        if (
            projectStatusesWithDeveloper.includes(projectStatus)
            && developerName.trim().length === 0
        ) {
            errors.developerName = 'Developer name is required';
        }

        if (region.trim().length === 0) {
            errors.region = 'Address is required';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
}

export default usePropertyInfoValidation;