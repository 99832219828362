import Tooltip from "./Tooltip";

type Props = {
    className?: string;
    contentsWrapperClassName?: string;
    label: string;
    children: JSX.Element | JSX.Element[];
    showTooltip?: boolean;
    tooltipText?: string;
};

export default function FormGroup({
    className,
    label,
    children,
    contentsWrapperClassName,
    showTooltip = false,
    tooltipText = "Tooltip Text"
}: Props) {
    return (
        <div className={`w-full ${className || ''}`}>
            <section className="w-full flex justify-center items-center gap-3">
                <label className={`form-label mb-2 text-center block ${!showTooltip ? "w-full" : ''}`}>{ label }</label>
                {
                (showTooltip)
                ? <Tooltip className={`mb-2`} text={tooltipText} />
                : <></>
            }
            </section>
            <div className={`w-full relative ${contentsWrapperClassName || ''}`}>
                {children}
            </div>
        </div>
    );
}