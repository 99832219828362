import { useMemo } from 'react';
import { CircleMinusSolidIcon, CirclePlusSolidIcon, XMarkSolidIcon } from 'src/components/icons';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { selectSelectedSubProjects, setSelectedSubProjects } from 'src/store/features/preApproveLoanPropertyInfoSlice';

type Props = {
    isSummaryShown: boolean;
    onClose: () => void;
}

export default function SummarySlidePanel({
    isSummaryShown,
    onClose
}: Props) {
    const dispatch = useAppDispatch();
    
    const selectedSubProjects = useAppSelector(selectSelectedSubProjects);

    const totalAmount = useMemo(() => {
        return selectedSubProjects.reduce((prev, curVal) => {
            return prev + (curVal.quantity * curVal.price)
        }, 0);
    }, [selectedSubProjects]);

    const handleDecrementQuantity = async (idx: number) => {
        if (selectedSubProjects[idx].quantity === 1) {
            const subProjects = selectedSubProjects.filter((subProj, index) => (index !== idx));
            dispatch(setSelectedSubProjects(subProjects));
        }
        else {
            const subProjects = selectedSubProjects.map(subProj => ({ ...subProj }));
            subProjects[idx].quantity -= 1;

            dispatch(setSelectedSubProjects(subProjects));
        }
    };

    const handleIncrementQuantity = async (idx: number) => {
        const subProjects = selectedSubProjects.map(subProj => ({ ...subProj }));
        subProjects[idx].quantity += 1;

        dispatch(setSelectedSubProjects(subProjects));
    };
    
    return (
        <div
            className={`
                w-full sm:w-[400px]
                max-h-[calc(100vh-258px)] min-h-[calc(100vh-258px)]
                md:max-h-[500px] md:min-h-[500px]
                lg:max-h-[430px] lg:min-h-[430px]
                bg-white
                pr-4 lg:pr-5
                pl-0
                absolute transition-[left] z-[7] lg:static
                overflow-auto uwi-scrollbar
                lg:w-[35%]
                shadow-[7px_-10px_10px_0px_rgba(0,0,0,0.1)] lg:shadow-none
            `}
            style={{ left: (isSummaryShown) ? '0' : 'calc(-100% - 32px)' }}
        >
            <h2 className="text-sm font-bold font-arial leading-none relative">
                Summary
                <button
                    className={`
                        absolute top-2 right-0
                        hover:bg-transparent focus:bg-transparent text-black hover:text-uwi-primary-200
                        lg:hidden
                    `}
                    onClick={() => onClose()}
                >
                    <XMarkSolidIcon className="w-4 h-4" />
                </button>
            </h2>

            <div className="mt-[22px] pt-3 mb-2 md:mb-0 border-t border-solid border-gray-50 text-center">
                {(selectedSubProjects.length === 0) && (
                    <div>No selected property</div>
                )}

                {(selectedSubProjects.length > 0) && (
                    <>
                        {selectedSubProjects.map((subProj, idx) => (
                            <div
                                key={`${subProj.id}_${idx}`}
                                className="flex pb-3 mb-3 border-b border-solid border-[#e5e7eb]"
                            >
                                <div className="w-[80px]">
                                    <img
                                        className="w-[60px] h-[60px] rounded-xl"
                                        src={subProj.image}
                                        alt={`${subProj.name} thumbnail`}
                                    />
                                </div>
                                <div className="w-full pl-4">
                                    <div className="font-bold text-[11px] md:text-xs text-left leading-none">
                                        {subProj.name}
                                    </div>
                                    <div className="w-full py-2">
                                        <div className="font-bold text-xs md:text-[15px] text-left">
                                            {(subProj.price * subProj.quantity).toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) }
                                        </div>
                                    </div>
                                    <div className="text-xs text-left flex items-center justify-between">
                                        <div className="mr-2">Quantity: </div>

                                        <div className="text-[15px] font-bold flex items-center">
                                            <button
                                                className={`
                                                    mr-2
                                                    text-uwi-primary-100 hover:text-uwi-primary-200 focus:text-uwi-primary-200
                                                `}
                                                onClick={() => handleDecrementQuantity(idx)}
                                            >
                                                <CircleMinusSolidIcon className="w-4 h-4"/>
                                            </button>
                                            <div className="mr-2">{subProj.quantity}</div>
                                            <button
                                                className="text-uwi-primary-100 hover:text-uwi-primary-200 focus:text-uwi-primary-200"
                                                onClick={() => handleIncrementQuantity(idx)}
                                            >
                                                <CirclePlusSolidIcon className="w-4 h-4"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-between items-center">
                            <div className="text-xs">Total</div>
                            <div className="text-[15px] font-bold">
                                {totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) }
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
