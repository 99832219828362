// This file is automatically generated
// Don't edit manually
 
const str = `
## **Purpose & Scope**

UWI respects and values your privacy and the secrecy of your account information with us. This Privacy Policy (“Policy”) informs you how we collect, use, store, and process your personal data. We adhere to the data privacy principles of (1) legitimate purpose – we only process upon your consent, in compliance with law or contract; (2) transparency – we notify everything that happens to your data; and (3) proportionality – collection is limited based on purpose.

This Policy applies to data subjects whether as: (1) clients – current, past and prospective customers as individuals; or (2) non-clients – payees or payors; visitors or inquirers through our online channels; ultimate beneficial owners, directors or representatives of corporate clients; and such other persons involved in transactions with us or with our customers (“Data Subjects”).

<br />

## **Collection of your Personal and Sensitive Personal Information**
Personal Information refers to any information whether recorded in a material form or not, from which the identity of an individual is apparent or can be reasonably and directly ascertained by the entity holding the information, or when put together with other information would directly and
certainly identify an individual. On the other hand, Sensitive Personal Information refers to any personal information (1) about an individual’s race, ethnic origin, marital status, age, color, and religious, philosophical or political affiliations; (2) about an individual's health, education, genetic or sexual life of a person, or to any proceeding for any offense committed or alleged to have been committed by such person, the disposal of such proceedings, or the sentence of any court in such proceedings; (3) issued by government agencies peculiar to an individual which includes, but not limited to, social security numbers, previous or current health records, licenses or its denials, suspension or revocation, and tax returns; and (4) specifically established by an executive order or an act of Congress to be kept classified.    

We collect your Personal and Sensitive Personal Information (“Personal Data”) when you register, sign-up or use our products and services or contact us about them. We also collect through your organization whether private corporation or government instrumentality which you have authorized. We may also obtain your information from other sources (i.e., publicly available platforms, financial institutions, credit agencies, payment gateway processors, public authorities, and other registers) for purposes of identity verification.

<br />

## **Kinds of Data We Process**

Know-Your-Customer (KYC) / Identification Data: refer to Personal Information and Sensitive Personal Information which we collect when you sign up or register to avail of our products and services such as full legal name, gender, date of birth, nationality, civil status, permanent address, present address, business address, tax identification number and other government-issued identification numbers, mobile number, home number, office contact details, company name, job position or rank, office address, source of funds, gross annual income, and such other information as may be necessary for our partner banks, lenders, and developers to conduct due diligence.

Financial Data: information as may be required by our partner lenders and developers such as the value of your property and assets, liabilities, income, credit history, among others for the purpose of your interest in purchasing a property, acquisition of a property, understanding whether you can prequalify for a real estate loan and other loans related to the purchase of your property, and for credit investigation of our partner lenders for your real estate loan application or other loans related to the purchase of your property and our partner developer’s loan application that makes use of documents provided by the bank/lender who provided you with a real estate loan.

Transactional Data: linkable information to your Personal Information as may be required by our partner lenders and developers such as Bank Statements which may include, but not limited to your bank account number, deposits, withdrawals, such other transactions or transfers made to or from your account, and details thereof such as the reference number and place and time these transactions were made.

Behavioral Data: this refers to your online behavior, customer segment, usage of our products and services, internet protocol address of your devices used to access our applications, interests and needs you share with us, and customer behavior which we may collect as part of due diligence, to prevent fraudulent conduct and to comply with banking rules on anti-money laundering, terrorism financing, and tax fraud.

Sensitive Personal Information: we may require the following Sensitive Personal Information upon obtaining your express consent, for the following purposes: (1) government-issued identification numbers or cards which may include, but are not limited to, social security numbers, previous cm-rent health records, licenses or its denials, suspensions or revocation, and tax returns for purposes of customer verification by our partner developers and to allow autofill of loan applications with our partner lenders; (2) your educational attainment for purposes of allowing autofill of loan applications with our partner lenders and customer verification with our partner developers; (3) any record of any proceeding for any offense committed or alleged to have been committed by such person, the disposal of such proceedings, or the sentence of any court in such proceedings for purposes of credit investigation, and (4) information about your race, ethnic origin, marital status, age, and color that may be necessary or incidental to contractual agreement or in connection with a requested product or service, for purposes of prequalification for loan products of partner lenders and customer verification of our partner developers.

The foregoing data are collectively referred to as “Customer Data” or “Personal Data”.

<br />

## **Data Processing**

Processing means any operation or any set of operations performed upon personal information including, but not limited to, the collection, recording, organization, storage, updating or modification, retrieval, consultation, use, consolidation, blocking, erasure or destruction of data.

We process Customer Data only for legitimate purposes and with lawful basis such as your express consent, as when you agree to the terms and conditions of the product/s or service/s you availed, and as required by law and regulation. We likewise process Customer Data to enable us to perform our obligations and responsibilities in accordance with any contract or agreement we have with the Customer. We may use third-party service providers to further process Customer Data. We ensure that only authorized employees and third-party service providers, who satisfy our stringent risk management, governance, information security, and data privacy requirements, can process your data.

!!! block-1
### **Data Storage**

We securely store encrypted Customer Data in managed cloud environments.

### **Data Access**

Customer Data can only be accessed by authorized personnel in a role-based manner following the proportionality principle that authorized personnel can only access Customer Data they need for their role and purpose in the bank.

### **Data Use**

!!!! block-2

### ***Customer Engagement***

We use your contact details with us to communicate with you about your relationship with us. We may ask for feedback, surveys or polls about our products and services.

We may send you email or mobile notifications, telephone calls, or newsletters about product and services enhancements and account security reminders.

You have the right to opt out from this form of communications with you or choose another means for which we can contact you.

### ***Marketing***

We may use your information for us to send out campaigns of commercial products and services we hope you find interesting, relevant, and useful, during and after sales negotiations.

We want to establish a more personalized relationship with you by providing you offers that would suit your lifestyle and needs.

We perform data analysis on results of our marketing campaigns to measure their effectiveness and relevance.

You have the right to withdraw your consent or unsubscribe from receiving personalized offers.

### ***Due Diligence and Regulatory Compliance***

We may use Customer Data to evaluate your eligibility for bank/lender products and services and other financial service providers.

In assessing your ability to repay your loans, we conduct credit risk and investigation and reporting on your credit history and account updates.

We use automated processes and data science solutions for faster decision-making in helping our partner lenders in granting loan products and in digitizing the sales process of our partner developers.

We process and share, whenever required, Customer Data, in order to comply with any legal obligation imposed upon us by any competent authority, regulatory agency or body, enforcement agency, court, quasi-judicial body or tribunal.

### ***Business Insights***

We perform data analysis and reporting based on your Customer Data and how we operationalize to aid our management make better decisions.

We analyze your behavioral data, your interactions with our products and services, and our communications with you to aid us understand the areas for improvement and development.

We analyze transactional data performed through our third-party service providers and partners in order to determine how we can jointly improve our products and services for you.

### ***Data Quality***

We shall process your Customer Data in compliance with the data quality standards imposed by regulators. We shall obtain additional information about you such as home address, contact information, email address, employment, education, credit information and history from government institutions or credit bureaus to improve the quality of your Customer Data with us. We may contact you to ensure accuracy and integrity of your information in our data processing systems.

!!!!

### **Data Retention**

&nbsp; &nbsp; &nbsp; &nbsp; As a rule, we shall keep your data as long as it is necessary: a) for the fulfillment of the declared, specified, and legitimate purposes, or when the processing relevant to the purposes has been terminated; b) for the establishment, exercise or defense of legal claims; or c) for legitimate business purposes, which shall be in accordance with the standards of the banking industry.

&nbsp; &nbsp; &nbsp; &nbsp; Subject to the rule above, and pursuant to BSP Regulations, a retention period for transaction records of five (5) years from the date of transaction may be observed, except where specific laws and/or regulations require a different retention period, in which case, the longer retention period is observed.

&nbsp; &nbsp; &nbsp; &nbsp; Similarly, for financial data and documents which indicate taxable transactions, data shall be preserved for ten (10) years per BIR regulation, subject to the rule above.

### **Data Disposal**

&nbsp; &nbsp; &nbsp; &nbsp; After the expiration of the imposed retention period, we dispose of personal data in a secure manner in order to prevent further processing, unauthorized access, or disclosure to any other.

!!!

<br />

## **Data Sharing and Purpose**

When you consent to the processing of your Customer Data with us, you also agree to help us comply with our contractual obligations with our partners. We may also share Customer Data externally with our partners, upon your written and/or electronic consent, for value added services you may find useful and relevant on top of your account with us. For contractual and value-added service data sharing agreements, we employ standardized model clauses as recommended by the National Privacy Commission to ensure data protection of Customer Data. Below are the disclosures required by the government entities, other regulatory authorities and financial institutions:

!!! block-1 

### **Bureau of Internal Revenue (BIR)**
- We may conduct random verification with the BIR in order to establish authenticity of tax returns submitted to us.
- BIR may inquire into bank accounts of the following: a) a decedent in order to determine his gross estate; b) a taxpayer who has filed an application to compromise his tax liability on the ground of financial incapacity; and c) a taxpayer, information on whose account is requested by a foreign tax authority.

<br />

### **Other Regulatory Authorities**
- Regulatory authorities when such other persons or entities we may deem as having authority or right to such disclosure of information as in the case of regulatory agencies, government or otherwise, which have required such disclosure from us and when the circumstances warrant.

<br />

### **Financial Institutions**
- We disclose your Customer Data with insurers, insurance brokers and companies that assist such insurers and insurance brokers source clients.

<br />

### **Value-Added Services**
- With your express consent, we may disclose your Customer Data to our partners who collaborate with us to provide services to you and provide joint communications that we hope you find of interest.
- With your express consent, we may sell your Customer Data to our partners who collaborate with us to provide services to you and provide joint communications that we hope you find of interest.
- Through our digital channels, you may instruct other mobile financial technology applications to retrieve your account information, initiate payments or cash-in from your account with us via our Application Programming Interface (API) facility.

!!!

<br />

## **Security Measures**

The company shall employ the appropriate organizational, physical, and technical security measures to safeguard your Personal Data.

<br />

## **Rights of Data Subjects**

Under the Data Privacy Act of 2012, you have the following rights:

Right to be informed – you may demand the details as to how your Personal Information is being processed or have been processed by the Company, including the existence of automated decision-making and profiling system.

Right to access – upon written request, you may demand reasonable access to your Personal Information, which may include the contents of your processed personal information, the manner of processing, sources where they were obtained, recipients and reason of disclosure.

Right to rectify – you may dispute inaccuracy or error in your Personal Information in the Company’s systems through e-mail and have the Company correct the same.

Right to object – you may object to the processing of your personal data, including processing for direct marketing, automated processing or profiling, and prevent further processing, upon request, and may exercise your right to opt-out of any commercial communication or advertising purposes from the Company.

Right to data erasure – based on reasonable grounds, you have the right to suspend, withdraw or order blocking, removal or destruction of your personal data from the Company’s filing system, without prejudice to the Company’s continuous processing for commercial, operational, legal, and regulatory purposes.

Right to data portability – you have the right to obtain from the Company your Personal Information in an electronic or structured format that is commonly used and allows for further use.

Right to be indemnified for damages – as data subject, you have every right to be indemnified for any damages sustained due to such violation of your right to privacy through inaccurate, false, unlawfully obtained or unauthorized use of your information.

Right to file a complaint – you may file your complaint or any concerns with our Data Protection Officer through dpo@uwihomes.com and/or with the National Privacy Commission through <a href="https://www.privacy.gov.ph" target="_blank">www.privacy.gov.ph</a>.

<br />

Should you have any concern regarding this Privacy Policy, you may reach us via any of the following: 

Email: dpo@uwihomes.com

Telephone: (02)85652506

Post: 21F 8 Rockwell Plaza Drive, Rockwell Center, Makati City
    
`;

export default str;
                        