type Props = {
  className?: string;
};

const EWalletIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      className={className}
    >
      <path
        stroke="#5D7077"
        d="M23 2.5H9A1.5 1.5 0 007.5 4v24A1.5 1.5 0 009 29.5h14a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0023 2.5zm-15.5 4h17m-17 17h17"
      ></path>
      <path stroke="#5D7077" strokeLinecap="round" d="M12.5 26.5h7"></path>
    </svg>
  );
}

export default EWalletIcon;