import { useState } from 'react';
import { XMarkSolidIcon } from 'src/components/icons';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectProjectIds,
    selectProjectTypes,
    selectProjectStatuses,
    setProjectIds,
    setProjectTypes,
    setProjectStatuses
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import ProjectFilter from './ProjectFilter';
import ProjectTypeFilter from './ProjectTypeFilter';
import ProjectStatusFilter from './ProjectStatusFilter';
import Container from 'typedi';
import { SubProject, SubProjectApi } from 'src/api/SubProjectApi';

type Props = {
    visible: boolean;
    onClose: () => void;
    subProjects: SubProject[];
    setSubProjects: (sb: SubProject[]) => void;
}

export default function FilterSlidePanel({
    visible,
    onClose,
    setSubProjects
}: Props) {
    const dispatch = useAppDispatch();
    const projectIds = useAppSelector(selectProjectIds);
    const projectTypes = useAppSelector(selectProjectTypes);
    const projectStatuses = useAppSelector(selectProjectStatuses);

    const [isProjectFilterOpen, setIsProjectFilterOpen] = useState(true);
    const [isProjectTypeFilterOpen, setIsProjectTypeFilterOpen] = useState(false);
    const [isProjectStatusFilterOpen, setIsProjectStatusFilterOpen] = useState(false);

    const handleChangeProjectIds = async (projIds: string[]) => {
        dispatch(setProjectIds(projIds));

        const subProjects = await Container.get(SubProjectApi).search(
            projIds,
            projectTypes,
            projectStatuses
        );
        setSubProjects(subProjects);
    };

    const handleChangeProjectTypes = async (projTypes: string[]) => {
        dispatch(setProjectTypes(projTypes));

        const subProjects = await Container.get(SubProjectApi).search(
            projectIds,
            projTypes,
            projectStatuses
        );
        setSubProjects(subProjects);
    };

    const handleChangeProjectStatuses = async (projStatuses: string[]) => {
        dispatch(setProjectStatuses(projStatuses));

        const subProjects = await Container.get(SubProjectApi).search(
            projectIds,
            projectTypes,
            projStatuses
        );
        setSubProjects(subProjects);
    };

    return (
        <div
            className={`
                w-full sm:w-[300px] lg:w-[30%]
                max-h-[calc(100vh-258px)] min-h-[calc(100vh-258px)]
                md:max-h-[500px] md:min-h-[500px]
                lg:max-h-[430px] lg:min-h-[430px]
                bg-white
                pl-4 pr-4 lg:pr-5
                absolute transition-[left] z-[7]
                overflow-auto uwi-scrollbar
                shadow:none sm:shadow-[-10px_0px_12px_0px_rgba(0,0,0,0.1)]
                ${(visible) ? 'left-0 sm:left-[calc(100%-300px)] lg:left-[calc(70%)]' : 'left-[calc(100%+32px)] sm:left-[calc(100%+300px)] lg:left-[calc(130%)]'}
            `}
        >
            <h2 className="text-sm font-bold font-arial leading-none relative">
                Filters
                <button
                    className={`
                        absolute top-2 right-0
                        hover:bg-transparent focus:bg-transparent text-black hover:text-uwi-primary-200
                    `}
                    onClick={() => onClose()}
                >
                    <XMarkSolidIcon className="w-4 h-4" />
                </button>
            </h2>

            <div className="mt-[22px] pt-3 mb-2 md:mb-0 border-t border-solid border-gray-50 text-center">
                <ProjectFilter
                    open={isProjectFilterOpen}
                    projectIds={projectIds}
                    onToggle={() => setIsProjectFilterOpen(!isProjectFilterOpen)}
                    onChange={handleChangeProjectIds}
                />

                <ProjectTypeFilter
                    open={isProjectTypeFilterOpen}
                    selectedProjectTypes={projectTypes}
                    onToggle={() => setIsProjectTypeFilterOpen(!isProjectTypeFilterOpen)}
                    onChange={handleChangeProjectTypes}
                />

                <ProjectStatusFilter
                    open={isProjectStatusFilterOpen}
                    selectedProjectStatuses={projectStatuses}
                    onToggle={() => setIsProjectStatusFilterOpen(!isProjectStatusFilterOpen)}
                    onChange={handleChangeProjectStatuses}
                />
            </div>
        </div>
    );
};
