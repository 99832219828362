import { useCallback } from 'react';
import { Container } from 'typedi';
import { WidgetApi } from 'src/api/WidgetApi';
import Store from 'src/store';
import {
    setAgents,
    setContactEmail,
    setCtaPosition,
    setHasBookingFeature,
    setIsAgentSelectionEnabled,
    setWidgetType,
    setHasReservationPaymentsFeature,
    setHasXenditPayment,
    setIsProjectOnlyView,
    setProjectId,
    setHasBuyerPortal
} from 'src/store/features/commonSlice';
import {
    setProjects,
    setCities,
    setPriceRange
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    setProjectTypes,
    setProjectStatuses
} from 'src/store/features/preApproveLoanStaticDataSlice';
import { hasProp } from 'src/helpers/object';

const url = new URL(window.location.toString());
const params = new URLSearchParams(url.search);

const useLoadWidget = (store: typeof Store) => {

    const loadWidget = useCallback(() => {
        let clientId = '';
        if (params.get('clientId') && url.origin === process.env.REACT_APP_BASE_LOADER_URI) {
            clientId = params.get('clientId') || '';
        }
        else if (typeof window !== 'undefined' && hasProp(window, '__UWI_WIDGET_CLIENT_ID__')) {
            clientId = (window as any).__UWI_WIDGET_CLIENT_ID__;
        }

        let projectId = '';
        if (params.get('projectId') && url.origin === process.env.REACT_APP_BASE_LOADER_URI) {
            projectId = params.get('projectId') || '';
        }
        else if (typeof window !== 'undefined' && hasProp(window, '__UWI_WIDGET_PROJECT_ID__')) {
            projectId = (window as any).__UWI_WIDGET_PROJECT_ID__;
        }

        if (clientId.length > 0) {
            Container
                .get(WidgetApi)
                .load(clientId)
                .then((widget) => {
                    const style = document.createElement('style');
                    style.innerHTML = widget.css;

                    const head = document.getElementsByTagName("head")[0];
                    if (head) {
                        head.appendChild(style);
                    }

                    store.dispatch(setCtaPosition(widget.position));
                    store.dispatch(setWidgetType(widget.widgetType));
                    store.dispatch(setHasBookingFeature(widget.hasBookingFeature));
                    store.dispatch(setHasReservationPaymentsFeature(widget.hasReservationPaymentsFeature));
                    store.dispatch(setHasXenditPayment(widget.hasXenditPayment));
                    store.dispatch(setHasBuyerPortal(widget.hasBuyerPortal));

                    if (widget.contactEmail !== '') {
                        store.dispatch(setContactEmail(widget.contactEmail));
                    }

                    if (widget.isAgentSelectionEnabled !== undefined) {
                        store.dispatch(setIsAgentSelectionEnabled(widget.isAgentSelectionEnabled));
                    }

                    if (projectId !== '') {
                        store.dispatch(setIsProjectOnlyView(true));
                        store.dispatch(setProjectId(projectId));
                    }

                    if (
                        widget.widgetType === 'advance'
                        && widget.projects
                        && widget.projectTypes
                        && widget.projectStatuses
                        && widget.cities
                        && widget.agents
                        && typeof widget.minPrice !== 'undefined'
                        && typeof widget.maxPrice !== 'undefined'
                    ) {
                        store.dispatch(setProjects(widget.projects));
                        store.dispatch(setProjectTypes(widget.projectTypes));
                        store.dispatch(setProjectStatuses(widget.projectStatuses));
                        store.dispatch(setCities(widget.cities));
                        store.dispatch(setAgents(widget.agents));
                        store.dispatch(setPriceRange({
                            min: widget.minPrice,
                            max: widget.maxPrice
                        }));
                    }
                });
        }
    }, [store]);

    return loadWidget;
};

export default useLoadWidget;
