import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookATourIcon, EllipsisSolidIcon, MagnifyingGlassSolidIcon } from 'src/components/icons';
import useClickoutside from 'src/hooks/useClickoutside';

type Props = {
    preQualificationId: string;
    isShowProperty: boolean;
    onToggleIsShowProperty: (isShow: boolean) => void;
};

const ButtonWithMenu = ({
    preQualificationId,
    isShowProperty,
    onToggleIsShowProperty
}: Props) => {
    const navigate = useNavigate();

    const [isMenuShown, setIsMenuShown] = useState(false);

    const handleClickOutside = () => {
        setIsMenuShown(false);
    };
    const clickRef = useClickoutside(handleClickOutside);

    const handleToggleIsShowProperty = (isShow: boolean) => {
        onToggleIsShowProperty(isShow);
        setIsMenuShown(false);
    };

    return (
        <div ref={clickRef}>
            <button
                className="p-2 rounded-lg text-[#788188] hover:bg-[#f2f5f9] hover:text-black/80"
                onClick={() => setIsMenuShown(!isMenuShown)}
            >
                <EllipsisSolidIcon className="w-4 h-4"/>
            </button>

            {(isMenuShown) && (
                <div
                    className={`
                        absolute 
                        bg-white border border-gray-75 
                        shadow-lg shadow-gray-50
                        py-2 rounded-lg 
                        right-4 top-8 z-10
                    `}
                >
                    <ul>
                        <li>
                            <button
                                className="w-full py-2 flex items-center hover:bg-uwi-primary-100 hover:text-white px-5"
                                onClick={() => {
                                    navigate(`/pre-qualification-view?id=${preQualificationId}`);
                                }}
                            >
                                <MagnifyingGlassSolidIcon className="w-4 h-4 mr-2" />
                                View Result
                            </button>
                        </li>
                        <li>
                            <button
                                type="button"
                                className="w-full py-2 flex items-center hover:bg-uwi-primary-100 hover:text-white px-5"
                                onClick={() => handleToggleIsShowProperty(!isShowProperty)}
                            >
                                <BookATourIcon className="w-4 h-4 mr-2"/>
                                {(isShowProperty)  ? 'Hide Property' : 'Show Property'}
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ButtonWithMenu;
