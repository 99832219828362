import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
    children?: JSX.Element | JSX.Element[] | string | number;
}

export default function ModalPortal({
    children
}: Props) {
    const [el, setEl] = useState<HTMLDivElement | undefined>(undefined);

    useEffect(() => {
        const wrapper = document.getElementById('uwi_widget_high_portal');
        const el = document.createElement('div');
        setEl(el);

        wrapper?.appendChild(el);

        return () => {
            wrapper?.removeChild(el);
        };
    }, []);

    return (el) ? createPortal(children, el) : <></>;
};
