import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dependent } from 'src/api/HomeLoanApplicationApi';
import { RootState } from '../index';

interface ApplyLoanOtherInfoState {
    dependents: Dependent[];
    projectContactPersonName: string;
    projectAddress: string;
    projectContactPersonNumber: string;
    attorneyInfactName: string;
    attorneyInfactAddress: string;
    attorneyInfactContactNumber: string;
    attorneyInfactRelationship: string;
    formErrors: Record<string, string>;
}

const initialState: ApplyLoanOtherInfoState = {
    dependents: [],
    projectContactPersonName: '',
    projectAddress: '',
    projectContactPersonNumber: '',
    attorneyInfactName: '',
    attorneyInfactAddress: '',
    attorneyInfactContactNumber: '',
    attorneyInfactRelationship: '',
    formErrors: {}
};

export const applyLoanOtherInfoSlice = createSlice({
    name: 'applyLoanOtherInfo',

    initialState,

    reducers: {
        setDependents: (state: ApplyLoanOtherInfoState, action: PayloadAction<Dependent[]>) => {
            state.dependents = action.payload;
        },

        setProjectContactPersonName: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.projectContactPersonName = action.payload;
        },

        setProjectAddress: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.projectAddress = action.payload;
        },

        setProjectContactPersonNumber: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.projectContactPersonNumber = action.payload;
        },

        setAttorneyInfactName: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.attorneyInfactName = action.payload;
        },

        setAttorneyInfactAddress: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.attorneyInfactAddress = action.payload;
        },

        setAttorneyInfactContactNumber: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.attorneyInfactContactNumber = action.payload;
        },

        setAttorneyInfactRelationship: (state: ApplyLoanOtherInfoState, action: PayloadAction<string>) => {
            state.attorneyInfactRelationship = action.payload;
        },

        setState: (state: ApplyLoanOtherInfoState, action: PayloadAction<Partial<ApplyLoanOtherInfoState>>) => {
            state.dependents = (action.payload.dependents !== undefined) ? action.payload.dependents : state.dependents;
            state.projectContactPersonName = (action.payload.projectContactPersonName !== undefined) ? action.payload.projectContactPersonName : state.projectContactPersonName;
            state.projectAddress = (action.payload.projectAddress !== undefined) ? action.payload.projectAddress : state.projectAddress;
            state.projectContactPersonNumber = (action.payload.projectContactPersonNumber !== undefined) ? action.payload.projectContactPersonNumber : state.projectContactPersonNumber;
            state.attorneyInfactName = (action.payload.attorneyInfactName !== undefined) ? action.payload.attorneyInfactName : state.attorneyInfactName;
            state.attorneyInfactAddress = (action.payload.attorneyInfactAddress !== undefined) ? action.payload.attorneyInfactAddress : state.attorneyInfactAddress;
            state.attorneyInfactContactNumber = (action.payload.attorneyInfactContactNumber !== undefined) ? action.payload.attorneyInfactContactNumber : state.attorneyInfactContactNumber;
            state.attorneyInfactRelationship = (action.payload.attorneyInfactRelationship !== undefined) ? action.payload.attorneyInfactRelationship : state.attorneyInfactRelationship;
        },

        setFormErrors: (state: ApplyLoanOtherInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        },

        setDefaultFormValues: (state: ApplyLoanOtherInfoState) => {
            state.dependents = [];
            state.projectContactPersonName = '';
            state.projectAddress = '';
            state.projectContactPersonNumber = '';
            state.attorneyInfactName = '';
            state.attorneyInfactAddress = '';
            state.attorneyInfactContactNumber = '';
            state.attorneyInfactRelationship = '';
        }
    }
});

export const {
    setDependents,
    setProjectContactPersonName,
    setProjectAddress,
    setProjectContactPersonNumber,
    setAttorneyInfactName,
    setAttorneyInfactAddress,
    setAttorneyInfactContactNumber,
    setAttorneyInfactRelationship,
    setState,
    setFormErrors,
    setDefaultFormValues
} = applyLoanOtherInfoSlice.actions;

export const selectDependents = (state: RootState) => state.applyLoanOtherInfo.dependents;
export const selectProjectContactPersonName = (state: RootState) => state.applyLoanOtherInfo.projectContactPersonName;
export const selectProjectAddress = (state: RootState) => state.applyLoanOtherInfo.projectAddress;
export const selectProjectContactPersonNumber = (state: RootState) => state.applyLoanOtherInfo.projectContactPersonNumber;
export const selectAttorneyInfactName = (state: RootState) => state.applyLoanOtherInfo.attorneyInfactName;
export const selectAttorneyInfactAddress = (state: RootState) => state.applyLoanOtherInfo.attorneyInfactAddress;
export const selectAttorneyInfactContactNumber = (state: RootState) => state.applyLoanOtherInfo.attorneyInfactContactNumber;
export const selectAttorneyInfactRelationship = (state: RootState) => state.applyLoanOtherInfo.attorneyInfactRelationship;
export const selectFormErrors = (state: RootState) => state.applyLoanOtherInfo.formErrors;

export default applyLoanOtherInfoSlice.reducer;