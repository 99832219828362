export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const daysOfWeekShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export interface CalendarDayInfo {
    label: string;
    date: Date;
    isDisabled: boolean;
    hasSchedule: boolean;
    isAvailable: boolean;
}

export function getLastDayOfMonth(month: number, year: number) {
    return new Date(year, month + 1, 0).getDate();
}

export function getMonthCalendar(month: number, year: number): CalendarDayInfo[][] {
    const lastDayOfPreviousMonth = getLastDayOfMonth(month - 1, year);
    const date = new Date(year, month, 1);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = date.getDay();
    const calendar: CalendarDayInfo[] = [];
  
    for (let i = (firstDay - 1); i >= 0; i--) {
        calendar.push({
            label: `${lastDayOfPreviousMonth - i}`,
            date: new Date(year, month - 1, lastDayOfPreviousMonth - i),
            isDisabled: true,
            hasSchedule: false,
            isAvailable: false
        });
    }
  
    for (let i = 1; i <= daysInMonth; i++) {
        calendar.push({
            label: i.toString(),
            date: new Date(year, month, i),
            isDisabled: false,
            hasSchedule: false,
            isAvailable: false
        });
    }

    const remainingDays = 42 - (daysInMonth + firstDay - 1);
    for (let i = 1; i < remainingDays; i++) {
        calendar.push({
            label: i.toString(),
            date: new Date(year, month + 1, i),
            isDisabled: true,
            hasSchedule: false,
            isAvailable: false
        });
    }
  
    const result: CalendarDayInfo[][] = [];
    for (let i = 0; i < calendar.length; i += 7) {
        result.push(calendar.slice(i, i + 7));
    }
  
    return result;
}