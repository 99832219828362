import { useEffect, useState } from 'react';
import { CTAPosition } from 'src/api/WidgetApi';
import WidgetCTAIcon from 'src/components/icons/WidgetCTAIcon';
import { useAppSelector } from 'src/hooks/generic';
import { selectCtaPosition } from 'src/store/features/commonSlice';

type Props = {
    onClick?: () => void;
};

export default function CtaButton({
    onClick
}: Props){
    const ctaPosition = useAppSelector(selectCtaPosition);

    const [widgetPosition, setWidgetPosition] = useState<CTAPosition>('none');

    useEffect(() => {
        if (ctaPosition) {
            setWidgetPosition(ctaPosition);
        }
    }, [ctaPosition]);

    const handleClick = onClick || (() => {});

    return (
        <div className={`
            fixed z-[999999] uwi-cta-button-wrapper
            ${(widgetPosition === 'none') ? 'hidden' : ''}
        `}>
            <button
                className="rounded-full uwi-cta-button"
                onClick={() => handleClick()}
            >
                <WidgetCTAIcon className="uwi-cta-button-icon" />
            </button>
        </div>
    );
};