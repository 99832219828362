import { IBirthdate } from 'src/hooks/useBirthdate';

/**
 * Calculates the difference in years of the two dates.
 * 
 * @return string
 */
export function dateDiffInYears(date1: Date, date2: Date) {
    const date1Time = date1.getTime();
    const date2Time = date2.getTime();
    const diff = (date1Time > date2Time) ? date1Time - date2Time : date2Time - date1Time;

    return Math.round(diff / 31536000000); // divide by 1 year in milliseconds
}

/**
 * Convert date string with YYYY-MM-DD pattern to IBirthDate
 */
export function convertDateStringToIBirthDate(dateStr: string): IBirthdate | null {
    const datePcs = dateStr.split('-');
    
    if (
        datePcs.length !== 3
        || datePcs[0].length !== 4
        || datePcs[1].length !== 2
        || datePcs[2].length !== 2
        || isNaN(parseInt(datePcs[0]))
        || isNaN(parseInt(datePcs[1]))
        || isNaN(parseInt(datePcs[2]))
    ) {
        return null;
    }

    const year = parseInt(datePcs[0]);
    const month = Math.max(parseInt(datePcs[1]) - 1, 0);
    const day = parseInt(datePcs[2]);

    return {
        month,
        day,
        year
    };
}

/**
 * Formats the given date object to YYYY-MM-DD
 */
export function formatDateToYYYYMMDD(date: Date): string {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - (offset*60*1000));

    return newDate.toISOString().split('T')[0];
}

/**
 * Formats the given date object to YYYY-MM-DD
 */
export function formatDateToYYYYMMDDWithoutTimezone(date: Date): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}
