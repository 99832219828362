import { useState } from 'react';
import { Container } from 'typedi';
import { HomeLoanApplication, HomeLoanApplicationView, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import { HomeLoanApplicationDocument, HomeLoanApplicationDocumentApi } from 'src/api/HomeLoanApplicationDocumentApi';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import FieldDocuments from '../fields/FieldDocuments';

type Props = {
    homeLoanApp?: HomeLoanApplication;
};

const RequiredInfo = ({
    homeLoanApp
}: Props) => {
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const [legalDocs, setLegalDocs] = useState<HomeLoanApplicationDocument[]>([]);
    const [incomeDocs, setIncomeDocs] = useState<HomeLoanApplicationDocument[]>([]);
    const [collateralDocs, setCollateralDocs] = useState<HomeLoanApplicationDocument[]>([]);
    const [homeLoanSummary, setHomeLoanSummary] = useState<HomeLoanApplicationView|null>(null);

    useEffectOnce(() => {
        if (homeLoanApp) {
            Container
                .get(HomeLoanApplicationDocumentApi)
                .getDocs(homeLoanApp.id)
                .then((docs) => {
                    const legalDocs = docs.filter(doc => (
                        (doc.documentGroup === 'legal_document')
                    ));
                    setLegalDocs(legalDocs);
        
                    const incomeDocs = docs.filter(doc => (
                        (doc.documentGroup === 'income_document')
                    ));
                    setIncomeDocs(incomeDocs);
        
                    const collateralDocs = docs.filter(doc => (
                        (doc.documentGroup === 'collateral_document')
                    ));
                    setCollateralDocs(collateralDocs);
                })
                .catch(errorHandlerWithToast);
        }

        if (homeLoanApp && homeLoanApp.lenderLoanId) {
            Container
                .get(HomeLoanApplicationApi)
                .getSummary(homeLoanApp.id)
                .then((homeLoanView) => {
                    if (homeLoanView) {
                        setHomeLoanSummary(homeLoanView);
                    }
                })
                .catch(errorHandlerWithToast);
        }
    });

    return (
        <div className="w-full">
            <div className="w-full flex flex-col items-center">
                {(homeLoanSummary) && (
                    <div className="
                        w-full bg-gray-25
                        py-4 lg:py-5 mb-8
                        flex justify-center items-center
                        rounded-xl"
                    >
                        <img src={homeLoanSummary.lenderThumbnail} className="w-[50px] mr-2" alt="Lender thumbnail" />
                        <b>{ homeLoanSummary.lenderName }</b>
                    </div>
                )}
                
                <div className="w-full">
                    <FieldDocuments
                        title="LEGAL DOCUMENTS"
                        helperText="of the minimum documentary requirements has been completed*"
                        className="mb-8"
                        docs={legalDocs}
                    />

                    <FieldDocuments
                        title="INCOME DOCUMENTS"
                        helperText="of the minimum documentary requirements has been completed*"
                        className="mb-8"
                        docs={incomeDocs}
                    />

                    <FieldDocuments
                        title="COLLATERAL DOCUMENTS"
                        helperText="of the minimum documentary requirements has been completed*"
                        className="mb-8"
                        docs={collateralDocs}
                    />
                </div>
            </div>
        </div>
    );
};

export default RequiredInfo;