import { useEffect, useState } from 'react';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

export interface IBirthdate {
    month: number|undefined;
    day: number|undefined;
    year: number|undefined;
}

const useBirthdate = () => {
    const [month, setMonth] = useState<number|undefined>();
    const [day, setDay] = useState<number|undefined>();
    const [year, setYear] = useState<number|undefined>();
    const [numDays, setNumDays] = useState(30);
    const [yearInfo, setYearInfo] = useState({
        start: 2022,
        end: 1994
    });

    useEffect(() => {
        const currentYear = (new Date()).getFullYear();
        const start = currentYear - 18;
        const end = currentYear - 70;

        setYearInfo({ start, end });
        setYear(start);
    }, []);

    useEffect(() => {
        if (
            typeof month === 'number'
            && typeof year === 'number'
        ) {
            const is31 = (((month % 7) % 2) === 0);
            let maxDays = (is31) ? 31 : 30;
            if (month === 1) {
                const isLeapYear = (
                    ((year % 4) === 0)
                    && (
                        ((year % 100) !== 0)
                        || ((year % 400) === 0)
                    )
                );
    
                maxDays = (isLeapYear) ? 29 : 28;  
            }
    
            setNumDays(maxDays);
        }
    }, [month, year]);

    return {
        month,
        setMonth,
        day,
        setDay,
        year,
        setYear,
        yearInfo,
        numDays,
        months
    };
};

export default useBirthdate;
