import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

type FeeType = 'flat' | 'percent';

export interface PaymentMethod {
    id: string;
    developerId: string;
    paymentMethods: PaymentMethodOption[]
};

export interface PaymentMethodOption {
    key: string;
    options: string[];
}

interface PaymentFeeRule {
    name: string;
    feeType: FeeType;
    feeValue: number;
}

export interface Rule {
    feeType: FeeType;
    feeValue: number;
}

interface TransactionFeeRule {
    name: string;
    rules: Rule[];
}

export interface PaymentMethodWithDetais {
    [k: string]: Record<string, { paymentFeeRule: PaymentFeeRule, transactionFeeRule: TransactionFeeRule}>;
}

@Service()
export class PaymentMethodsApi extends BaseApi {
    /**
     * API path for get payment methods of the developer.
     */
    static readonly PATH_GET = '/v1/widget/payment-methods/get';

    /**
     * API path for get payment methods of the developer.
     */
    static readonly PATH_GET_WITH_DETAILS = '/v1/widget/payment-methods/get-with-details';

    get(): Promise<PaymentMethod|null> {
        return this.http.get(
            PaymentMethodsApi.PATH_GET,
            {},
            {},
            true
        );
    };

    getWithDetails(): Promise<PaymentMethodWithDetais|null> {
        return this.http.get(
            PaymentMethodsApi.PATH_GET_WITH_DETAILS,
            {},
            {},
            true
        );
    };
}
