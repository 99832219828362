import { useAppSelector } from 'src/hooks/generic';
import {
    selectHasCreditCard,
    selectHasCancelledCreditCard,
    selectHasFullPaymentCertificate,
    selectExtraIncomeAmount,
    selectExistingDebtAmount,
    selectCoBorrowerMonthlyIncome
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';

type Props = {
    className?: string;
    headerClassName?: string;
    listClassName?: string;
    listEntryClassName?: string;
    listEntryLeftClassName?: string;
    listEntryLeftValueClassName?: string;
};

const DebtIncomeInfo = ({
    className,
    headerClassName,
    listEntryLeftClassName,
    listEntryLeftValueClassName
}: Props) => {
    const hasCreditCard = useAppSelector(selectHasCreditCard);
    const hasCancelledCreditCard = useAppSelector(selectHasCancelledCreditCard);
    const hasFullPaymentCertificate = useAppSelector(selectHasFullPaymentCertificate);
    const extraIncomeAmount = useAppSelector(selectExtraIncomeAmount);
    const existingDebtAmount = useAppSelector(selectExistingDebtAmount);
    const coBorrowerMonthlyIncome = useAppSelector(selectCoBorrowerMonthlyIncome);

    return (
        <div className={`w-full ${className || ''}`}>
            <h3 className={`text-base text-white bg-uwi-primary-100 py-4 font-arial font-bold mb-2 ${headerClassName || ''}`}>INCOME/DEBT INFORMATION</h3>
            <table className="w-full !m-0 !border-none">
                <tbody>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Co-borrower Monthly Income:</td>
                        <td className={listEntryLeftValueClassName || ''}>{coBorrowerMonthlyIncome.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Extra Income:</td>
                        <td className={listEntryLeftValueClassName || ''}>{(extraIncomeAmount) ? extraIncomeAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) : '0'}</td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Has Credit Card?:</td>
                        <td className={listEntryLeftValueClassName || ''}>
                            {(hasCreditCard) ? 'Yes' : 'No'}
                        </td>
                    </tr>
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Has Cancelled Credit Card?:</td>
                        <td className={listEntryLeftValueClassName || ''}>{(hasCancelledCreditCard) ? 'Yes' : 'No'}</td>
                    </tr>
                    {(hasCancelledCreditCard) && (
                        <tr>
                            <td className={listEntryLeftClassName || ''}>Has Full Payment Certificate?:</td>
                            <td className={listEntryLeftValueClassName || ''}>{(hasFullPaymentCertificate) ? 'Yes' : 'No'}</td>
                        </tr>
                    )}
                    <tr>
                        <td className={listEntryLeftClassName || ''}>Debt Amount:</td>
                        <td className={listEntryLeftValueClassName || ''}>{(existingDebtAmount) ? existingDebtAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }) : '0'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DebtIncomeInfo;
