import { useState } from 'react';
import { Container } from 'typedi';
import { HomeLoanApplication, HomeLoanApplicationApi } from 'src/api/HomeLoanApplicationApi';
import { validateEmail } from 'src/helpers/validation';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectPhoneNumber,
    selectMobileNumber,
    selectEmail,
    selectCurrentAddress,
    selectCurrentAddressHomeOwnership,
    selectCurrentAddressResidenceSince,
    selectPermanentAddress,
    selectPermanentAddressHomeOwnership,
    selectPermanentAddressResidenceSince,
    selectPreviousAddress,
    selectPreviousAddressHomeOwnership,
    selectPreviousAddressResidenceSince,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/applyLoanContactInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

const useContactInfo = (homeLoanApp?: HomeLoanApplication) => {
    const { extractFormErrors } = useGenericErrorHandler();
    
    const dispatch = useAppDispatch();
    const selectedPhoneNumber = useAppSelector(selectPhoneNumber);
    const selectedMobileNumber = useAppSelector(selectMobileNumber);
    const selectedEmail = useAppSelector(selectEmail);
    const selectedCurrentAddress = useAppSelector(selectCurrentAddress);
    const selectedCurrentAddressHomeOwnership = useAppSelector(selectCurrentAddressHomeOwnership);
    const selectedCurrentAddressResidenceSince = useAppSelector(selectCurrentAddressResidenceSince);
    const selectedPermanentAddress = useAppSelector(selectPermanentAddress);
    const selectedPermanentAddressHomeOwnership = useAppSelector(selectPermanentAddressHomeOwnership);
    const selectedPermanentAddressResidenceSince = useAppSelector(selectPermanentAddressResidenceSince);
    const selectedPreviousAddress = useAppSelector(selectPreviousAddress);
    const selectedPreviousAddressHomeOwnership = useAppSelector(selectPreviousAddressHomeOwnership);
    const selectedPreviousAddressResidenceSince = useAppSelector(selectPreviousAddressResidenceSince);
    const formErrors = useAppSelector(selectFormErrors);

    const [isDirty, setIsDirty] = useState(false);

    const validateForm = () => {
        const errors:Record<string, string> = {};

        if (selectedMobileNumber.trim().length === 0) {
            errors.mobileNumber = 'Mobile number is required';
        }
        else if (selectedMobileNumber.trim().length < 5) {
            errors.mobileNumber = 'Mobile Number must be longer than or equal to 5 characters';
        }

        if (selectedEmail.trim().length === 0) {
            errors.email = 'Email Address is required';
        }
        else if (selectedEmail.trim().length < 5) {
            errors.email = 'Email Address must be longer than or equal to 5 characters';
        }
        else if (selectedEmail && !validateEmail(selectedEmail.trim())) {
            errors.email = 'Email Address has an invalid format.';
        }

        if (selectedCurrentAddress === '') {
            errors.currentAddress = 'Current Address is required';
        }
        else if (selectedCurrentAddress.trim().length < 5) {
            errors.currentAddress = 'Current Address must be longer than or equal to 5 characters';
        }

        if (selectedCurrentAddressHomeOwnership === '') {
            errors.currentAddressHomeOwnership = 'Current Address (Home Ownership) is required.';
        }

        if (
            selectedCurrentAddressResidenceSince <= 0
            || isNaN(selectedCurrentAddressResidenceSince)
        ) {
            errors.currentAddressResidenceSince = 'Current Address(Years of Residence) should not be less than 1';
        }
        else if (selectedCurrentAddressResidenceSince > 100) {
            errors.currentAddressResidenceSince = 'Current Address(Years of Residence) should not be more than 100';
        }

        if (selectedPermanentAddress === '') {
            errors.permanentAddress = 'Permanent Address is required.';
        }
        else if (selectedPermanentAddress.trim().length < 5) {
            errors.permanentAddress = 'Permanent Address must be longer than or equal to 5 characters';
        }

        if (selectedPermanentAddressHomeOwnership === '') {
            errors.permanentAddressHomeOwnership = 'Permanent Address (Home Ownership) is required';
        }

        if (
            selectedPermanentAddressResidenceSince <= 0
            || isNaN(selectedPermanentAddressResidenceSince)
        ) {
            errors.permanentAddressResidenceSince = 'Permanent Address(Years of Residence) should not be less than 1';
        }
        else if (selectedPermanentAddressResidenceSince > 100) {
            errors.permanentAddressResidenceSince = 'Permanent Address(Years of Residence) should not be more than 100';
        }

        if (selectedPreviousAddress === '') {
            errors.previousAddress = 'Previous Address is required.';
        }
        else if (selectedPreviousAddress.trim().length < 5) {
            errors.previousAddress = 'Previous Address must be longer than or equal to 5 characters';
        }

        if (selectedPreviousAddressHomeOwnership === '') {
            errors.previousAddressHomeOwnership = 'Previous Address (Home Ownership) is required';
        }

        if (
            selectedPreviousAddressResidenceSince <= 0
            || isNaN(selectedPreviousAddressResidenceSince)
        ) {
            errors.previousAddressResidenceSince = 'Previous Address(Years of Residence) should not be less than 1';
        }
        else if (selectedPreviousAddressResidenceSince > 100) {
            errors.previousAddressResidenceSince = 'Previous Address(Years of Residence) should not be more than 100';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    const handleNext = async () => {
        if (!validateForm()) {
            return false;
        }

        if (!isDirty) {
            return true;
        }

        if (homeLoanApp) {
            try {
                await Container.get(HomeLoanApplicationApi).update(
                    homeLoanApp.id,
                    {
                        phoneNumber: (selectedPhoneNumber !== '') ? selectedPhoneNumber : undefined,
                        mobileNumber: selectedMobileNumber,
                        email: selectedEmail,
                        currentAddress: selectedCurrentAddress,
                        currentAddressHomeOwnership: selectedCurrentAddressHomeOwnership,
                        currentAddressResidenceSince: selectedCurrentAddressResidenceSince,
                        permanentAddress: selectedPermanentAddress,
                        permanentAddressHomeOwnership: selectedPermanentAddressHomeOwnership,
                        permanentAddressResidenceSince: selectedPermanentAddressResidenceSince,
                        previousAddress: selectedPreviousAddress,
                        previousAddressHomeOwnership: selectedPreviousAddressHomeOwnership,
                        previousAddressResidenceSince: selectedPreviousAddressResidenceSince
                    }
                );

                return true;
            }
            catch (ex) {
                const errors = extractFormErrors(ex);
                dispatch(setFormErrors(errors));
            }
        }

        return false;
    };

    return {
        validateForm,
        formErrors,
        setFormErrors,
        handleNext,
        setIsDirty
    };
};

export default useContactInfo;
