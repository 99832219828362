import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectExtraIncomeAmount,
    selectExistingDebtAmount,
    selectCoBorrowerMonthlyIncome,
    selectFormErrors,
    setFormErrors
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';

const useDebtIncomeInfoValidation = () => {
    const dispatch = useAppDispatch();
    const selectedExtraIncomeAmount = useAppSelector(selectExtraIncomeAmount);
    const selectedExistingDebtAmount = useAppSelector(selectExistingDebtAmount);
    const selectedCoBorrowerMonthlyIncome = useAppSelector(selectCoBorrowerMonthlyIncome);
    const formErrors = useAppSelector(selectFormErrors);

    const validateForm = async () => {
        const errors:Record<string, string> = {};

        if (selectedExtraIncomeAmount <= -1) {
            errors.extraIncomeAmount = 'Extra income amount should be greater than -1.';
        }

        if (isNaN(selectedExtraIncomeAmount)) {
            errors.extraIncomeAmount = 'Extra income amount is required.';
        }

        if (selectedExistingDebtAmount <= -1) {
            errors.existingDebtAmount = 'Existing debt amount should be greater than -1.';
        }

        if (isNaN(selectedExistingDebtAmount)) {
            errors.existingDebtAmount = 'Existing debt amount is required.';
        }

        if (selectedCoBorrowerMonthlyIncome <= -1) {
            errors.coBorrowerMonthlyIncome = 'Co Borrower Monthly income should be greater than -1.';
        }

        if (isNaN(selectedCoBorrowerMonthlyIncome)) {
            errors.coBorrowerMonthlyIncome = 'Co Borrower Monthly income is required.';
        }

        dispatch(setFormErrors(errors));

        return (Object.keys(errors).length === 0);
    };

    return {
        validateForm,
        formErrors,
        setFormErrors
    };
}

export default useDebtIncomeInfoValidation;
