import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';
import { formatDateToYYYYMMDDWithoutTimezone } from 'src/helpers/date';

export type Status = 'active' | 'cancelled_by_user' | 'cancelled_by_agent' | 'done';

export interface Booking {
    id: string;
    developerId: string;
    developerAccountId: string;
    userId: string;
    preQualificationId: string;
    subProjectId: string;
    bookingDate: Date;
    referenceNumber: string;
    startTime: string;
    endTime: string
    status: Status;
    projectId: string;
    subProjectName: string;
    subProjectImage: string;
    projectTitle: string;
    agentName: string;
    agentEmail: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface BookingCalendarInfo {
    hasSchedule: boolean;
    isAvailable: boolean;
}

export interface BookingCalendarTimeSlot {
    id: string;
    startTime: string;
    endTime: string;
    isAvailable: boolean;
}

export interface BookDateAndTimeDto {
    preQualificationId: string;
    date: Date;
    bookingScheduleId: string;
    agentId: string;
}

export interface SubProjectCalendarInfoDto {
    startDate: Date;
    endDate: Date;
    agentId?: string;
}

export interface SubProjectTimeSlotsInfoDto {
    date: Date;
    agentId?: string;
}

@Service()
export class BookingApi extends BaseApi {
    /**
     * API path for cancelling the booking.
     */
    static readonly PATH_CANCEL_BOOKING = '/v1/widget/booking/cancel/$bookingId';

    /**
     * API path for getting the list of bookings of logged user.
     */
    static readonly PATH_LIST = '/v1/widget/booking/list';

    /**
     * API path for getting the calendar info with startDate and endDate for the given sub project.
     */
    static readonly PATH_SUB_PROJECT_CALENDAR_INFO = '/v1/widget/booking/sub-project-calendar-info/$subProjectId';

    /**
     * API path for getting the time slots info with date for the given sub project.
     */
    static readonly PATH_SUB_PROJECT_TIME_SLOT_INFO = '/v1/widget/booking/sub-project-time-slots-info/$subProjectId';

    /**
     * API path for booking a specific date and time slot.
     */
    static readonly PATH_SUB_PROJECT_BOOK_DATE_AND_TIME_SLOT = '/v1/widget/booking/sub-project-book-date-and-time-slot/$subProjectId';

    /**
     * API path for getting the booking info of the sub project.
     */
    static readonly PATH_SUB_PROJECT_BOOKING_INFO = '/v1/widget/booking/sub-project-booking-info/$subProjectId';

    cancelBooking(bookingId: string): Promise<string> {
        return this.http.post(
            BookingApi.PATH_CANCEL_BOOKING.replace(/\$bookingId/, bookingId),
            {},
            {},
            true
        );
    }

    async list(): Promise<Booking[]> {
        const bookings: Booking[] = await this.http.get(
            BookingApi.PATH_LIST,
            {},
            {},
            true
        );

        return bookings.map(booking => ({
            ...booking,
            ...{
                createdAt: new Date(booking.createdAt),
                updatedAt: new Date(booking.updatedAt),
                bookingDate: new Date(booking.bookingDate)
            }
        }));
    }

    async subProjectBookingInfo(subProjectId: string, preQualificationId: string): Promise<Booking|null> {
        const { booking } = await this.http.get(
            BookingApi.PATH_SUB_PROJECT_BOOKING_INFO.replace(/\$subProjectId/, subProjectId),
            { preQualificationId },
            {},
            true
        );

        return (booking)
            ? {
                ...booking,
                ...{
                    createdAt: new Date(booking.createdAt),
                    updatedAt: new Date(booking.updatedAt),
                    bookingDate: new Date(booking.bookingDate)
                }
            }
            : null;
    }

    async subProjectBookDateAndTimeSlot(subProjectId: string, dto: BookDateAndTimeDto): Promise<Booking> {
        const booking = await this.http.post(
            BookingApi.PATH_SUB_PROJECT_BOOK_DATE_AND_TIME_SLOT.replace(/\$subProjectId/, subProjectId),
            { 
                ...dto,
                ...{
                    date: formatDateToYYYYMMDDWithoutTimezone(dto.date)
                }
            },
            {},
            true
        );

        return {
            ...booking,
            ...{
                createdAt: new Date(booking.createdAt),
                updatedAt: new Date(booking.updatedAt),
                bookingDate: new Date(booking.bookingDate)
            }
        };
    }

    subProjectCalendarInfo(subProjectId: string, dto: SubProjectCalendarInfoDto): Promise<Record<string, BookingCalendarInfo>> {
        return this.http.get(
            BookingApi.PATH_SUB_PROJECT_CALENDAR_INFO.replace(/\$subProjectId/, subProjectId),
            { 
                ...dto,
                ...{
                    startDate: formatDateToYYYYMMDDWithoutTimezone(dto.startDate),
                    endDate: formatDateToYYYYMMDDWithoutTimezone(dto.endDate)
                }
            },
            {},
            true
        );
    }

    subProjectTimeSlotsInfoForDate(subProjectId: string, dto: SubProjectTimeSlotsInfoDto): Promise<BookingCalendarTimeSlot[]> {
        return this.http.get(
            BookingApi.PATH_SUB_PROJECT_TIME_SLOT_INFO.replace(/\$subProjectId/, subProjectId),
            { 
                ...dto,
                ...{
                    date: formatDateToYYYYMMDDWithoutTimezone(dto.date)
                }
            },
            {},
            true
        );
    }
}
