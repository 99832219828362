import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface ApplyLoanEmploymentInfoState {
    companyIndustry: string;
    companyName: string;
    officePhone: string;
    officeAddress: string;
    tin: string;
    sss: string;
    employmentRank: string;
    officeEmail: string;
    formErrors: Record<string, string>;
}

const initialState: ApplyLoanEmploymentInfoState = {
    companyIndustry: '',
    companyName: '',
    officePhone: '',
    officeAddress: '',
    tin: '',
    sss: '',
    employmentRank: '',
    officeEmail: '',
    formErrors: {}
};

export const applyLoanEmploymentInfoSlice = createSlice({
    name: 'applyLoanEmploymentInfo',

    initialState,

    reducers: {
        setCompanyIndustry: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.companyIndustry = action.payload;
        },

        setCompanyName: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.companyName = action.payload;
        },

        setOfficePhone: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.officePhone = action.payload;
        },

        setOfficeAddress: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.officeAddress = action.payload;
        },

        setTin: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.tin = action.payload;
        },

        setSss: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.sss = action.payload;
        },

        setEmploymentRank: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.employmentRank = action.payload;
        },

        setOfficeEmail: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<string>) => {
            state.officeEmail = action.payload;
        },

        setState: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<Partial<ApplyLoanEmploymentInfoState>>) => {
            state.companyIndustry = action.payload.companyIndustry || state.companyIndustry;
            state.companyName = action.payload.companyName || state.companyName;
            state.officePhone = action.payload.officePhone || state.officePhone;
            state.officeAddress = action.payload.officeAddress || state.officeAddress;
            state.tin = action.payload.tin || state.tin;
            state.sss = action.payload.sss || state.sss;
            state.employmentRank = action.payload.employmentRank || state.employmentRank;
            state.officeEmail = action.payload.officeEmail || state.officeEmail;
        },

        setFormErrors: (state: ApplyLoanEmploymentInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        },

        setDefaultFormValues: (state: ApplyLoanEmploymentInfoState) => {
            state.companyIndustry = '';
            state.companyName = '';
            state.officePhone = '';
            state.officeAddress = '';
            state.tin = '';
            state.sss = '';
            state.employmentRank = '';
            state.officeEmail = '';
        }
    }
});

export const {
    setCompanyIndustry,
    setCompanyName,
    setOfficePhone,
    setOfficeAddress,
    setTin,
    setSss,
    setEmploymentRank,
    setOfficeEmail,
    setState,
    setFormErrors,
    setDefaultFormValues
} = applyLoanEmploymentInfoSlice.actions;

export const selectCompanyIndustry = (state: RootState) => state.applyLoanEmploymentInfo.companyIndustry;
export const selectCompanyName = (state: RootState) => state.applyLoanEmploymentInfo.companyName;
export const selectOfficePhone = (state: RootState) => state.applyLoanEmploymentInfo.officePhone;
export const selectOfficeAddress = (state: RootState) => state.applyLoanEmploymentInfo.officeAddress;
export const selectTin = (state: RootState) => state.applyLoanEmploymentInfo.tin;
export const selectSss = (state: RootState) => state.applyLoanEmploymentInfo.sss;
export const selectEmploymentRank = (state: RootState) => state.applyLoanEmploymentInfo.employmentRank;
export const selectOfficeEmail = (state: RootState) => state.applyLoanEmploymentInfo.officeEmail;
export const selectFormErrors = (state: RootState) => state.applyLoanEmploymentInfo.formErrors;

export default applyLoanEmploymentInfoSlice.reducer;