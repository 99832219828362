import { useCallback, useState } from 'react';
import { Container } from 'typedi';
import { CaptchaApi } from 'src/api/CaptchaApi';

export default function useCaptcha() {
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bgImgSrc, setBgImgSrc] = useState('');
    const [croppedImgSrc, setCroppedImgSrc] = useState('');
    const [posY, setPosY] = useState(0);
    const [captchaKey, setCaptchaKey] = useState('');
    const [bgImageWidth] = useState(300);
    const [trackerImageWidth] = useState(50);
    
    const loadNewCaptchaImage = useCallback(async (): Promise<void> => {
        setIsLoading(true);
        setIsErrored(false);

        const createResp = await Container.get(CaptchaApi).create();

        setBgImgSrc(createResp.bgImg);
        setCroppedImgSrc(createResp.croppedImg);
        setPosY(createResp.y);
        setCaptchaKey(createResp.captchaKey);
        setIsVerified(false);

        setIsLoading(false);
    }, []);

    const submitValidate = async (posX: number) => {
        setIsVerifying(true);

        const verifyResp = await Container.get(CaptchaApi).verify(captchaKey, posX);

        setIsVerified(verifyResp.isValid);
        setIsErrored(!verifyResp.isValid);
        setIsVerifying(false);

        return verifyResp;
    };

    return {
        isLoading,
        bgImgSrc,
        captchaKey,
        croppedImgSrc,
        posY,
        isVerifying,
        isVerified,
        isErrored,
        bgImageWidth,
        trackerImageWidth,
        loadNewCaptchaImage,
        submitValidate
    };
}
