import {
    useMemo,
    useRef,
    useState,
    KeyboardEvent,
    FocusEvent,
    useEffect
} from 'react';
import { imageUrl } from 'src/helpers/url';
import { AngleDownSolidIcon } from 'src/components/icons';
import mobileCodes from './countryCodes.json';

const sortedMobileCodes = [...mobileCodes].sort((a, b) => {
    if (a.idd.length === b.idd.length) {
        return 0;
    }

    return (a.idd.length > b.idd.length) ? -1 : 1;
});

const sortedNameMobileCodes = [...mobileCodes].sort((a, b) => {
    return a.name.localeCompare(b.name);
});

interface MobileCode {
    id: string;
    code: string;
    name: string;
    idd: string;
};

type Props = {
    className?: string;
    onChange?: (val: string) => void;
    value?: string;
    attrs?: Record<string, unknown>;
};

export default function MobileNumberField({
    className,
    value,
    attrs,
    onChange
}: Props) {
    const rawMobileCode = mobileCodes.find(code => (code.code === 'PH'));
    const defaultMobileCode = (rawMobileCode === undefined) ? null : rawMobileCode;

    const [isPopupShown, setIsPopupShown] = useState(false);
    const [val, setVal] = useState(defaultMobileCode?.idd)
    const [selectedMobileCode, setSelectedMobileCode] = useState<MobileCode|null>(defaultMobileCode);
    const [searchFieldId] = useState(`search_field_${new Date().getTime()}`);
    const [portalId] = useState(`mobile_number_portal_${new Date().getTime()}`);
    const [keyword, setKeyword] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const el = useRef(null);
    const inputFieldEl = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (value) {
            setVal(value);

            const mobileCode = sortedMobileCodes.find(code => (code.idd === value));
            if (mobileCode) {
                setSelectedMobileCode(mobileCode);
            }
            else {
                const mobileCode = sortedMobileCodes.find(code => (value.startsWith(code.idd)));
                if (mobileCode) {
                    setSelectedMobileCode(mobileCode);
                }
            }
        }
    }, [value]);

    const filteredMobileCodes = useMemo(() => {
        return sortedNameMobileCodes.filter(
            code => (
                code.idd.startsWith(keyword.toLowerCase())
                || code.name.toLowerCase().startsWith(keyword.toLowerCase())
            )
        );
    }, [keyword]);

    const windowClickHandler = (e: MouseEvent) => {
        const htmlElem = e.target as HTMLElement;

        if (htmlElem.id !== searchFieldId) {
            setIsPopupShown(false);
            window.removeEventListener('click', windowClickHandler);
        }
    };

    const handleClick = () => {
        setTimeout(() => {
            if (!isPopupShown) {
                setKeyword('');
                setSelectedIndex(-1);
    
                setTimeout(() => {
                    const elem = document.getElementById(searchFieldId);
                    if (elem) {
                        elem.focus();
                    }
                }, 500);
    
                window.addEventListener('click', windowClickHandler);
            }

            setIsPopupShown(!isPopupShown);
        }, 50);
    };

    const handleSelectEntry= (mobileCode: MobileCode) => {
        setVal(mobileCode.idd);
        setSelectedMobileCode(mobileCode);

        if (inputFieldEl.current) {
            inputFieldEl.current.focus();
        }
    };

    const handleSearchKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            const newIdx = (selectedIndex + 1 < filteredMobileCodes.length - 1) ? selectedIndex + 1 : filteredMobileCodes.length - 1;
            setSelectedIndex(newIdx);

            const elem = document.getElementById(portalId);
            if (elem) {
                const height = elem.getBoundingClientRect().height;
                const newPos = (newIdx * 32) + 55;

                if (newPos + 32 > height) {
                    elem.scrollTop = ((newPos + 32) - height) + 10;
                }
            }
        }
        else if (e.key === 'ArrowUp') {
            const newIdx = (selectedIndex <= 0) ? selectedIndex : selectedIndex - 1;
            setSelectedIndex(newIdx);

            const elem = document.getElementById(portalId);
            if (elem) {
                const newPos = (newIdx * 32) + 55;

                if (newPos < elem.scrollTop) {
                    elem.scrollTop = Math.max(newPos - 72, 0);
                }
            }
        }
        else if (e.key === 'Enter') {
            const mobileCode = (selectedIndex >= 0 && selectedIndex < filteredMobileCodes.length) ? filteredMobileCodes[selectedIndex] : null;
            if (mobileCode) {
                handleSelectEntry(mobileCode);
                setIsPopupShown(false);
            }

            e.stopPropagation();
            e.preventDefault();
        }
        else if (
            e.key === 'ArrowLeft'
            || e.key === 'ArrowRight'
        ) {

        }
        else {
            setSelectedIndex(0);
        }
    }

    const handleInputKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
        const allowedKeys = [
            'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Backspace', 'Enter', 'Tab', 'Space',
            '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+'
        ]
        if (
            !allowedKeys.includes(e.key)
            && !(
                e.ctrlKey && (
                    e.key === 'v' || e.key === 'c' || e.key === 'a'
                )
            )
        ) {
            e.preventDefault();
        }
    }

    const handleChange = (val: string) => {
        const mobileCode = sortedMobileCodes.find(code => (code.idd === val));
        if (mobileCode) {
            setSelectedMobileCode(mobileCode);
        }
        else {
            const mobileCode = sortedMobileCodes.find(code => (val.startsWith(code.idd)));
            if (mobileCode) {
                setSelectedMobileCode(mobileCode);
            }   
        }

        if (onChange) {
            onChange(val);
        }
    }

    const handleComponentFocus = (evt: FocusEvent<HTMLElement>) => {
        // if (inputFieldEl.current) {
        //     inputFieldEl.current.select();
        //     inputFieldEl.current.focus();
        // }
    };

    return (
        <div
            ref={el}
            tabIndex={0}
            className={`relative flex ${className || ''}`}
            onFocus={handleComponentFocus}
        >
            <div
                className={`w-[80px] flex items-center bg-gray-200 rounded-l-md text-gray-150 pl-3 pr-2.5 py-0.5 text-base overflow-hidden cursor-pointer mobile-number-field`}
                onClick={() => handleClick()}
            >
                <span className={`mr-2 flex-grow whitespace-nowrap overflow-hidden`}>
                    {(selectedMobileCode) && (
                        <img
                            src={imageUrl(`/flags/${selectedMobileCode.code}.svg`)}
                            className="w-8 h-8"
                            alt={`Flag for country code ${selectedMobileCode.code}`}
                        />
                    )}
                </span>
                
                <AngleDownSolidIcon className={`w-4 h-4 text-gray-300`} />
            </div>

            <input
                ref={inputFieldEl}
                type="tel"
                className={`w-[calc(100%-80px)] rounded-r-xl border border-solid border-gray-200 focus-visible:outline-gray-300 py-2 px-3`}
                onChange={(e) => {
                    setVal(e.target.value);
                    handleChange(e.target.value);
                }}
                onKeyDown={handleInputKeydown}
                value={val}
                { ...attrs }
            />

            { /* popup section */ }
            {(isPopupShown) && (
                <div
                    className={`
                        absolute z-10 top-[calc(100%+4px)] max-h-[150px] overflow-y-auto
                        rounded-lg shadow-lg shadow-slate-400 bg-white
                    `}
                    id={portalId}
                >
                    <div className="w-full px-2 py-2 sticky top-0 left-0 bg-white z-10">
                        <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => {
                                setKeyword(e.target.value);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onKeyDown={handleSearchKeydown}
                            className="w-full form-field px-2"
                            id={searchFieldId}
                            tabIndex={-1}
                        />
                    </div>

                    <div className="py-2 ">
                        {filteredMobileCodes.map((mobileCode, idx) => (
                            <div
                                key={mobileCode.id}
                                className={`
                                    flex hover:bg-slate-200
                                    py-1 px-2 items-center cursor-pointer
                                    ${(selectedIndex === idx) ? 'bg-slate-200' : ''}
                                `}
                                onClick={() => handleSelectEntry(mobileCode)}
                            >
                                <img
                                    src={imageUrl(`/flags/${mobileCode.code}.svg`)}
                                    className="w-6 h-6 mr-1.5"
                                    alt={`Flag for country code ${mobileCode.code}`}
                                />
                                <span className="max-w-[calc(100%-100px)] whitespace-nowrap overflow-hidden overflow-ellipsis mr-1.5">{mobileCode.name}</span>
                                <span>{mobileCode.idd}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}