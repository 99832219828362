import { useState } from 'react';
import FirstForm from 'src/page_components/forgot_password/FirstForm';
import SecondForm from 'src/page_components/forgot_password/SecondForm';
import ThirdForm from 'src/page_components/forgot_password/ThirdForm';

type ActiveForm = 'form-1' | 'form-2' | 'form-3';

const ForgotPassword = () => {
    const [activeForm, setActiveForm] = useState<ActiveForm>('form-1');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');

    return (
        <>
            <div
                className={`
                    bg-uwi-content-100 shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]
                    text-black rounded-t-3xl
                    h-[calc(100vh-156px)] xs:h-[calc(100vh-155px)] md:h-[612px] lg:h-[597px] w-full
                    flex justify-center
                `}
            >
                <div className="w-[400px] flex flex-col p-5 justify-center items-center">
                    {(activeForm === 'form-1') && (
                        <FirstForm
                            onEmailSent={(email) => {
                                setActiveForm('form-2');
                                setEmail(email);
                            }}
                        />
                    )}

                    {(activeForm === 'form-2') && (
                        <SecondForm
                            email={email}
                            onContinue={(otp) => {
                                setActiveForm('form-3');
                                setOtp(otp);
                            }}
                            onChangeClick={() => setActiveForm('form-1')}
                        />
                    )}

                    {(activeForm === 'form-3') && (
                        <ThirdForm
                            email={email}
                            otp={otp}
                            onButtonClick={() => setActiveForm('form-2')}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;