import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleLoaderIcon } from 'src/components/icons';
import { useAppSelector } from 'src/hooks/generic';
import { selectIsProjectOnlyView, selectWidgetType } from 'src/store/features/commonSlice';
import Context from '../PreQualificationContext';

type Props = {
    onNext?: () => Promise<boolean>;
};

const FieldGroupBottomButtons = ({
    onNext
}: Props) => {
    const widgetType = useAppSelector(selectWidgetType);
    
    const navigate = useNavigate();

    const { page, setPage } = useContext(Context);
    const isProjectOnlyView = useAppSelector(selectIsProjectOnlyView);
    const [isLoading, setIsLoading ]= useState(false)

    const handleBack = () => {
        if (page === 0) {
            navigate(-1);
        }
        else if (widgetType === 'advance' && !isProjectOnlyView) {
            setPage((page <= 1) ? page - 0.5 : page - 1);
        }
        else {
            setPage(page - 1);
        }
    };

    const handleNext = async () => {
        if (onNext) {
            try {
                setIsLoading(true);
                const isSuccess = await onNext();
                if (isSuccess) {
                    if (widgetType === 'advance' && !isProjectOnlyView) {
                        setPage((page < 1) ? page + 0.5 : page + 1);
                    }
                    else {
                        setPage(page + 1);
                    }
                }
            }
            finally {
                setIsLoading(false);
            }
        }
        else {
            if (widgetType === 'advance' && !isProjectOnlyView) {
                setPage((page < 1) ? page + 0.5 : page + 1);
            }
            else {
                setPage(page + 1);
            }
        }
    };
    
    return (
        <div className="w-full flex justify-center">
            <button
                className={`
                    w-[calc(50%-8px)] md:w-[150px] mr-4
                    flex justify-center items-center rounded-2xl
                    px-8 py-4
                    font-bold text-[15px]
                    border border-solid
                    border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                    hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                `}
                onClick={() => handleBack()}
            >
                Back
            </button>
            <button
                className={`
                    w-[calc(50%-8px)] md:w-[150px]
                    flex justify-center items-center rounded-2xl
                    text-[15px] font-bold
                    text-white px-8 py-4
                    bg-uwi-primary-100 hover:bg-uwi-primary-200
                `}
                onClick={() => handleNext()}
            >
                {(page === 4) ? 'Finish' : 'Next'}

                {(isLoading) && (
                    <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                )}
            </button>
        </div>
    );
};

export default FieldGroupBottomButtons;
