import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { hasProp } from 'src/helpers/object';

interface PreApproveLoanWorkInfoState {
    workType: string;
    isRegularEmployee: boolean;
    isBusinessProfitable: boolean;
    workExperience: string;
    businessProprietorship: string;
    monthlyIncome: number;
    saveToStorage: boolean;
    formErrors: Record<string, string>;
}

const savePreApproveLoanWorkInfoState = (info: PreApproveLoanWorkInfoState) => {
    if (window.sessionStorage) {
        const { formErrors, saveToStorage, ...others } = info;
        window.sessionStorage.setItem('preApproveLoanWorkInfoState', JSON.stringify(others));
    }
};

const loadPreApproveLoanWorkInfoState = (): PreApproveLoanWorkInfoState => {
    try {
        if (typeof window !== 'undefined' && window.sessionStorage) {
            const serializedState = window.sessionStorage.getItem('preApproveLoanWorkInfoState');
            if (serializedState !== null) {
                const parsedState = JSON.parse(serializedState);
                parsedState.formErrors = {};
                parsedState.saveToStorage = true;
    
                return parsedState as PreApproveLoanWorkInfoState;
            }
        }
    }
    catch (ex) {
        console.error(ex);
    }

    return {
        workType: '',
        isRegularEmployee: false,
        isBusinessProfitable: false,
        workExperience: '',
        businessProprietorship: '',
        monthlyIncome: 0,
        saveToStorage: true,
        formErrors: {}
    };
}

const initialState: PreApproveLoanWorkInfoState = {
    workType: '',
    isRegularEmployee: false,
    isBusinessProfitable: false,
    workExperience: '',
    businessProprietorship: '',
    monthlyIncome: 0,
    saveToStorage: true,
    formErrors: {}
};

export const preApproveLoanWorkInfoSlice = createSlice({
    name: 'preApproveLoanWorkInfo',

    initialState,

    reducers: {
        setWorkType: (state: PreApproveLoanWorkInfoState, action: PayloadAction<string>) => {
            state.workType = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);
            }
        },

        setIsRegularEmployee: (state: PreApproveLoanWorkInfoState, action: PayloadAction<boolean>) => {
            state.isRegularEmployee = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);
            }
        },

        setIsBusinessProfitable: (state: PreApproveLoanWorkInfoState, action: PayloadAction<boolean>) => {
            state.isBusinessProfitable = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);
            }
        },

        setWorkExperience: (state: PreApproveLoanWorkInfoState, action: PayloadAction<string>) => {
            state.workExperience = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);
            }
        },

        setBusinessProprietorship: (state: PreApproveLoanWorkInfoState, action: PayloadAction<string>) => {
            state.businessProprietorship = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);
            }
        },

        setMonthlyIncome: (state: PreApproveLoanWorkInfoState, action: PayloadAction<number>) => {
            state.monthlyIncome = action.payload;
            if (state.saveToStorage) {
                savePreApproveLoanWorkInfoState(state);   
            }
        },

        setSaveToStorage: (state: PreApproveLoanWorkInfoState, action: PayloadAction<boolean>) => {
            state.saveToStorage = action.payload;
        },

        loadFromStorage: () => {
            return loadPreApproveLoanWorkInfoState();
        },

        setState: (state: PreApproveLoanWorkInfoState, action: PayloadAction<Partial<PreApproveLoanWorkInfoState>>) => {
            state.workType = action.payload.workType || state.workType;
            state.isRegularEmployee = hasProp(action.payload, 'isRegularEmployee') ? (action.payload.isRegularEmployee || false) : state.isRegularEmployee;
            state.isBusinessProfitable = hasProp(action.payload, 'isBusinessProfitable') ? (action.payload.isBusinessProfitable || false) : state.isBusinessProfitable;
            state.workExperience = action.payload.workExperience || state.workExperience;
            state.monthlyIncome = action.payload.monthlyIncome || state.monthlyIncome;
            state.saveToStorage = action.payload.saveToStorage || state.saveToStorage;
            state.formErrors = {};
        },

        setDefaultFormValues: (state: PreApproveLoanWorkInfoState) => {
            state.workType = '';
            state.isRegularEmployee = false;
            state.isBusinessProfitable = false;
            state.workExperience = '';
            state.monthlyIncome = 0;

            savePreApproveLoanWorkInfoState(state);
        },

        setFormErrors: (state: PreApproveLoanWorkInfoState, action: PayloadAction<Record<string, string>>) => {
            state.formErrors = action.payload;
        }
    }
});

export const {
    setWorkType,
    setIsRegularEmployee,
    setIsBusinessProfitable,
    setWorkExperience,
    setBusinessProprietorship,
    setMonthlyIncome,
    setSaveToStorage,
    loadFromStorage,
    setState,
    setFormErrors,
    setDefaultFormValues
} = preApproveLoanWorkInfoSlice.actions;

export const selectWorkType = (state: RootState) => state.preApproveLoanWorkInfo.workType;
export const selectIsRegularEmployee = (state: RootState) => state.preApproveLoanWorkInfo.isRegularEmployee;
export const selectIsBusinessProfitable = (state: RootState) => state.preApproveLoanWorkInfo.isBusinessProfitable;
export const selectWorkExperience = (state: RootState) => state.preApproveLoanWorkInfo.workExperience;
export const selectBusinessProprietorship = (state: RootState) => state.preApproveLoanWorkInfo.businessProprietorship;
export const selectMonthlyIncome = (state: RootState) => state.preApproveLoanWorkInfo.monthlyIncome;
export const selectFormErrors = (state: RootState) => state.preApproveLoanWorkInfo.formErrors;

export default preApproveLoanWorkInfoSlice.reducer;