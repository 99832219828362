import LinePaginationItem from './LinePaginationItem';

type Props = {
    selectedPage: number;
    setPage: (page: number) => void;
    className?: string;
    paginationItems: string[];
};

const LinePagination = ({
    selectedPage,
    className,
    setPage,
    paginationItems
}: Props) => {
    return (
        <div className={`flex flex-nowrap justify-center ${className || ''}`}>
            <div className="w-full flex flex-nowrap justify-center items-center">
                {paginationItems.map((item, idx) => (
                    <LinePaginationItem
                        key={idx}
                        text={item}
                        className="w-1/5"
                        isSelected={(Math.floor(selectedPage) === idx)}
                        hasPrevious={(idx > 0)}
                        hasNext={(idx < paginationItems.length - 1)}
                        onClickItem={() => setPage(idx)}
                    />
                ))}
            </div>
        </div>
    );
}

export default LinePagination;
