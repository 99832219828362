/**
 * Creates an absolute url of the given image path.
 * 
 * @param   imagePath    The absolute url of the image.
 * 
 * @return string
 */
export function imageUrl(imagePath: string):string {
    return `${process.env.REACT_APP_BASE_URL}/img${imagePath}`;
}
