import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';

export type AdditionalCostType = "flat" | "percent";

export interface SubProject {
    id: string;
    projectId: string;
    name: string;
    prices: number[];
    priceType: string;
    minPrice: number;
    image: string;
    projectTitle: string;
    projectType: string;
    projectAddress: string;
    projectStatus: string;
    additionalCost: { additionalCostType: AdditionalCostType, additionalCostValue: number }
}

export interface SubProjectPreQualificationTableRow {
    id: string;
    name: string;
    image: string;
    price: number;
    quantity: number;
    projectTitle: string;
}

export interface AddedSubProject {
    id: string;
    name: string;
    image: string;
    price: number;
    quantity: number;
    projectStatus: string;
    projectType: string;
    projectId: string;
    subProjectReservationFee: number;
}

@Service()
export class SubProjectApi extends BaseApi {
    /**
     * API path to search the list of sub projects
     */
    static readonly PATH_SEARCH = '/v1/widget/sub-projects/search';

    /**
     * API path to search the list of sub projects
     */
    static readonly PATH_IMAGES = '/v1/widget/sub-projects/$subProjectId/images';

    search(
        projectIds: string[] = [],
        projectTypes: string[] = [],
        projectStatuses: string[] = [],
        minPrice: number = -1,
        maxPrice: number = -1
    ): Promise<SubProject[]> {
        const data: Record<string, any> = {};

        if (projectIds.length > 0) {
            data.projectIds = projectIds;
        }

        if (projectTypes.length > 0) {
            data.projectTypes = projectTypes;
        }

        if (projectStatuses.length > 0) {
            data.projectStatuses = projectStatuses;
        }

        if (minPrice > 0 && maxPrice > 0) {
            data.minPrice = minPrice.toString();
            data.maxPrice = maxPrice.toString();
        }

        return this.http.post(
            SubProjectApi.PATH_SEARCH,
            data
        );
    }

    subProjectImages(subProjectId: string) {
        return this.http.get(
            SubProjectApi.PATH_IMAGES.replace(/\$subProjectId/, subProjectId),
            {},
            {},
            false
        );
    }
}
