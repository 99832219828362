import { useEffect, useState } from 'react';
import { Container } from 'typedi';
import { CircleLoaderIcon } from 'src/components/icons';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { AddedSubProject } from 'src/api/SubProjectApi';
import { BookingApi, Booking } from 'src/api/BookingApi';
import BookingSummary from './BookingSummary';
import UpdateBooking from './UpdateBookingForm';

type Props = {
    subProject: AddedSubProject|null;
    subProjectId: string;
    preQualificationId: string;
};

const TabContent = ({
    subProject,
    subProjectId,
    preQualificationId
}: Props) => {
    const {
        commonErrorHandler
    } = useGenericErrorHandler();

    const [booking, setBooking] = useState<Booking | null>(null);
    const [isUpdateBookingFormShown, setIsUpdateBookingFormShown] = useState(false);

    useEffect(() => {
        if (subProjectId !== '') {
            Container
                .get(BookingApi)
                .subProjectBookingInfo(subProjectId, preQualificationId)
                .then(booking => setBooking(booking))
                .catch(commonErrorHandler);
        }
    }, [subProjectId, preQualificationId, commonErrorHandler]);

    if (!subProject || !booking) {
        return (
            <div className="w-full flex justify-center items-center h-[100px]">
                <CircleLoaderIcon className="animate-spin ml-3 text-uwi-primary-100 !h-20 !w-20" />
            </div>
        );
    }

    return (
        <>
            {
                (isUpdateBookingFormShown)
                  ? <UpdateBooking
                        subProject={subProject}
                        booking={booking}
                        preQualificationId={preQualificationId}
                        onUpdatedBooking={(booking) => setBooking(booking)}
                    />
                  : <BookingSummary
                        subProject={subProject}
                        booking={booking}
                        onBookingUpdated={(booking) => setBooking(booking)}
                        onClickUpdateBooking={() => setIsUpdateBookingFormShown(true)}
                    />
            }
        </>
    );
};

export default TabContent;
