import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { AngleLeftSolidIcon, PhoneIcon } from '../icons';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import { selectHasBookingFeature, selectHasReservationPaymentsFeature } from 'src/store/features/commonSlice';
import {
    setDefaultFormValues as setLoanPropertyInfoDefaultFormValues
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    setDefaultFormValues as setLoanDebtDefaultFormValues
} from 'src/store/features/preApproveLoanDebtIncomeInfoSlice';
import {
    setDefaultFormValues as setLoanLoanDefaultFormValues
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import {
    setDefaultFormValues as setLoanOtherInfoDefaultFormValues
} from 'src/store/features/preApproveLoanOtherInfoSlice';
import {
    setDefaultFormValues as setLoanPropertyDefaultFormValues
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    setDefaultFormValues as setLoanWorkInfoDefaultFormValues
} from 'src/store/features/preApproveLoanWorkInfoSlice';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';

type Props = {
    children?: React.ReactNode
    dimmerClassName?: string;
    className?: string;
    onClose?: () => void;
    visible?: boolean;
};

export default function Menu({
    onClose,
    visible
}: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const { errorHandlerWithToast } = useGenericErrorHandler();

    const hasBookingFeature = useAppSelector(selectHasBookingFeature);
    const hasReservationPaymentsFeature = useAppSelector(selectHasReservationPaymentsFeature);

    const [mailToLink, setMailToLink] = useState('');

    useEffect(() => {
        setMailToLink('mailto:loans@uwihomes.com');
    }, []);

    const handleClose = onClose || (() => {});

    const handleLogout = () => {
        logout()
            .then(() => {
                navigate('/');
                dispatch(setLoanPropertyInfoDefaultFormValues());
                dispatch(setLoanDebtDefaultFormValues());
                dispatch(setLoanLoanDefaultFormValues());
                dispatch(setLoanOtherInfoDefaultFormValues());
                dispatch(setLoanPropertyDefaultFormValues());
                dispatch(setLoanWorkInfoDefaultFormValues());
            })
            .catch(errorHandlerWithToast);
    };

    return (
        <>
            <div
                className={`
                    bg-white/50 z-20 absolute
                    h-full w-full
                    ${(visible) ? 'block' : 'hidden'}
                `}
                onClick={() => handleClose()}
            ></div>
            <div
                className={`
                    absolute z-[21] bg-white w-[250px] h-full
                    transition-transform transform duration-300
                    ${(visible) ? 'translate-x-0 shadow-[10px_0px_20px_5px_rgba(0,0,0,0.1)]' : '-translate-x-full'}
                `}
            >
                <div className="py-5 px-5">
                    <button
                        onClick={() => handleClose()}
                        className="text-gray-100 hover:text-gray-300 hover:bg-transparent"
                    >
                        <AngleLeftSolidIcon className="w-6 h-6" />
                    </button>
                </div>

                <div className="border-b border-b-gray-200 border-solid px-5 py-4">
                    <button
                        onClick={() => {
                            handleClose();
                            navigate('/pre-qualification');
                        }}
                        className="text-black hover:text-uwi-primary-100 hover:bg-transparent font-bold text-[14px]"
                    >
                        Take Home Loan Assessment
                    </button>
                </div>

                {(!user) && (
                    <div className="px-6 mt-5">
                        <button
                            onClick={() => {
                                handleClose();
                                navigate('/signup');
                            }}
                            className={`
                                rounded-xl w-full py-4 mt-3
                                font-bold text-[15px]
                                border border-solid text-white
                                border-uwi-primary-100 hover:border-uwi-primary-200
                                bg-uwi-primary-100 hover:bg-uwi-primary-200
                            `}
                        >
                            Sign Up
                        </button>

                        <button
                            onClick={() => {
                                handleClose();
                                navigate('/login');
                            }}
                            className={`
                                rounded-xl w-full py-4 mt-3
                                font-bold text-[15px]
                                border border-solid
                                border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                            `}
                        >
                            Login
                        </button>
                    </div>
                )}

                {(!!user) && (
                    <>
                        <div className="border-b border-b-gray-200 border-solid px-5 py-5">
                            <button
                                onClick={() => {
                                    handleClose();
                                    navigate('/dashboard-pre-qualifications');
                                }}
                                className="text-black hover:text-uwi-primary-100 hover:bg-transparent font-bold text-[15px] mb-5"
                            >
                                Home Loan Assessments
                            </button>

                            <button
                                onClick={() => {
                                    handleClose();
                                    navigate('/dashboard_home_loan_applications');
                                }}
                                className="text-black hover:text-uwi-primary-100 hover:bg-transparent font-bold text-[15px]"
                            >
                                Home Loan Applications
                            </button>

                            {(hasBookingFeature) && (
                                <button
                                    onClick={() => {
                                        handleClose();
                                        navigate('/dashboard-bookings');
                                    }}
                                    className="text-black hover:text-uwi-primary-100 hover:bg-transparent font-bold text-[15px] mt-5"
                                >
                                    Bookings
                                </button>
                            )}

                            {(hasReservationPaymentsFeature) && (
                                <button
                                    onClick={() => {
                                        handleClose();
                                        navigate('/dashboard-payment-reservations');
                                    }}
                                    className="text-black hover:text-uwi-primary-100 hover:bg-transparent font-bold text-[15px] mt-5"
                                >
                                    Payment Reservations
                                </button>
                            )}
                        </div>

                        <div className="px-6 mt-5">
                            <button
                                onClick={() => handleLogout()}
                                className={`
                                    rounded-xl w-full py-4 mt-2
                                    font-bold text-[15px]
                                    border border-solid
                                    border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                    hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                                `}
                            >
                                Logout
                            </button>
                        </div>
                    </>
                )}

                <div className="absolute bottom-6 left-6 flex items-center text-[15px] text-gray-50">
                    <PhoneIcon
                        className="bg-uwi-primary-100 p-1.5 mr-2"
                        innerClassName="w-[13px] h-[13px] text-white"
                    />
                    Question?&nbsp;
                    <a
                        href={(mailToLink.length > 0) ? mailToLink : '#'}
                        className="text-uwi-primary-100 hover:text-uwi-primary-200"
                    >
                        Contact Us
                    </a>
                </div>
            </div>
        </>
    );
};