import { useEffect, useState } from 'react';
import { Modal } from 'src/components/common/modal';
import { CircleLoaderIcon, CircleXMarkSolidIcon } from 'src/components/icons';
import DebtIncomeInfo from './result/DebtIncomeInfo';
import LoanInfo from './result/LoanInfo';
import OtherInfo from './result/OtherInfo';
import PropertyInfo from './result/PropertyInfo';
import WorkInfo from './result/WorkInfo';
import usePreQualificationApi from './usePreQualificationApi';

type Props = {
    visible: boolean;
    onClose?: () => void;
    onEdit?: () => void;
    preQualificationId?: string;
};

const PreQualificationResultFormValuesModal = ({
    onClose,
    visible,
    onEdit,
    preQualificationId
}: Props) => {
    const {
        createPreQualification,
        updatePreQualification,
        isSavingPreQualification
    } = usePreQualificationApi();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        else {
            setIsVisible(false);
        }
    };

    const handleEdit = onEdit || (() => {});

    return (
        <Modal
            visible={isVisible}
            onClose={handleClose}
            className="w-full bg-white min-h-screen"
        >
            <div className="text-center !mb-3 !pt-4">
                <button
                    className="group"
                    onClick={() => handleClose()}
                >
                    <CircleXMarkSolidIcon className="w-10 h-10" />
                </button>
            </div>

            <div className="text-center !mb-4">Your pre-approved requirements</div>

            <div
                className=""
            >
                <PropertyInfo
                    className="mb-4"
                    headerClassName="w-full text-center"
                    listClassName="w-full"
                    listEntryClassName="w-full flex pt-1"
                    listEntryLeftClassName="w-[calc((100%-8px)/2)] block text-right px-6"
                />
                <WorkInfo
                    className="mb-4"
                    headerClassName="w-full text-center"
                    listClassName="w-full"
                    listEntryClassName="w-full flex pt-1"
                    listEntryLeftClassName="w-[calc((100%-8px)/2)] block text-right px-6"
                />
                <OtherInfo 
                    className="mb-4"
                    headerClassName="w-full text-center"
                    listClassName="w-full"
                    listEntryClassName="w-full flex pt-1"
                    listEntryLeftClassName="w-[calc((100%-8px)/2)] block text-right px-6"
                />
                <DebtIncomeInfo
                    className="mb-4"
                    headerClassName="w-full text-center"
                    listClassName="w-full"
                    listEntryClassName="w-full flex pt-1"
                    listEntryLeftClassName="w-[calc((100%-8px)/2)] block text-right px-6"
                />
                <LoanInfo
                    headerClassName="w-full text-center"
                    listClassName="w-full"
                    listEntryClassName="w-full flex pt-1"
                    listEntryLeftClassName="w-[calc((100%-8px)/2)] block text-right px-6"
                />
            </div>
            <div className="w-full px-8 pb-10 pt-8">
                <div className="w-full flex justify-center">
                    <button
                        className={`
                            w-[calc((100%-8px)/2)] rounded-lg py-4 flex justify-center mr-2
                            font-bold text-[15px]
                            border border-solid
                            ${
                                (!isSavingPreQualification)
                                ? `
                                    border-uwi-primary-100 text-uwi-primary-100 bg-transparent
                                    hover:bg-uwi-primary-200 hover:border-uwi-primary-200 hover:text-white
                                `
                                : 'bg-gray-100 text-white'
                            }
                        `}
                        onClick={() => handleEdit()}
                    >
                        Edit
                    </button>
                    <button
                        className={`
                            w-[calc((100%-8px)/2)] rounded-lg py-4 flex justify-center
                            font-bold text-[15px]
                            border border-solid
                            ${
                                (!isSavingPreQualification)
                                ? `
                                    border-uwi-primary-100 hover:border-uwi-primary-200
                                    bg-uwi-primary-100 hover:bg-uwi-primary-200 
                                    text-white
                                `
                                : 'bg-gray-100 text-white'
                            }
                        `}
                        onClick={() => {
                            if (preQualificationId) {
                                updatePreQualification(preQualificationId);
                            }
                            else {
                                createPreQualification();
                            }
                        }}
                    >
                        Save
                        {isSavingPreQualification && (
                            <CircleLoaderIcon className="animate-spin ml-3 text-white" />
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    )
};

export default PreQualificationResultFormValuesModal;
