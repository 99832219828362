import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'typedi';
import {
    MagnifyingGlassSolidIcon,
    CircleLoaderIcon,
    CirclePlusSolidIcon
} from 'src/components/icons';
import useAuth from 'src/hooks/useAuth';
import { Booking, BookingApi } from 'src/api/BookingApi';
import useGenericErrorHandler from 'src/hooks/useGenericErrorHandler';
import { formatDateToYYYYMMDD } from 'src/helpers/date';

const DashboardBookingsPage = () => {
    const {
        errorHandlerWithToast
    } = useGenericErrorHandler();
    
    const navigate = useNavigate();
    const { user, hasLoadedFromStorage } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState<Booking[]>([]);

    useEffect(() => {
        setIsLoading(true);
        Container
            .get(BookingApi)
            .list()
            .then(bookings => setBookings(bookings))
            .catch(errorHandlerWithToast)
            .finally(() => {
                setIsLoading(false);
            });
    }, [errorHandlerWithToast]);

    useEffect(() => {
        if (!user && hasLoadedFromStorage) {
            navigate(`/login?referrer=${encodeURIComponent('/dashboard-bookings')}`);
        }
    }, [user, navigate, hasLoadedFromStorage]);

    return (
        <>
            <div className="bg-uwi-content-100 text-black rounded-t-3xl min-h-[456px] shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]">
                <div className="pt-10">
                    <div className="overflow-y-auto uwi-scrollbar h-[calc(100vh-196px)] xs:h-[calc(100vh-195px)] md:h-[572px] lg:h-[557px] mx-4 md:mx-[40px]">
                        <div className="flex mb-4 pb-4 border-b border-solid border-gray-50">
                            <div className="w-full flex items-baseline flex-wrap md:flex-nowrap">
                                <div className="text-xl text-uwi-primary-100 mr-1 font-bold">Hi {(user) ? user.firstName : ''}!</div>
                                <div className="text-sm text-black mt-1 md:mt-0">Here are your bookings below.</div>
                            </div>
                        </div>

                        <table className="w-full !m-0 !border-none">
                            <thead className="border-b border-solid border-gray-50">
                                <tr>
                                    <th className="font-normal text-left text-sm border-0 border-none p-0 pb-4 pl-4 whitespace-nowrap">Date</th>
                                    <th className="hidden md:!table-cell md:!visible font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Booking No.</th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Property</th>
                                    <th className="hidden lg:!table-cell md:!visible font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Status</th>
                                    <th className="font-normal text-left text-sm border-none p-0 pb-4 whitespace-nowrap">Action</th>
                                </tr>
                            </thead>
                            <tbody className="relative">
                                {(isLoading) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none !p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            <CircleLoaderIcon className="animate-spin ml-3 text-white inline-block" />
                                            &nbsp;Fetching records...
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading && bookings.length === 0) && (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="text-center text-sm text-gray-50 items-center border-0 border-none p-0 !py-12 !px-6 whitespace-nowrap"
                                        >
                                            No records found
                                        </td>
                                    </tr>
                                )}

                                {(!isLoading) && (
                                    bookings.map(booking => (
                                        <tr
                                            key={booking.id}
                                            className={`w-full h-full items-center even:bg-gray-75`}
                                        >
                                            <td className="text-left text-sm border-0 border-none align-middle p-0 py-4 pl-4 whitespace-nowrap">
                                                {formatDateToYYYYMMDD(booking.bookingDate)}
                                            </td>
                                            <td className="hidden md:!table-cell md:!visible text-left text-uwi-primary-100 text-sm align-middle border-0 border-none p-0 py-4 whitespace-nowrap">
                                                {booking.referenceNumber}
                                            </td>
                                            <td className="text-left text-sm items-center align-middle border-0 border-none p-0 py-4 whitespace-nowrap max-w-[140px] overflow-hidden overflow-ellipsis md:max-w-[200px]">
                                                {booking.projectTitle} - {booking.subProjectName}
                                            </td>
                                            <td className="hidden lg:!table-cell md:!visible text-sm align-middle text-left border-0 border-none p-0 py-4 whitespace-nowrap uppercase">
                                                {
                                                    (booking.status.startsWith('cancelled'))
                                                    ? <span className="text-red-100">CANCELLED</span>
                                                    : <span>{booking.status}</span>
                                                }
                                            </td>
                                            <td className="border-0 border-none p-0 py-3 align-middle whitespace-nowrap">
                                                <button
                                                    className="w-6 h-6 text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                                                    onClick={() => {
                                                        navigate(`/view-booking?preQualificationId=${booking.preQualificationId}&subProjectId=${booking.subProjectId}`);
                                                    }}
                                                >
                                                    <MagnifyingGlassSolidIcon />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardBookingsPage;