import { Container } from "typedi";
import { HttpRequest } from "./lib/HttpRequest";
import { HttpRequestFactory } from "./lib/HttpRequestFactory";

export interface LoginResponse {
    jwtToken: string;
    refreshToken: string;
}

export interface SignupForm {
    email: string;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    password: string;
    captcha: string;
}

export class AuthApi {
    /**
     * The HTTP request object.
     */
    protected readonly http: HttpRequest;

    /**
     * API path to login into the system.
     */
    static readonly PATH_LOGIN = '/v1/widget/auth/login';

    /**
     * API path to logout from the system.
     */
    static readonly PATH_LOGOUT = '/v1/widget/auth/logout';

    /**
     * API path for refreshing the session
     */
    static readonly PATH_REFRESH_SESSION = '/v1/widget/auth/refresh-session';

    /**
     * API path to signup in the system.
     */
     static readonly PATH_SIGNUP = '/v1/widget/auth/signup';

    constructor() {
        this.http = Container.get(HttpRequestFactory).getImplementation();
    }

    login(
        email: string,
        password: string
    ): Promise<LoginResponse> {
        return this.http.post(
            AuthApi.PATH_LOGIN,
            { email, password }
        );
    }

    logout(
        refreshToken: string
    ): Promise<string> {
        return this.http.post(
            AuthApi.PATH_LOGOUT,
            { refreshToken },
            {},
            true
        );
    }

    refreshSession(
        refreshToken: string
    ): Promise<LoginResponse> {
        return this.http.post(
            AuthApi.PATH_REFRESH_SESSION,
            { refreshToken }
        );
    }

    signup(form: SignupForm): Promise<string> {
        return this.http.post(
            AuthApi.PATH_SIGNUP,
            form
        );
    }

}
