import { CircleExclamationSolidIcon } from "../icons";

type Props = {
    className?: string;
    exclamationClassName?: string;
    showError: boolean;
    errorMessage?: string;
    children: JSX.Element;
    messageClassName?:string;
};

export default function FormFieldWrapper({
    className,
    exclamationClassName,
    showError,
    errorMessage,
    children,
    messageClassName
}: Props) {
    return (
        <div className={`relative ${className || ''}`}>
            {children}

            <div className="group absolute top-[calc((100%-24px)/2)] right-2">
                <div
                    className={`
                        rounded-full cursor-pointer
                        ${(showError) ? 'block' : 'hidden'}
                    `}
                >
                    <CircleExclamationSolidIcon
                        className={`w-6 h-6 text-red-200 ${exclamationClassName || ''}`}
                    />
                </div>

                <div className={`${messageClassName || ''} absolute top-[calc(100%+6px)] -left-[90px] w-[200px] bg-red-300 text-red-200 border border-red-100 text-xs rounded-lg p-2 z-[1] hidden group-hover:!block`}>
                    {errorMessage}
                </div>
            </div>
        </div>
    );
}