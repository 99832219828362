type Props = {
  className?: string;
};

const GCashSmIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="18"
      fill="none"
      viewBox="0 0 17 14"
      className={className}
    >
      <path
        fill="#017BFF"
        d="M8.147 0a6.665 6.665 0 013.2 1.044c.179.113.354.235.52.366a.727.727 0 01.276.754.73.73 0 01-.567.598.757.757 0 01-.679-.144 5.185 5.185 0 00-1.178-.683 5.04 5.04 0 00-1.785-.387C6.363 1.5 5.026 2.04 3.935 3.17a4.985 4.985 0 00-1.37 2.785 5.197 5.197 0 004.087 5.89c1.549.312 2.964-.013 4.23-.957.327-.243.768-.23 1.051.057a.764.764 0 01-.065 1.151c-.264.211-.547.395-.842.56a6.623 6.623 0 01-2.492.808 6.761 6.761 0 01-6.861-3.673 6.498 6.498 0 01-.674-2.23l-.029-.28a.764.764 0 00-.04-.335v-.353l.038-.359c.024-.325.07-.647.142-.964.374-1.629 1.236-2.945 2.57-3.947C4.75.519 5.962.088 7.298 0h.85z"
      ></path>
      <path
        fill="#0125C0"
        d="M10.321 7.534H8.508a.782.782 0 01-.2-1.537.948.948 0 01.22-.027 688.3 688.3 0 012.676-.005c.37 0 .671.218.77.56.023.083.036.17.035.255-.021 1.137-.42 2.122-1.209 2.941a4.088 4.088 0 01-2.18 1.209c-1.438.286-2.713-.06-3.801-1.042a4.08 4.08 0 01-1.334-2.506C3.163 5.158 4.52 3.178 6.54 2.631c1.207-.328 2.357-.175 3.435.472a.778.778 0 01.1 1.293.797.797 0 01-.886.066c-.168-.093-.337-.189-.516-.256a2.716 2.716 0 00-3.62 2.04 2.717 2.717 0 005.237 1.395c.012-.03.02-.064.032-.107z"
      ></path>
      <path
        fill="#57BBFD"
        d="M16.998 6.643c.004 1.46-.276 2.74-.834 3.96-.054.117-.107.235-.168.349a.774.774 0 01-1.205.227c-.267-.225-.34-.629-.17-.957.198-.384.357-.784.485-1.197.137-.447.231-.902.287-1.366a7.684 7.684 0 00-.594-4.01c-.06-.136-.128-.268-.19-.404a.771.771 0 01.378-1.041.765.765 0 01.985.305c.192.338.338.7.472 1.064a9.05 9.05 0 01.47 1.877 7.6 7.6 0 01.084 1.193zM14.494 6.64c-.004 1.088-.204 2.027-.61 2.922a.778.778 0 11-1.424-.632c.209-.452.35-.924.42-1.415.057-.392.069-.787.037-1.184a5.4 5.4 0 00-.457-1.766.764.764 0 01.508-1.061c.381-.101.745.073.892.397.13.284.241.578.339.876.139.421.217.857.261 1.298.023.224.027.448.034.564z"
      ></path>
    </svg>
  );
}

export default GCashSmIcon;