import { useNavigate } from 'react-router-dom';
import { imageUrl } from 'src/helpers/url';
import useAuth from 'src/hooks/useAuth';

const HomePage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            <div className="flex justify-center bg-uwi-primary-100 pb-[50px]">
                <img
                    src={imageUrl('/widget-banner.svg')}
                    className="w-[300px] h-[203.82px] xs:w-[400px] xs:h-[271.75px] sm:w-[450px] sm:h-[305.72px] lg:w-[524px] lg:h-[356px] max-w-[524px] max-h-[356px]"
                    alt="Get A Friendly Home Loan"
                />
            </div>
            <div
                className={`
                    bg-uwi-content-100 text-black
                    rounded-t-3xl
                    h-[calc(100vh-410px)] xs:h-[calc(100vh-477px)] sm:h-[calc(100vh-511px)] md:h-[256px] lg:h-[191px]
                    shadow-[0px_-4px_20px_1px_rgba(0,0,0,0.1)]
                `}
            >
                <div className="flex items-center justify-center flex-col sm:flex-row !mb-[35px] !pt-[50px]">
                    <button
                        className={`
                            rounded-2xl
                            py-[30px] px-[50px]
                            font-bold text-[15px]
                            bg-uwi-primary-100 hover:bg-uwi-primary-200 text-white
                        `}
                        onClick={() => {
                            navigate('/pre-qualification');
                        }}
                    >
                        Take Home Loan Assessment now
                    </button>
                </div>

                {(!user) && (
                    <div className="text-center text-[15px]">
                        Already have an account?
                        <button
                            className="ml-1 font-bold text-uwi-primary-100 hover:text-uwi-primary-200 hover:bg-transparent"
                            onClick={() => {
                                navigate('/login');
                            }}
                        >
                            Login
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default HomePage;
