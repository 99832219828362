import { useEffect, useState } from 'react';

type Props = {
    onChange?: (val: boolean) => void;
    value?: boolean;
};

const YesNoField = ({
    onChange,
    value
}: Props) => {
    const [isYes, setIsYes] = useState(false);

    useEffect(() => {
        if (value) {
            setIsYes(value);
        }
    }, [value]);

    const handleChange = onChange || (() => {});

    return (
        <div className="w-full flex">
            <button
                className={`
                    w-[calc(50%-5px)] form-btn-field mr-2.5
                    ${(isYes) ? 'bg-uwi-primary-100 focus:bg-uwi-primary-200 hover:bg-uwi-primary-200 text-white' : 'text-gray-100 hover:bg-uwi-primary-100 focus:bg-uwi-primary-100 hover:text-white'}
                `}
                onClick={() => {
                    setIsYes(true);
                    handleChange(true);
                }}
            >
                Yes
            </button>

            <button
                className={`
                    w-[calc(50%-5px)] form-btn-field
                    ${(!isYes) ? 'bg-uwi-primary-100 focus:bg-uwi-primary-200 hover:bg-uwi-primary-200 text-white' : 'text-gray-100 hover:bg-uwi-primary-100 focus:bg-uwi-primary-100 hover:text-white'}
                `}
                onClick={() => {
                    setIsYes(false);
                    handleChange(false);
                }}
            >
                No
            </button>
        </div>
    );
}

export default YesNoField;
