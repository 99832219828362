import { AngleDownSolidIcon } from 'src/components/icons';
import { useAppSelector } from 'src/hooks/generic';
import {
    selectProjectStatuses,
} from 'src/store/features/preApproveLoanStaticDataSlice';

type Props = {
    open: boolean;
    selectedProjectStatuses: string[];
    onToggle?: () => void;
    className?: string;
    onChange?: (projectStatuses: string[]) => void;
};

const ProjectStatusFilter = ({
    className,
    onChange,
    open,
    onToggle,
    selectedProjectStatuses
}: Props) => {
    const projectStatuses = useAppSelector(selectProjectStatuses);

    const handleChange = onChange || (() => {});
    const handleToggle = onToggle || (() => {});

    const handleChangeProjectStatus = (isChecked: boolean, projecStatus: string) => {
        const projStatuses = selectedProjectStatuses.filter(projStatus => (projStatus !== projecStatus));
        if (isChecked) {
            projStatuses.push(projecStatus)
        }

        handleChange(projStatuses);
    };

    return (
        <div
            className={`
                mt-4 pt-4 border-t border-solid border-gray-50
                ${className || ''}
            `}
        >
            <h3
                className="text-xs font-bold font-arial m-0 p-0 flex justify-between items-center cursor-pointer"
                onClick={() => handleToggle()}
            >
                <div>Project Status</div>
                <div>
                    <button
                        className="hover:bg-transparent focus:bg-transparent hover:text-uwi-primary-100"
                        onClick={() => handleToggle()}
                    >
                        <AngleDownSolidIcon
                            className={`
                                w-4 h-4
                                ${(open) ? 'rotate-180' : ''}
                            `}
                        />
                    </button>
                </div>
            </h3>
            <ul
                className={`
                    overflow-hidden transition-all 
                    ${(open) ? 'max-h-screen' : 'max-h-0'}
                `}
            >
                {projectStatuses.map(projectStatus => (
                    <li
                        key={projectStatus}
                        className="whitespace-nowrap overflow-hidden text-sm py-[5px] flex items-center justify-start"
                    >
                        <input
                            id={`project_status_filter_${projectStatus}`}
                            type="checkbox"
                            className="m-0 mr-2 uwi-input cursor-pointer"
                            onChange={(e) => handleChangeProjectStatus(e.target.checked, projectStatus)}
                            checked={(selectedProjectStatuses.includes(projectStatus))}
                        />
                        <label htmlFor={`project_status_filter_${projectStatus}`} className="form-label m-0 text-gray-50 cursor-pointer pointer-events-auto">{projectStatus}</label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ProjectStatusFilter;
