import { useContext, useEffect, useMemo, useState } from 'react';
import { FormFieldWrapper, Multiselect, Select, SelectOption } from 'src/components/common';
import { hasProp } from 'src/helpers/object';
import { useAppDispatch, useAppSelector } from 'src/hooks/generic';
import {
    selectProjectCost,
    selectTotalSubProjectsCost
} from 'src/store/features/preApproveLoanPropertyInfoSlice';
import {
    setLoanTerm as setLoanTermAct,
    setPercentOfProjectCost as setPercentOfProjectCostAct,
    setOwnedBanks as setOwnedBanksAct,
    selectLoanTerm,
    selectPercentOfDownpayment,
    setPercentOfDownpayment,
    selectOwnedBanks
} from 'src/store/features/preApproveLoanLoanInfoSlice';
import PreApprovalFormGroup from './PreApprovalFormGroup';
import { maxLoanTerms } from '../data/loanInfoData';
import useLoanInfoValidation from '../validations/useLoanInfoValidation';
import FieldGroupBottomButtons from './FieldGroupBottomButtons';
import useValidation from './../validations/useValidation';
import PreApproveContext from '../PreQualificationContext';
import { paginationItemKeys } from './../data/paginationItemsData';
import { selectWidgetType } from 'src/store/features/commonSlice';
import Container from 'typedi';
import { LenderApi } from 'src/api/LenderApi';
import { MultiOption } from 'src/components/common/Multiselect';
import CheckBox from '../fields/CheckBox';

const certs = [
    "I certify that the information I provided are true and correct.",
    "I certify that I have no pending legal case nor I am subjected to the final decision of courts.",
    "I certify that I have no history of a default check, have not defaulted on loans, credit card debt, and other forms of debt.",
    "I certify that I have no past due accounts with telecommunication companies and manufacturing companies (if applicable)"
];

const LoanInfo = () => {
    const {
        formErrors,
        setFormErrors,
        validateForm
    } = useLoanInfoValidation();
    const { validate } = useValidation();
    const { setPage } = useContext(PreApproveContext);

    const dispatch = useAppDispatch();

    const widgetType = useAppSelector(selectWidgetType);
    const totalSubProjectCost = useAppSelector(selectTotalSubProjectsCost);
    const projectCost = useAppSelector(selectProjectCost);
    const loanTerm = useAppSelector(selectLoanTerm);
    const percentOfDownpayment = useAppSelector(selectPercentOfDownpayment)
    const ownedBanks = useAppSelector(selectOwnedBanks);
    const [isChecked, setIsChecked] = useState<boolean[]>([]);
    const [isCheckedError, setIsCheckedError] = useState<boolean[]>([]);

    const [bankOptions, setBankOptions] = useState<MultiOption[]>([]);

    useEffect(() => {
        const checkedList : boolean[] = [];
        const errList : boolean[] = [];
        certs.forEach((cert, index) => {
            checkedList[index] = false;
            errList[index] = false;
        });

        setIsChecked(checkedList);
        setIsCheckedError(errList);

        Container
            .get(LenderApi)
            .list()
            .then(banks => {
                const bankItems = banks.map(bank => ({
                    id: bank.id,
                    text: bank.shortName || bank.name
                }));
                setBankOptions(bankItems);
            });
    }, []);

    const calculatedProjectCost = useMemo(() => {
        return (widgetType === 'advance') ? totalSubProjectCost : projectCost;
    }, [
        totalSubProjectCost,
        projectCost,
        widgetType
    ]);

    const calculatedDownpaymentAmount = useMemo(() => {
        return (calculatedProjectCost * (percentOfDownpayment / 100));
    }, [percentOfDownpayment, calculatedProjectCost]);

    const calculatedLoanableAmount = useMemo(() => {
        return calculatedProjectCost - calculatedDownpaymentAmount;
    }, [calculatedDownpaymentAmount, calculatedProjectCost]);

    const handleValidateForm = async () => {
        const errorList = {...isCheckedError};
        setIsCheckedError(errorList);
        let hasCheckError = false;
        certs.forEach((cert, index) => {
            errorList[index] = !isChecked[index];
            setIsCheckedError(errorList);

            if (!isChecked[index]) {
                hasCheckError = true;
            }
        });

        if (hasCheckError) {
            return false;
        }

        const isValidForm = await validateForm();
        if (!isValidForm) {
            return false;
        }

        const [isValid, tab] = await validate();
        if (!isValid) {
            if (paginationItemKeys.includes(tab as string)) {
                const page = paginationItemKeys.indexOf(tab as string);
                setPage(page);
                return false;
            }
            else if (tab === 'projectInfoScreen2') {
                setPage(0.5);
                return false;
            }
        }

        return true;
    };

    const handlePercentProjectDownpaymentChange = (val: number) => {
        dispatch(setPercentOfDownpayment(val));
        const { percentOfProjectCost, ...others } = formErrors;
        dispatch(setFormErrors(others));
        dispatch(setPercentOfProjectCostAct(100 - val));
    };

    return (
        <section className='px-4 md:px-10'>
            <div className='flex w-full justify-center'>
                <div className="flex flex-col gap-3  sm:flex-row justify-center w-[80%]">
                    <div className="w-full flex flex-col items-center">
                        <div className="w-full ">
                            <PreApprovalFormGroup
                                label="Preferred Bank/s (Leave blank if none)"
                                tooltipText="Your preferred specific financial institution or bank(s) as selected lender(s)."
                                showTooltip={true}
                            >
                                <FormFieldWrapper
                                    showError={hasProp(formErrors, 'ownedBanks')}
                                    errorMessage={(hasProp(formErrors, 'ownedBanks')) ? formErrors.ownedBanks : ''}
                                    className="w-full"
                                >
                                    <Multiselect
                                        placeholder="Select Bank"
                                        options={bankOptions}
                                        value={ownedBanks}
                                        onChange={(val) => {
                                            dispatch(setOwnedBanksAct(val as string[]));
                                            const { ownedBanks, ...others } = formErrors;
                                            dispatch(setFormErrors(others));
                                        }}
                                    ></Multiselect>
                                </FormFieldWrapper>
                            </PreApprovalFormGroup>

                            <PreApprovalFormGroup
                                label="Loan Term"
                                tooltipText="Specifies the duration, in years, for which the loan agreement is set to be repaid."
                                className="z-[3]"
                                showTooltip={true}
                            >
                                <Select
                                    className={`
                                        w-full
                                        ${(hasProp(formErrors, 'loanTerm')) ? '!border-red-200' : ''}
                                    `}
                                    placeholder="Please Select"
                                    value={loanTerm}
                                    onChange={(val) => {
                                        dispatch(setLoanTermAct(val as number));
                                        const { loanTerm, ...others } = formErrors;
                                        dispatch(setFormErrors(others));
                                    }}
                                >
                                    {(new Array(maxLoanTerms)).fill(1).map((val, idx) => (
                                        <SelectOption
                                            key={idx}
                                            value={(idx + 1)}
                                        >
                                            {`${idx + 1} ${((idx + 1) > 1) ? 'Years' : 'Year'}`}
                                        </SelectOption>
                                    ))}
                                </Select>
                            </PreApprovalFormGroup>

                            <PreApprovalFormGroup
                                label='Downpayment Percentage'
                                tooltipText="This percentage represents the initial payment made at the time of acquisition."
                                showTooltip={true}
                            >
                                <div className='w-full flex flex-col items-center'>
                                    <Select
                                        onChange={(val) => handlePercentProjectDownpaymentChange(val as number)}
                                        className={'w-full'}
                                        value={percentOfDownpayment}
                                    >
                                        {(new Array(20)).fill(1).map((val, idx) => (
                                            <SelectOption
                                                key={idx}
                                                value={((idx + 1) * 5)}
                                            >
                                                {`${(idx + 1) * 5}%`}
                                            </SelectOption>
                                        ))}
                                    </Select>
                                </div>
                            </PreApprovalFormGroup>
                        </div>
                    </div>
                    <div className=' w-full pb-4'>
                        <section className='flex flex-col rounded-xl bg-white h-full mb-2 drop-shadow-md'>
                           <div className='flex justify-center items-center w-full h-1/2'>
                               <section className='my-4'>
                                    <h1 className='text-[0.875rem] text-center '>Downpayment Amount</h1>
                                    <p className='text-center mt-2 text-uwi-primary-100'>
                                        <span className='text-[1.25rem]'>₱</span>
                                        <span className='text-[1.875rem] font-semibold'>{calculatedDownpaymentAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                                    </p>
                               </section>
                           </div>
                           <div className='flex justify-center '>
                                <hr className='w-[90%]'/>
                           </div>
                           <div className='flex justify-center items-center w-full h-1/2'>
                                <section className='my-4 mx-3'>
                                    <h1 className='text-[0.875rem] text-center'>Loanable Amount</h1>
                                    <p className='text-center mt-2 text-uwi-primary-100'>
                                        <span className='text-[1.25rem]'>₱</span>
                                        <span className='text-[1.875rem] font-semibold'>{calculatedLoanableAmount.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                                    </p>
                               </section>
                           </div>
                        </section>
                    </div>
                </div>
            </div>

            <div className="flex w-full items-center justify-center text-xs mb-4 flex-col py-4">
                {certs.map((cert, index) => (
                    <CheckBox
                        label={cert}
                        isError={isCheckedError[index]}
                        isChecked={isChecked[index]}
                        onChecked={(checked) => {
                            const list = {...isChecked};
                            list[index] = checked;
                            setIsChecked(list);
                        }}
                        index={index}
                    />
                ))}
            </div>

            <div className="w-full text-center text-xs text-gray-50 mb-4">*all fields are required</div>

            <FieldGroupBottomButtons
                onNext={() => handleValidateForm()}
            />
        </section>


    );
}

export default LoanInfo;
