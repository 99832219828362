type Props = {
  className?: string;
};

const UnionBankIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="64"
      fill="none"
      viewBox="0 0 113 27"
      className={className}
    >
      <path
        fill="#262626"
        fillRule="evenodd"
        d="M30.62 16.958V11.15h2.87V16.9c0 1.493.748 2.202 1.89 2.202 1.157 0 1.904-.68 1.904-2.128V11.15h2.872v5.734c0 3.325-1.89 4.803-4.805 4.803-2.9 0-4.731-1.492-4.731-4.729zM41.897 13.5h2.783v1.123c.513-.665 1.245-1.3 2.373-1.3 1.684 0 2.695 1.123 2.695 2.94v5.232H46.98v-4.33c0-.887-.454-1.36-1.128-1.36-.674 0-1.172.473-1.172 1.36v4.33h-2.783V13.5z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0"
        width="113"
        height="27"
        x="0"
        y="0"
        fill="none"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#FFF" d="M0 27h112.709V0H0v27z"></path>
      </mask>
      <g fillRule="evenodd" clipRule="evenodd" mask="url(#mask0)">
        <path
          fill="#4C4C4E"
          d="M51.475 12.671h2.768v-1.964h-2.768v1.964z"
        ></path>
        <path
          fill="#262626"
          d="M51.475 21.495h2.768V13.5h-2.768v7.995zM61.772 17.534v-.03c0-.99-.718-1.817-1.7-1.817-1.01 0-1.655.813-1.655 1.789v.029c0 .975.703 1.803 1.685 1.803 1.01 0 1.67-.813 1.67-1.774zm-6.05 0v-.03c0-2.275 1.846-4.182 4.38-4.182s4.365 1.877 4.365 4.154v.029c0 2.276-1.846 4.182-4.394 4.182-2.52 0-4.35-1.877-4.35-4.153zM65.856 13.5h2.784v1.123c.512-.665 1.245-1.3 2.373-1.3 1.684 0 2.695 1.123 2.695 2.94v5.232H70.94v-4.33c0-.887-.454-1.36-1.128-1.36-.674 0-1.172.473-1.172 1.36v4.33h-2.784V13.5zM80.031 17.283h-1.74v1.907h1.77c.879 0 1.362-.34 1.362-.946v-.03c0-.562-.44-.93-1.392-.93zm.982-2.94c0-.577-.454-.887-1.29-.887h-1.432v1.817h1.36c.864 0 1.362-.295 1.362-.901v-.03zm-5.49-3.193h4.88c1.084 0 1.948.334 2.567.905.71.655 1.058 1.956.657 2.992-.194.503-.608.824-1.098 1.04 1.244.378 1.997 1.122 1.997 2.526v.03c0 1.773-1.435 2.852-3.882 2.852h-5.121V11.15zM90.71 18.598v-.369a3.038 3.038 0 00-1.098-.207c-.805 0-1.274.385-1.274.99v.03c0 .547.41.872.981.872.82 0 1.392-.517 1.392-1.316zm-5.038.607v-.03c0-1.67 1.245-2.513 3.106-2.513.732 0 1.45.134 1.904.31v-.132c0-.842-.513-1.315-1.583-1.315-.834 0-1.479.177-2.197.443l-.57-1.965c.892-.37 1.845-.621 3.207-.621 1.421 0 2.388.34 3.018.975.6.591.85 1.419.85 2.542v4.596h-2.74v-.842c-.557.606-1.303 1.02-2.344 1.02-1.509 0-2.651-.887-2.651-2.468zM95.045 13.5h2.783v1.123c.513-.665 1.246-1.3 2.373-1.3 1.685 0 2.696 1.123 2.696 2.94v5.232h-2.769v-4.33c0-.887-.454-1.36-1.128-1.36-.674 0-1.172.473-1.172 1.36v4.33h-2.783V13.5zM104.55 10.707h2.783v5.468l2.153-2.675h3.15l-2.754 3.192 2.827 4.803h-3.076l-1.582-2.734-.718.843v1.891h-2.783V10.706z"
        ></path>
        <path
          fill="#FF8000"
          d="M17.48 14.213c-1.136-.147-1.63-.207-1.63-.207V9.964c.28.07 2.127.518 2.127.518 1.13.224 1.706 1.047 1.706 2.032 0 .986-.878 1.87-2.204 1.699zm4.555 1.67s1.31-1.302 1.31-3.756c0-2.048-1.38-4.318-3.812-5.172L11.54 4.119l-.026 13.87s.003 2.009-2.238 2.433c-1.5.285-2.962-1.073-2.962-2.76.002 0 .008-10.932.002-15.412L0 0v18.26c0 6.522 5.918 9.281 9.553 8.652 4.34-.75 6.297-4.104 6.297-7.732v-1.288l3.061.104c1.013 0 1.766.883 1.766 1.809 0 .82-.573 1.704-1.753 1.806l-1.683.129c-.27 1.107-.854 2.636-2.166 4.164l4.93-.872c1.985-.36 4.293-2.083 4.293-4.93 0-1.213-.41-2.989-2.263-4.218z"
        ></path>
      </g>
    </svg>
  );
}

export default UnionBankIcon;