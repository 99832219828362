import 'reflect-metadata';
import { Service } from 'typedi';
import BaseApi from './BaseApi';
import { Project } from './ProjectApi';

export type CTAPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'center-left' | 'center-right' | 'none';
export type DeveloperWidgetType = 'basic' | 'advance';

export interface Agent {
    id: string;
    name: string;
}

export interface Widget {
    css: string;
    position: CTAPosition;
    theme: string;
    widgetType: DeveloperWidgetType;
    projects?: Project[];
    projectTypes?: string[];
    projectStatuses?: string[];
    cities?: string[];
    minPrice?: number;
    maxPrice?: number;
    contactEmail: string;
    hasBookingFeature: boolean;
    isAgentSelectionEnabled?: boolean;
    agents: Agent[];
    hasReservationPaymentsFeature: boolean;
    hasXenditPayment: boolean;
    hasBuyerPortal: boolean;
}

@Service()
export class WidgetApi extends BaseApi {
    /**
     * API path for getting the css theme
     */
    static readonly PATH_GET_CSS_THEME = '/v1/widget/load/$developerClientId';

    load(developerClientId: string): Promise<Widget> {
        return this.http.get(
            WidgetApi.PATH_GET_CSS_THEME.replace(/\$developerClientId/, developerClientId)
        );
    }

}
