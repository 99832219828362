import { useEffect, useState } from 'react';
import { SubProject, SubProjectApi } from 'src/api/SubProjectApi';
import { XMarkSolidIcon } from 'src/components/icons';
import Container from 'typedi';
import {default as ImageSlider, SubProjectImage} from './ImageGallery/Slider';

type Props = {
    className?: string;
    onClose?: () => void;
    visible: boolean;
    subProject: SubProject | null;
};

const ViewImage = ({
    onClose,
    className,
    visible,
    subProject
}: Props) => {
    const handleClose = onClose || (() => {});
    const [current, setCurrent] = useState<number>(0);
    const [images, setImages] = useState<SubProjectImage[]>([]);

    useEffect(() => {
        if (visible) {
            setCurrent(0);

            if(subProject) {
                Container
                    .get(SubProjectApi)
                    .subProjectImages(subProject.id)
                    .then((images) => {
                        setImages(images);
                    })
                    .catch(console.error);
            }
        }
    }, [subProject, visible]);

    const handleOnClose = () => {
        setImages([]);
        handleClose();
    }

    return (
        <>
            {(visible) && (
                <div
                    className={`
                        absolute top-0 left-0
                        w-full
                        md:pl-5 bg-white z-[10]
                        flex
                        flex-wrap md:flex-nowrap
                        flex-col-reverse md:flex-row
                        justify-center
                        h-[calc(100vh-258px)] md:h-[500px] lg:h-[430px]
                        ${className || ''}
                    `}
                >
                    <ImageSlider
                        subProject={subProject}
                        images={images}
                        currentVal={current}
                        current={(val) => setCurrent(val)
                    }/>

                    <div
                        className="absolute right-0 top-0">
                        <button
                            type="button"
                            className="text-black hover:text-uwi-primary-200 hover:bg-transparent focus:bg-transparent"
                            onClick={() => handleOnClose()}
                        >
                            <XMarkSolidIcon className="w-6 h-6"/>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ViewImage;
